import { Column } from 'react-table'
import { VinculosActionsCell } from './VinculosActionsCell'
import { CustomHeader } from '../../../../commons/table/CustomHeader'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { Vinculos } from '../../core/_models'

const vinculosColumns: ReadonlyArray<Column<Vinculos>> = [
  {
    Header: (props) => (
      <CustomHeader
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title='Especialidade'
        isAbleToSort={false}
      />
    ),
    id: 'especialidade.nome',
    accessor: (row) => row.especialidade?.nome,
  },
  {
    Header: (props) => (
      <CustomHeader
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title='Empresas'
        isAbleToSort={false}
      />
    ),
    id: 'empresa.nome',
    accessor: (row) => {
      const mainEmpresa = row.empresas[0]?.nomeFantasia || row.empresas[0]?.nome || 'N/A'
      const additionalCount = row.empresas.length > 1 ? ` +${row.empresas.length - 1}` : ''
      return `${mainEmpresa}${additionalCount}`
    },
  },
  {
    Header: (props) => (
      <CustomHeader
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title='Ações'
        className='text-end min-w-100px'
      />
    ),
    id: 'actions',
    Cell: ({ ...props }) => <VinculosActionsCell row={props.row} />,
  },
]

export { vinculosColumns }
