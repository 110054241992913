import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { RelatorioListWrapper } from './relatorio-list/RelatorioList'

const usersBreadcrumbs: Array<PageLink> = [
  /* {
    title: 'Relatórios',
    path: '/apps/relatorios/atendimentos',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  }, */
]

const RelatorioPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/atendimentos'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Relatório de atendimentos</PageTitle>
              <RelatorioListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/apps/relatorios/atendimentos' />} />
    </Routes>
  )
}

export default RelatorioPage
