import { useQuery } from 'react-query'
import { isNotEmpty, QUERIES } from '../../../../../../_metronic/helpers'
import { useListView } from '../core/ListViewProvider'
import { getAtendimentoById } from '../../../atendimento/core/_requests'
import { AtendimentoMedicoEditModalForm } from './AtendimentoMedicoEditModalForm'

const AtendimentoMedicoEditModalFormWrapper = () => {
  const { itemIdForUpdate, setItemIdForUpdate } = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: atendimentoMedico,
    error,
  } = useQuery(
    `${QUERIES.ATENDIMENTO_MEDICO_LIST}-atendimentoMedico-${itemIdForUpdate}`,
    () => {
      return getAtendimentoById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return <AtendimentoMedicoEditModalForm atendimentoMedico={{ id: undefined }} />
  }

  if (!isLoading && !error && atendimentoMedico) {
    return <AtendimentoMedicoEditModalForm atendimentoMedico={atendimentoMedico} />
  }

  return null
}

export { AtendimentoMedicoEditModalFormWrapper }
