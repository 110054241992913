import React, { useEffect, useState } from 'react'

interface InputProps {
  type?: 'text' | 'number'
  name?: string
  placeholder?: string
  value?: string | number | undefined
  required?: boolean
  className?: string
  disabled?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void
  isValid?: boolean
  isInvalid?: boolean
  errorMessage?: string
}

export const Input: React.FC<InputProps> = ({
  type = 'text',
  name,
  placeholder = 'Preencha o campo',
  value: initialValue,
  required = false,
  className = '',
  disabled = false,
  onChange,
  onBlur,
  isValid,
  isInvalid,
  errorMessage,
  ...props
}) => {
  const [value, setValue] = useState(initialValue)

  useEffect(() => setValue(initialValue), [initialValue])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
    if (onChange) onChange(e)
  }

  return (
    <div className='w-100'>
      <input
        {...props}
        className={`form-control form-control-solid w-100 ${className} ${
          isInvalid ? 'is-invalid' : isValid ? 'is-valid' : ''
        }`}
        type={type}
        name={name}
        placeholder={placeholder}
        value={value}
        required={required}
        disabled={disabled}
        onChange={handleChange}
        onBlur={onBlur}
      />
      {isInvalid && errorMessage && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>
            <span role='alert'>{errorMessage}</span>
          </div>
        </div>
      )}
    </div>
  )
}
