import { FC, useEffect, useRef, useState } from 'react'

import { MenuComponent } from '../../../../../../../_metronic/assets/ts/components'
import { Button, CustomModal } from '../../../../../../components'
import Swal from 'sweetalert2'
import { useAuth } from '../../../../../auth'
import {
  alertCertificado,
  FormularioSituacao,
  IconPaths,
  IconSize,
  ModalSize,
  showAlert,
} from '../../../../../../../_metronic/helpers'
import { signAvaliacao } from '../../core/_requests'
import { getFormularioAtendimento } from '../../../../atendimento/core/_requests'
import { FormularioAtendimentoList } from '../../core/_models'
import { FormularioModal } from '../../components/modal/FormularioModal'
import { AxiosError } from 'axios'
import { FormularioRespostaList } from '../../../../atendimento_medicos/atendimento-video/core/_models'

type Props = {
  formularioAtendimento: FormularioAtendimentoList
}

export const AvaliacoesPendentesActionsCell: FC<Props> = ({ formularioAtendimento }) => {
  const modalRef = useRef<{ open: () => void; close: () => void }>(null)

  const user = useAuth().currentUser
  const possuiCertificado = user?.pessoa?.certificadoDigital

  const isMedico = user?.vinculos?.some((vinculo) => vinculo.cargo === 'MEDICO') || false

  const [answers, setAnswers] = useState<FormularioRespostaList[]>()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const handleAssinarAvaliacao = () => {
    if (possuiCertificado) {
      try {
        alertCertificado(signAvaliacao, {
          formularioId: formularioAtendimento.formulario.id,
          atendimentoId: formularioAtendimento.atendimento.id,
        })
        showAlert({
          title: 'Sucesso!',
          text: 'Avaliação assinada.',
          icon: 'success',
        })
      } catch (error) {
        const err = error as AxiosError
        showAlert({
          title: 'Erro ao assinar!',
          text: err.response?.data.data.message,
          icon: 'error',
        })
      }
    } else {
      showAlert({
        title: 'Ausência de certificado!',
        text: 'Informe primeiramente o seu certificado na edição do perfil.',
        icon: 'error',
      })
    }
  }

  const showAvaliacao = () => {
    getFormularioAtendimento(
      formularioAtendimento.atendimento.id,
      formularioAtendimento.formulario.id
    ).then((data) => {
      setAnswers(data.respostas)
      modalRef.current?.open()
    })
  }

  return (
    <>
      <div className='d-flex justify-content-end'>
        {isMedico ? (
          <div className='me-2'>
            <Button
              className='btn-secondary btn-sm me-2'
              onClick={handleAssinarAvaliacao}
              icon={IconPaths.Key}
              iconSize={IconSize.Small}
            >
              Assinar
            </Button>
          </div>
        ) : null}

        {isMedico && formularioAtendimento.situacao !== FormularioSituacao.Assinado ? (
          <Button
            className='btn-secondary btn-sm'
            onClick={showAvaliacao}
            icon={IconPaths.Search}
            iconSize={IconSize.Small}
          >
            Visualizar
          </Button>
        ) : null}
      </div>

      <CustomModal
        ref={modalRef}
        size={ModalSize.Large}
        title={`Avaliação de ${formularioAtendimento.paciente.nome}`}
      >
        <FormularioModal data={answers} />
      </CustomModal>
    </>
  )
}
