import React, { useState, useImperativeHandle, forwardRef } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { Input } from '../../../../../components'
import { ButtonType, ID, showAlert } from '../../../../../../_metronic/helpers'
import { registerPendencia, resolvePendencia } from '../../../atendimento/core/_requests'

interface IAnotacaoModal {
  isRequired: boolean
  onHide?: () => void
  atendimentoId: ID
}

export const AnotacaoModal = forwardRef<{ open: () => void; close: () => void }, IAnotacaoModal>(
  ({ isRequired, onHide, atendimentoId }, ref) => {
    const [anotacao, setAnotacao] = useState<string>('')
    const [show, setShow] = useState<boolean>(false)

    const close = () => {
      setShow(false)
      onHide && onHide()
    }

    const open = () => setShow(true)

    useImperativeHandle(ref, () => ({
      open,
      close,
    }))

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      if (!isRequired) {
        resolvePendencia(anotacao, atendimentoId).then(() => {
          showAlert({
            title: 'Resolvido',
            text: 'Pendência resolvida com sucesso!',
            icon: 'success',
          }).then(() => close())
        })
      } else {
        registerPendencia(anotacao, atendimentoId).then(() => {
          showAlert({
            title: 'Pendência Adicionada',
            text: 'Pendência adicionada com sucesso!',
            icon: 'success',
          }).then(() => close())
        })
      }
      setAnotacao('')
    }

    return (
      <Modal className='modal-lg' show={show} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>{isRequired ? 'Adicionar Pendência' : 'Resolver Pendência'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={(e) => handleSubmit(e)}>
            <Input
              placeholder='Digite a pendência'
              onChange={(e) => setAnotacao(e.target.value)}
              value={anotacao}
              required={isRequired}
            />
            <div className='d-flex justify-content-end pt-3'>
              <Button type={ButtonType.Submit} className='btn-primary'>
                Salvar
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    )
  }
)
