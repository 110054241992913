import { createContext, Dispatch, SetStateAction, useEffect, useState } from 'react'
import qs from 'qs'
import {
  ID,
  QueryResponseContextProps,
  QueryResponseContextPropsSemPaginacao,
  QueryState,
  QueryStateEspecialidade,
} from './models'
import { downloadAnexo } from '../../../app/modules/apps/atendimento/core/_requests'
import Swal from 'sweetalert2'
import { IAvaliacoesSelect } from '../../../app/modules/apps/avaliacoes/avaliacoes-pendentes-list/core/_models'
import { QueryStateAvaliacoes } from '../../../app/modules/apps/avaliacoes/avaliacoes-concluidas-list/core/_models'
import { QueryStateRelatorios } from '../../../app/modules/apps/relatorios/relatorio-list/core/_models'

type QueryStateUnion = QueryState | QueryStateAvaliacoes | QueryStateEspecialidade

function createResponseContext<T>(initialState: QueryResponseContextProps<T>) {
  return createContext(initialState)
}

function createResponseContextSemPaginacao<T>(
  initialState: QueryResponseContextPropsSemPaginacao<T>
) {
  return createContext(initialState)
}

function isNotEmpty(obj: unknown) {
  return obj !== undefined && obj !== null && obj !== ''
}

// Example: page=1&size=10&sort=id&order=desc&search=a&filter_name=a&filter_online=false
function stringifyRequestQuery(state: QueryStateUnion): string {
  const pagination = qs.stringify(state, { filter: ['page', 'size'], skipNulls: true })
  const sort = state.sort ? `sort=${state.sort}%2C${state.order ?? ''}` : null
  const search = isNotEmpty(state.search)
    ? qs.stringify(state, { filter: ['search'], skipNulls: true })
    : ''

  const filter = state.filter
    ? Object.entries(state.filter as object)
        .filter((obj) => isNotEmpty(obj[1]))
        .map((obj) => `filter_${obj[0]}=${obj[1] ?? ''}`) // Ensures value is a string
        .join('&')
    : ''

  const extra = [
    (state as QueryStateAvaliacoes).concluidos != null
      ? `concluidos=${(state as QueryStateAvaliacoes).concluidos}`
      : null,
    (state as QueryStateEspecialidade).tipo
      ? `tipo=${encodeURIComponent((state as QueryStateEspecialidade).tipo ?? '')}`
      : null,
  ]
    .filter(Boolean)
    .join('&')

  return [pagination, sort, search, filter, extra].filter(Boolean).join('&')
}

export function stringifyRequestQueryResumo(state: QueryState): string {
  const newState = { ...state, search: state.search?.replace(/estado:[^,]*,?/, '') } // remover estado

  const pagination = qs.stringify(newState, { filter: ['page', 'size'], skipNulls: true })
  const sort = newState.sort ? `sort=${newState.sort}%2C${newState.order ?? ''}` : null
  const search = isNotEmpty(newState.search)
    ? qs.stringify(newState, { filter: ['search'], skipNulls: true })
    : ''

  const filter = newState.filter
    ? Object.entries(newState.filter as object)
        .filter((obj) => isNotEmpty(obj[1]))
        .map((obj) => `filter_${obj[0]}=${obj[1] ?? ''}`)
        .join('&')
    : ''

  return [pagination, sort, search, filter].filter(Boolean).join('&')
}

export const stringifyRequestQuerySemPaginacao = (state: QueryStateRelatorios): string => {
  const sort = state.sort ? `sort=${state.sort}%2C${state.order}` : null
  const search = isNotEmpty(state.search)
    ? qs.stringify(state, { filter: ['search'], skipNulls: true })
    : ''
  return [search, sort].filter((f) => f).join('&')
}

function parseRequestQuery(query: string): QueryState {
  const cache: unknown = qs.parse(query)
  return cache as QueryState
}

function calculatedGroupingIsDisabled<T>(isLoading: boolean, data: Array<T> | undefined): boolean {
  if (isLoading) {
    return true
  }

  return !data || !data.length
}

function calculateIsAllDataSelected<T>(
  data: Array<T> | undefined,
  selected: Array<ID | IAvaliacoesSelect>
): boolean {
  if (!data) {
    return false
  }

  return data.length > 0 && data.length === selected.length
}

function groupingOnSelect(
  id: ID,
  selected: Array<ID>,
  setSelected: Dispatch<SetStateAction<Array<ID>>>
) {
  if (!id) {
    return
  }

  if (selected.includes(id)) {
    setSelected(selected.filter((itemId) => itemId !== id))
  } else {
    const updatedSelected = [...selected]
    updatedSelected.push(id)
    setSelected(updatedSelected)
  }
}

function groupingOnSelectAll<T>(
  isAllSelected: boolean,
  setSelected: Dispatch<SetStateAction<Array<ID>>>,
  data?: Array<T & { id?: ID }>
) {
  if (isAllSelected) {
    setSelected([])
    return
  }

  if (!data || !data.length) {
    return
  }

  setSelected(data.filter((item) => item.id).map((item) => item.id))
}

// Hook
function useDebounce(value: string | undefined, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value)
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay] // Only re-call effect if value or delay changes
  )
  return debouncedValue
}
function getParameterByName(name: string, url: string) {
  name = name.replace(/[\[\]]/g, '\\$&')
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export function getArquivoAnexo(idAtendimento: ID, idAnexo: ID) {
  downloadAnexo(idAtendimento, idAnexo)
    .then((anexo) => {
      if (anexo !== undefined) {
        var a = document.createElement('a') //Create <a>
        a.href = 'data:application/pdf;base64,' + (anexo !== undefined ? anexo.contentBase64 : '') //Image Base64 Goes here
        a.download = String(anexo.name) //File name Here
        a.click()
      }
    })
    .catch((error) => {
      Swal.fire({
        text: 'Não foi possível realizar o download',
        icon: 'error',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    })
}
export {
  createResponseContext,
  stringifyRequestQuery,
  parseRequestQuery,
  calculatedGroupingIsDisabled,
  calculateIsAllDataSelected,
  groupingOnSelect,
  groupingOnSelectAll,
  useDebounce,
  isNotEmpty,
  getParameterByName,
  createResponseContextSemPaginacao,
}
