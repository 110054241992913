/* eslint-disable jsx-a11y/anchor-is-valid */

import { FC, useState } from 'react'
import { AsideMenuAtendimento } from './aside/AsideMenuAtendimento'
import { AtendimentoView } from '../../atendimento/core/_models'
import { useSearchParams } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { useLayout } from '../../../../../_metronic/layout/core'
import Swal from 'sweetalert2'
import { adicionarAnotacao } from '../../atendimento/core/_requests'
import { useAuth } from '../../../auth'
import { TextEditor } from '../../../../components'

type Props = {
  atendimento: AtendimentoView
}

const VideoAtendimento: FC<Props> = ({ atendimento }) => {
  const { currentUser } = useAuth()
  const { classes } = useLayout()
  const [searchParams] = useSearchParams()
  const [anotacao, setAnotacao] = useState<string>('')
  const [loading, setLoading] = useState(false)
  const idAtendimento = String(searchParams.get('atendimento'))

  function atualizarAnotacao() {
    setLoading(true)
    adicionarAnotacao(atendimento?.id, anotacao, 'ANAMNESE')
      .then((result) => {
        setAnotacao('')
        Swal.fire({
          text: 'Anotação enviada com sucesso!',
          icon: 'success',
          buttonsStyling: false,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        console.error(error)
        Swal.fire({
          text: 'Erro ao enviar a anotação: Anotação em branco',
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      })
  }

  return (
    <div className='d-flex w-100 h-100'>
      <div className='w-300px h-100 m-4'>
        {idAtendimento !== 'null' ? (
          <AsideMenuAtendimento asideMenuCSSClasses={classes.asideMenu} atendimento={atendimento} />
        ) : (
          <></>
        )}
      </div>
      <div className='d-flex flex-column w-100 h-100 justify-content-center'>
        <div className='d-flex  flex-column w-100 h-400px'>
          <div className='row w-100 h-100'>
            <iframe
              style={{ display: 'block' }}
              className='w-100 h-100'
              src={`${toAbsoluteUrl(atendimento.urlSalaVideo as string)}&externalId=${
                currentUser?.id
              }`}
              allow='camera; microphone; fullscreen; speaker; display-capture; autoplay'
            ></iframe>
          </div>
        </div>
        <div className='my-2 mb-3 w-100' style={{ display: 'inline' }}>
          {idAtendimento !== 'null' ? (
            <>
              <p className='fw-bold fs-5'>Registro de Anamnese:</p>
              <TextEditor value={anotacao} onChange={setAnotacao} />
            </>
          ) : (
            <></>
          )}
        </div>
        {idAtendimento !== 'null' ? (
          <div className='row w-100 mt-1' style={{ marginLeft: '2px' }}>
            <button
              type='submit'
              className='right btn btn-primary mb-3'
              disabled={loading}
              onClick={atualizarAnotacao}
            >
              {!loading && 'Enviar'}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Aguarde...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

export { VideoAtendimento }
