import axios, { AxiosResponse } from 'axios'
import { AtendimentoReportQueryResponse, AtendimentoResumo } from './_models'
import { responsivePropType } from 'react-bootstrap/esm/createUtilityClasses'

const API_URL = process.env.REACT_APP_API_URL
const ATENDIMENTO_RESPONSAVEL_URL = `${API_URL}/atendimentos/responsavel`

export const getAtendimentos = (query: string): Promise<AtendimentoReportQueryResponse> => {
  return axios.get(`${ATENDIMENTO_RESPONSAVEL_URL}?${query}`).then((d: AxiosResponse) => d.data)
}

export const getAtendimentosResumo = (query: string): Promise<AtendimentoResumo> => {
  return axios
    .get(`${ATENDIMENTO_RESPONSAVEL_URL}/resumo?${query}`)
    .then((resp: AxiosResponse) => resp.data)
}
