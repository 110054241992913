import { FC } from 'react'
import {
  AnotacaoAtendimentoView,
  DispositivoList,
  ProcedimentoAtendimentoView,
} from '../../../../atendimento/core/_models'
import { Dispositivos, Label, LoadingProgressor } from '../../../../../../components'

type IAtendimentoExtra = {
  isLoading: boolean
  dispositivos: DispositivoList[] | undefined
  retorno: boolean | undefined
  anotacoes: AnotacaoAtendimentoView[] | undefined
  procedimentos: ProcedimentoAtendimentoView[] | undefined
}
export const AtendimentoExtra: FC<IAtendimentoExtra> = ({
  isLoading,
  dispositivos,
  retorno,
  anotacoes,
  procedimentos,
}) => {
  return (
    <div className='col-12 p-4'>
      <div className='row pb-4'>
        <div className='col-12 col-md-8 d-flex align-items-center'>
          <Label className='pe-3'>Sinais -</Label>
          {isLoading ? (
            <LoadingProgressor />
          ) : dispositivos ? (
            <Dispositivos dispositivos={dispositivos} />
          ) : (
            <Label>Sem Sinais</Label>
          )}
        </div>
        <div className='col-12 col-md-4 d-flex  align-items-center '>
          <Label className='pe-3'>Retorno -</Label>
          {isLoading ? <LoadingProgressor /> : <Label>{retorno ? 'Sim' : 'Não'}</Label>}
        </div>
      </div>

      <div className='row pb-4'>
        <div className='col-12'>
          <div className='row'>
            <div className='col-12'>
              <Label className='pe-3'>Anotações -</Label>
            </div>
          </div>
          <div className='row ps-5 pt-2'>
            {isLoading ? (
              <LoadingProgressor />
            ) : anotacoes && anotacoes.length > 0 ? (
              <>
                {anotacoes
                  .filter((anotacao) => anotacao.tipo === 'PENDENCIA')
                  .map((anotacao, index) => (
                    <div key={index} className='col-12'>
                      <Label>{anotacao.dataFormatada} - </Label>
                      <Label>{anotacao.usuario?.nome} - </Label>
                      <Label>{anotacao.anotacao}</Label>
                    </div>
                  ))}
              </>
            ) : (
              <Label className='pe-3'>Sem Anotações</Label>
            )}
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-12'>
          <Label className='pe-3'>Procedimentos -</Label>
          {isLoading ? (
            <LoadingProgressor />
          ) : procedimentos && procedimentos.length > 0 ? (
            <div>
              {procedimentos.map((procedimento, index) => (
                <div key={index} className='d-inline-block'>
                  <Label>{procedimento.nome}</Label>
                  {index < procedimentos.length - 1 && <span>, </span>}{' '}
                </div>
              ))}
            </div>
          ) : (
            <Label>Sem Procedimentos</Label>
          )}
        </div>
      </div>
    </div>
  )
}
