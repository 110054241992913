import { useListView } from '../../core/ListViewProvider'
import { SetorClienteListGrouping } from './SetorClienteListGrouping'
import { SetorClienteListSearchComponent } from './SetorClienteListSearchComponent'
import { SetorClienteListToolbar } from './SetorClienteListToolbar'

const SetorClienteListHeader = () => {
  const { selected } = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <SetorClienteListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <SetorClienteListGrouping /> : <SetorClienteListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export { SetorClienteListHeader }
