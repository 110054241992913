import axios, { AxiosResponse } from 'axios'
import { ID } from '../../../../../../_metronic/helpers'
import { EspecialidadesQueryResponse, EspecialidadeView } from './_models'
import { TiposAtendimento } from '../../../atendimento/core/_models'

const API_URL = process.env.REACT_APP_API_URL
const GET_ESPECIALIDADES_URL = `${API_URL}/especialidades`
const GET_TIPO_URL = `${API_URL}/tipos/atendimento`

export const getEspecialidades = (query?: string): Promise<EspecialidadesQueryResponse> => {
  return axios
    .get(`${GET_ESPECIALIDADES_URL}?${query}`)
    .then((d: AxiosResponse<EspecialidadesQueryResponse>) => d.data)
}

export const getEspecialidadesSemQuery = (): Promise<EspecialidadesQueryResponse> => {
  return axios
    .get(`${GET_ESPECIALIDADES_URL}`)
    .then((d: AxiosResponse<EspecialidadesQueryResponse>) => d.data)
}

export const getTiposAtendimentos = (): Promise<TiposAtendimento[]> => {
  return axios.get(`${GET_TIPO_URL}`).then((d: AxiosResponse<TiposAtendimento[]>) => d.data)
}

export const createEspecialidade = (requestData: {
  nome: string
  tiposAtendimento: string[]
}): Promise<EspecialidadeView> => {
  return axios.post(`${GET_ESPECIALIDADES_URL}`, requestData)
}

export const editEspecialidade = (
  id: ID,
  nome: string,
  tiposAtendimento: string[]
): Promise<EspecialidadeView> => {
  return axios.put(`${GET_ESPECIALIDADES_URL}`, {
    id,
    nome,
    tiposAtendimento,
  })
}

export const updateEspecialidadeStatus = (id: ID, ativa: boolean): Promise<EspecialidadeView> => {
  return axios.put(`${GET_ESPECIALIDADES_URL}`, {
    id,
    ativa,
  })
}
