import { useEffect, useState } from "react";

import { Importacao } from "../../list/core/_models";
import { getDadosImportacao } from "../core/_requests";
import { useParams } from "react-router-dom";

const ImportacaoErroListToolbar = () => {
  const [importacao, setImportacao] = useState<Importacao>();
  const { id } = useParams();
  const loadData = async () => {
    const data = await getDadosImportacao(Number(id));
    setImportacao(data);
  }

  useEffect(() => {
    loadData();
  }, []);  

  return (
    <div className='d-flex flex-column' style={{ marginLeft: '20px' }} data-kt-user-table-toolbar='base'>
        <h1 className="d-flex flex-column text-dark fw-bolder fs-3 mb-0">Dados da importação</h1>
        <div className="d-flex flex-column mt-5">
            <div>Descrição: {importacao?.descricao}</div>
            <div>Registros encontrados: {importacao?.totalRegistros}</div>
            <div>Registros válidos: {importacao?.registrosValidos}</div>
            <div>Erros: {importacao?.registrosErros}</div>
            <div>Registros importados: {importacao?.registrosImportados}</div>
            <div>Pacientes cadastrados: {importacao?.pacientesCadastrados}</div>
            <div>Data/Hora início: {importacao?.inicioFormatado}</div>
            <div>Data/Hora término: {importacao?.terminoFormatado}</div>
        </div>
    </div>
  )
}

export { ImportacaoErroListToolbar }
