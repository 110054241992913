import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { PersonalAtendimentosListWrapper } from './meus-atendimentos-list/PersonalAtendimentosList'

const atendimentosBreadcrumbs: Array<PageLink> = [
  /*  {
    title: 'Atendimentos',
    path: '/apps/atendimentosMedicos/lista',
    isSeparator: false,
    isActive: false,
  }, */
]

const PersonalAtendimentos = () => {
  return (
    <Routes>
      <Route
        path='lista'
        element={
          <>
            <PageTitle breadcrumbs={atendimentosBreadcrumbs}>Meus Atendimentos</PageTitle>
            <PersonalAtendimentosListWrapper />
          </>
        }
      />

      <Route index element={<Navigate to='/apps/meus-atendimentos/lista' />} />
    </Routes>
  )
}

export default PersonalAtendimentos
