import { FC } from 'react'
import { FormularioView } from '../../core/_models'
import { FormularioEdit } from './FormularioEdit'

type Props = {
  formulario: FormularioView | undefined
  isLoading: boolean
}

export const FormularioEditWrapper: FC<Props> = ({ formulario, isLoading }) => {
  if (!isLoading) {
    return <FormularioEdit isLoading={isLoading} formulario={formulario} />
  }

  return null
}
