import { KTSVG } from '../../../../../../../_metronic/helpers'
import { useListView } from '../../core/ListViewProvider'

const SetorClienteListToolbar = () => {
  const { setItemIdForUpdate } = useListView()
  const openAddSetor = () => {
    setItemIdForUpdate(null)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <button type='button' className='btn btn-primary' onClick={openAddSetor}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Adicionar
      </button>
    </div>
  )
}

export { SetorClienteListToolbar }
