import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { FormulariosListWrapper } from './formularios-list/FormulariosList'
import { FormularioCreateWrapper } from './formularios-create/FormularioCreate'
import { VinculosListWrapper } from './formularios-vinculos-list/VinculosList'

const apiBreadcrumbs: Array<PageLink> = [
  {
    title: 'Formulários',
    path: '/apps/formularios',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const FormulariosPage = () => {
  return (
    <Routes>
      <Route
        path=''
        element={
          <>
            <PageTitle breadcrumbs={apiBreadcrumbs}>Lista</PageTitle>
            <FormulariosListWrapper />
          </>
        }
      />
      <Route
        path='criar'
        element={
          <>
            <PageTitle breadcrumbs={apiBreadcrumbs}>Novo Formulário</PageTitle>
            <FormularioCreateWrapper />
          </>
        }
      />
      <Route
        path='editar'
        element={
          <>
            <PageTitle breadcrumbs={apiBreadcrumbs}>Editar Formulário</PageTitle>
            <FormularioCreateWrapper />
          </>
        }
      />
      <Route
        path='vinculos'
        element={
          <>
            <PageTitle breadcrumbs={apiBreadcrumbs}>Vínculos</PageTitle>
            <VinculosListWrapper />
          </>
        }
      />
    </Routes>
  )
}

export default FormulariosPage
