import { FC, useContext, useState, useEffect, useMemo } from 'react'
import { useQuery } from 'react-query'
import {
  createResponseContextSemPaginacao,
  initialQueryResponse,
  QUERIES,
  stringifyRequestQuerySemPaginacao,
  WithChildren,
} from '../../../../../../_metronic/helpers'
import { getAtendimentosRelatorio } from './_requests'
import { useQueryRequest } from './QueryRequestProvider'
import { useSearchParams } from 'react-router-dom'
import { AtendimentoReport } from '../../../meus-atendimentos/meus-atendimentos-list/core/_models'

const QueryResponseContext =
  createResponseContextSemPaginacao<AtendimentoReport>(initialQueryResponse)

const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const [searchParams] = useSearchParams()
  const idEmpresa = String(searchParams.get('empresa'))
  const { state } = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuerySemPaginacao(state))
  const updatedQuery = useMemo(() => stringifyRequestQuerySemPaginacao(state), [state])

  useEffect(() => {
    if (query !== updatedQuery) {
      console.log(updatedQuery)
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.ATENDIMENTO_MEDICO_LIST}-${query}`,
    () => {
      if (state.search !== '') {
        return getAtendimentosRelatorio(query)
      }
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  )

  return (
    <QueryResponseContext.Provider
      value={{ isLoading: isFetching, refetch, response, query, idEmpresa }}
    >
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = (): AtendimentoReport[] => {
  const { response } = useQueryResponse()
  if (!response) {
    return []
  }

  return response || []
}

const useQueryResponseLoading = (): boolean => {
  const { isLoading } = useQueryResponse()
  return isLoading
}

export { useQueryResponseData, useQueryResponseLoading, QueryResponseProvider }
