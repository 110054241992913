import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { AvatarDto } from '../../modules/apps/empresa/empresas-list/core/_models'

export type ILogoInput = {
  onChange: (value: AvatarDto) => void
  value?: string
}

export const LogoInput: FC<ILogoInput> = ({ onChange, value }) => {
  const [logo, setLogo] = useState<string | undefined>(value)

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]

    if (file) {
      const fileName = file.name
      const fileType = file.type

      const reader = new FileReader()
      reader.onloadend = () => {
        const fileContent = (reader.result as string).split(',')[1]
        setLogo(`data:${fileType};base64,${fileContent}`)
        onChange({ action: 'u', fileName, fileType, fileContent })
      }
      reader.readAsDataURL(file)
    }
  }

  return (
    <div className='d-flex align-items-center mb-3'>
      <input
        type='file'
        className='form-control form-control-solid'
        accept='image/*'
        onChange={handleFileChange}
        required
        style={{ flex: 1 }}
      />

      {logo && (
        <div className='ms-3'>
          <img
            src={logo}
            alt='Uploaded Logo'
            className='img-fluid'
            width={50}
            height={50}
            style={{ maxWidth: '100px', maxHeight: '100px', objectFit: 'contain' }}
          />
        </div>
      )}
    </div>
  )
}
