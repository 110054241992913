import { useAuth } from '../../../../../auth'
import { useListView } from '../../core/ListViewProvider'
import { AvaliacoesPendentesListGrouping } from './AvaliacoesPendentesListGrouping'
import { AvaliacoesPendentesListSearchComponent } from './AvaliacoesPendentesListSearchComponent'

export const AvaliacoesPendentesListHeader = () => {
  const { selected } = useListView()

  return (
    <div className='card-header border-0 pt-6'>
      <AvaliacoesPendentesListSearchComponent />
      <div className='w-100'>
        {selected.length > 0 ? <AvaliacoesPendentesListGrouping /> : null}
      </div>
    </div>
  )
}
