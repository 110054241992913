import { useState, useEffect } from 'react'
import {
  IconPaths,
  initialQueryState,
  OptionsHelper,
  Status,
  useDebounce,
} from '../../../../../../../_metronic/helpers'
import { useQueryRequest } from '../../../formularios-list/core/QueryRequestProvider'
import { useNavigate } from 'react-router-dom'
import { Button, CustomSelect, Label, TextInput } from '../../../../../../components'

export type IStatusOptions = {
  value: Status
  label: string
}

const FormulariosListSearchComponent = () => {
  const { updateState } = useQueryRequest()
  const navigate = useNavigate()

  const [nomeSel, setNomeSel] = useState<string>('')
  const [status, setStatus] = useState<IStatusOptions>()

  const [searchTerm, setSearchTerm] = useState<string>('')

  const debouncedSearchTerm = useDebounce(searchTerm, 150)

  useEffect(() => {
    if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
      updateState({
        filter: [],
        search: searchTerm,
        sort: 'nome',
        order: 'asc',
        ...initialQueryState,
      })
    }
  }, [searchTerm, debouncedSearchTerm])

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNomeSel(e.target.value)
    let search = ''

    const statusQuery = status ? `ativo:${status?.value},` : ''
    const searchQuery = e.target.value !== '' ? `nome~*${e.target.value}*` : ''

    search = `${statusQuery}${searchQuery}`

    setSearchTerm(search)
  }

  const handleStatus = (status: IStatusOptions) => {
    const statusQuery = status ? `ativo:${status.value},` : ''
    const searchQuery = nomeSel !== '' ? `nome~*${nomeSel}*` : ''

    setStatus(status)
    setSearchTerm(`${statusQuery}${searchQuery}`)
  }

  const handleFormulario = () => navigate('criar')

  return (
    <div className='d-flex flex-column flex-md-row align-items-center justify-content-between col-12'>
      <div className='d-flex flex-column flex-sm-row align-items-center col-12 col-md-10'>
        <div className='col-12 col-sm-7 col-md-6 mb-5 me-0 me-sm-2'>
          <Label className='pb-1'>Formulário</Label>
          <TextInput placeholder='Digite o formulário' onChange={onChange} />
        </div>

        <div className='col-12 col-sm-5 col-md-3 mb-5'>
          <Label className='pb-1'>Situação</Label>
          <CustomSelect
            onChange={handleStatus}
            options={OptionsHelper.status}
            value={status}
            placeholder='Selecione'
          />
        </div>
      </div>

      <div className='col-12 col-md-auto'>
        <Button className='btn-primary' icon={IconPaths.Add} onClick={handleFormulario}>
          Adicionar
        </Button>
      </div>
    </div>
  )
}

export { FormulariosListSearchComponent }
