import { useListView } from '../../core/ListViewProvider'
import { HistoricoListGrouping } from './HistoricoListGrouping'
import { HistoricoListSearchComponent } from './HistoricoListSearchComponent'
import { HistoricoListToolbar } from './HistoricoListToolbar'

const HistoricoListHeader = () => {
  const { selected } = useListView()
  return (
    <div className='border-0 pt-6'>
      <HistoricoListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <HistoricoListGrouping /> : null}
        {/* {selected.length > 0 ? <HistoricoListGrouping /> : <HistoricoListToolbar />} */}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export { HistoricoListHeader }
