/* eslint-disable react/jsx-no-target-blank */

import { Modal } from 'react-bootstrap'
import { useEffect, useRef, useState } from 'react'

import { AtendimentoView } from '../../../atendimento/core/_models'
import { AtendimentoShowDispositivos } from '../dispositivos/AtendimetnoShowDispositivos'
import {
  AtendimentoType,
  IconPaths,
  IconSize,
  KTSVG,
  Tipo,
} from '../../../../../../_metronic/helpers'
import { Cronometro } from '../../../atendimento/cronometro/Cronometro'
import { differenceInSeconds } from 'date-fns'
import { HistoricoClinico } from '../../atendimento-historico/historico'
import { ProcedimentoAso } from '../../../atendimento-ASO/Procedimento'
import { alteraAtendimento, getAtendimentoById } from '../../../atendimento/core/_requests'
import Swal from 'sweetalert2'
import { useAuth } from '../../../../auth'
import { useNavigate } from 'react-router-dom'
import { MarcarFaltaModal } from '../../atendimentos-medico-list/component/MarcarFaltaModal/MarcarFaltaModal'
import { Anamnese } from '../Anamnese'
import FormCID from '../formCID'
import Aso from '../../../atendimento-ASO/Aso'
import { Button } from '../../../../../components'
import { FormularioModalWrapper } from '../modal/formulario-answer/FormularioModalWrapper'
import { AnotacaoModal } from '../../atendimentos-medico-list/atendimento-medico-anotacao-modal/AnotacaoModal'
type Props = {
  atendimento: AtendimentoView
}
export const AsideMenuAtendimentoMain: React.FC<Props> = ({ atendimento }) => {
  const modalRef = useRef<{ open: () => void; close: () => void }>(null)

  const [show, setShow] = useState(false)
  const [atendimentoEx, setAtendimentoEx] = useState<AtendimentoView | undefined>(atendimento)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const { currentUser } = useAuth()
  const [maisInfos, setMaisInfos] = useState(false)
  const [showCID, setShowCID] = useState(false)
  const handleCloseCID = () => setShowCID(false)
  const handleShowCID = () => setShowCID(true)
  const navigate = useNavigate()
  const [showFaltaAtendimento, setShowFaltaAtendimento] = useState(false)
  const isMedico = currentUser?.vinculos?.some((vinculo) => vinculo?.cargo === 'MEDICO')

  const [showHistorico, setShowHistorico] = useState(false)
  const handleCloseHistorico = () => setShowHistorico(false)

  const [answered, setOnAnswered] = useState(false)
  const [showFormulario, setShowFormulario] = useState(false)
  const handleCloseFormulario = () => setShowFormulario(false)
  const handleShowFormulario = () => setShowFormulario(true)
  const handleAnswered = () => setOnAnswered(true)

  const [showAso, setShowAso] = useState(false)
  const handleCloseAso = () => setShowAso(false)
  const handleShowAso = () => setShowAso(true)
  const [copy, setCopy] = useState(false)

  const [showExame, setShowExame] = useState(false)
  const handleCloseExame = () => setShowExame(false)
  const handleShowExame = () => setShowExame(true)
  const handleShowMaisInfos = () => setMaisInfos(true)
  const handleCloseMaisInfos = () => setMaisInfos(false)

  const dataSolicitacao = new Date(String(atendimento.inicio))
  const dataAtual = new Date()
  const diffSecons = differenceInSeconds(dataAtual, dataSolicitacao)
  dataAtual.setSeconds(dataAtual.getSeconds() + diffSecons)
  const API_URL = 'https://one.lauduz.com'
  const url = `${API_URL}/tele-consulta/${atendimento?.protocolo}`

  const mensagem = `
  https://api.whatsapp.com/send?phone=+55${String(atendimento?.paciente?.pessoa?.telefone).replace(
    /[-()\s]/g,
    ''
  )}
  &text=Olá, estou entrando em contato sobre o seu atendimento. 
  Link da chamada: ${url}
`

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(url)
    setCopy(true)
    setTimeout(() => {
      setCopy(false)
    }, 3000)
  }

  const defineAtendimento = () => {
    getAtendimentoById(atendimento?.id).then((a) => {
      setAtendimentoEx(a)
    })
  }

  function redirectHistorico() {
    window.open(
      '/apps/atendimentosMedicos/historicoClinico?paciente=' + atendimento.paciente?.id,
      '_blank',
      'noreferrer'
    )
    // handleShowHistorico()
    //navigate('/apps/atendimentosMedicos/historicoClinico?paciente=' + atendimento.paciente?.id)
  }

  function redirectPrescricao() {
    window.open(
      '/apps/atendimentosMedicos/prescricao?idAtendimento=' + atendimento.id,
      '_blank',
      'noreferrer'
    )
  }

  function calcularIdade(dataNascimento: string) {
    const hoje = new Date()
    const nascimento = new Date(dataNascimento)
    let idade = hoje.getFullYear() - nascimento.getFullYear()
    const mesAtual = hoje.getMonth()
    const mesNascimento = nascimento.getMonth()

    if (
      mesAtual < mesNascimento ||
      (mesAtual === mesNascimento && hoje.getDate() < nascimento.getDate())
    ) {
      idade--
    }

    return idade
  }

  function finalizarAtendimento() {
    alteraAtendimento(Number(atendimento.id), 3, Number(currentUser?.pessoa?.id), true, null)
      .then((result) => {
        Swal.fire({
          text: 'Atendimento Finalizado',
          icon: 'success',
          buttonsStyling: false,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        }).then(() => {
          navigate('/apps/atendimentosMedicos/lista')
        })
      })
      .catch((error) => {
        Swal.fire({
          text: error.response?.data.data.message,
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      })
  }

  const handleFaltouAtendimento = () => {
    setShowFaltaAtendimento((prev) => !prev)
  }

  return (
    <>
      <div className='menu-item'>
        {atendimento.id !== -1 ? (
          <Cronometro expiryTimestamp={dataAtual} />
        ) : (
          <>Sem Informações sobre a data de solicitação</>
        )}
        <div className='d-flex flex-column'>
          <div
            className='card-header border-0 cursor-pointer'
            role='button'
            data-bs-toggle='collapse'
            data-bs-target='#kt_account_connected_accounts'
            aria-expanded='true'
            aria-controls='kt_account_connected_accounts'
          >
            <div className='card-title mt-3'>
              <div className='fw-bold text-muted'>{atendimento.protocolo}</div>
            </div>
            <div className='card-title m-1 mt-3'>
              <div className='fw-bold text-muted'>
                <span className='menu-icon'>
                  <KTSVG path='/media/icons/duotune/general/view2.svg' className='svg-icon-2' />
                </span>
                <span className='ms-2'>Nome Paciente</span>
              </div>
              <div className='fw-bolder d-flex align-items-center fs-5 mt-3'>
                {atendimento.paciente?.nome}
              </div>
            </div>
          </div>

          <div id='kt_account_connected_accounts' className='collapse'>
            <div className='fw-bold text-muted'>CPF</div>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {atendimento.paciente?.pessoa?.cpf}
            </div>
            <div className='fw-bold text-muted'>Sexo</div>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {atendimento.paciente?.pessoa?.sexo}
            </div>
            <div className='fw-bold text-muted'>Data Nascimento</div>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {atendimento?.paciente?.pessoa?.dataNascimentoFormatada} (
              {calcularIdade(atendimento?.paciente?.pessoa?.dataNascimento as string)} anos)
            </div>
          </div>
        </div>
      </div>
      <div className='menu-item '>
        <Button
          className='fw-bolder btn-sm fs-7'
          onClick={handleShow}
          icon={IconPaths.NewFile}
          iconSize={IconSize.Small}
        >
          Verificar Dispositivos
        </Button>
      </div>
      <div className='menu-item'>
        <Button
          className='fw-bolder btn-sm fs-7'
          onClick={redirectHistorico}
          icon={IconPaths.NewFile}
          iconSize={IconSize.Small}
        >
          Histórico Clínico
        </Button>
      </div>
      <div className='menu-item'>
        <Button
          className='fw-bolder btn-sm fs-7'
          onClick={handleShowCID}
          icon={IconPaths.NewFile}
          iconSize={IconSize.Small}
        >
          CID
        </Button>
      </div>
      <div className='menu-item'>
        <Button
          className='fw-bolder btn-sm fs-7'
          onClick={redirectPrescricao}
          icon={IconPaths.NewFile}
          iconSize={IconSize.Small}
        >
          Prescrição
        </Button>
      </div>
      {atendimento.tipo === Tipo.ASO ? (
        <div className='menu-item'>
          <Button
            className='fw-bolder btn-sm fs-7'
            onClick={handleShowAso}
            icon={IconPaths.NewFile}
            iconSize={IconSize.Small}
          >
            Emitir ASO
          </Button>
        </div>
      ) : (
        <>
          <div className='menu-item'>
            <Button
              className='fw-bolder btn-sm fs-7'
              onClick={handleShowExame}
              icon={IconPaths.NewFile}
              iconSize={IconSize.Small}
            >
              Exames
            </Button>
          </div>
        </>
      )}
      <div className='menu-item'>
        <Button
          className='fw-bolder btn-sm fs-7'
          onClick={handleShowMaisInfos}
          icon={IconPaths.NewFile}
          iconSize={IconSize.Small}
        >
          Ver anamnese
        </Button>
      </div>
      <div className='d-flex flex-column mt-4 '>
        <Button className='btn-primary mb-2' onClick={() => modalRef.current?.open()}>
          Adicionar Pendência
        </Button>
        {atendimento.formularios && atendimento.formularios?.length > 0 ? (
          <Button className='btn-secondary mb-2' onClick={handleShowFormulario}>
            {answered ? 'Alterar Avaliação' : 'Preencher Avaliação'}
          </Button>
        ) : null}

        <Button className='btn-primary mb-2' onClick={handleCopyUrl}>
          URL da Chamada
        </Button>
        {copy && <p className='text-success'>Link copiado com sucesso</p>}
        {!!atendimento?.paciente?.pessoa?.telefone && (
          <a href={mensagem} target='_blank' className='btn btn-success mb-2'>
            Enviar Mensagem no WhatsApp
          </a>
        )}
        {isMedico && (
          <button
            type='button'
            className='btn btn-secondary fw-bold fs-6 mb-2'
            onClick={handleFaltouAtendimento}
          >
            Marcar falta paciente
          </button>
        )}
        {isMedico && (
          <button
            type='button'
            className='btn btn-danger fw-bold fs-6 mb-2'
            onClick={finalizarAtendimento}
          >
            Finalizar Atendimento
          </button>
        )}
      </div>
      {atendimento?.id && (
        <>
          <AnotacaoModal ref={modalRef} isRequired={true} atendimentoId={atendimento.id} />
          <Modal show={show} onHide={handleClose} className='modal-lg'>
            <Modal.Header closeButton>
              <Modal.Title>Informações dos dispositivos</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <AtendimentoShowDispositivos atendimento={atendimento} />
            </Modal.Body>
            {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
          </Modal>
          <Modal className='modal-md' show={showCID} onHide={handleCloseCID}>
            <Modal.Header closeButton>
              <Modal.Title>CID Atendimento</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <FormCID atendimento={atendimento} closeButton={showCID} />
            </Modal.Body>
          </Modal>
          <Modal className='modal-xl' show={showHistorico} onHide={handleCloseHistorico}>
            <Modal.Header closeButton>
              <Modal.Title>Histórico Médico</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <HistoricoClinico idPaciente={String(atendimento.paciente?.id)} />
            </Modal.Body>
          </Modal>
          {atendimento.formularios && atendimento.formularios?.length > 0 ? (
            <Modal className='modal-xl' show={showFormulario} onHide={handleCloseFormulario}>
              <Modal.Header closeButton>
                <Modal.Title>{atendimento.formularios[0].formulario.nome}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <FormularioModalWrapper
                  formId={atendimento.formularios[0].formulario.id}
                  onHide={handleCloseFormulario}
                  onAnswered={handleAnswered}
                />
              </Modal.Body>
            </Modal>
          ) : null}
          <Modal className='modal-xl' show={showAso} onHide={handleCloseAso}>
            <Modal.Header closeButton>
              <Modal.Title>Emitir ASO</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Aso id={atendimento?.id} />
            </Modal.Body>
          </Modal>
          <Modal className='modal-xl' show={showExame} onHide={handleCloseExame}>
            <Modal.Header closeButton>
              <Modal.Title>Exames</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ProcedimentoAso
                defineAtendimento={defineAtendimento}
                //@ts-ignore
                atendimento={atendimento}
              />
            </Modal.Body>
          </Modal>

          <Modal className='modal-md' show={maisInfos} onHide={handleCloseMaisInfos}>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <Anamnese atendimento={atendimento} />
            </Modal.Body>
          </Modal>

          {showFaltaAtendimento && (
            <MarcarFaltaModal
              atendimento={atendimento}
              showMarcarFalta={showFaltaAtendimento}
              onClose={handleFaltouAtendimento}
            />
          )}
        </>
      )}
    </>
  )
}
