/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { ID, KTSVG, toAbsoluteUrl } from '../../../../../../_metronic/helpers'
import { deleteFuncionario } from '../../../funcionarios-management/funcionarios-list/core/_requests'
import Swal from 'sweetalert2'
import { getUserByCPF } from '../../../../auth/core/_requests'
import { Vinculo } from '../../../../auth'

type Props = {
  vinculos: Vinculo[] | undefined
  cpf: string | undefined
  showExc?: boolean | undefined
}

const EmpresasVinculo: React.FC<Props> = ({ vinculos, cpf, showExc }) => {
  const [vinculosForm, setVinculosForm] = useState(vinculos)
  const [showExclude, setShowExclude] = useState(showExc !== undefined ? showExc : true)
  const removerVinculo = (idVinculo: ID) => {
    Swal.fire({
      title: 'Confirma a exclusão do funcinário?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sim',
      denyButtonText: `Não`,
      showClass: {
        popup: 'animate__animated animate__fadeInDown',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        deleteFuncionario(idVinculo)
          .then((resul) => {
            if (cpf !== undefined) {
              getUserByCPF(cpf)
                .then((result) => {
                  //Aqui tem que definir o id
                  setVinculosForm(result.data.vinculos)
                })
                .catch((error) => {
                  Swal.fire('Não foi possível localizar os vínculos', '', 'error')
                })
            }
            Swal.fire('Removido com sucesso!', '', 'success')
          })
          .catch((error) => {
            Swal.fire('Não foi possível remover', '', 'error')
          })
      }
    })
  }
  useEffect(() => {
    setVinculosForm(vinculos)
  }, [vinculos])
  const formatDescription = (description: string): string => {
    const descriptionMap = {
      RESPONSAVEL: 'Responsável',
      MEDICO: 'Profissional de Saúde',
      COLABORADOR: 'Colaborador',
    }
    //@ts-ignore
    return descriptionMap[description] || description
  }

  return (
    <div className='card-body pt-2' key={1}>
      {/* begin::Item */}
      {vinculosForm !== undefined ? (
        vinculosForm
          .filter((vinculo) => vinculo.paciente === false)
          .map((vinculo) => (
            <div className='d-flex align-items-center mb-7' key={vinculo.id}>
              {/* begin::Avatar */}
              <div className='symbol symbol-50px me-5'>
                <img src={toAbsoluteUrl('/media/empresa/predio.png')} className='' alt='' />
              </div>
              {/* end::Avatar */}
              {/* begin::Text */}
              <div className='flex-grow-1'>
                <div className='card-toolbar'>
                  <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                    {vinculo.empresa?.nomeFantasia}
                  </a>

                  {/* begin::Menu */}
                  {showExclude ? (
                    <button
                      type='button'
                      className='btn btn-sm btn-icon btn-color-danger btn-active-light-danger'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                      data-kt-menu-flip='top-end'
                      onClick={() => {
                        removerVinculo(vinculo.id)
                      }}
                    >
                      <KTSVG path='/media/icons/duotune/arrows/arr019.svg' className='svg-icon-3' />
                    </button>
                  ) : (
                    <></>
                  )}
                  {/* end::Menu */}
                </div>
                <span className='text-muted d-block fw-semibold'>
                  {formatDescription(vinculo.cargo as string)}
                </span>
              </div>
              {/* end::Text */}
            </div>
          ))
      ) : (
        <>
          <div className='flex-grow-1'>
            {/* <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                Usuário sem vínculo
              </a> */}
            <span className='text-muted d-block fw-semibold'>Usuário sem vínculo</span>
          </div>
        </>
      )}
    </div>
  )
}

export { EmpresasVinculo }
