/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { MenuComponent } from '../../../../../../../_metronic/assets/ts/components'
import { ID, KTSVG, onDeleteAlert } from '../../../../../../../_metronic/helpers'
import Swal from 'sweetalert2'
import { deleteColaboradorCliente } from '../../../empresas-list/core/_requests'
import { Coordenador } from '../../../empresasCliente-list/empresa-cliente-edit-modal/Coordenador'
import { useSearchParams } from 'react-router-dom'
import { refreshEmpresaCliente } from '../../CoordenadorClienteList'
import { getEmpresaClienteById } from '../../../empresasCliente-list/core/_request'
import { ColaboradorList } from '../../core/_models'
import { ClienteView } from '../../../empresasCliente-list/core/_models'
import { Dropdown, DropdownItem } from '../../../../../../components'

type Props = {
  colaborador: ColaboradorList
}

const CoordenadorActionsCell: FC<Props> = ({ colaborador }) => {
  const [searchParams] = useSearchParams()
  const cliente = Number(searchParams.get('cliente'))
  const [empresaCliente, setEmpresaCliente] = useState<ClienteView>()
  const [showCadastroCoord, setShowCadastroCoord] = useState<boolean>(false)

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const updateStateCliente = async () => {
    getEmpresaClienteById(cliente).then((result) => {
      if (result) {
        setEmpresaCliente(result)
      } else {
        Swal.fire({
          text: 'Não foi possível atualizar os dados do Empregador ASO!',
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      }
    })
  }

  const excluiCoordenador = () => {
    deleteColaboradorCliente(cliente, colaborador.id)
      .then(() => {
        Swal.fire({
          text: 'Coordenador excluído com sucesso!',
          icon: 'success',
          buttonsStyling: false,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        }).then(() => {
          refreshEmpresaCliente()
        })
      })
      .catch((error) => {
        Swal.fire({
          text: 'Não foi possível excluir este coordenador!',
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      })
  }

  const handleDelete = () => onDeleteAlert(excluiCoordenador)

  const editCoordenador = () => {
    setShowCadastroCoord(true)
  }

  const handleShowCoordenador = async () => {
    updateStateCliente()
    setShowCadastroCoord(!showCadastroCoord)
  }
  return (
    <>
      <Dropdown name='Ações'>
        <DropdownItem onClick={editCoordenador}>Alterar</DropdownItem>
        <DropdownItem onClick={handleDelete}>Excluir</DropdownItem>
      </Dropdown>

      {showCadastroCoord && (
        <div>
          <div className='modal-backdrop fade show' style={{ zIndex: 1050 }}></div>
          <div
            className='modal fade show'
            id='exampleModal'
            tabIndex={-1}
            aria-labelledby='exampleModalLabel'
            aria-hidden='true'
            style={{ display: 'block' }}
          >
            <div className='modal-dialog modal-xl'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <button
                    type='button'
                    className='btn-close'
                    aria-label='Close'
                    onClick={handleShowCoordenador}
                  ></button>
                </div>
                <div className='modal-body justify-content-start text-start'>
                  <Coordenador
                    empresaCliente={empresaCliente}
                    coordenador={colaborador}
                    onCancel={handleShowCoordenador}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export { CoordenadorActionsCell }
