import { FC, ReactNode, useEffect } from 'react'
import { ModalHeader } from './ModalHeader'

type IModal = {
  onClose: () => void
  children: ReactNode
  id: string
  title?: string
  size?: number
}

export const Modal: FC<IModal> = ({ onClose, children, title, id, size }) => {
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <>
      <div
        className='modal fade show d-block'
        id={`kt_modal_${id}`}
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div className={`modal-dialog modal-dialog-centered mw-${size}px`}>
          <div className='modal-content'>
            <ModalHeader onClose={onClose}>{title}</ModalHeader>
            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>{children}</div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

Modal.defaultProps = {
  title: 'Preencha o titulo do modal',
  size: 850,
}
