import { useEffect } from 'react'
import { EmpresaClienteEditModalHeader } from './EmpresaEditModalHeader'
import { EmpresaClienteEditModalFormWrapper } from './EmpresaEditModalFormWrapper'
import { useListView } from '../core/ListViewProvider'
import { Modal } from '../../../../../components'

const EmpresaClienteEditModal = () => {
  const { setItemIdForUpdate } = useListView()

  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  const onClose = () => setItemIdForUpdate(undefined)

  return (
    <Modal onClose={onClose} id='add_empresa' size={650} title='Empregador ASO'>
      <EmpresaClienteEditModalFormWrapper />
    </Modal>
  )
}

export { EmpresaClienteEditModal }
