import { Response, SearchState, SortState } from '../../../../../../_metronic/helpers'
import { AtendimentoReport } from '../../../meus-atendimentos/meus-atendimentos-list/core/_models'

export type IInfoRelatorio = {
  total: number
  assistencial: number
  screening: number
  ocupacional: number
  emAtendimento: number
  finalizado: number
  cancelado: number
  aguardando: number
}

export type QueryStateRelatorios = SortState & SearchState

export type QueryRequestContextPropsRelatorios = {
  state: QueryStateRelatorios
  updateState: (updates: Partial<QueryStateRelatorios>) => void
}

export type AtendimentoQueryResponse = Array<AtendimentoReport>

export const initialQueryState: QueryStateRelatorios = {
  search: '',
  sort: '',
  order: 'asc',
}
export const initialQueryRequest: QueryRequestContextPropsRelatorios = {
  state: initialQueryState,
  updateState: () => {},
}
