import { FC, ReactNode } from 'react'
import { ButtonType, IconPaths, IconSize, KTSVG } from '../../../_metronic/helpers'

type IButton = {
  onClick?: () => void
  children?: ReactNode
  icon?: IconPaths | null
  iconSize?: IconSize
  type?: ButtonType
  className?: string
  disabled?: boolean
}

export const Button: FC<IButton> = ({
  children,
  onClick,
  icon,
  type,
  className,
  iconSize,
  disabled,
}) => {
  return (
    <button
      type={type}
      className={`d-flex btn ${className} ${
        icon ? 'align-items-center' : 'justify-content-center'
      }`}
      onClick={onClick}
      disabled={disabled}
    >
      {icon ? <KTSVG path={icon} className={`svg-icon-${iconSize}`} /> : null}
      {children}
    </button>
  )
}

Button.defaultProps = {
  icon: null,
  type: ButtonType.Button,
  className: '',
  iconSize: IconSize.Medium,
  disabled: false,
}
