import axios, { AxiosResponse } from 'axios'
import { AvaliacoesQueryResponse } from './_models'
import { ID } from '../../../../../../_metronic/helpers'
import { ArquivoDto } from '../../../atendimento/core/_models'

const API_URL = process.env.REACT_APP_API_URL
const AVALIACOES_URL = `${API_URL}/atendimentos/formulario`

export const getAvaliacoesCompleted = (query: string): Promise<AvaliacoesQueryResponse> => {
  return axios
    .get(`${AVALIACOES_URL}?${query}`)
    .then((d: AxiosResponse<AvaliacoesQueryResponse>) => d.data)
}

export const downloadAvaliacaoLote = (avaliacoesIds: ID[]): Promise<ArquivoDto[]> => {
  const query = avaliacoesIds.join(',')
  return axios
    .get(`${AVALIACOES_URL}/download?formularios=${query}`)
    .then((resp: AxiosResponse<ArquivoDto[]>) => resp.data)
}

export const downloadAvaliacao = (idAtendimento: ID, idFormulario: ID): Promise<ArquivoDto> => {
  return axios
    .get(`${AVALIACOES_URL}/download/${idAtendimento}/${idFormulario}`)
    .then((resp: AxiosResponse<ArquivoDto>) => resp.data)
}
