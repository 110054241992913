import { FC } from 'react'
import { IconPaths, KTSVG } from '../../../../../_metronic/helpers'

export type IExpandRow = {
  onToggle: () => void
  isExpanded: boolean
}

export const ExpandRow: FC<IExpandRow> = ({ onToggle, isExpanded }) => {
  return (
    <span onClick={onToggle} style={{ cursor: 'pointer' }}>
      <KTSVG
        path={isExpanded ? IconPaths.ArrowDown : IconPaths.ArrowRight}
        className='svg-icon-1'
      />
    </span>
  )
}
