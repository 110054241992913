import { IconPaths } from '../../../../../../../_metronic/helpers'
import { Button } from '../../../../../../components'
import { useListView } from '../../core/ListViewProvider'

const EmpresasClienteListToolbar = () => {
  const { setItemIdForUpdate } = useListView()
  const openAddEmpresa = () => {
    //aqui tenho q alterar criando um atendimento com empresa nula para ser cadastrada
    setItemIdForUpdate(null)
  }

  return (
    <Button className='btn-primary' icon={IconPaths.Add} onClick={openAddEmpresa}>
      Adicionar
    </Button>
  )
}

export { EmpresasClienteListToolbar }
