import { FC } from 'react'
import { DispositivoList, TiposDispositivos } from '../../modules/apps/atendimento/core/_models'

export type IDispositivos = {
  dispositivos: DispositivoList[]
}
export const Dispositivos: FC<IDispositivos> = ({ dispositivos }) => {
  const getIconsPath = (dispositivos: DispositivoList[], tipo: TiposDispositivos) => {
    const dispositivoEncontrado = dispositivos.find((d) => d.tipo === tipo)

    if (dispositivoEncontrado) {
      return `/media/icons/duotune/sinais/${tipo.toLowerCase()}-on.png`
    } else {
      return `/media/icons/duotune/sinais/${tipo.toLowerCase()}.png`
    }
  }

  return (
    <div className='d-flex justify-content-center align-items-center'>
      <img src={getIconsPath(dispositivos, 'BALANCA')} width={33} alt='Ícone de balança' />
      <img
        src={getIconsPath(dispositivos, 'PRESSAO')}
        width={35}
        alt='Ícone de aferidor de pressão arterial'
      />
      <img src={getIconsPath(dispositivos, 'OXIMETRO')} width={25} alt='Ícone de oxímetro' />

      <img src={getIconsPath(dispositivos, 'TERMOMETRO')} width={25} alt='Ícone de termometro' />
      <img src={getIconsPath(dispositivos, 'CAMERA')} width={25} alt='Ícone de inspeção' />
      <img src={getIconsPath(dispositivos, 'OTOSCOPIO')} width={25} alt='Ícone de otoscópio' />
      <img
        src={getIconsPath(dispositivos, 'GLICOSIMETRO')}
        width={25}
        alt='Ícone de Glicosímetro'
      />
    </div>
  )
}
