import { useMemo } from 'react'
import { useQueryResponseData, useQueryResponseLoading } from '../core/QueryResponseProvider'
import { ColumnInstance, Row, useTable } from 'react-table'
import { KTCardBody } from '../../../../../../_metronic/helpers'
import { AtendimentoList } from '../../../atendimento/core/_models'
import { ListLoading } from '../../../commons/components/ListLoading'
import { AtendimentosAsoDownloadColumns } from './columns/_columns'
import { DownloadAsoListPagination } from '../component/pagination/DownloadAsoListPagination'
import { CustomHeaderColumn } from '../../../commons/table/CustomHeaderColumn'
import { CustomRow } from '../../../commons/table/CustomRow'

interface DownloadAsoTableProps {}

const DownloadAsoTable: React.FC<DownloadAsoTableProps> = () => {
  const atendimentosMedicos = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => atendimentosMedicos, [atendimentosMedicos])
  const downloadColumns = useMemo(() => AtendimentosAsoDownloadColumns, [])
  const { getTableProps, getTableBodyProps, columns, headers, rows, prepareRow } = useTable({
    columns: downloadColumns,
    data,
  })

  return (
    <>
      <KTCardBody className='py-4'>
        <div className='table-responsive'>
          <table
            id='kt_table_atendimento_medico'
            className='table align-middle table-row-bordered fs-6 gy-5 dataTable no-footer'
            {...getTableProps()}
          >
            <thead>
              <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0 border-secondary'>
                {headers.map((column: ColumnInstance<AtendimentoList>) => (
                  <CustomHeaderColumn key={column.id} column={column} />
                ))}
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map((row: Row<AtendimentoList>, i) => {
                  prepareRow(row)
                  return <CustomRow row={row} key={`row-${i}-${row.id}`} />
                })
              ) : (
                <tr>
                  <td colSpan={columns.length}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      Nenhum registro encontrado
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <DownloadAsoListPagination />
        {isLoading && <ListLoading />}
      </KTCardBody>
    </>
  )
}

export { DownloadAsoTable }
