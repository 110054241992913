import { Column } from 'react-table'
import { EmpresasActionsCell } from './EmpresaActionsCell'
import { Badge } from '../../../../../../components'
import { CustomHeader } from '../../../../commons/table/CustomHeader'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { SelectionHeader } from '../../../../commons/table/SelectionHeader'
import { SelectionCell } from '../../../../commons/table/SelectionCell'
import { useListView } from '../../core/ListViewProvider'
import { EmpresaList } from '../../core/_models'

const empresasColumns: ReadonlyArray<Column<EmpresaList>> = [
  {
    Header: (props) => <SelectionHeader useListView={useListView} tableProps={props} />,
    id: 'selection',
    Cell: ({ ...props }) => <SelectionCell useListView={useListView} id={props.row.original.id} />,
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title='Razão social'
        className='min-w-125px'
        useQueryRequest={useQueryRequest}
      />
    ),
    id: 'nome',
    accessor: 'nome',
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title='Nome fantasia'
        className='min-w-125px'
        useQueryRequest={useQueryRequest}
      />
    ),
    id: 'nomeFantasia',
    accessor: 'nomeFantasia',
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title='CNPJ'
        className='min-w-125px'
        useQueryRequest={useQueryRequest}
      />
    ),
    accessor: 'cnpj',
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title='Status'
        className='min-w-125px'
        useQueryRequest={useQueryRequest}
      />
    ),
    accessor: 'ativa',
    Cell: ({ value }) => <Badge isActive={value} />,
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title='Ações'
        className='text-end min-w-100px'
        useQueryRequest={useQueryRequest}
      />
    ),
    id: 'actions',
    Cell: ({ ...props }) => <EmpresasActionsCell id={props.row.original.id} row={props.row} />,
  },
]

export { empresasColumns }
