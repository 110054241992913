import * as Yup from 'yup'

import { useFormik } from 'formik'
import { FC, useEffect } from 'react'
import { ListLoading } from '../../../commons/components/ListLoading'
import clsx from 'clsx'
import { isNotEmpty } from '../../../../../../_metronic/helpers'
import { useListView } from '../core/ListViewProvider'
import { useQueryResponse } from '../core/QueryResponseProvider'
import Swal from 'sweetalert2'
import { useSearchParams } from 'react-router-dom'
import { AxiosError } from 'axios'
import { getEmpresaClienteById } from '../../empresasCliente-list/core/_request'
import { createCargoCliente, editCargoCliente } from '../core/_request'
import { CargoAsoView } from '../core/_models'

type Props = {
  isEmpresaLoading: boolean
  cargo: CargoAsoView
}

const CargoClienteEditModalForm: FC<Props> = ({ isEmpresaLoading, cargo }) => {
  const { setItemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()
  const [searchParams] = useSearchParams()
  const idEmpresaCliente = Number(searchParams.get('cliente'))

  //consulta pelo nome do responsavel
  const editCargoClienteSchema = Yup.object().shape({
    codigo: Yup.string().required('Código é obrigatório'),
    nome: Yup.string().required('Nome é obrigatório'),
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }
  const cargoClienteForEdit = {
    id: cargo.id !== undefined ? cargo.id : undefined,
    nome: cargo.nome !== undefined ? cargo.nome : '',
    codigo: cargo.codigo !== undefined ? cargo.codigo : '',
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: cargoClienteForEdit,
    validationSchema: editCargoClienteSchema,
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          editCargoCliente(values.id, String(values.nome), String(values.codigo))
            .then((result) => {
              Swal.fire({
                text: 'Cargo alterado com sucesso!',
                icon: 'success',
                buttonsStyling: false,
                confirmButtonText: 'Ok',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              }).then(() => cancel(true))
            })
            .catch((error) => {
              const errors = error.response.data.data.errors
              if (errors !== undefined) {
                setFieldError('codigo', errors['codigo'] !== undefined ? errors.cnpj[0] : '')
                setFieldError('nome', errors['nome'] !== undefined ? errors.nome[0] : '')
              } else {
                Swal.fire({
                  text: 'Não foi possível alterar o cargo!',
                  icon: 'error',
                  buttonsStyling: false,
                  confirmButtonText: 'Ok',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                })
              }
            })
        } else {
          createCargoCliente(idEmpresaCliente, String(values.nome), String(values.codigo))
            .then((result) => {
              Swal.fire({
                text: 'Cargo criado com sucesso!',
                icon: 'success',
                buttonsStyling: false,
                confirmButtonText: 'Ok',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              }).then(() => cancel(true))
            })
            .catch((error) => {
              const errors = error.response.data.data.errors
              if (errors !== undefined) {
                setFieldError('codigo', errors['codigo'] !== undefined ? errors.cnpj[0] : '')
                setFieldError('nome', errors['nome'] !== undefined ? errors.nome[0] : '')
              } else {
                Swal.fire({
                  text: 'Não foi possível criar cargo!',
                  icon: 'error',
                  buttonsStyling: false,
                  confirmButtonText: 'Ok',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                })
              }
            })
        }
      } catch (error: any) {
        const errors = error.response.data.data.errors
        setFieldError('cnpj', errors['cnpj'] !== undefined ? errors.cnpj[0] : '')
        setFieldError('nome', errors['nome'] !== undefined ? errors.nome[0] : '')

        console.error(error)
      } finally {
        setSubmitting(false)
      }
    },
  })
  useEffect(() => {
    if (idEmpresaCliente !== undefined) {
      getEmpresaClienteById(idEmpresaCliente).catch((error) => {
        const err = error as AxiosError
        Swal.fire({
          text: 'Erro ao buscar empresa! ' + err.response?.data.data.message,
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      })
    } else {
      Swal.fire({
        text: 'Informe o Empregador ASO ',
        icon: 'error',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    }
  }, [cargo])

  return (
    <>
      <form
        id='kt_modal_add_empresa_form'
        className='form'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column me-n7 '
          id='kt_modal_add_empresa_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_empresa_header'
          data-kt-scroll-wrappers='#kt_modal_add_empresa_scroll'
          // data-kt-scroll-offset='300px'
        >
          {/*           
          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Nome</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Nome'
              {...formik.getFieldProps('nome')}
              type='text'
              name='nome'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.nome && formik.errors.nome },
                {
                  'is-valid': formik.touched.nome && !formik.errors.nome,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isEmpresaLoading}
            />
            {formik.touched.nome && formik.errors.nome && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.nome}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Código</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Código'
              {...formik.getFieldProps('codigo')}
              type='text'
              name='codigo'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.codigo && formik.errors.codigo },
                {
                  'is-valid': formik.touched.codigo && !formik.errors.codigo,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isEmpresaLoading}
            />
            {formik.touched.codigo && formik.errors.codigo && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.codigo}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}

          {/* end::Input group */}
        </div>

        {/* </div> */}
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isEmpresaLoading}
          >
            Cancelar
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isEmpresaLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Enviar</span>
            {(formik.isSubmitting || isEmpresaLoading) && (
              <span className='indicator-progress'>
                Aguarde...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isEmpresaLoading) && <ListLoading />}
    </>
  )
}

export { CargoClienteEditModalForm }
