import axios, { AxiosResponse } from 'axios'
import { ID } from '../../../../../../_metronic/helpers'
import { ExameAsoView, ExamesClienteQueryResponse } from './_models'

const API_URL = process.env.REACT_APP_API_URL
const EXAMES_URL = `${API_URL}/empresas/cliente/exame`

export const getExamesCliente = (
  idEmpresaCliente: ID,
  query: string
): Promise<ExamesClienteQueryResponse> => {
  return axios
    .get(`${EXAMES_URL}?idEmpresa=${idEmpresaCliente}&${query}`)
    .then((d: AxiosResponse<ExamesClienteQueryResponse>) => d.data)
}

export const getExameClienteById = (idExame: ID): Promise<ExameAsoView | undefined> => {
  return axios
    .get(`${EXAMES_URL}/${idExame}`)
    .then((response: AxiosResponse<ExameAsoView>) => response.data)
}

export const createExameCliente = (
  idEmpresaCliente: ID,
  idCargo: ID,
  idSetor: ID,
  exame: string
): Promise<ExameAsoView | undefined> => {
  return axios.post(`${EXAMES_URL}/${idEmpresaCliente}`, {
    cargo: idCargo,
    setor: idSetor,
    exame,
  })
}
export const editExameCliente = (
  idEmpresaCliente: ID,
  idCargo: ID,
  idSetor: ID,
  exame: string,
  ativo: boolean
): Promise<ExameAsoView | undefined> => {
  return axios.put(`${EXAMES_URL}/${idEmpresaCliente}`, {
    cargo: idCargo,
    setor: idSetor,
    exame,
    ativo,
  })
}
export const deleteExameCliente = (idSetor: ID): Promise<string> => {
  return axios.delete(`${EXAMES_URL}/${idSetor}`)
}

export const deleteLoteExamesCliente = async (examesIds: Array<ID>): Promise<void> => {
  try {
    const requests = examesIds.map((id) => axios.delete(`${EXAMES_URL}/${id}`))
    await Promise.all(requests)
  } catch (error) {
    console.error('Erro deletando exames', error)
    throw error
  }
}
