import { SelectionHeaderContainer } from '../../../../../../components'
import { ImportacaoListSearchComponent } from './ImportacaoListSearchComponent'
import { ImportacaoListToolbar } from './ImportacaoListToolbar'

const ImportacaoListHeader = () => {
  return (
    <div className='border-0 pt-6 px-6'>
      <ImportacaoListSearchComponent />
      <SelectionHeaderContainer>
        <ImportacaoListToolbar />
      </SelectionHeaderContainer>
    </div>
  )
}

export { ImportacaoListHeader }
