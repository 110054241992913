import axios, { AxiosResponse } from 'axios'

import { CargoEnum, FuncionarioMemed, FuncionariosQueryResponse, FuncionarioView } from './_models'
import { ID } from '../../../../../../_metronic/helpers'
import { EnderecoList, EnderecoView } from '../../../empresa/empresas-list/core/_models'
import { UsuarioView } from '../../../../auth'

const API_URL = process.env.REACT_APP_API_URL
const FUNCIONARIO_URL = `${API_URL}/funcionarios`
const FUNCIONARIO_LOGADO_URL = `${FUNCIONARIO_URL}/me`
const FUNCIONARIO_MEMED_URL = `${FUNCIONARIO_URL}/memed`
const CARGOS_URL = `${API_URL}/cargos`
const FUNCIONARIO_EMPRESAS_URL = `${API_URL}/funcionarios/empresa/`

const getFuncionarios = (query: string): Promise<FuncionariosQueryResponse> => {
  return axios
    .get(`${FUNCIONARIO_URL}?${decodeURIComponent(query)}`)
    .then((d: AxiosResponse<FuncionariosQueryResponse>) => d.data)
}
export function getCargos() {
  return axios
    .get<CargoEnum[]>(CARGOS_URL)
    .then((response: AxiosResponse<CargoEnum[]>) => response.data)
}

const getFuncionariosEmpresa = (id: string, query: string): Promise<FuncionariosQueryResponse> => {
  return axios
    .get(`${FUNCIONARIO_EMPRESAS_URL}${id}?${query}`)
    .then((d: AxiosResponse<FuncionariosQueryResponse>) => d.data)
}

const getFuncionarioById = (id: ID): Promise<FuncionarioView> => {
  return axios
    .get(`${FUNCIONARIO_URL}/${id}`)
    .then((response: AxiosResponse<FuncionarioView>) => response.data)
}
export const getFuncionarioByCPFEmpresa = (
  cpf: string,
  idEmpresa: ID
): Promise<FuncionarioView> => {
  return axios
    .get(`${FUNCIONARIO_URL}/empresa/${idEmpresa}/cpf/${cpf}`)
    .then((response: AxiosResponse<FuncionarioView>) => response.data)
}
const getMemedFuncionarioById = (id: ID): Promise<FuncionarioMemed | undefined> => {
  return axios
    .get(`${FUNCIONARIO_MEMED_URL}/${id}`)
    .then((response: AxiosResponse<FuncionarioMemed>) => response.data)
}
const getMemedFuncionario = (): Promise<FuncionarioMemed | undefined> => {
  return axios
    .get(`${FUNCIONARIO_MEMED_URL}`)
    .then((response: AxiosResponse<FuncionarioMemed>) => response.data)
}
const getFuncionarioPessoaEmpresa = (idEmpresa: ID, idPessoa: ID): Promise<FuncionarioView> => {
  return axios
    .get(`${FUNCIONARIO_EMPRESAS_URL}${idEmpresa}/pessoa/${idPessoa}`)
    .then((response: AxiosResponse<FuncionarioView>) => response.data)
}

export const getFuncionarioLogado = (): Promise<FuncionarioView> => {
  return axios.get(`${FUNCIONARIO_LOGADO_URL}`).then((response) => response.data)
}

export const createFuncionario = (
  primeiroNome: string,
  sobreNome: string,
  cpf: string,
  cns: any,
  email: string,
  sexo: string,
  nascimento: string,
  cargo: string,
  crm: string,
  uf: string | null,
  idEmpresa: string,
  telefone: string | null,
  especialidades: number[] | null
): Promise<FuncionarioView> => {
  return axios
    .post(FUNCIONARIO_EMPRESAS_URL + idEmpresa, {
      primeiroNome,
      sobreNome,
      cpf,
      cns: cns !== '' && cns !== 'undefined' ? cns : null,
      email,
      telefone,
      sexo,
      nascimento,
      cargo,
      uf,
      crm: crm !== '' && crm !== 'undefined' ? crm : null,
      especialidades,
    })
    .then((response: AxiosResponse<FuncionarioView>) => response.data)
}
export function vincularFuncionarioEmpresa(
  cpf: string,
  cargo: string,
  idEmpresa: String,
  crm?: string,
  uf?: string | null,
  email?: string | null
) {
  return axios.put<{ result: FuncionarioView }>(FUNCIONARIO_URL + '/empresa/' + idEmpresa, {
    cpf,
    email,
    cargo,
    crm: crm !== '' ? crm : null,
    uf: uf,
  })
}

export function confirmarConta(idFuncionario: ID) {
  return axios.patch<{ result: UsuarioView }>(`${API_URL}/usuarios/${idFuncionario}/confirma`)
}

export function alterarSenha(idFuncionario: ID, novaSenha: string, confirmaNovaSenha: string) {
  return axios.put<{ result: UsuarioView }>(`${API_URL}/usuarios/${idFuncionario}/password`, {
    id: idFuncionario,
    novaSenha,
    confirmaNovaSenha,
  })
}

const updateFuncionario = (
  ativo: boolean,
  primeiroNome: string,
  sobreNome: string,
  cpf: string,
  cns: string,
  telefone: string | null,
  sexo: string,
  nascimento: string,
  cargo: string,
  crm: string,
  uf: string | null,
  idFuncionario: string,
  idPessoa: string,
  email: string | null,
  especialidades: number[] | null
): Promise<FuncionarioView> => {
  return axios
    .put(`${FUNCIONARIO_URL}/${idFuncionario}`, {
      ativo,
      cargo,
      crm: crm === 'null' || crm === '' ? null : crm,
      uf,
      pessoa: {
        id: idPessoa,
        primeiroNome,
        sobreNome,
        cpf,
        cns: cns === 'null' || cns === '' ? null : cns,
        telefone,
        nascimento,
        sexo,
      },
      email: email || null,
      especialidades,
    })
    .then((response: AxiosResponse<FuncionarioView>) => response.data)
}
export const createEnderecoFuncionario = (
  idFuncionario: ID,
  cep: string | null,
  logradouro: string | null,
  numero: string | null,
  complemento: string | null,
  bairro: string | null,
  cidade: string | null,
  uf: string
): Promise<EnderecoView> => {
  return axios.post(`${FUNCIONARIO_URL}/endereco/${idFuncionario}`, {
    tipo: 1,
    cep: cep,
    ativo: true,
    logradouro: logradouro,
    numero: numero,
    complemento: complemento,
    bairro: bairro,
    cidade: cidade,
    uf: uf,
  })
}
export const updateEnderecoFuncionario = (
  idFuncionario: ID,
  idEndereco: Number,
  cep: string | null,
  logradouro: string | null,
  numero: string | null,
  complemento: string | null,
  bairro: string | null,
  cidade: string | null,
  uf: string
): Promise<EnderecoView> => {
  return axios.put(`${FUNCIONARIO_URL}/endereco/${idFuncionario}`, {
    id: idEndereco,
    ativo: true,
    cep: cep,
    logradouro: logradouro,
    numero: numero,
    complemento: complemento,
    bairro: bairro,
    cidade: cidade,
    uf: uf,
  })
}
export const getEnderecoByIdFuncionario = (id: ID) => {
  return axios
    .get<EnderecoList[]>(`${FUNCIONARIO_URL}/endereco/${id}`)
    .then((response) => response.data)
    .catch((error) => console.error('Erro ao buscar endereço:', error))
}
export const getEnderecoPessoaId = (id: ID) => {
  return axios
    .get<EnderecoList[]>(`${API_URL}/enderecos/pessoa/${id}`)
    .then((response) => response.data)
    .catch((error) => console.error('Erro ao buscar endereço:', error))
}

const deleteFuncionario = (FuncionarioId: ID): Promise<void> => {
  return axios.delete(`${FUNCIONARIO_URL}/${FuncionarioId}`)
}
const inativarFuncionario = (FuncionarioId: ID): Promise<void> => {
  return axios.put(`${FUNCIONARIO_URL}/${FuncionarioId}`, {
    ativo: false,
  })
}

const ativarFuncionario = (FuncionarioId: ID): Promise<void> => {
  return axios.put(`${FUNCIONARIO_URL}/${FuncionarioId}`, {
    ativo: true,
  })
}

const deleteSelectedFuncionario = (FuncionarioIds: Array<ID>): Promise<void> => {
  const requests = FuncionarioIds.map((id) => axios.delete(`${FUNCIONARIO_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {
  getFuncionarios,
  inativarFuncionario,
  getFuncionariosEmpresa,
  getMemedFuncionarioById,
  getFuncionarioPessoaEmpresa,
  getMemedFuncionario,
  deleteFuncionario,
  deleteSelectedFuncionario,
  getFuncionarioById,
  updateFuncionario,
  ativarFuncionario,
}
