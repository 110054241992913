import axios, { AxiosResponse } from 'axios'
import { ColaboradorList, ColaboradorListQueryResponse } from './_models'
import { ID } from '../../../../../../_metronic/helpers'
import { ClienteView } from '../../empresasCliente-list/core/_models'

const API_URL = process.env.REACT_APP_API_URL
const EMPRESAS_CLIENTE_COLABORADOR_URL = `${API_URL}/empresas/cliente/colaborador`

export const getCoordernadores = (idEmpresa: ID): Promise<ColaboradorListQueryResponse> => {
  return axios
    .get(`${EMPRESAS_CLIENTE_COLABORADOR_URL}/${idEmpresa}`)
    .then((resp: AxiosResponse<ColaboradorListQueryResponse>) => resp.data)
}

export const getColaboradorPcmso = (idEmpresa: ID) => {
  return axios
    .get(`${EMPRESAS_CLIENTE_COLABORADOR_URL}/${idEmpresa}`)
    .then((response: AxiosResponse<ColaboradorList[] | undefined>) => response.data)
}

export const createCoordenador = (
  idColaborador: ID,
  nome: string,
  crm: string,
  rqe: string,
  uf: string
): Promise<ClienteView | undefined> => {
  return axios
    .post(`${EMPRESAS_CLIENTE_COLABORADOR_URL}/${idColaborador}`, {
      nome,
      crm,
      rqe,
      uf,
    })
    .then((resp: AxiosResponse<ClienteView>) => resp.data)
}

export const alteraCoordernador = (
  idEmpresaCliente: ID,
  idColaborador: ID,
  nome: string,
  crm: string,
  rqe: string,
  uf: string
): Promise<ClienteView | undefined> => {
  return axios.put(`${EMPRESAS_CLIENTE_COLABORADOR_URL}/${idEmpresaCliente}`, {
    id: idColaborador,
    nome,
    crm,
    rqe,
    uf,
  })
}

export const deleteLoteCoordenadoresCliente = async (coordenadoresId: ID[]): Promise<void> => {
  try {
    const requests = coordenadoresId.map((id) =>
      axios.delete(`${EMPRESAS_CLIENTE_COLABORADOR_URL}/${id}`)
    )
    await Promise.all(requests)
  } catch (error) {
    console.error('Erro deletando coordenadores', error)
    throw error
  }
}
