/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC } from 'react'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import { PrivateRoutes } from './PrivateRoutes'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { Logout, AuthPage, useAuth } from '../modules/auth'
import { App } from '../App'
import ResetPasswordPage from '../modules/auth/components/ResetPasswordPage'
import { AutenticacaoASO } from '../modules/apps/atendimento-ASO/Autenticacao/AutenticacaoASO'
import { TeleConsultaPage } from '../modules/tele-consulta'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env

const AppRoutes: FC = () => {
  const { currentUser } = useAuth()
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {currentUser ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              {/* <PrivateRoutes path="/apps/empresas" role={['ADMIN','SUPER_ADMIN']} /> */}
              {/* <Route index element={<Navigate to='/admin' />} /> */}
            </>
          ) : (
            <>
              <Route path='reset-password/*' element={<ResetPasswordPage />} />
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='confirm-account/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
          <Route path='tele-consulta/*' element={<TeleConsultaPage />} />
          <Route path='autenticar/*' element={<AutenticacaoASO />} />
          <Route path='authenticate/*' element={<AutenticacaoASO />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export { AppRoutes }
