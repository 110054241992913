import clsx from 'clsx'
import { FC } from 'react'
import { Row } from 'react-table'
import { IconPaths, KTSVG } from '../../../../../_metronic/helpers'
import { ExpandRow } from './ExpandRow'

type Props<T extends object = {}> = {
  row: Row<T>
  isExpanded?: boolean
  toggleRow?: () => void
  children?: React.ReactNode
}

const CustomRow = <T extends object = {}>({
  row,
  isExpanded = false,
  toggleRow,
  children,
}: Props<T>): JSX.Element => {
  return (
    <>
      <tr {...row.getRowProps()} style={{ borderBottom: '1px solid var(--bs-gray-300)' }}>
        {row.cells.map((cell) => {
          const isInner = cell.column.id.startsWith('inner-')
          if (isInner) return
          return (
            <td
              {...cell.getCellProps()}
              className={clsx({ 'text-end min-w-100px': cell.column.id === 'actions' })}
            >
              {cell.render('Cell')}
              {cell.column.id === 'expand' && toggleRow && (
                <ExpandRow isExpanded={isExpanded} onToggle={toggleRow} />
              )}
            </td>
          )
        })}
      </tr>
      {isExpanded && (
        <tr {...row.getRowProps()} key={`${row.getRowProps().key}-extended`}>
          <td colSpan={row.cells.length} className='p-0'>
            {children ? children : null}
          </td>
        </tr>
      )}
    </>
  )
}

export { CustomRow }
