import { AxiosError } from 'axios'
import { Button, Modal } from 'react-bootstrap'
import Swal from 'sweetalert2'
import { AtendimentoView } from '../../../../atendimento/core/_models'
import {
  getAtendimentoById,
  marcarPresenca,
  marcarPresencaPaciente,
} from '../../../../atendimento/core/_requests'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { AtendimentoMedicoInfo } from '../AtendimentoInfo/AtendimentoInfo'
import { useQuery } from 'react-query'
import { QUERIES } from '../../../../../../../_metronic/helpers'
import { useState } from 'react'

interface MarcarPresencaModalProps {
  atendimento: AtendimentoView
  showMarcarPresenca: boolean
  isMedico?: boolean
  onClose: () => void
}

const MarcarPresencaModal: React.FC<MarcarPresencaModalProps> = ({
  atendimento,
  showMarcarPresenca,
  onClose,
  isMedico = false,
}) => {
  const { refetch } = useQueryResponse()
  const [disable, setDisable] = useState(true)

  const handlePresencaAtendimento = async () => {
    try {
      await marcarPresencaPaciente(atendimento?.id)
      Swal.fire({
        text: 'Presença marcarcada com sucesso',
        icon: 'success',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
      refetch()
      onClose()
    } catch (error) {
      const err = error as AxiosError
      Swal.fire({
        text: `${error}`,
        icon: 'error',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    }
  }

  const {
    isLoading,
    data: atendimentoMedico,
    error,
  } = useQuery(
    `${QUERIES.ATENDIMENTO_MEDICO_LIST}-atendimentoMedico-${atendimento?.id}`,
    () => {
      return getAtendimentoById(atendimento?.id)
    },
    {
      cacheTime: 0,
      onError: (err) => {
        console.error(err)
      },
    }
  )

  return (
    <Modal className='modal-lg' show={showMarcarPresenca} onHide={onClose}>
      <Modal.Body>
        {isLoading ? (
          <>Carregando...</>
        ) : (
          <>
            <AtendimentoMedicoInfo
              atendimentoMedico={atendimentoMedico as AtendimentoView}
              isMarcarPresencaModal
              setDisable={setDisable}
            />
            <div className='d-flex justify-content-end mt-6'>
              <Button variant='secondary' onClick={onClose} className='me-3'>
                Cancelar
              </Button>
              <Button variant='success' onClick={handlePresencaAtendimento} disabled={disable}>
                Marcar Presença
              </Button>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  )
}

export { MarcarPresencaModal }
