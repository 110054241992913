import { FC, ReactNode } from 'react'
import { IconPaths, KTSVG } from '../../../_metronic/helpers'

type IDropdown = {
  children: ReactNode
  name: string
  isBottom?: boolean
  size?: number
}

export const Dropdown: FC<IDropdown> = ({ children, name, isBottom, size }) => {
  return (
    <>
      <a
        href='#'
        className='btn btn-secondary btn-active-secondary-primary btn-sm w-auto d-inline-flex align-items-center justify-content-center'
        data-kt-menu-trigger='click'
        data-kt-menu-placement={isBottom ? 'bottom-end' : 'top-end'}
      >
        <span>{name}</span>
        <KTSVG path={IconPaths.Arrow} className='svg-icon-5 m-0' />
      </a>
      <div
        className={`menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-${size}px py-4`}
        data-kt-menu='true'
      >
        {children}
      </div>
    </>
  )
}

Dropdown.defaultProps = {
  name: 'Default Name',
  isBottom: true,
  size: 125,
}
