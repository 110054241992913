import { useListView } from '../../core/ListViewProvider'

import { VincularFormularioModalForm } from './VincularFormularioModalForm'
import { FC, useMemo } from 'react'
import { useQueryResponseData } from '../../core/QueryResponseProvider'
import { useSearchParams } from 'react-router-dom'

type IProps = {
  id: string
}

const VincularFormularioModalWrapper: FC<IProps> = ({ id }) => {
  const { itemIdForUpdate, setItemIdForUpdate } = useListView()
  const vinculos = useQueryResponseData()
  const [searchParams] = useSearchParams()
  const formId = Number(searchParams.get('id'))
  const vinculo = useMemo(
    () => vinculos.find((item) => item.especialidade.id === itemIdForUpdate),
    [vinculos, itemIdForUpdate]
  )

  if (!itemIdForUpdate) {
    return <VincularFormularioModalForm id={id} formularioId={formId} />
  }

  if (itemIdForUpdate) {
    return <VincularFormularioModalForm id={id} formularioId={formId} vinculo={vinculo} />
  }

  return null
}

export { VincularFormularioModalWrapper }
