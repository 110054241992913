import { KTCard } from '../../../../../_metronic/helpers'
import { ListViewProvider, useListView } from '../core/ListViewProvider'
import { QueryRequestProvider } from '../core/QueryRequestProvider'
import { QueryResponseProvider } from '../core/QueryResponseProvider'
import { AtendimentoEditModal } from './atendimento-edit-modal/AtendimentoEditModal'
import { AtendimentosListHeader } from './components/header/AtendimentosListHeader'
import { AtendimentosTable } from './table/AtendimentosTable'

const AtendimentoList = () => {
  const { itemIdForUpdate } = useListView()
  return (
    <>
      <div className='d-flex justify-content-end mb-4'>
        <a
          href='#'
          className='btn btn-success fw-bold fs-4 w-100 w-md-auto'
          data-bs-toggle='modal'
          data-bs-target='#kt_atendimento_modal'
        >
          Solicitar Atendimento
        </a>
      </div>
      <KTCard>
        <AtendimentosListHeader />
        <AtendimentosTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <AtendimentoEditModal />}
    </>
  )
}

const AtendimentosListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <AtendimentoList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { AtendimentosListWrapper }
