import { FC, useEffect, useState } from 'react'
import { Carousel } from 'react-bootstrap'

type ICustomCarousel = {
  items: { mediaUrl: string; title: string; type: string }[]
  initialIndex: number | undefined
}

export const CustomCarousel: FC<ICustomCarousel> = ({ items, initialIndex }) => {
  const [activeIndex, setActiveIndex] = useState<number | undefined>(initialIndex || 0)
  const [pauseButtonVisible, setPauseButtonVisible] = useState(false)

  useEffect(() => {
    setActiveIndex(initialIndex)
  }, [items, initialIndex])

  const handleSelect = (selectedIndex: number) => {
    setActiveIndex(selectedIndex)
  }

  return (
    <Carousel activeIndex={activeIndex} onSelect={handleSelect} interval={null} indicators={false}>
      {items.map((item, index) => (
        <Carousel.Item key={index}>
          {item.type.startsWith('image') ? (
            <img
              src={item.mediaUrl}
              alt={item.title}
              className='d-block w-100'
              style={{ maxHeight: '90vh', objectFit: 'contain' }}
            />
          ) : item.type.startsWith('video') ? (
            <div
              className='video-container'
              style={{
                position: 'relative',
                maxHeight: '90vh',
                objectFit: 'contain',
              }}
            >
              <video
                id={`video-${index}`}
                className='d-block w-100'
                style={{
                  maxHeight: '90vh',
                  objectFit: 'contain',
                  position: 'relative',
                }}
                onPlay={() => {
                  setPauseButtonVisible(true)
                  setTimeout(() => {
                    setPauseButtonVisible(false)
                  }, 2000)
                }}
                onClick={(e) => {
                  const video = e.target as HTMLVideoElement
                  if (video.paused) {
                    video.play()
                  } else {
                    video.pause()
                  }
                }}
              >
                <source src={item.mediaUrl} type={item.type} />
                Seu navegador não suporta a tag de vídeo.
              </video>

              <div
                onClick={(e) => {
                  const video = document.getElementById(`video-${index}`) as HTMLVideoElement | null
                  if (video && video.paused) {
                    video.play()
                    const playButton = e.target as HTMLButtonElement
                    playButton.style.display = 'none'
                  }
                }}
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  zIndex: 1056,

                  padding: '20px',
                  borderRadius: '50%',
                  cursor: 'pointer',
                }}
              >
                <img
                  src='https://img.icons8.com/ios-filled/50/ffffff/play.png'
                  alt='Play'
                  style={{ width: '50px', height: '50px' }}
                />
              </div>

              {pauseButtonVisible && (
                <div
                  className='pause-button-overlay'
                  onClick={(e) => {
                    const video = document.getElementById(
                      `video-${index}`
                    ) as HTMLVideoElement | null
                    if (video) {
                      video.pause()
                      setPauseButtonVisible(false)
                    }
                  }}
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 1057,
                    padding: '20px',
                    borderRadius: '50%',
                    cursor: 'pointer',
                  }}
                >
                  <img
                    src='https://img.icons8.com/ios-filled/50/ffffff/pause.png'
                    alt='Pause'
                    style={{ width: '50px', height: '50px' }}
                  />
                </div>
              )}
            </div>
          ) : (
            <p>Tipo de dados não suportados</p>
          )}
        </Carousel.Item>
      ))}
    </Carousel>
  )
}
