import React, { FC, ReactNode } from 'react'
import { KTSVG } from '../../../_metronic/helpers'

type IDropdownItem = {
  onClick: () => void
  children: ReactNode
}

export const DropdownItem: FC<IDropdownItem> = ({ onClick, children }) => {
  return (
    <div className='menu-item text-start px-3'>
      <a className='menu-link px-3' onClick={onClick}>
        {children}
      </a>
    </div>
  )
}
