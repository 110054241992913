import { FC, useEffect, useState } from 'react'
import { useListView } from '../../core/ListViewProvider'
import { MenuComponent } from '../../../../../../../_metronic/assets/ts/components'
import { Dropdown, DropdownItem } from '../../../../../../components'
import { useMutation, useQueryClient } from 'react-query'
import { QUERIES } from '../../../../../../../_metronic/helpers'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { AxiosError } from 'axios'
import Swal from 'sweetalert2'
import { deleteVinculo } from '../../core/_requests'
import { useSearchParams } from 'react-router-dom'

type Props = {
  row: any
}

const VinculosActionsCell: FC<Props> = ({ row }) => {
  const { setItemIdForUpdate } = useListView()
  const { query, refetch } = useQueryResponse()
  const queryClient = useQueryClient()
  const [searchParams] = useSearchParams()
  const formId = Number(searchParams.get('id'))

  const openModal = () => setItemIdForUpdate(row.original.especialidade.id)

  useEffect(() => MenuComponent.reinitialization(), [])

  const refresh = () => {
    setItemIdForUpdate(undefined)
    refetch()
  }

  const deleteEspecialidade = useMutation(
    () => deleteVinculo(formId, row.original.especialidade.id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([`${QUERIES.FORMULARIOS_LIST}-${query}`])
        Swal.fire({
          text: `Especialidade deletada do vínculo.`,
          icon: 'success',
          buttonsStyling: false,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        }).then(() => refresh())
      },
      onError(error, variables, context) {
        const err = error as AxiosError
        Swal.fire({
          text: err.response?.data.message,
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      },
    }
  )

  return (
    <Dropdown name='Ações'>
      <DropdownItem onClick={openModal}>Editar</DropdownItem>
      <DropdownItem onClick={async () => await deleteEspecialidade.mutateAsync()}>
        Excluir
      </DropdownItem>
      {/* <DropdownItem
        onClick={async () => await toggleActive.mutateAsync()}
      >
        {row.original.ativo ? 'Inativar' : 'Ativar'}
      </DropdownItem>
      <DropdownItem onClick={openModal}>Vincular</DropdownItem>
      <DropdownItem onClick={async () => await deleteForm.mutateAsync()}>Excluir</DropdownItem> */}
    </Dropdown>
  )
}

export { VinculosActionsCell }
