import { createContext, FC, useContext, useMemo, useState } from 'react'
import {
  calculatedGroupingIsDisabled,
  calculateIsAllDataSelected,
  ID,
  WithChildren,
} from '../../../../../../_metronic/helpers'
import { useQueryResponse, useQueryResponseData } from './QueryResponseProvider'
import {
  FormularioAtendimentoList,
  IAvaliacoesSelect,
  ListViewContextPropsAvaliacoes,
} from '../../avaliacoes-pendentes-list/core/_models'
import { initialListViewAvaliacoes } from './_models'

const ListViewContext = createContext<ListViewContextPropsAvaliacoes>(initialListViewAvaliacoes)

const ListViewProvider: FC<WithChildren> = ({ children }) => {
  const data = useQueryResponseData()

  const [selected, setSelected] = useState<IAvaliacoesSelect[]>(initialListViewAvaliacoes.selected)

  const isAllSelected = useMemo(() => calculateIsAllDataSelected(data, selected), [data, selected])

  const { isLoading } = useQueryResponse()
  const disabled = useMemo(() => calculatedGroupingIsDisabled(isLoading, data), [isLoading, data])

  const groupingOnSelect = (ids: IAvaliacoesSelect, selected: IAvaliacoesSelect[]) => {
    if (!ids) {
      return
    }
    const itemIndex = selected.findIndex(
      (element) =>
        element.atendimentoId === ids.atendimentoId &&
        element.formularioId === ids.formularioId &&
        element.avaliacaoId === ids.avaliacaoId
    )

    if (itemIndex > -1) {
      setSelected([...selected.slice(0, itemIndex), ...selected.slice(itemIndex + 1)])
    } else {
      setSelected([...selected, ids])
    }
  }

  const groupingOnSelectAll = (isAllSelected: boolean, data?: FormularioAtendimentoList[]) => {
    if (isAllSelected) {
      setSelected([])
      return
    }

    if (!data || !data.length) {
      return
    }

    setSelected(
      data.map((item) => ({
        avaliacaoId: item.id,
        atendimentoId: item.atendimento.id,
        formularioId: item.formulario.id,
      }))
    )
  }

  return (
    <ListViewContext.Provider
      value={{
        selected,
        disabled,

        isAllSelected,
        onSelect: (ids: IAvaliacoesSelect) => {
          groupingOnSelect(ids, selected)
        },
        onSelectAll: () => {
          groupingOnSelectAll(isAllSelected, data)
        },
        clearSelected: () => {
          setSelected([])
        },
      }}
    >
      {children}
    </ListViewContext.Provider>
  )
}

const useListView = () => useContext(ListViewContext)

export { ListViewProvider, useListView }
