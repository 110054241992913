import { VinculosListSearchComponent } from './VinculosListSearchComponent'

const VinculosListHeader = () => {
  return (
    <div className='card-header border-0 pt-6'>
      <VinculosListSearchComponent />
    </div>
  )
}

export { VinculosListHeader }
