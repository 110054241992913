import * as Yup from 'yup'
import { FormularioCampoView } from '../../../../../formularios/formularios-create/core/_models'
import { InputType } from '../../../../../../../../_metronic/helpers'

export const createValidationSchema = (campos: FormularioCampoView[]) => {
  return Yup.object().shape(
    campos.reduce((acc: Record<string, any>, field) => {
      if (field.obrigatorio) {
        if (field.tipo === InputType.Number) {
          acc[String(field.id)] = Yup.number().required('Este campo é obrigatório')
        } else if (field.tipo === InputType.Select) {
          acc[String(field.id)] = field.multiplo
            ? Yup.array()
                .min(1, 'Selecione pelo menos uma opção') // Validate user has selected at least one option
                .required('Este campo é obrigatório') // Required for multi-select
            : Yup.mixed()
                .test('valid-selection', 'Selecione uma opção válida', (value) => {
                  return value && value.value // Ensure the user has selected something
                })
                .required('Este campo é obrigatório') // Required for single-select
        } else {
          acc[String(field.id)] = Yup.string().required('Este campo é obrigatório')
        }
      }
      return acc
    }, {})
  )
}
