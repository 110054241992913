import axios, { AxiosResponse } from 'axios'
import { ID, Response, isNotEmpty } from '../../../../../../_metronic/helpers'
import {
  PacienteDtoCreate,
  PacienteDtoUpdate,
  PacienteDtoUpdateAso,
  PacientesQueryResponse,
  PacienteView,
} from './_models'
import { Picture } from '../../../../auth'
import {
  EnderecoDtoCreate,
  EnderecoDtoUpdate,
  EnderecoList,
  EnderecoView,
} from '../../../empresa/empresas-list/core/_models'

const API_URL = process.env.REACT_APP_API_URL
const PACIENTE_URL = `${API_URL}/pacientes`
const ATENDIMENTOS_URL = `${API_URL}/atendimentos`
const PACIENTES_EMPRESAS_URL = `${API_URL}/pacientes/empresa/`
const GET_PACIENTES_URL = `${API_URL}/pacientes`

const getPacientes = (query: string): Promise<PacientesQueryResponse> => {
  return axios
    .get(`${GET_PACIENTES_URL}?${decodeURIComponent(query)}`)
    .then((d: AxiosResponse<PacientesQueryResponse>) => d.data)
}
const getPacientesEmpresa = (id: string, query: string): Promise<PacientesQueryResponse> => {
  return axios
    .get(`${PACIENTES_EMPRESAS_URL}${id}?${query}`)
    .then((d: AxiosResponse<PacientesQueryResponse>) => d.data)
}

const getPacienteById = (id: ID): Promise<PacienteView> => {
  return axios
    .get(`${PACIENTE_URL}/${id}`)
    .then((response: AxiosResponse<PacienteView>) => response.data)
}

export const downloadProntuario = (id: string): Promise<any> => {
  return axios
    .get(`${ATENDIMENTOS_URL}/prontuario/${id}`)
    .then((response: AxiosResponse<any>) => response.data)
}
export function getPacienteLogado() {
  return axios.get(`${PACIENTE_URL}/me`).then((response) => response.data)
}

const createPaciente = (
  idEmpresa: ID,
  paciente: PacienteDtoCreate
): Promise<PacienteView | undefined> => {
  return axios
    .post(`${PACIENTE_URL}/empresa/${idEmpresa}`, paciente)
    .then((response: AxiosResponse<PacienteView>) => response.data)
}

export const updateAsoPaciente = (
  id: ID,
  pacienteAso: PacienteDtoUpdateAso
): Promise<PacienteView | undefined> => {
  return axios
    .put(PACIENTE_URL + '/aso/' + id, pacienteAso)
    .then((response: AxiosResponse<PacienteView>) => response.data)
}
export const createEnderecoPaciente = (
  idPaciente: ID,
  endereco: EnderecoDtoCreate
): Promise<EnderecoView | undefined> => {
  return axios.post(`${PACIENTE_URL}/endereco/${idPaciente}`, endereco)
}
export const updateEnderecoPaciente = (
  pacienteId: number,
  endereco: EnderecoDtoUpdate
): Promise<EnderecoView | undefined> => {
  return axios.put(`${PACIENTE_URL}/endereco/${pacienteId}`, endereco)
}
export const getEnderecoByIdPaciente = (id: ID) => {
  return axios.get<EnderecoList[]>(`${PACIENTE_URL}/endereco/${id}`)
}
export function vincularPacienteEmpresa(cpf: string, prontuario: string, idEmpresa: String) {
  return axios.put<{ result: PacienteView }>(PACIENTE_URL + '/empresa/' + idEmpresa, {
    cpf,
    prontuario,
  })
}

const updatePaciente = (
  idPaciente: number,
  paciente: PacienteDtoUpdate
): Promise<PacienteView | undefined> => {
  return axios
    .put(`${PACIENTE_URL}/${idPaciente}`, paciente)
    .then((response: AxiosResponse<PacienteView>) => response.data)
}
export const atualizaEmailPaciente = (
  idPaciente: ID,
  email: string
): Promise<PacienteView | undefined> => {
  return axios
    .put(`${PACIENTE_URL}/${idPaciente}`, {
      email: email,
    })
    .then((response: AxiosResponse<PacienteView>) => response.data)
}

const adicionaAlergia = (idPaciente: ID, descricao: String): Promise<any> => {
  return axios
    .put(`${PACIENTE_URL}/alergia/${idPaciente}`, {
      descricao,
    })
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data)
}

const deletePaciente = (pacienteId: ID): Promise<void> => {
  return axios.delete(`${PACIENTE_URL}/${pacienteId}`)
}
const inativarPaciente = (pacienteId: ID): Promise<void> => {
  return axios.put(`${PACIENTE_URL}/${pacienteId}`, {
    ativo: false,
  })
}
const deleteSelectedPaciente = (pacienteIds: Array<ID>): Promise<void> => {
  const requests = pacienteIds.map((id) => axios.delete(`${PACIENTE_URL}/${id}`))
  return axios.all(requests).then(() => {})
}
export const getAvatarPaciente = (idPaciente: ID): Promise<Picture | undefined> => {
  return axios
    .get(`${PACIENTE_URL}/${idPaciente}/avatar`)
    .then((response: AxiosResponse<Picture>) => response.data)
}

export {
  getPacientes,
  inativarPaciente,
  getPacientesEmpresa,
  adicionaAlergia,
  deletePaciente,
  deleteSelectedPaciente,
  getPacienteById,
  createPaciente,
  updatePaciente,
}
