import axios, { AxiosResponse } from 'axios'
import {
  AtendimentoView,
  AtendimentoIniciadoQueryResponse,
  AtendimentoPacienteQueryResponse,
  AtendimentoQueryResponse,
  AtendimentosQueryResponse,
  CIDQueryResponse,
  EstadosDeAtendimentoQueryResponse,
  PendenciaAso,
  TipoASO,
  TipoAtendimentoEmpresa,
  ArquivoDto,
  AtendimentoDtoInformacaoAgrupada,
} from './_models'
import { ID, ISituacaoAso, Response } from '../../../../../_metronic/helpers'
import { Prescricao } from '../../atendimento_medicos/memed/_models'
import Swal from 'sweetalert2'
import {
  FormularioAtendimentoDto,
  FormularioAtendimentoView,
} from '../../atendimento_medicos/atendimento-video/core/_models'

const API_URL = process.env.REACT_APP_API_URL
const ATENDIMENTO_URL = `${API_URL}/atendimentos`
const CID_URL = `${API_URL}/cid`
const FATOR_RISCO_URL = `${API_URL}/fatoresRisco`
const TIPOS_URL = `${API_URL}/tipos`
const ESTADOS_URL = `${ATENDIMENTO_URL}/estados`
const ATENDIMENTO_FILA_URL = `${API_URL}/atendimentos/empresa/`
const ATENDIMENTO_FILA_PACIENTE_URL = `${API_URL}/atendimentos/empresa/paciente/`
const ATENDIMENTO_PACIENTE_URL = `${API_URL}/atendimentos/paciente`
const ATENDIMENTO_ANOTACAO_URL = `${API_URL}/atendimentos/anotacao`
const ATENDIMENTO_FORMULARIO_URL = `${API_URL}/atendimentos/formulario`
const ATENDIMENTO_PENDENTE_URL = `${API_URL}/atendimentos/pendente`

export function iniciarAtendimento(idPacienteEmpresa: string, tipo: string, tipoAso?: string) {
  return axios.post(ATENDIMENTO_PACIENTE_URL, {
    paciente: idPacienteEmpresa,
    tipo: tipo,
    tipoAso: tipoAso,
  })
}

export function iniciarAtendimentoPaciente(
  idPacienteEmpresa: string,
  tipo: string,
  tipoAso?: string
) {
  return axios.post(`${ATENDIMENTO_PACIENTE_URL}/${idPacienteEmpresa}`, {
    tipo: tipo,
    tipoAso: tipoAso,
  })
}

export function iniciarAtendimentoProfissional(
  idPacienteEmpresa: string,
  tipo: string,
  data?: string
) {
  return axios.post(`${ATENDIMENTO_URL}/${idPacienteEmpresa}`, {
    tipo: tipo,
    data: data !== '' ? data : null,
  })
}
export function agendaAtendimentoProfissional(
  idPacienteEmpresa: string,
  tipo: string,
  retorno: boolean,
  data?: string,
  pcmso?: ID,
  cliente?: ID,
  especialidade?: ID
) {
  return axios.post(`${ATENDIMENTO_URL}/agenda/${idPacienteEmpresa}`, {
    tipo: tipo,
    data: data !== '' ? data : null,
    pcmso: pcmso || null,
    cliente: cliente || null,
    especialidade,
    retorno,
  })
}

export const getAtendimentos = (query: string): Promise<AtendimentoIniciadoQueryResponse> => {
  return axios
    .get(`${ATENDIMENTO_PACIENTE_URL}?${query}`)
    .then((d: AxiosResponse<AtendimentoIniciadoQueryResponse>) => d.data)
}
export const getAtendimentosMedico = (query: string): Promise<AtendimentoQueryResponse> => {
  return axios
    .get(`${ATENDIMENTO_URL}/?${query}`)
    .then((d: AxiosResponse<AtendimentoIniciadoQueryResponse>) => d.data)
}
export const getAtendimentosMedicoByEmpresa = (
  query: string
): Promise<AtendimentoQueryResponse> => {
  return axios
    .get(`${ATENDIMENTO_URL}/relatorio/?${query}`)
    .then((d: AxiosResponse<AtendimentoIniciadoQueryResponse>) => d.data)
}

export const getAtendimentosRelatorio = (id: string): Promise<AtendimentosQueryResponse> => {
  return axios
    .get(`${ATENDIMENTO_URL}/empresa/${id}`)
    .then((d: AxiosResponse<AtendimentosQueryResponse>) => d.data)
}

export const downloadProntuario = (idAtendimento: ID): Promise<ArquivoDto> => {
  return axios
    .get(`${ATENDIMENTO_URL}/download/${idAtendimento}`)
    .then((d: AxiosResponse<ArquivoDto>) => d.data)
}

export const alteraAtendimentoASO = (
  idAtendimento: ID,
  matricula: string,
  cargo: string | null,
  setor: string | null,
  idCargo: ID,
  idSetor: ID,
  cliente?: ID,
  pcmso?: ID
): Promise<AtendimentoView | undefined> => {
  return axios
    .put(`${ATENDIMENTO_URL}/aso/${idAtendimento}`, {
      matricula: matricula,
      cargo: cargo,
      setor: setor,
      cargoAso: idCargo,
      setorAso: idSetor,
      cliente: cliente || null,
      pcmso: pcmso || null,
    })
    .then((response: AxiosResponse<Response<AtendimentoView>>) => response.data)
    .then((response: Response<AtendimentoView>) => response.data)
}
export const getCIDs = (query: string): Promise<CIDQueryResponse> => {
  return axios.get(`${CID_URL}?${query}`).then((d: AxiosResponse<CIDQueryResponse>) => d.data)
}
export const getAtendimentosPaciente = (
  idPaciente: ID,
  query: string
): Promise<AtendimentoIniciadoQueryResponse> => {
  return axios
    .get(`${ATENDIMENTO_FILA_PACIENTE_URL}${idPaciente}?${query}`)
    .then((d: AxiosResponse<AtendimentoIniciadoQueryResponse>) => d.data)
}
export const getAtendimentosHistorico = (
  idPaciente: ID,
  query: string
): Promise<AtendimentoPacienteQueryResponse> => {
  return axios
    .get(`${ATENDIMENTO_FILA_PACIENTE_URL}${idPaciente}?${query}`)
    .then((d: AxiosResponse<AtendimentoIniciadoQueryResponse>) => d.data)
}
export const getAtendimentosEmpresaAguardando = (
  idEmpresa: string,
  query: string
): Promise<AtendimentoQueryResponse> => {
  console.log(`getAtendimentosEmpresaAguardando`, query)
  const queryWithoutFilter0 = query.replace(/&?filter_0=\d*/, '')
  return axios
    .get(`${ATENDIMENTO_FILA_URL}${idEmpresa}?${queryWithoutFilter0}`)
    .then((d: AxiosResponse<AtendimentoQueryResponse>) => d.data)
}
export const getAtendimentoById = (id: ID): Promise<AtendimentoView | undefined> => {
  return axios
    .get(`${ATENDIMENTO_URL}/${id}`)
    .then((response: AxiosResponse<AtendimentoView>) => response.data)
}
export const cancelarAtendimentoById = (
  id: ID,
  observacao: string
): Promise<AtendimentoView | undefined> => {
  return axios
    .put(`${ATENDIMENTO_URL}/cancela/${id}`, { observacao })
    .then((response: AxiosResponse<AtendimentoView>) => response.data)
}

export const faltaAtendimentoById = (
  id: ID,
  observacao: string
): Promise<AtendimentoView | undefined> => {
  return axios
    .put(`${ATENDIMENTO_URL}/falta/${id}`, { observacao })
    .then((response: AxiosResponse<AtendimentoView>) => response.data)
}
export const marcarPresenca = (id: ID): Promise<AtendimentoView | undefined> => {
  return axios
    .patch(`${ATENDIMENTO_URL}/paciente/${id}`)
    .then((response: AxiosResponse<AtendimentoView>) => response.data)
}
export const marcarPresencaPaciente = (id: ID): Promise<AtendimentoView | undefined> => {
  return axios
    .put(`${ATENDIMENTO_URL}/presenca/${id}`)
    .then((response: AxiosResponse<AtendimentoView>) => response.data)
}
export const reabrirAtendimentoById = (
  id: ID,
  observacao: string
): Promise<AtendimentoView | undefined> => {
  return axios
    .put(`${ATENDIMENTO_URL}/reabre/${id}`, { observacao })
    .then((response: AxiosResponse<AtendimentoView>) => response.data)
}
export const getTiposAtendimentosByPaciente = (id: ID) => {
  return axios
    .get<TipoAtendimentoEmpresa[]>(`${ATENDIMENTO_PACIENTE_URL}/${id}/tipos`)
    .then((response: AxiosResponse<TipoAtendimentoEmpresa[]>) => response.data)
}
export const generateAso = (idAtendimento: ID) => {
  return axios
    .put<ArquivoDto>(`${ATENDIMENTO_URL}/aso/generate/${idAtendimento}`)
    .then((response: AxiosResponse<ArquivoDto>) => response.data)
}
export const downloadAso = (idAtendimento: ID) => {
  return axios
    .get<ArquivoDto>(`${ATENDIMENTO_URL}/aso/download/${idAtendimento}`)
    .then((response: AxiosResponse<ArquivoDto>) => response.data)
}
export const downloadAnexo = (idAtendimento: ID, idAnexo: ID) => {
  return axios
    .get<ArquivoDto>(`${ATENDIMENTO_URL}/download/${idAtendimento}/${idAnexo}`)
    .then((response: AxiosResponse<ArquivoDto>) => response.data)
}
export const generateSelectedAso = (atendimentosIds: Array<ID>): Promise<void> => {
  const requests = atendimentosIds.map((id) => axios.put(`${ATENDIMENTO_URL}/aso/generate/${id}`))
  return axios.all(requests).then(() => {})
}
export const singSelectedAso = (atendimentosIds: Array<ID>, senha: String): Promise<void> => {
  const requests = atendimentosIds.map((id) =>
    axios.put(`${ATENDIMENTO_URL}/aso/sign/${id}`, {
      senha: senha,
    })
  )
  return axios.all(requests).then(() => {})
}
export const getInformacoesAtendimentoById = (
  id: ID
): Promise<AtendimentoDtoInformacaoAgrupada[] | undefined> => {
  return axios
    .get(`${ATENDIMENTO_URL}/informacao/${id}`)
    .then((response: AxiosResponse<AtendimentoDtoInformacaoAgrupada[]>) => response.data)
}
export const getAtendimentoPacienteById = (id: ID): Promise<AtendimentoView | undefined> => {
  return axios
    .get(`${ATENDIMENTO_PACIENTE_URL}/${id}`)
    .then((response: AxiosResponse<AtendimentoView>) => response.data)
}
export function getEstadosDeAtendimento(): Promise<EstadosDeAtendimentoQueryResponse> {
  return axios
    .get(ESTADOS_URL)
    .then((d: AxiosResponse<EstadosDeAtendimentoQueryResponse>) => d.data)
}
export const getTiposResultadoASO = () => {
  return axios
    .get<TipoASO[]>(`${TIPOS_URL}/aso/resultado`)
    .then((d: AxiosResponse<TipoASO[]>) => d.data)
}
export const getTiposASO = () => {
  return axios.get<TipoASO[]>(`${TIPOS_URL}/aso`).then((d: AxiosResponse<TipoASO[]>) => d.data)
}
export const getTiposFatorASO = () => {
  return axios.get<TipoASO[]>(`${FATOR_RISCO_URL}`).then((d: AxiosResponse<TipoASO[]>) => d.data)
}
export const getSituacaoesAso = () => {
  return axios
    .get(`${API_URL}/tipos/aso/situacao`)
    .then((d: AxiosResponse<ISituacaoAso[]>) => d.data)
}
export const alteraAtendimento = (
  idAtendimento: ID,
  estado: Number,
  responsavel: Number,
  salaVideo: boolean,
  cid: Number | null
): Promise<AtendimentoView | undefined> => {
  return axios
    .put(`${ATENDIMENTO_URL}/${idAtendimento}`, {
      estado,
      responsavel,
      salaVideo,
      cid,
    })
    .then((response: AxiosResponse<Response<AtendimentoView>>) => response.data)
    .then((response: Response<AtendimentoView>) => response.data)
}

export const deleteCid = (idAtendimento: ID, idCid: ID): Promise<AtendimentoView | undefined> => {
  return axios
    .delete(`${ATENDIMENTO_URL}/cid/${idAtendimento}?idCid=${idCid}`)
    .then((response: AxiosResponse<Response<AtendimentoView>>) => response.data)
    .then((response: Response<AtendimentoView>) => response.data)
}
export const adicionarCid = (
  idAtendimento: ID,
  codigo: string
): Promise<AtendimentoView | undefined> => {
  return axios
    .put(`${ATENDIMENTO_URL}/cid/${idAtendimento}`, {
      codigo,
    })
    .then((response: AxiosResponse<Response<AtendimentoView>>) => response.data)
    .then((response: Response<AtendimentoView>) => response.data)
}
export const alteraEmpresaClienteAtendimento = (
  idAtendimento: ID,
  cliente: ID,
  pcmso?: ID
): Promise<AtendimentoView | undefined> => {
  return axios
    .put(`${ATENDIMENTO_URL}/${idAtendimento}`, {
      cliente: cliente,
      pcmso: pcmso ? pcmso : null,
    })
    .then((response: AxiosResponse<Response<AtendimentoView>>) => response.data)
    .then((response: Response<AtendimentoView>) => response.data)
}
export const vinculaCoordenadorPcmso = (
  idAtendimento: ID,
  colaboradorId: ID
): Promise<AtendimentoView | undefined> => {
  return axios
    .put(`${ATENDIMENTO_URL}/${idAtendimento}`, {
      pcmso: colaboradorId,
    })
    .then((response: AxiosResponse<Response<AtendimentoView>>) => response.data)
    .then((response: Response<AtendimentoView>) => response.data)
}
export const alteraTipoAso = (
  idAtendimento: ID,
  tipoAso: string
): Promise<AtendimentoView | undefined> => {
  return axios
    .put(`${ATENDIMENTO_URL}/${idAtendimento}`, {
      tipoAso: tipoAso,
    })
    .then((response: AxiosResponse<Response<AtendimentoView>>) => response.data)
    .then((response: Response<AtendimentoView>) => response.data)
}
export const informaResultado = (
  idAtendimento: ID,
  resultado: string
): Promise<AtendimentoView | undefined> => {
  return axios
    .put(`${ATENDIMENTO_URL}/${idAtendimento}`, {
      resultado: resultado,
    })
    .then((response: AxiosResponse<Response<AtendimentoView>>) => response.data)
    .then((response: Response<AtendimentoView>) => response.data)
}
export const adicionaProcedimento = (
  idAtendimento: ID,
  data: String,
  nome: String,
  descricao: String
): Promise<AtendimentoView | undefined> => {
  return axios
    .put(`${ATENDIMENTO_URL}/procedimento/${idAtendimento}`, {
      data,
      nome,
      descricao,
    })
    .then((response: AxiosResponse<Response<AtendimentoView>>) => response.data)
    .then((response: Response<AtendimentoView>) => response.data)
}
export async function editarProcedimentoAnexo(
  idAtendimento: ID,
  idProcedimento: ID,
  data: string,
  nome: string,
  descricao: string,
  defineAtendimento: Function,
  anexo: File | undefined
) {
  try {
    let result = ''
    let fileName = ''
    let fileType = ''

    if (anexo) {
      // Extrair informações do anexo
      result = await new Promise<string>((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => resolve(reader.result as string)
        reader.onerror = reject
        reader.readAsDataURL(anexo)
      })
      fileName = anexo.name
      fileType = anexo.type
    }

    const atendimento = await editarProcedimento(
      idAtendimento,
      idProcedimento,
      data,
      nome,
      descricao,
      result,
      fileName,
      fileType
    )
    defineAtendimento(atendimento?.id as ID)

    Swal.fire({
      text: 'Procedimento editado com sucesso!',
      icon: 'success',
      buttonsStyling: false,
      confirmButtonText: 'Ok',
      customClass: {
        confirmButton: 'btn btn-primary',
      },
    })
  } catch (error: any) {
    Swal.fire({
      text: error.response?.data.data.message || 'Erro ao editar procedimento',
      icon: 'error',
      buttonsStyling: false,
      confirmButtonText: 'Ok',
      customClass: {
        confirmButton: 'btn btn-primary',
      },
    })
  }
}

export async function criarProcedimentoAnexo(
  idAtendimento: ID,
  data: string,
  nome: string,
  descricao: string,
  defineAtendimento: Function,
  anexo?: File
) {
  try {
    let result = ''
    let fileName = ''
    let fileType = ''

    if (anexo) {
      // Extrair informações do anexo
      result = await new Promise<string>((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => resolve(reader.result as string)
        reader.onerror = reject
        reader.readAsDataURL(anexo)
      })
      fileName = anexo.name
      fileType = anexo.type
    }

    const atendimento = await adicionarProcedimento(
      idAtendimento,
      data,
      nome,
      descricao,
      result,
      fileName,
      fileType
    )
    defineAtendimento(atendimento?.id as ID)

    Swal.fire({
      text: 'Procedimento adicionado com sucesso!',
      icon: 'success',
      buttonsStyling: false,
      confirmButtonText: 'Ok',
      customClass: {
        confirmButton: 'btn btn-primary',
      },
    })
  } catch (error: any) {
    Swal.fire({
      text: error.response?.data.data.message || 'Erro ao editar procedimento',
      icon: 'error',
      buttonsStyling: false,
      confirmButtonText: 'Ok',
      customClass: {
        confirmButton: 'btn btn-primary',
      },
    })
  }
}
export const editarProcedimento = (
  idAtendimento: ID,
  idProcedimento: ID,
  data: String,
  nome: String,
  descricao: String,
  fileContent: string,
  fileName: String,
  fileType: String
): Promise<AtendimentoView | undefined> => {
  if (fileContent) {
    return axios
      .patch(`${ATENDIMENTO_URL}/procedimento/${idAtendimento}`, {
        id: idProcedimento,
        data,
        nome,
        descricao: !!descricao ? descricao : null,
        anexo: {
          fileContent: fileContent.split(',').pop(),
          fileName: fileName,
          fileType: fileType,
        },
      })
      .then((response: AxiosResponse<Response<AtendimentoView>>) => response.data)
      .then((response: Response<AtendimentoView>) => response.data)
  } else {
    return axios
      .patch(`${ATENDIMENTO_URL}/procedimento/${idAtendimento}`, {
        id: idProcedimento,
        data,
        nome,
        descricao: !!descricao ? descricao : null,
      })
      .then((response: AxiosResponse<Response<AtendimentoView>>) => response.data)
      .then((response: Response<AtendimentoView>) => response.data)
  }
}

export const adicionarProcedimento = (
  idAtendimento: ID,
  data: String,
  nome: String,
  descricao: String,
  fileContent: any,
  fileName: String,
  fileType: String
): Promise<AtendimentoView | undefined> => {
  if (fileContent) {
    return axios
      .put(`${ATENDIMENTO_URL}/procedimento/${idAtendimento}`, {
        data,
        nome,
        descricao: !!descricao ? descricao : null,
        anexo: {
          fileContent: fileContent.split(',').pop(),
          fileName: fileName,
          fileType: fileType,
        },
      })
      .then((response: AxiosResponse<Response<AtendimentoView>>) => response.data)
      .then((response: Response<AtendimentoView>) => response.data)
  } else {
    return axios
      .put(`${ATENDIMENTO_URL}/procedimento/${idAtendimento}`, {
        data,
        nome,
        descricao: !!descricao ? descricao : null,
      })
      .then((response: AxiosResponse<Response<AtendimentoView>>) => response.data)
      .then((response: Response<AtendimentoView>) => response.data)
  }
}
export const deleteProcedimento = (atendimentoId: ID, procedimentoId: ID): Promise<void> => {
  return axios.delete(
    `${ATENDIMENTO_URL}/procedimento/${atendimentoId}?idProcedimento=${procedimentoId}`
  )
}
export const adicionaFatorRisco = (
  idAtendimento: ID,
  fatorRisco: String,
  valor: String
): Promise<AtendimentoView | undefined> => {
  return axios
    .put(`${ATENDIMENTO_URL}/fatorRisco/${idAtendimento}`, {
      fatorRisco,
      valor,
    })
    .then((response: AxiosResponse<Response<AtendimentoView>>) => response.data)
    .then((response: Response<AtendimentoView>) => response.data)
}
export const editarFatorRisco = (
  idAtendimento: ID,
  idFatorRisco: ID,
  fatorRisco: String,
  valor: String
): Promise<AtendimentoView | undefined> => {
  return axios
    .put(`${ATENDIMENTO_URL}/fatorRisco/${idAtendimento}`, {
      id: idFatorRisco,
      fatorRisco,
      valor,
    })
    .then((response: AxiosResponse<Response<AtendimentoView>>) => response.data)
    .then((response: Response<AtendimentoView>) => response.data)
}
export const deleteFatorRisco = (atendimentoId: ID, fatorRiscoId: ID): Promise<void> => {
  return axios.delete(`${ATENDIMENTO_URL}/fatorRisco/${atendimentoId}?idFatorRisco=${fatorRiscoId}`)
}
export const validateAso = (idAtendimento: ID): Promise<PendenciaAso | undefined> => {
  return axios
    .get(`${ATENDIMENTO_URL}/aso/validate/${idAtendimento}`)
    .then((response: AxiosResponse<Response<PendenciaAso>>) => response.data)
    .then((response: Response<PendenciaAso>) => response.data)
}
export const adicionaPrescricao = (
  idAtendimento: ID,
  prescricao: Prescricao
): Promise<AtendimentoView | undefined> => {
  return axios
    .put(`${ATENDIMENTO_URL}/prescricao/${idAtendimento}`, prescricao)
    .then((response: AxiosResponse<Response<AtendimentoView>>) => response.data)
    .then((response: Response<AtendimentoView>) => response.data)
}
export const adicionarAnotacao = (
  idAtendimento: ID,
  anotacao: string,
  tipo: string
): Promise<AtendimentoView | undefined> => {
  return axios
    .put(`${ATENDIMENTO_ANOTACAO_URL}/${idAtendimento}`, {
      tipo: tipo,
      anotacao: anotacao,
    })
    .then((response: AxiosResponse<Response<AtendimentoView>>) => response.data)
    .then((response: Response<AtendimentoView>) => response.data)
}
export const deleteAnotacao = (atendimentoId: ID, anotacaoId: ID): Promise<void> => {
  return axios.delete(`${ATENDIMENTO_URL}/anotacao/${atendimentoId}?idAnotacao=${anotacaoId}`)
}
export const deleteAtendimento = (atendimentoId: ID): Promise<void> => {
  return axios.delete(`${ATENDIMENTO_URL}/${atendimentoId}`)
}
export const deleteSelectedAtendimento = (atendimentoIds: Array<ID>): Promise<void> => {
  const requests = atendimentoIds.map((id) => axios.delete(`${ATENDIMENTO_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export const getFormularioAtendimento = (
  atendimentoId: ID,
  formularioId: ID
): Promise<FormularioAtendimentoView> => {
  return axios
    .get(`${ATENDIMENTO_FORMULARIO_URL}/${atendimentoId}/${formularioId}`)
    .then((resp: AxiosResponse<FormularioAtendimentoView>) => resp.data)
}

export const updateAnswersFormulario = (
  answersForm: FormularioAtendimentoDto,
  atendimentoId: ID
): Promise<void> => {
  return axios.patch(`${ATENDIMENTO_FORMULARIO_URL}/${atendimentoId}`, answersForm)
}

export const registerPendencia = (observacao: string, atendimentoId: ID): Promise<void> => {
  return axios.put(`${ATENDIMENTO_PENDENTE_URL}/${atendimentoId}`, { observacao })
}

export const resolvePendencia = (observacao: string, atendimentoId: ID): Promise<void> => {
  return axios.patch(`${ATENDIMENTO_PENDENTE_URL}/${atendimentoId}`, { observacao })
}
