import { KTSVG } from '../../../../../../../_metronic/helpers'
import { useListView } from '../../../core/ListViewProvider'
import { UsersListFilter } from './UsersListFilter'

const UsersListToolbar = () => {
  const { setItemIdForUpdate } = useListView()
  const openAddUserModal = () => {
    setItemIdForUpdate(null)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      {/* begin::Add user 
      <button type='button' className='btn btn-primary'  onClick={openAddUserModal}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Adicionar Paciente
      </button>
      {/* end::Add user */}

      {/* begin::Button */}
      {/* <a
            href='#'
            className='btn btn-sm btn-primary'
            data-bs-toggle='modal'
            data-bs-target='#kt_paciente_find_modal'
            id='kt_toolbar_primary_button'
          >
            Adicionar Paciente
          </a>
          end::Button */}
    </div>
  )
}

export { UsersListToolbar }
