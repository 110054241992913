/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useContext, useEffect, useState } from 'react'
import { MenuComponent } from '../../../../../../../_metronic/assets/ts/components'
import {
  alertCertificado,
  FormularioSituacao,
  IconPaths,
  IconSize,
  ID,
  KTSVG,
  QUERIES,
  showAlert,
} from '../../../../../../../_metronic/helpers'
import { useListView } from '../../core/ListViewProvider'
import Swal from 'sweetalert2'
import { useAuth } from '../../../../../auth'
import { downloadProntuario } from '../../../../atendimento/core/_requests'
import { Button, Dropdown, DropdownItem } from '../../../../../../components'
import { downloadAllPrescricoes } from '../../core/_request'
import { signAvaliacao } from '../../../../avaliacoes/avaliacoes-pendentes-list/core/_requests'
import { downloadAvaliacao } from '../../../../avaliacoes/avaliacoes-concluidas-list/core/_requests'
import { FormularioAtendimentoList } from '../../../../avaliacoes/avaliacoes-pendentes-list/core/_models'
import { AxiosError } from 'axios'

type Props = {
  atendimentoId: ID
  formulario: FormularioAtendimentoList | undefined
  hasPrescricoes: boolean | undefined
}

const HistoricoActionsCell: FC<Props> = ({ formulario, atendimentoId, hasPrescricoes }) => {
  const user = useAuth().currentUser
  const possuiCertificado = user?.pessoa?.certificadoDigital
  const { setItemIdForUpdate } = useListView()
  const { currentUser } = useAuth()
  const isMedico =
    currentUser?.grupo?.nome === 'SUPER_MEDICO' || currentUser?.grupo?.nome === 'MEDICO'

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => setItemIdForUpdate(atendimentoId)

  const handleDownloadProntuario = () => {
    downloadProntuario(atendimentoId)
      .then((anexo) => {
        if (anexo !== undefined) {
          var a = document.createElement('a') //Create <a>
          a.href = 'data:application/pdf;base64,' + (anexo !== undefined ? anexo.contentBase64 : '') //Image Base64 Goes here
          a.download = String(anexo.name) //File name Here
          a.click()
        }
      })
      .catch((error) => {
        const errors = error.response.data.data.message

        Swal.fire({
          text: `Não foi possível realizar o download: ${errors}.`,
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      })
  }

  const handleDownloadFormulario = () => {
    downloadAvaliacao(atendimentoId, formulario?.formulario.id)
      .then((anexo) => {
        if (anexo !== undefined) {
          var a = document.createElement('a')
          a.href = 'data:application/pdf;base64,' + (anexo !== undefined ? anexo.contentBase64 : '')
          a.download = String(anexo.name)
          a.click()
        }
      })
      .catch((error) => {
        const errors = error.response.data.data.message
        console.log(error.response.data)

        Swal.fire({
          text: `Não foi possível realizar o download: ${errors}.`,
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      })
  }
  const handleDownloadReceituario = () => {
    downloadAllPrescricoes(atendimentoId)
      .then((data) => {
        data.forEach((anexo) => {
          if (anexo !== undefined) {
            var a = document.createElement('a')
            a.href =
              'data:application/pdf;base64,' + (anexo !== undefined ? anexo.contentBase64 : '')
            a.download = String(anexo.name)
            a.click()
          }
        })
      })
      .catch((error) => {
        const errors = error.response.data.data.message
        console.log(error.response.data)

        Swal.fire({
          text: `Não foi possível realizar o download: ${errors}.`,
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      })
  }

  const handleAssinarAvaliacao = () => {
    if (possuiCertificado) {
      try {
        alertCertificado(signAvaliacao, {
          formularioId: formulario?.formulario.id,
          atendimentoId,
        })
        showAlert({
          title: 'Sucesso!',
          text: 'Avaliação assinada.',
          icon: 'success',
        })
      } catch (error) {
        const err = error as AxiosError
        showAlert({
          title: 'Erro ao assinar!',
          text: err.response?.data.data.message,
          icon: 'error',
        })
      }
    } else {
      showAlert({
        title: 'Ausência de certificado!',
        text: 'Informe primeiramente o seu certificado na edição do perfil.',
        icon: 'error',
      })
    }
  }
  return (
    <>
      <div className='d-flex justify-content-end'>
        <div className='me-2'>
          <Button
            className='btn-primary btn-sm fs-7'
            iconSize={IconSize.Small}
            icon={IconPaths.Search}
            onClick={openEditModal}
          >
            Visualizar
          </Button>
        </div>
        <Dropdown name='Ações' size={175}>
          <DropdownItem onClick={handleDownloadProntuario}>Baixar Atendimento</DropdownItem>
          {formulario && formulario.situacao === FormularioSituacao.Assinado && isMedico ? (
            <DropdownItem onClick={handleDownloadFormulario}>Baixar Avaliação</DropdownItem>
          ) : formulario && formulario.respondido ? (
            <DropdownItem onClick={handleAssinarAvaliacao}>Assinar Avaliação</DropdownItem>
          ) : null}
          {hasPrescricoes ? (
            <DropdownItem onClick={handleDownloadReceituario}>Baixar Receituário</DropdownItem>
          ) : null}
        </Dropdown>
      </div>
    </>
  )
}

export { HistoricoActionsCell }
