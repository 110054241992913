import { Column } from 'react-table'
import { EmpresasActionsCell } from './EmpresaActionsCell'
import { DataColumn } from '../../../../commons/table/DataColumn'
import { ClienteList } from '../../core/_models'
import { CustomHeader } from '../../../../commons/table/CustomHeader'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { SelectionCell } from '../../../../commons/table/SelectionCell'
import { SelectionHeader } from '../../../../commons/table/SelectionHeader'
import { useListView } from '../../core/ListViewProvider'

const empresasColumns: ReadonlyArray<Column<ClienteList>> = [
  {
    Header: (props) => <SelectionHeader useListView={useListView} tableProps={props} />,
    id: 'selection',
    Cell: ({ ...props }) => <SelectionCell useListView={useListView} id={props.row.original.id} />,
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title='Empregador ASO'
        className='min-w-125px'
        useQueryRequest={useQueryRequest}
      />
    ),
    id: 'nome',
    accessor: 'nome',
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title='CNPj, CPF ou CAEPF'
        className='min-w-125px'
        useQueryRequest={useQueryRequest}
      />
    ),
    accessor: 'documento',
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title='Empresa vínculo'
        className='min-w-125px'
        useQueryRequest={useQueryRequest}
      />
    ),
    id: 'empresa.nomeFantasia',
    accessor: (row) => row.empresa?.nomeFantasia,
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title='Data criação'
        useQueryRequest={useQueryRequest}
        isAbleToSort={false}
      />
    ),
    id: 'dataInclusao',
    Cell: ({ ...props }) => <DataColumn data={props.row.original.dataInclusao} />,
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title='Data edição'
        useQueryRequest={useQueryRequest}
        isAbleToSort={false}
      />
    ),
    id: 'dataAlteracao',
    Cell: ({ ...props }) => <DataColumn data={props.row.original.dataAlteracao} />,
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title='Ações'
        className='text-end min-w-100px'
        useQueryRequest={useQueryRequest}
      />
    ),
    id: 'actions',
    Cell: ({ ...props }) => <EmpresasActionsCell id={props.row.original.id} />,
  },
]

export { empresasColumns }
