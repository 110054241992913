import { useEffect, useMemo, useState } from 'react'
import { ColumnInstance, Row, useTable } from 'react-table'
import { ID, KTCardBody, KTSVG } from '../../../../../../_metronic/helpers'
import { ListLoading } from '../../../commons/components/ListLoading'
import { CustomHeaderColumn } from '../../../commons/table/CustomHeaderColumn'
import { CustomRow } from '../../../commons/table/CustomRow'
import { Coordenador } from '../../empresasCliente-list/empresa-cliente-edit-modal/Coordenador'
import { coordenadorColumns } from './columns/_columns'
import { ClienteView } from '../../empresasCliente-list/core/_models'
import { getEmpresaClienteById } from '../../empresasCliente-list/core/_request'
import { ColaboradorList } from '../core/_models'

interface CoordenadorClienteTableProps {
  empresa: ClienteView | undefined
  isLoading: boolean
}

const CoordenadorClienteTable: React.FC<CoordenadorClienteTableProps> = ({
  empresa,
  isLoading,
}) => {
  const columns = useMemo(() => coordenadorColumns, [])
  const [cliente, setCliente] = useState<ClienteView | null>(null)
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns,
    data: empresa?.colaboradores || [],
  })
  const urlParams = new URLSearchParams(window.location.search)
  const idCliente = urlParams.get('cliente')
  const title = cliente?.nome ? (
    <span className='fs-4'>
      Coordenadores do Empregador ASO <strong>{cliente?.nome}</strong>
    </span>
  ) : (
    'Setor'
  )

  const [showCadastroCoord, setShowCadastroCoord] = useState<boolean>(false)

  const handleShowCoordenador = () => {
    setShowCadastroCoord(!showCadastroCoord)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const respostaEmpresa = await getEmpresaClienteById(idCliente as ID)
        setCliente(respostaEmpresa)
      } catch (error) {
        console.error('Erro ao obter dados da empresa:', error)
      }
    }
    if (idCliente) {
      fetchData()
    }
  }, [idCliente])

  return (
    <KTCardBody className='py-4'>
      {isLoading ? (
        <ListLoading />
      ) : (
        <>
          <div className='d-flex justify-content-between col-12' data-kt-user-table-toolbar='base'>
            <label className='form-label ps-1'>{title}</label>
            <button type='button' className='btn btn-primary' onClick={handleShowCoordenador}>
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
              Adicionar
            </button>
          </div>
          <div className='table-responsive'>
            <table
              id='kt_table_coordenadores_cliente'
              className='table align-middle table-row-bordered fs-6 gy-5 dataTable no-footer'
              {...getTableProps()}
            >
              <thead>
                <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0 border-secondary'>
                  {headers.map((column: ColumnInstance<ColaboradorList>) => (
                    <CustomHeaderColumn key={column.id} column={column} />
                  ))}
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
                {rows.length > 0 ? (
                  rows.map((row: Row<ColaboradorList>, i) => {
                    prepareRow(row)
                    return <CustomRow row={row} key={`row-${i}-${row.id}`} />
                  })
                ) : (
                  <tr>
                    <td colSpan={columns.length}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        Nenhum registro encontrado
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {showCadastroCoord && (
              <div>
                <div className='modal-backdrop fade show' style={{ zIndex: 1050 }}></div>
                <div
                  className='modal fade show'
                  id='exampleModalCoord'
                  tabIndex={-1}
                  aria-labelledby='exampleModalLabel'
                  aria-hidden='true'
                  style={{ display: 'block' }}
                >
                  <div className='modal-dialog modal-xl'>
                    <div className='modal-content rounded'>
                      <div className='modal-header'>
                        <button
                          type='button'
                          className='btn-close'
                          aria-label='Close'
                          onClick={handleShowCoordenador}
                        ></button>
                      </div>
                      <div className='modal-body'>
                        <Coordenador
                          empresaCliente={empresa}
                          coordenador={undefined}
                          onCancel={handleShowCoordenador}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </KTCardBody>
  )
}

export { CoordenadorClienteTable }
