import { Link } from 'react-router-dom'
import { useAuth } from '../../../../app/modules/auth'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { useEffect } from 'react'
import { getAvatarUser } from '../../../../app/modules/auth/core/_requests'

/* eslint-disable jsx-a11y/anchor-is-valid */
const AsideToolbar = () => {
  const { currentUser } = useAuth()

  const imageDefault = '/media/avatars/blank.png'
  const imagePerfil = toAbsoluteUrl(String(currentUser?.imagemPerfil))
  const hasImage = currentUser?.imagemPerfil !== null

  return (
    <div className='aside-user d-flex align-items-sm-center justify-content-center py-5'>
      <div className='symbol symbol-50px'>
        <img src={hasImage ? imagePerfil : imageDefault} alt='Imagem do Perfil' />
      </div>

      <div className='aside-user-info flex-row-fluid flex-wrap ms-5'>
        <div className='d-flex'>
          <div className='flex-grow-1 me-2'>
            <div className='me-n2'>
              <Link to='/crafted/account/overview' className='d-flex align-items-center'>
                <span style={{ color: 'white' }} className='me-3'>
                  {currentUser?.pessoa?.nome}
                </span>
                <KTSVG
                  path='/media/icons/duotune/art/art005.svg'
                  className='svg-icon-muted svg-icon-12'
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { AsideToolbar }
