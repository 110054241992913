import React, { useState, useEffect } from 'react'
import { OptionsHelper, useDebounce } from '../../../../../../../_metronic/helpers'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { useAuth } from '../../../../../auth'
import { CustomSelect, IOption, Label, DateTimeInput } from '../../../../../../components'

const RelatorioListSearchComponent = () => {
  const { updateState } = useQueryRequest()
  const { currentUser } = useAuth()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const debouncedSearchTerm = useDebounce(searchTerm, 150)
  const [dataInicial, setDataInicial] = useState<string>()
  const [dataFinal, setDataFinal] = useState<string>()

  const [optionsTiposAtendimento, setOptionsTiposAtendimento] = useState<IOption[]>([])

  const [retorno, setRetorno] = useState<IOption>()
  const [especialidade, setEspecialidade] = useState<IOption>()
  const [empresa, setEmpresa] = useState<IOption>()
  const [profissional, setProfissional] = useState<IOption>()
  const [solicitante, setSolicitante] = useState<IOption>()
  const [estado, setEstado] = useState<IOption>()
  const [tipoAtendimento, setTipoAtendimento] = useState<IOption>()

  const getTiposAtendimento = () =>
    setOptionsTiposAtendimento(OptionsHelper.getOptionsTiposAtendimentoUsuario(currentUser))

  const updateFilter = () => {
    const search = [
      empresa ? `paciente.empresa:${empresa.value}` : '',
      tipoAtendimento ? `tipo:${tipoAtendimento.value}` : '',
      dataInicial ? `inicio>${dataInicial}` : '',
      dataFinal ? `termino<'${dataFinal}` : '',
      profissional ? `responsavel.pessoa.id:${profissional.value}` : '',
      estado ? `estado:${estado.value}` : '',
      solicitante ? `criador.id:${solicitante.value}` : '',
      especialidade ? `especialidade:${especialidade.value}` : '',
    ]
      .filter(Boolean)
      .join(',')
    setSearchTerm(search)
  }

  useEffect(() => {
    getTiposAtendimento()
  }, [])

  useEffect(() => {
    if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
      updateState({
        search: searchTerm,
        sort: 'solicitacao',
        order: 'asc',
      })
    }
  }, [debouncedSearchTerm, updateState])

  useEffect(() => {
    updateFilter()
  }, [
    empresa,
    tipoAtendimento,
    dataInicial,
    dataFinal,
    profissional,
    estado,
    solicitante,
    especialidade,
    retorno,
  ])

  return (
    <div>
      <div className='row mb-0'>
        <div className='col-lg-4 mb-2'>
          <Label className='pb-2'>Empresa</Label>
          <CustomSelect
            placeholder='Selecione uma empresa'
            onChange={(data) => setEmpresa(data)}
            fetchOptions={OptionsHelper.getOptionsEmpresas}
            value={empresa}
          />
        </div>
        <div className='col-lg-4 mb-2'>
          <Label className='pb-2'>Profissional de Saúde</Label>
          <CustomSelect
            placeholder='Selecione um profissional'
            onChange={(data) => setProfissional(data)}
            fetchOptions={OptionsHelper.getOptionsProfissionalSaude}
            value={profissional}
          />
        </div>
        <div className='col-lg-4 mb-2'>
          <Label className='pb-2'>Estado</Label>
          <CustomSelect
            placeholder='Selecione um estado'
            onChange={(data) => setEstado(data)}
            fetchOptions={OptionsHelper.getOptionsEstadosAtendimento}
            value={estado}
          />
        </div>
      </div>
      <div className='row mb-0'>
        <div className='col-lg-4 mb-2'>
          <Label className='pb-2'>Tipo atendimento</Label>
          <CustomSelect
            placeholder='Tipo de atendimento'
            onChange={(data) => setTipoAtendimento(data)}
            options={optionsTiposAtendimento}
            value={tipoAtendimento}
          />
        </div>
        <div className='col-lg-4 mb-4'>
          <Label className='pb-2'>Data de início</Label>
          <DateTimeInput
            placeholder='Selecione a data inicial'
            value={dataInicial || ''}
            onChange={(e) => setDataInicial(e.target.value)}
          />
        </div>

        <div className='col-lg-4 mb-4'>
          <Label className='pb-2'>Data de final</Label>
          <DateTimeInput
            placeholder='Selecione a data final'
            value={dataFinal || ''}
            onChange={(e) => setDataFinal(e.target.value)}
          />
        </div>
      </div>
      <div className='row mb-0'>
        <div className='col-lg-4 mb-2'>
          <Label className='pb-2'>Solicitante</Label>
          <CustomSelect
            placeholder='Selecione um solicitante'
            onChange={(data) => setSolicitante(data)}
            fetchOptions={OptionsHelper.getOptionsSolicitante}
            value={solicitante}
          />
        </div>
        <div className='col-lg-4 mb-2'>
          <Label className='pb-2'>Especialidade</Label>
          <CustomSelect
            placeholder='Selecione uma especialidade'
            onChange={(data) => setEspecialidade(data)}
            fetchOptions={OptionsHelper.getOptionsEspecialidades}
            value={especialidade}
          />
        </div>
      </div>
    </div>
  )
}

export { RelatorioListSearchComponent }
