import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'

const DashboardPage = () => (
  <>
    <div className='row g-5 g-xl-10 mb-xl-10'>
      <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
        <div
          className='card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-center border-0 h-md-50 mb-5 mb-xl-10'
          style={{ backgroundColor: '#080655' }}
        >
          <div className='card-header pt-5'>
            <div className='card-title d-flex flex-column'>
              <span className='fs-2hx fw-bold text-white me-2 lh-1 ls-n2'>69</span>
              <span className='text-white opacity-50 pt-1 fw-semibold fs-6'>Empresas</span>
            </div>
          </div>
        </div>
        <div
          className='card card-flush h-md-50 mb-5 mb-xl-10'
          style={{ backgroundColor: '#F4F4F4' }}
        >
          <div className='card-header pt-5'>
            <div className='card-title d-flex flex-column'>
              <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>800</span>
              <span className='text-gray-400 pt-1 fw-semibold fs-6'>Pacientes</span>
            </div>
          </div>
          <div className='card-body d-flex flex-column justify-content-end pe-0'>
            <div className='symbol-group symbol-hover flex-nowrap'>
              <div
                className='symbol symbol-35px symbol-circle'
                data-bs-toggle='tooltip'
                title='Alan Warden'
              >
                <span className='symbol-label bg-warning text-inverse-warning fw-bold'>A</span>
              </div>
              <div
                className='symbol symbol-35px symbol-circle'
                data-bs-toggle='tooltip'
                title='Susan Redwood'
              >
                <span className='symbol-label bg-primary text-inverse-primary fw-bold'>S</span>
              </div>
              <div
                className='symbol symbol-35px symbol-circle'
                data-bs-toggle='tooltip'
                title='Perry Matthew'
              >
                <span className='symbol-label bg-danger text-inverse-danger fw-bold'>P</span>
              </div>
              <a
                href='#'
                className='symbol symbol-35px symbol-circle'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_view_users'
              >
                <span className='symbol-label bg-dark text-gray-300 fs-8 fw-bold'>+42</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
        <div
          className='card card-flush h-md-50 mb-5 mb-xl-10'
          style={{ backgroundColor: '#F4F4F4' }}
        >
          <div className='card-header pt-5'>
            <div className='card-title d-flex flex-column'>
              <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>357</span>
              <span className='text-gray-400 pt-1 fw-semibold fs-6'>Profissionais</span>
            </div>
          </div>
          <div className='card-body d-flex flex-column justify-content-end pe-0'>
            <div className='symbol-group symbol-hover flex-nowrap'>
              <div
                className='symbol symbol-35px symbol-circle'
                data-bs-toggle='tooltip'
                title='Alan Warden'
              >
                <span className='symbol-label bg-warning text-inverse-warning fw-bold'>A</span>
              </div>
              <div
                className='symbol symbol-35px symbol-circle'
                data-bs-toggle='tooltip'
                title='Susan Redwood'
              >
                <span className='symbol-label bg-primary text-inverse-primary fw-bold'>S</span>
              </div>
              <div
                className='symbol symbol-35px symbol-circle'
                data-bs-toggle='tooltip'
                title='Perry Matthew'
              >
                <span className='symbol-label bg-danger text-inverse-danger fw-bold'>P</span>
              </div>
              <a
                href='#'
                className='symbol symbol-35px symbol-circle'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_view_users'
              >
                <span className='symbol-label bg-dark text-gray-300 fs-8 fw-bold'>+42</span>
              </a>
            </div>
          </div>
        </div>
        <div className='card card-flush h-lg-50' style={{ backgroundColor: '#F4F4F4' }}>
          <div className='card-header pt-5'>
            <h3 className='card-title text-gray-800'>Avaliações</h3>
            <div className='card-toolbar d-none'>
              <div
                data-kt-daterangepicker='true'
                data-kt-daterangepicker-opens='left'
                className='btn btn-sm btn-light d-flex align-items-center px-4'
              >
                <div className='text-gray-600 fw-bold'>Loading date range...</div>
                <span className='svg-icon svg-icon-1 ms-2 me-0'>
                  <svg
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      opacity='0.3'
                      d='M21 22H3C2.4 22 2 21.6 2 21V5C2 4.4 2.4 4 3 4H21C21.6 4 22 4.4 22 5V21C22 21.6 21.6 22 21 22Z'
                      fill='currentColor'
                    />
                    <path
                      d='M6 6C5.4 6 5 5.6 5 5V3C5 2.4 5.4 2 6 2C6.6 2 7 2.4 7 3V5C7 5.6 6.6 6 6 6ZM11 5V3C11 2.4 10.6 2 10 2C9.4 2 9 2.4 9 3V5C9 5.6 9.4 6 10 6C10.6 6 11 5.6 11 5ZM15 5V3C15 2.4 14.6 2 14 2C13.4 2 13 2.4 13 3V5C13 5.6 13.4 6 14 6C14.6 6 15 5.6 15 5ZM19 5V3C19 2.4 18.6 2 18 2C17.4 2 17 2.4 17 3V5C17 5.6 17.4 6 18 6C18.6 6 19 5.6 19 5Z'
                      fill='currentColor'
                    />
                    <path
                      d='M8.8 13.1C9.2 13.1 9.5 13 9.7 12.8C9.9 12.6 10.1 12.3 10.1 11.9C10.1 11.6 10 11.3 9.8 11.1C9.6 10.9 9.3 10.8 9 10.8C8.8 10.8 8.59999 10.8 8.39999 10.9C8.19999 11 8.1 11.1 8 11.2C7.9 11.3 7.8 11.4 7.7 11.6C7.6 11.8 7.5 11.9 7.5 12.1C7.5 12.2 7.4 12.2 7.3 12.3C7.2 12.4 7.09999 12.4 6.89999 12.4C6.69999 12.4 6.6 12.3 6.5 12.2C6.4 12.1 6.3 11.9 6.3 11.7C6.3 11.5 6.4 11.3 6.5 11.1C6.6 10.9 6.8 10.7 7 10.5C7.2 10.3 7.49999 10.1 7.89999 10C8.29999 9.90003 8.60001 9.80003 9.10001 9.80003C9.50001 9.80003 9.80001 9.90003 10.1 10C10.4 10.1 10.7 10.3 10.9 10.4C11.1 10.5 11.3 10.8 11.4 11.1C11.5 11.4 11.6 11.6 11.6 11.9C11.6 12.3 11.5 12.6 11.3 12.9C11.1 13.2 10.9 13.5 10.6 13.7C10.9 13.9 11.2 14.1 11.4 14.3C11.6 14.5 11.8 14.7 11.9 15C12 15.3 12.1 15.5 12.1 15.8C12.1 16.2 12 16.5 11.9 16.8C11.8 17.1 11.5 17.4 11.3 17.7C11.1 18 10.7 18.2 10.3 18.3C9.9 18.4 9.5 18.5 9 18.5C8.5 18.5 8.1 18.4 7.7 18.2C7.3 18 7 17.8 6.8 17.6C6.6 17.4 6.4 17.1 6.3 16.8C6.2 16.5 6.10001 16.3 6.10001 16.1C6.10001 15.9 6.2 15.7 6.3 15.6C6.4 15.5 6.6 15.4 6.8 15.4C6.9 15.4 7.00001 15.4 7.10001 15.5C7.20001 15.6 7.3 15.6 7.3 15.7C7.5 16.2 7.7 16.6 8 16.9C8.3 17.2 8.6 17.3 9 17.3C9.2 17.3 9.5 17.2 9.7 17.1C9.9 17 10.1 16.8 10.3 16.6C10.5 16.4 10.5 16.1 10.5 15.8C10.5 15.3 10.4 15 10.1 14.7C9.80001 14.4 9.50001 14.3 9.10001 14.3C9.00001 14.3 8.9 14.3 8.7 14.3C8.5 14.3 8.39999 14.3 8.39999 14.3C8.19999 14.3 7.99999 14.2 7.89999 14.1C7.79999 14 7.7 13.8 7.7 13.7C7.7 13.5 7.79999 13.4 7.89999 13.2C7.99999 13 8.2 13 8.5 13H8.8V13.1ZM15.3 17.5V12.2C14.3 13 13.6 13.3 13.3 13.3C13.1 13.3 13 13.2 12.9 13.1C12.8 13 12.7 12.8 12.7 12.6C12.7 12.4 12.8 12.3 12.9 12.2C13 12.1 13.2 12 13.6 11.8C14.1 11.6 14.5 11.3 14.7 11.1C14.9 10.9 15.2 10.6 15.5 10.3C15.8 10 15.9 9.80003 15.9 9.70003C15.9 9.60003 16.1 9.60004 16.3 9.60004C16.5 9.60004 16.7 9.70003 16.8 9.80003C16.9 9.90003 17 10.2 17 10.5V17.2C17 18 16.7 18.4 16.2 18.4C16 18.4 15.8 18.3 15.6 18.2C15.4 18.1 15.3 17.8 15.3 17.5Z'
                      fill='currentColor'
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
          <div className='card-body pt-5'>
            <div className='d-flex flex-stack'>
              <div className='text-gray-700 fw-semibold fs-6 me-2'>NPS</div>
              <div className='d-flex align-items-senter'>
                <span className='svg-icon svg-icon-2 svg-icon-success me-2'>
                  <svg
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <rect
                      opacity='0.5'
                      x='16.9497'
                      y='8.46448'
                      width='13'
                      height='2'
                      rx='1'
                      transform='rotate(135 16.9497 8.46448)'
                      fill='currentColor'
                    />
                    <path
                      d='M14.8284 9.97157L14.8284 15.8891C14.8284 16.4749 15.3033 16.9497 15.8891 16.9497C16.4749 16.9497 16.9497 16.4749 16.9497 15.8891L16.9497 8.05025C16.9497 7.49797 16.502 7.05025 15.9497 7.05025L8.11091 7.05025C7.52512 7.05025 7.05025 7.52513 7.05025 8.11091C7.05025 8.6967 7.52512 9.17157 8.11091 9.17157L14.0284 9.17157C14.4703 9.17157 14.8284 9.52975 14.8284 9.97157Z'
                      fill='currentColor'
                    />
                  </svg>
                </span>
                <span className='text-gray-900 fw-bolder fs-6'>92</span>
                <span className='text-gray-400 fw-bold fs-6'>/100</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='col-lg-12 col-xl-12 col-xxl-6 mb-5 mb-xl-0'>
        <div className='card h-md-100' style={{ backgroundColor: '#F4F4F4' }}>
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold text-dark'>Eventos</span>
            </h3>
          </div>
          <div className='card-body pt-7 px-0'>
            <ul className='nav nav-stretch nav-pills nav-pills-custom nav-pills-active-custom d-flex justify-content-between mb-8 px-5'>
              <li className='nav-item p-0 ms-0'>
                <a
                  className='nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger'
                  data-bs-toggle='tab'
                  href='#kt_timeline_widget_3_tab_content_1'
                >
                  <span className='fs-7 fw-semibold'>Sex</span>
                  <span className='fs-6 fw-bold'>20</span>
                </a>
              </li>
              <li className='nav-item p-0 ms-0'>
                <a
                  className='nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger'
                  data-bs-toggle='tab'
                  href='#kt_timeline_widget_3_tab_content_2'
                >
                  <span className='fs-7 fw-semibold'>Sab</span>
                  <span className='fs-6 fw-bold'>21</span>
                </a>
              </li>
              <li className='nav-item p-0 ms-0'>
                <a
                  className='nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger'
                  data-bs-toggle='tab'
                  href='#kt_timeline_widget_3_tab_content_3'
                >
                  <span className='fs-7 fw-semibold'>Dom</span>
                  <span className='fs-6 fw-bold'>22</span>
                </a>
              </li>
              <li className='nav-item p-0 ms-0'>
                <a
                  className='nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger active'
                  data-bs-toggle='tab'
                  href='#kt_timeline_widget_3_tab_content_4'
                >
                  <span className='fs-7 fw-semibold'>Seg</span>
                  <span className='fs-6 fw-bold'>23</span>
                </a>
              </li>
              <li className='nav-item p-0 ms-0'>
                <a
                  className='nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger'
                  data-bs-toggle='tab'
                  href='#kt_timeline_widget_3_tab_content_5'
                >
                  <span className='fs-7 fw-semibold'>Ter</span>
                  <span className='fs-6 fw-bold'>24</span>
                </a>
              </li>
              <li className='nav-item p-0 ms-0'>
                <a
                  className='nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger'
                  data-bs-toggle='tab'
                  href='#kt_timeline_widget_3_tab_content_6'
                >
                  <span className='fs-7 fw-semibold'>Qua</span>
                  <span className='fs-6 fw-bold'>25</span>
                </a>
              </li>
              <li className='nav-item p-0 ms-0'>
                <a
                  className='nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger'
                  data-bs-toggle='tab'
                  href='#kt_timeline_widget_3_tab_content_7'
                >
                  <span className='fs-7 fw-semibold'>Qui</span>
                  <span className='fs-6 fw-bold'>26</span>
                </a>
              </li>
              <li className='nav-item p-0 ms-0'>
                <a
                  className='nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger'
                  data-bs-toggle='tab'
                  href='#kt_timeline_widget_3_tab_content_8'
                >
                  <span className='fs-7 fw-semibold'>Sex</span>
                  <span className='fs-6 fw-bold'>27</span>
                </a>
              </li>
              <li className='nav-item p-0 ms-0'>
                <a
                  className='nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger'
                  data-bs-toggle='tab'
                  href='#kt_timeline_widget_3_tab_content_9'
                >
                  <span className='fs-7 fw-semibold'>Sab</span>
                  <span className='fs-6 fw-bold'>28</span>
                </a>
              </li>
              <li className='nav-item p-0 ms-0'>
                <a
                  className='nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger'
                  data-bs-toggle='tab'
                  href='#kt_timeline_widget_3_tab_content_10'
                >
                  <span className='fs-7 fw-semibold'>Dom</span>
                  <span className='fs-6 fw-bold'>29</span>
                </a>
              </li>
              <li className='nav-item p-0 ms-0'>
                <a
                  className='nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger'
                  data-bs-toggle='tab'
                  href='#kt_timeline_widget_3_tab_content_11'
                >
                  <span className='fs-7 fw-semibold'>Seg</span>
                  <span className='fs-6 fw-bold'>30</span>
                </a>
              </li>
            </ul>
            <div className='tab-content mb-2 px-9'>
              <div className='tab-pane fade' id='kt_timeline_widget_3_tab_content_3'>
                <div className='d-flex align-items-center mb-6'>
                  <span
                    data-kt-element='bullet'
                    className='bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-primary'
                  ></span>
                  <div className='flex-grow-1 me-5'>
                    <div className='text-gray-800 fw-semibold fs-2'>
                      10:00 - 10:00
                      <span className='text-gray-400 fw-semibold fs-7'> AM</span>
                    </div>
                    <div className='text-gray-700 fw-semibold fs-6'>
                      Nova empresa: Lauduz Tecnologias
                    </div>
                    <div className='text-gray-400 fw-semibold fs-7'>
                      Cadastrada por
                      <a href='#' className='text-primary opacity-75-hover fw-semibold'>
                        {' '}
                        Wilson Zatt
                      </a>
                    </div>
                  </div>
                  <a
                    href='#'
                    className='btn btn-sm btn-light'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_create_project'
                  >
                    Ver
                  </a>
                </div>
                <div className='d-flex align-items-center mb-6'>
                  <span
                    data-kt-element='bullet'
                    className='bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-warning'
                  ></span>
                  <div className='flex-grow-1 me-5'>
                    <div className='text-gray-800 fw-semibold fs-2'>
                      11:00 - 11:30
                      <span className='text-gray-400 fw-semibold fs-7'> AM</span>
                    </div>
                    <div className='text-gray-700 fw-semibold fs-6'>Consulta Paciente da Silva</div>
                    <div className='text-gray-400 fw-semibold fs-7'>
                      Paciente de
                      <a href='#' className='text-primary opacity-75-hover fw-semibold'>
                        {' '}
                        Médico da Silva
                      </a>
                    </div>
                  </div>
                  <a
                    href='#'
                    className='btn btn-sm btn-light'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_create_project'
                  >
                    Ver
                  </a>
                </div>
                <div className='d-flex align-items-center mb-6'>
                  <span
                    data-kt-element='bullet'
                    className='bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-info'
                  ></span>
                  <div className='flex-grow-1 me-5'>
                    <div className='text-gray-800 fw-semibold fs-2'>
                      16:00 - 16:00
                      <span className='text-gray-400 fw-semibold fs-7'> PM</span>
                    </div>
                    <div className='text-gray-700 fw-semibold fs-6'>
                      Novo profissional João da Silva
                    </div>
                    <div className='text-gray-400 fw-semibold fs-7'>
                      Cadastrado por
                      <a href='#' className='text-primary opacity-75-hover fw-semibold'>
                        {' '}
                        Lauduz Tecnologia
                      </a>
                    </div>
                  </div>
                  <a
                    href='#'
                    className='btn btn-sm btn-light'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_create_project'
                  >
                    Ver
                  </a>
                </div>
              </div>
              <div className='tab-pane fade' id='kt_timeline_widget_3_tab_content_11'>
                <div className='d-flex align-items-center mb-6'>
                  <span
                    data-kt-element='bullet'
                    className='bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-info'
                  ></span>
                  <div className='flex-grow-1 me-5'>
                    <div className='text-gray-800 fw-semibold fs-2'>
                      16:30 - 17:00
                      <span className='text-gray-400 fw-semibold fs-7'>PM</span>
                    </div>
                    <div className='text-gray-700 fw-semibold fs-6'>
                      9 Dashboard UI/UX Design Review
                    </div>
                    <div className='text-gray-400 fw-semibold fs-7'>
                      Lead by
                      <a href='#' className='text-primary opacity-75-hover fw-semibold'>
                        Lead by Mark Morris
                      </a>
                    </div>
                  </div>
                  <a
                    href='#'
                    className='btn btn-sm btn-light'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_create_project'
                  >
                    View
                  </a>
                </div>
                <div className='d-flex align-items-center mb-6'>
                  <span
                    data-kt-element='bullet'
                    className='bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-danger'
                  ></span>
                  <div className='flex-grow-1 me-5'>
                    <div className='text-gray-800 fw-semibold fs-2'>
                      10:20 - 11:00
                      <span className='text-gray-400 fw-semibold fs-7'>AM</span>
                    </div>
                    <div className='text-gray-700 fw-semibold fs-6'>
                      Marketing Campaign Discussion
                    </div>
                    <div className='text-gray-400 fw-semibold fs-7'>
                      Lead by
                      <a href='#' className='text-primary opacity-75-hover fw-semibold'>
                        Peter Marcus
                      </a>
                    </div>
                  </div>
                  <a
                    href='#'
                    className='btn btn-sm btn-light'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_create_project'
                  >
                    View
                  </a>
                </div>
                <div className='d-flex align-items-center mb-6'>
                  <span
                    data-kt-element='bullet'
                    className='bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-primary'
                  ></span>
                  <div className='flex-grow-1 me-5'>
                    <div className='text-gray-800 fw-semibold fs-2'>
                      12:00 - 13:40
                      <span className='text-gray-400 fw-semibold fs-7'>AM</span>
                    </div>
                    <div className='text-gray-700 fw-semibold fs-6'>
                      9 Degree Project Estimation Meeting
                    </div>
                    <div className='text-gray-400 fw-semibold fs-7'>
                      Lead by
                      <a href='#' className='text-primary opacity-75-hover fw-semibold'>
                        Lead by Bob
                      </a>
                    </div>
                  </div>
                  <a
                    href='#'
                    className='btn btn-sm btn-light'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_create_project'
                  >
                    View
                  </a>
                </div>
              </div>
            </div>
            <div className='float-end d-none'>
              <a
                href='#'
                className='btn btn-sm btn-light me-2'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_create_project'
              >
                Add Lesson
              </a>
              <a
                href='#'
                className='btn btn-sm btn-danger'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_create_app'
              >
                Call Sick for Today
              </a>
            </div>
          </div>
        </div>
        <div className='card card-flush d-none h-md-100'>
          <div className='card-header mt-6'>
            <div className='card-title flex-column'>
              <h3 className='fw-bold mb-1'>What's on the road?</h3>
              <div className='fs-6 text-gray-400'>Total 482 participants</div>
            </div>
            <div className='card-toolbar'>
              <select
                name='status'
                data-control='select2'
                data-hide-search='true'
                className='form-select form-select-solid form-select-sm fw-bold w-100px'
              >
                <option value='1' selected={true}>
                  Options
                </option>
                <option value='2'>Option 1</option>
                <option value='3'>Option 2</option>
                <option value='4'>Option 3</option>
              </select>
            </div>
          </div>
          <div className='card-body p-0'>
            <ul className='nav nav-pills d-flex flex-nowrap hover-scroll-x py-2 ms-4'>
              <li className='nav-item me-1'>
                <a
                  className='nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px me-2 py-4 px-3 btn-color-active-white btn-active-danger'
                  data-bs-toggle='tab'
                  href='#kt_schedule_day_0'
                >
                  <span className='text-gray-400 fs-7 fw-semibold'>Fr</span>
                  <span className='fs-6 text-gray-800 fw-bold'>20</span>
                </a>
              </li>
              <li className='nav-item me-1'>
                <a
                  className='nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px me-2 py-4 px-3 btn-color-active-white btn-active-danger'
                  data-bs-toggle='tab'
                  href='#kt_schedule_day_1'
                >
                  <span className='text-gray-400 fs-7 fw-semibold'>Sa</span>
                  <span className='fs-6 text-gray-800 fw-bold'>21</span>
                </a>
              </li>
              <li className='nav-item me-1'>
                <a
                  className='nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px me-2 py-4 px-3 btn-color-active-white btn-active-danger'
                  data-bs-toggle='tab'
                  href='#kt_schedule_day_2'
                >
                  <span className='text-gray-400 fs-7 fw-semibold'>Su</span>
                  <span className='fs-6 text-gray-800 fw-bold'>22</span>
                </a>
              </li>
              <li className='nav-item me-1'>
                <a
                  className='nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px me-2 py-4 px-3 btn-color-active-white btn-active-danger active'
                  data-bs-toggle='tab'
                  href='#kt_schedule_day_3'
                >
                  <span className='text-gray-400 fs-7 fw-semibold'>Mo</span>
                  <span className='fs-6 text-gray-800 fw-bold'>23</span>
                </a>
              </li>
              <li className='nav-item me-1'>
                <a
                  className='nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px me-2 py-4 px-3 btn-color-active-white btn-active-danger'
                  data-bs-toggle='tab'
                  href='#kt_schedule_day_4'
                >
                  <span className='text-gray-400 fs-7 fw-semibold'>Tu</span>
                  <span className='fs-6 text-gray-800 fw-bold'>24</span>
                </a>
              </li>
              <li className='nav-item me-1'>
                <a
                  className='nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px me-2 py-4 px-3 btn-color-active-white btn-active-danger'
                  data-bs-toggle='tab'
                  href='#kt_schedule_day_5'
                >
                  <span className='text-gray-400 fs-7 fw-semibold'>We</span>
                  <span className='fs-6 text-gray-800 fw-bold'>25</span>
                </a>
              </li>
              <li className='nav-item me-1'>
                <a
                  className='nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px me-2 py-4 px-3 btn-color-active-white btn-active-danger'
                  data-bs-toggle='tab'
                  href='#kt_schedule_day_6'
                >
                  <span className='text-gray-400 fs-7 fw-semibold'>Th</span>
                  <span className='fs-6 text-gray-800 fw-bold'>26</span>
                </a>
              </li>
              <li className='nav-item me-1'>
                <a
                  className='nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px me-2 py-4 px-3 btn-color-active-white btn-active-danger'
                  data-bs-toggle='tab'
                  href='#kt_schedule_day_7'
                >
                  <span className='text-gray-400 fs-7 fw-semibold'>Fr</span>
                  <span className='fs-6 text-gray-800 fw-bold'>27</span>
                </a>
              </li>
              <li className='nav-item me-1'>
                <a
                  className='nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px me-2 py-4 px-3 btn-color-active-white btn-active-danger'
                  data-bs-toggle='tab'
                  href='#kt_schedule_day_8'
                >
                  <span className='text-gray-400 fs-7 fw-semibold'>Sa</span>
                  <span className='fs-6 text-gray-800 fw-bold'>28</span>
                </a>
              </li>
              <li className='nav-item me-1'>
                <a
                  className='nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px me-2 py-4 px-3 btn-color-active-white btn-active-danger'
                  data-bs-toggle='tab'
                  href='#kt_schedule_day_9'
                >
                  <span className='text-gray-400 fs-7 fw-semibold'>Su</span>
                  <span className='fs-6 text-gray-800 fw-bold'>29</span>
                </a>
              </li>
              <li className='nav-item me-1'>
                <a
                  className='nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px me-2 py-4 px-3 btn-color-active-white btn-active-danger'
                  data-bs-toggle='tab'
                  href='#kt_schedule_day_10'
                >
                  <span className='text-gray-400 fs-7 fw-semibold'>Mo</span>
                  <span className='fs-6 text-gray-800 fw-bold'>30</span>
                </a>
              </li>
              <li className='nav-item me-1'>
                <a
                  className='nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px me-2 py-4 px-3 btn-color-active-white btn-active-danger'
                  data-bs-toggle='tab'
                  href='#kt_schedule_day_11'
                >
                  <span className='text-gray-400 fs-7 fw-semibold'>Tu</span>
                  <span className='fs-6 text-gray-800 fw-bold'>31</span>
                </a>
              </li>
            </ul>
            <div className='tab-content px-9'>
              <div id='kt_schedule_day_0' className='tab-pane fade show'>
                <div className='d-flex flex-stack position-relative mt-8'>
                  <div className='position-absolute h-100 w-4px bg-secondary rounded top-0 start-0'></div>
                  <div className='fw-semibold ms-5 text-gray-600'>
                    <div className='fs-5'>
                      13:00 - 14:00
                      <span className='fs-7 text-gray-400 text-uppercase'>pm</span>
                    </div>
                    <a href='#' className='fs-5 fw-bold text-gray-800 text-hover-primary mb-2'>
                      Sales Pitch Proposal
                    </a>
                    <div className='text-gray-400'>
                      Lead by
                      <a href='#'>Walter White</a>
                    </div>
                  </div>
                  <a href='#' className='btn btn-bg-light btn-active-color-primary btn-sm'>
                    View
                  </a>
                </div>
                <div className='d-flex flex-stack position-relative mt-8'>
                  <div className='position-absolute h-100 w-4px bg-secondary rounded top-0 start-0'></div>
                  <div className='fw-semibold ms-5 text-gray-600'>
                    <div className='fs-5'>
                      12:00 - 13:00
                      <span className='fs-7 text-gray-400 text-uppercase'>pm</span>
                    </div>
                    <a href='#' className='fs-5 fw-bold text-gray-800 text-hover-primary mb-2'>
                      9 Degree Project Estimation Meeting
                    </a>
                    <div className='text-gray-400'>
                      Lead by
                      <a href='#'>Caleb Donaldson</a>
                    </div>
                  </div>
                  <a href='#' className='btn btn-bg-light btn-active-color-primary btn-sm'>
                    View
                  </a>
                </div>
                <div className='d-flex flex-stack position-relative mt-8'>
                  <div className='position-absolute h-100 w-4px bg-secondary rounded top-0 start-0'></div>
                  <div className='fw-semibold ms-5 text-gray-600'>
                    <div className='fs-5'>
                      13:00 - 14:00
                      <span className='fs-7 text-gray-400 text-uppercase'>pm</span>
                    </div>
                    <a href='#' className='fs-5 fw-bold text-gray-800 text-hover-primary mb-2'>
                      9 Degree Project Estimation Meeting
                    </a>
                    <div className='text-gray-400'>
                      Lead by
                      <a href='#'>Sean Bean</a>
                    </div>
                  </div>
                  <a href='#' className='btn btn-bg-light btn-active-color-primary btn-sm'>
                    View
                  </a>
                </div>
              </div>
              <div id='kt_schedule_day_1' className='tab-pane fade show active'>
                <div className='d-flex flex-stack position-relative mt-8'>
                  <div className='position-absolute h-100 w-4px bg-secondary rounded top-0 start-0'></div>
                  <div className='fw-semibold ms-5 text-gray-600'>
                    <div className='fs-5'>
                      13:00 - 14:00
                      <span className='fs-7 text-gray-400 text-uppercase'>pm</span>
                    </div>
                    <a href='#' className='fs-5 fw-bold text-gray-800 text-hover-primary mb-2'>
                      Lunch & Learn Catch Up
                    </a>
                    <div className='text-gray-400'>
                      Lead by
                      <a href='#'>Peter Marcus</a>
                    </div>
                  </div>
                  <a href='#' className='btn btn-bg-light btn-active-color-primary btn-sm'>
                    View
                  </a>
                </div>
                <div className='d-flex flex-stack position-relative mt-8'>
                  <div className='position-absolute h-100 w-4px bg-secondary rounded top-0 start-0'></div>
                  <div className='fw-semibold ms-5 text-gray-600'>
                    <div className='fs-5'>
                      11:00 - 11:45
                      <span className='fs-7 text-gray-400 text-uppercase'>am</span>
                    </div>
                    <a href='#' className='fs-5 fw-bold text-gray-800 text-hover-primary mb-2'>
                      9 Degree Project Estimation Meeting
                    </a>
                    <div className='text-gray-400'>
                      Lead by
                      <a href='#'>Michael Walters</a>
                    </div>
                  </div>
                  <a href='#' className='btn btn-bg-light btn-active-color-primary btn-sm'>
                    View
                  </a>
                </div>
                <div className='d-flex flex-stack position-relative mt-8'>
                  <div className='position-absolute h-100 w-4px bg-secondary rounded top-0 start-0'></div>
                  <div className='fw-semibold ms-5 text-gray-600'>
                    <div className='fs-5'>
                      9:00 - 10:00
                      <span className='fs-7 text-gray-400 text-uppercase'>am</span>
                    </div>
                    <a href='#' className='fs-5 fw-bold text-gray-800 text-hover-primary mb-2'>
                      Sales Pitch Proposal
                    </a>
                    <div className='text-gray-400'>
                      Lead by
                      <a href='#'>Yannis Gloverson</a>
                    </div>
                  </div>
                  <a href='#' className='btn btn-bg-light btn-active-color-primary btn-sm'>
                    View
                  </a>
                </div>
              </div>
              <div id='kt_schedule_day_2' className='tab-pane fade show'>
                <div className='d-flex flex-stack position-relative mt-8'>
                  <div className='position-absolute h-100 w-4px bg-secondary rounded top-0 start-0'></div>
                  <div className='fw-semibold ms-5 text-gray-600'>
                    <div className='fs-5'>
                      14:30 - 15:30
                      <span className='fs-7 text-gray-400 text-uppercase'>pm</span>
                    </div>
                    <a href='#' className='fs-5 fw-bold text-gray-800 text-hover-primary mb-2'>
                      Marketing Campaign Discussion
                    </a>
                    <div className='text-gray-400'>
                      Lead by
                      <a href='#'>Naomi Hayabusa</a>
                    </div>
                  </div>
                  <a href='#' className='btn btn-bg-light btn-active-color-primary btn-sm'>
                    View
                  </a>
                </div>
                <div className='d-flex flex-stack position-relative mt-8'>
                  <div className='position-absolute h-100 w-4px bg-secondary rounded top-0 start-0'></div>
                  <div className='fw-semibold ms-5 text-gray-600'>
                    <div className='fs-5'>
                      10:00 - 11:00
                      <span className='fs-7 text-gray-400 text-uppercase'>am</span>
                    </div>
                    <a href='#' className='fs-5 fw-bold text-gray-800 text-hover-primary mb-2'>
                      Development Team Capacity Review
                    </a>
                    <div className='text-gray-400'>
                      Lead by
                      <a href='#'>Michael Walters</a>
                    </div>
                  </div>
                  <a href='#' className='btn btn-bg-light btn-active-color-primary btn-sm'>
                    View
                  </a>
                </div>
                <div className='d-flex flex-stack position-relative mt-8'>
                  <div className='position-absolute h-100 w-4px bg-secondary rounded top-0 start-0'></div>
                  <div className='fw-semibold ms-5 text-gray-600'>
                    <div className='fs-5'>
                      13:00 - 14:00
                      <span className='fs-7 text-gray-400 text-uppercase'>pm</span>
                    </div>
                    <a href='#' className='fs-5 fw-bold text-gray-800 text-hover-primary mb-2'>
                      Dashboard UI/UX Design Review
                    </a>
                    <div className='text-gray-400'>
                      Lead by
                      <a href='#'>Kendell Trevor</a>
                    </div>
                  </div>
                  <a href='#' className='btn btn-bg-light btn-active-color-primary btn-sm'>
                    View
                  </a>
                </div>
              </div>
              <div id='kt_schedule_day_3' className='tab-pane fade show'>
                <div className='d-flex flex-stack position-relative mt-8'>
                  <div className='position-absolute h-100 w-4px bg-secondary rounded top-0 start-0'></div>
                  <div className='fw-semibold ms-5 text-gray-600'>
                    <div className='fs-5'>
                      9:00 - 10:00
                      <span className='fs-7 text-gray-400 text-uppercase'>am</span>
                    </div>
                    <a href='#' className='fs-5 fw-bold text-gray-800 text-hover-primary mb-2'>
                      Development Team Capacity Review
                    </a>
                    <div className='text-gray-400'>
                      Lead by
                      <a href='#'>Sean Bean</a>
                    </div>
                  </div>
                  <a href='#' className='btn btn-bg-light btn-active-color-primary btn-sm'>
                    View
                  </a>
                </div>
                <div className='d-flex flex-stack position-relative mt-8'>
                  <div className='position-absolute h-100 w-4px bg-secondary rounded top-0 start-0'></div>
                  <div className='fw-semibold ms-5 text-gray-600'>
                    <div className='fs-5'>
                      9:00 - 10:00
                      <span className='fs-7 text-gray-400 text-uppercase'>am</span>
                    </div>
                    <a href='#' className='fs-5 fw-bold text-gray-800 text-hover-primary mb-2'>
                      Team Backlog Grooming Session
                    </a>
                    <div className='text-gray-400'>
                      Lead by
                      <a href='#'>Mark Randall</a>
                    </div>
                  </div>
                  <a href='#' className='btn btn-bg-light btn-active-color-primary btn-sm'>
                    View
                  </a>
                </div>
                <div className='d-flex flex-stack position-relative mt-8'>
                  <div className='position-absolute h-100 w-4px bg-secondary rounded top-0 start-0'></div>
                  <div className='fw-semibold ms-5 text-gray-600'>
                    <div className='fs-5'>
                      16:30 - 17:30
                      <span className='fs-7 text-gray-400 text-uppercase'>pm</span>
                    </div>
                    <a href='#' className='fs-5 fw-bold text-gray-800 text-hover-primary mb-2'>
                      9 Degree Project Estimation Meeting
                    </a>
                    <div className='text-gray-400'>
                      Lead by
                      <a href='#'>Michael Walters</a>
                    </div>
                  </div>
                  <a href='#' className='btn btn-bg-light btn-active-color-primary btn-sm'>
                    View
                  </a>
                </div>
              </div>
              <div id='kt_schedule_day_4' className='tab-pane fade show'>
                <div className='d-flex flex-stack position-relative mt-8'>
                  <div className='position-absolute h-100 w-4px bg-secondary rounded top-0 start-0'></div>
                  <div className='fw-semibold ms-5 text-gray-600'>
                    <div className='fs-5'>
                      10:00 - 11:00
                      <span className='fs-7 text-gray-400 text-uppercase'>am</span>
                    </div>
                    <a href='#' className='fs-5 fw-bold text-gray-800 text-hover-primary mb-2'>
                      Creative Content Initiative
                    </a>
                    <div className='text-gray-400'>
                      Lead by
                      <a href='#'>David Stevenson</a>
                    </div>
                  </div>
                  <a href='#' className='btn btn-bg-light btn-active-color-primary btn-sm'>
                    View
                  </a>
                </div>
                <div className='d-flex flex-stack position-relative mt-8'>
                  <div className='position-absolute h-100 w-4px bg-secondary rounded top-0 start-0'></div>
                  <div className='fw-semibold ms-5 text-gray-600'>
                    <div className='fs-5'>
                      12:00 - 13:00
                      <span className='fs-7 text-gray-400 text-uppercase'>pm</span>
                    </div>
                    <a href='#' className='fs-5 fw-bold text-gray-800 text-hover-primary mb-2'>
                      Committee Review Approvals
                    </a>
                    <div className='text-gray-400'>
                      Lead by
                      <a href='#'>Peter Marcus</a>
                    </div>
                  </div>
                  <a href='#' className='btn btn-bg-light btn-active-color-primary btn-sm'>
                    View
                  </a>
                </div>
                <div className='d-flex flex-stack position-relative mt-8'>
                  <div className='position-absolute h-100 w-4px bg-secondary rounded top-0 start-0'></div>
                  <div className='fw-semibold ms-5 text-gray-600'>
                    <div className='fs-5'>
                      12:00 - 13:00
                      <span className='fs-7 text-gray-400 text-uppercase'>pm</span>
                    </div>
                    <a href='#' className='fs-5 fw-bold text-gray-800 text-hover-primary mb-2'>
                      Creative Content Initiative
                    </a>
                    <div className='text-gray-400'>
                      Lead by
                      <a href='#'>Kendell Trevor</a>
                    </div>
                  </div>
                  <a href='#' className='btn btn-bg-light btn-active-color-primary btn-sm'>
                    View
                  </a>
                </div>
              </div>
              <div id='kt_schedule_day_5' className='tab-pane fade show'>
                <div className='d-flex flex-stack position-relative mt-8'>
                  <div className='position-absolute h-100 w-4px bg-secondary rounded top-0 start-0'></div>
                  <div className='fw-semibold ms-5 text-gray-600'>
                    <div className='fs-5'>
                      10:00 - 11:00
                      <span className='fs-7 text-gray-400 text-uppercase'>am</span>
                    </div>
                    <a href='#' className='fs-5 fw-bold text-gray-800 text-hover-primary mb-2'>
                      Project Review & Testing
                    </a>
                    <div className='text-gray-400'>
                      Lead by
                      <a href='#'>Walter White</a>
                    </div>
                  </div>
                  <a href='#' className='btn btn-bg-light btn-active-color-primary btn-sm'>
                    View
                  </a>
                </div>
                <div className='d-flex flex-stack position-relative mt-8'>
                  <div className='position-absolute h-100 w-4px bg-secondary rounded top-0 start-0'></div>
                  <div className='fw-semibold ms-5 text-gray-600'>
                    <div className='fs-5'>
                      13:00 - 14:00
                      <span className='fs-7 text-gray-400 text-uppercase'>pm</span>
                    </div>
                    <a href='#' className='fs-5 fw-bold text-gray-800 text-hover-primary mb-2'>
                      Lunch & Learn Catch Up
                    </a>
                    <div className='text-gray-400'>
                      Lead by
                      <a href='#'>Mark Randall</a>
                    </div>
                  </div>
                  <a href='#' className='btn btn-bg-light btn-active-color-primary btn-sm'>
                    View
                  </a>
                </div>
                <div className='d-flex flex-stack position-relative mt-8'>
                  <div className='position-absolute h-100 w-4px bg-secondary rounded top-0 start-0'></div>
                  <div className='fw-semibold ms-5 text-gray-600'>
                    <div className='fs-5'>
                      9:00 - 10:00
                      <span className='fs-7 text-gray-400 text-uppercase'>am</span>
                    </div>
                    <a href='#' className='fs-5 fw-bold text-gray-800 text-hover-primary mb-2'>
                      Committee Review Approvals
                    </a>
                    <div className='text-gray-400'>
                      Lead by
                      <a href='#'>Yannis Gloverson</a>
                    </div>
                  </div>
                  <a href='#' className='btn btn-bg-light btn-active-color-primary btn-sm'>
                    View
                  </a>
                </div>
              </div>
              <div id='kt_schedule_day_6' className='tab-pane fade show'>
                <div className='d-flex flex-stack position-relative mt-8'>
                  <div className='position-absolute h-100 w-4px bg-secondary rounded top-0 start-0'></div>
                  <div className='fw-semibold ms-5 text-gray-600'>
                    <div className='fs-5'>
                      9:00 - 10:00
                      <span className='fs-7 text-gray-400 text-uppercase'>am</span>
                    </div>
                    <a href='#' className='fs-5 fw-bold text-gray-800 text-hover-primary mb-2'>
                      Sales Pitch Proposal
                    </a>
                    <div className='text-gray-400'>
                      Lead by
                      <a href='#'>Caleb Donaldson</a>
                    </div>
                  </div>
                  <a href='#' className='btn btn-bg-light btn-active-color-primary btn-sm'>
                    View
                  </a>
                </div>
                <div className='d-flex flex-stack position-relative mt-8'>
                  <div className='position-absolute h-100 w-4px bg-secondary rounded top-0 start-0'></div>
                  <div className='fw-semibold ms-5 text-gray-600'>
                    <div className='fs-5'>
                      11:00 - 11:45
                      <span className='fs-7 text-gray-400 text-uppercase'>am</span>
                    </div>
                    <a href='#' className='fs-5 fw-bold text-gray-800 text-hover-primary mb-2'>
                      Lunch & Learn Catch Up
                    </a>
                    <div className='text-gray-400'>
                      Lead by
                      <a href='#'>Sean Bean</a>
                    </div>
                  </div>
                  <a href='#' className='btn btn-bg-light btn-active-color-primary btn-sm'>
                    View
                  </a>
                </div>
                <div className='d-flex flex-stack position-relative mt-8'>
                  <div className='position-absolute h-100 w-4px bg-secondary rounded top-0 start-0'></div>
                  <div className='fw-semibold ms-5 text-gray-600'>
                    <div className='fs-5'>
                      11:00 - 11:45
                      <span className='fs-7 text-gray-400 text-uppercase'>am</span>
                    </div>
                    <a href='#' className='fs-5 fw-bold text-gray-800 text-hover-primary mb-2'>
                      Sales Pitch Proposal
                    </a>
                    <div className='text-gray-400'>
                      Lead by
                      <a href='#'>Michael Walters</a>
                    </div>
                  </div>
                  <a href='#' className='btn btn-bg-light btn-active-color-primary btn-sm'>
                    View
                  </a>
                </div>
              </div>
              <div id='kt_schedule_day_7' className='tab-pane fade show'>
                <div className='d-flex flex-stack position-relative mt-8'>
                  <div className='position-absolute h-100 w-4px bg-secondary rounded top-0 start-0'></div>
                  <div className='fw-semibold ms-5 text-gray-600'>
                    <div className='fs-5'>
                      14:30 - 15:30
                      <span className='fs-7 text-gray-400 text-uppercase'>pm</span>
                    </div>
                    <a href='#' className='fs-5 fw-bold text-gray-800 text-hover-primary mb-2'>
                      Project Review & Testing
                    </a>
                    <div className='text-gray-400'>
                      Lead by
                      <a href='#'>Karina Clarke</a>
                    </div>
                  </div>
                  <a href='#' className='btn btn-bg-light btn-active-color-primary btn-sm'>
                    View
                  </a>
                </div>
                <div className='d-flex flex-stack position-relative mt-8'>
                  <div className='position-absolute h-100 w-4px bg-secondary rounded top-0 start-0'></div>
                  <div className='fw-semibold ms-5 text-gray-600'>
                    <div className='fs-5'>
                      12:00 - 13:00
                      <span className='fs-7 text-gray-400 text-uppercase'>pm</span>
                    </div>
                    <a href='#' className='fs-5 fw-bold text-gray-800 text-hover-primary mb-2'>
                      Lunch & Learn Catch Up
                    </a>
                    <div className='text-gray-400'>
                      Lead by
                      <a href='#'>Bob Harris</a>
                    </div>
                  </div>
                  <a href='#' className='btn btn-bg-light btn-active-color-primary btn-sm'>
                    View
                  </a>
                </div>
                <div className='d-flex flex-stack position-relative mt-8'>
                  <div className='position-absolute h-100 w-4px bg-secondary rounded top-0 start-0'></div>
                  <div className='fw-semibold ms-5 text-gray-600'>
                    <div className='fs-5'>
                      10:00 - 11:00
                      <span className='fs-7 text-gray-400 text-uppercase'>am</span>
                    </div>
                    <a href='#' className='fs-5 fw-bold text-gray-800 text-hover-primary mb-2'>
                      9 Degree Project Estimation Meeting
                    </a>
                    <div className='text-gray-400'>
                      Lead by
                      <a href='#'>Peter Marcus</a>
                    </div>
                  </div>
                  <a href='#' className='btn btn-bg-light btn-active-color-primary btn-sm'>
                    View
                  </a>
                </div>
              </div>
              <div id='kt_schedule_day_8' className='tab-pane fade show'>
                <div className='d-flex flex-stack position-relative mt-8'>
                  <div className='position-absolute h-100 w-4px bg-secondary rounded top-0 start-0'></div>
                  <div className='fw-semibold ms-5 text-gray-600'>
                    <div className='fs-5'>
                      14:30 - 15:30
                      <span className='fs-7 text-gray-400 text-uppercase'>pm</span>
                    </div>
                    <a href='#' className='fs-5 fw-bold text-gray-800 text-hover-primary mb-2'>
                      9 Degree Project Estimation Meeting
                    </a>
                    <div className='text-gray-400'>
                      Lead by
                      <a href='#'>Kendell Trevor</a>
                    </div>
                  </div>
                  <a href='#' className='btn btn-bg-light btn-active-color-primary btn-sm'>
                    View
                  </a>
                </div>
                <div className='d-flex flex-stack position-relative mt-8'>
                  <div className='position-absolute h-100 w-4px bg-secondary rounded top-0 start-0'></div>
                  <div className='fw-semibold ms-5 text-gray-600'>
                    <div className='fs-5'>
                      9:00 - 10:00
                      <span className='fs-7 text-gray-400 text-uppercase'>am</span>
                    </div>
                    <a href='#' className='fs-5 fw-bold text-gray-800 text-hover-primary mb-2'>
                      Development Team Capacity Review
                    </a>
                    <div className='text-gray-400'>
                      Lead by
                      <a href='#'>Sean Bean</a>
                    </div>
                  </div>
                  <a href='#' className='btn btn-bg-light btn-active-color-primary btn-sm'>
                    View
                  </a>
                </div>
                <div className='d-flex flex-stack position-relative mt-8'>
                  <div className='position-absolute h-100 w-4px bg-secondary rounded top-0 start-0'></div>
                  <div className='fw-semibold ms-5 text-gray-600'>
                    <div className='fs-5'>
                      10:00 - 11:00
                      <span className='fs-7 text-gray-400 text-uppercase'>am</span>
                    </div>
                    <a href='#' className='fs-5 fw-bold text-gray-800 text-hover-primary mb-2'>
                      Creative Content Initiative
                    </a>
                    <div className='text-gray-400'>
                      Lead by
                      <a href='#'>Michael Walters</a>
                    </div>
                  </div>
                  <a href='#' className='btn btn-bg-light btn-active-color-primary btn-sm'>
                    View
                  </a>
                </div>
              </div>
              <div id='kt_schedule_day_9' className='tab-pane fade show'>
                <div className='d-flex flex-stack position-relative mt-8'>
                  <div className='position-absolute h-100 w-4px bg-secondary rounded top-0 start-0'></div>
                  <div className='fw-semibold ms-5 text-gray-600'>
                    <div className='fs-5'>
                      13:00 - 14:00
                      <span className='fs-7 text-gray-400 text-uppercase'>pm</span>
                    </div>
                    <a href='#' className='fs-5 fw-bold text-gray-800 text-hover-primary mb-2'>
                      9 Degree Project Estimation Meeting
                    </a>
                    <div className='text-gray-400'>
                      Lead by
                      <a href='#'>Sean Bean</a>
                    </div>
                  </div>
                  <a href='#' className='btn btn-bg-light btn-active-color-primary btn-sm'>
                    View
                  </a>
                </div>
                <div className='d-flex flex-stack position-relative mt-8'>
                  <div className='position-absolute h-100 w-4px bg-secondary rounded top-0 start-0'></div>
                  <div className='fw-semibold ms-5 text-gray-600'>
                    <div className='fs-5'>
                      14:30 - 15:30
                      <span className='fs-7 text-gray-400 text-uppercase'>pm</span>
                    </div>
                    <a href='#' className='fs-5 fw-bold text-gray-800 text-hover-primary mb-2'>
                      Team Backlog Grooming Session
                    </a>
                    <div className='text-gray-400'>
                      Lead by
                      <a href='#'>Mark Randall</a>
                    </div>
                  </div>
                  <a href='#' className='btn btn-bg-light btn-active-color-primary btn-sm'>
                    View
                  </a>
                </div>
                <div className='d-flex flex-stack position-relative mt-8'>
                  <div className='position-absolute h-100 w-4px bg-secondary rounded top-0 start-0'></div>
                  <div className='fw-semibold ms-5 text-gray-600'>
                    <div className='fs-5'>
                      11:00 - 11:45
                      <span className='fs-7 text-gray-400 text-uppercase'>am</span>
                    </div>
                    <a href='#' className='fs-5 fw-bold text-gray-800 text-hover-primary mb-2'>
                      Lunch & Learn Catch Up
                    </a>
                    <div className='text-gray-400'>
                      Lead by
                      <a href='#'>Caleb Donaldson</a>
                    </div>
                  </div>
                  <a href='#' className='btn btn-bg-light btn-active-color-primary btn-sm'>
                    View
                  </a>
                </div>
              </div>
              <div id='kt_schedule_day_10' className='tab-pane fade show'>
                <div className='d-flex flex-stack position-relative mt-8'>
                  <div className='position-absolute h-100 w-4px bg-secondary rounded top-0 start-0'></div>
                  <div className='fw-semibold ms-5 text-gray-600'>
                    <div className='fs-5'>
                      12:00 - 13:00
                      <span className='fs-7 text-gray-400 text-uppercase'>pm</span>
                    </div>
                    <a href='#' className='fs-5 fw-bold text-gray-800 text-hover-primary mb-2'>
                      Weekly Team Stand-Up
                    </a>
                    <div className='text-gray-400'>
                      Lead by
                      <a href='#'>Sean Bean</a>
                    </div>
                  </div>
                  <a href='#' className='btn btn-bg-light btn-active-color-primary btn-sm'>
                    View
                  </a>
                </div>
                <div className='d-flex flex-stack position-relative mt-8'>
                  <div className='position-absolute h-100 w-4px bg-secondary rounded top-0 start-0'></div>
                  <div className='fw-semibold ms-5 text-gray-600'>
                    {/*begin::Time */}
                    <div className='fs-5'>
                      9:00 - 10:00
                      <span className='fs-7 text-gray-400 text-uppercase'>am</span>
                    </div>
                    {/*end::Time */}
                    {/*begin::Title */}
                    <a href='#' className='fs-5 fw-bold text-gray-800 text-hover-primary mb-2'>
                      Weekly Team Stand-Up
                    </a>
                    {/*end::Title */}
                    {/*begin::User */}
                    <div className='text-gray-400'>
                      Lead by
                      <a href='#'>Mark Randall</a>
                    </div>
                    {/*end::User */}
                  </div>
                  {/*end::Info */}
                  {/*begin::Action */}
                  <a href='#' className='btn btn-bg-light btn-active-color-primary btn-sm'>
                    View
                  </a>
                  {/*end::Action */}
                </div>
                {/*end::Time */}
                {/*begin::Time */}
                <div className='d-flex flex-stack position-relative mt-8'>
                  {/*begin::Bar */}
                  <div className='position-absolute h-100 w-4px bg-secondary rounded top-0 start-0'></div>
                  {/*end::Bar */}
                  {/*begin::Info */}
                  <div className='fw-semibold ms-5 text-gray-600'>
                    {/*begin::Time */}
                    <div className='fs-5'>
                      13:00 - 14:00
                      <span className='fs-7 text-gray-400 text-uppercase'>pm</span>
                    </div>
                    {/*end::Time */}
                    {/*begin::Title */}
                    <a href='#' className='fs-5 fw-bold text-gray-800 text-hover-primary mb-2'>
                      Weekly Team Stand-Up
                    </a>
                    {/*end::Title */}
                    {/*begin::User */}
                    <div className='text-gray-400'>
                      Lead by
                      <a href='#'>Caleb Donaldson</a>
                    </div>
                    {/*end::User */}
                  </div>
                  {/*end::Info */}
                  {/*begin::Action */}
                  <a href='#' className='btn btn-bg-light btn-active-color-primary btn-sm'>
                    View
                  </a>
                  {/*end::Action */}
                </div>
                {/*end::Time */}
              </div>
              {/*end::Day */}
              {/*begin::Day */}
              <div id='kt_schedule_day_11' className='tab-pane fade show'>
                {/*begin::Time */}
                <div className='d-flex flex-stack position-relative mt-8'>
                  {/*begin::Bar */}
                  <div className='position-absolute h-100 w-4px bg-secondary rounded top-0 start-0'></div>
                  {/*end::Bar */}
                  {/*begin::Info */}
                  <div className='fw-semibold ms-5 text-gray-600'>
                    {/*begin::Time */}
                    <div className='fs-5'>
                      10:00 - 11:00
                      <span className='fs-7 text-gray-400 text-uppercase'>am</span>
                    </div>
                    {/*end::Time */}
                    {/*begin::Title */}
                    <a href='#' className='fs-5 fw-bold text-gray-800 text-hover-primary mb-2'>
                      9 Degree Project Estimation Meeting
                    </a>
                    {/*end::Title */}
                    {/*begin::User */}
                    <div className='text-gray-400'>
                      Lead by
                      <a href='#'>Yannis Gloverson</a>
                    </div>
                    {/*end::User */}
                  </div>
                  {/*end::Info */}
                  {/*begin::Action */}
                  <a href='#' className='btn btn-bg-light btn-active-color-primary btn-sm'>
                    View
                  </a>
                  {/*end::Action */}
                </div>
                {/*end::Time */}
                {/*begin::Time */}
                <div className='d-flex flex-stack position-relative mt-8'>
                  {/*begin::Bar */}
                  <div className='position-absolute h-100 w-4px bg-secondary rounded top-0 start-0'></div>
                  {/*end::Bar */}
                  {/*begin::Info */}
                  <div className='fw-semibold ms-5 text-gray-600'>
                    {/*begin::Time */}
                    <div className='fs-5'>
                      12:00 - 13:00
                      <span className='fs-7 text-gray-400 text-uppercase'>pm</span>
                    </div>
                    {/*end::Time */}
                    {/*begin::Title */}
                    <a href='#' className='fs-5 fw-bold text-gray-800 text-hover-primary mb-2'>
                      9 Degree Project Estimation Meeting
                    </a>
                    {/*end::Title */}
                    {/*begin::User */}
                    <div className='text-gray-400'>
                      Lead by
                      <a href='#'>Karina Clarke</a>
                    </div>
                    {/*end::User */}
                  </div>
                  {/*end::Info */}
                  {/*begin::Action */}
                  <a href='#' className='btn btn-bg-light btn-active-color-primary btn-sm'>
                    View
                  </a>
                  {/*end::Action */}
                </div>
                {/*end::Time */}
                {/*begin::Time */}
                <div className='d-flex flex-stack position-relative mt-8'>
                  {/*begin::Bar */}
                  <div className='position-absolute h-100 w-4px bg-secondary rounded top-0 start-0'></div>
                  {/*end::Bar */}
                  {/*begin::Info */}
                  <div className='fw-semibold ms-5 text-gray-600'>
                    {/*begin::Time */}
                    <div className='fs-5'>
                      16:30 - 17:30
                      <span className='fs-7 text-gray-400 text-uppercase'>pm</span>
                    </div>
                    {/*end::Time */}
                    {/*begin::Title */}
                    <a href='#' className='fs-5 fw-bold text-gray-800 text-hover-primary mb-2'>
                      Marketing Campaign Discussion
                    </a>
                    {/*end::Title */}
                    {/*begin::User */}
                    <div className='text-gray-400'>
                      Lead by
                      <a href='#'>Peter Marcus</a>
                    </div>
                    {/*end::User */}
                  </div>
                  {/*end::Info */}
                  {/*begin::Action */}
                  <a href='#' className='btn btn-bg-light btn-active-color-primary btn-sm'>
                    View
                  </a>
                  {/*end::Action */}
                </div>
                {/*end::Time */}
              </div>
              {/*end::Day */}
            </div>
            {/*end::Tab Content */}
          </div>
          {/*end::Card body */}
        </div>
        {/*end::Timeline widget-3 */}
      </div>
      {/*end::Col */}
    </div>
  </>
)

const DashboardProfissional = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <DashboardPage />
    </>
  )
}

export { DashboardProfissional }
