import { useListView } from '../../core/ListViewProvider'
import { AvaliacoesConcluidasListGrouping } from './AvaliacoesConcluidasListGrouping'
import { AvaliacoesConcluidasListSearchComponent } from './AvaliacoesConcluidasListSearchComponent'

export const AvaliacoesConcluidasListHeader = () => {
  const { selected } = useListView()

  return (
    <div className='card-header border-0 pt-6'>
      <AvaliacoesConcluidasListSearchComponent />
      <div className='w-100'>
        {selected.length > 0 ? <AvaliacoesConcluidasListGrouping /> : null}
      </div>
    </div>
  )
}
