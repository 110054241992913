import { IOption } from '../../app/components'
import {
  getEstadosDeAtendimento,
  getSituacaoesAso,
} from '../../app/modules/apps/atendimento/core/_requests'
import { getEmpresas } from '../../app/modules/apps/empresa/empresas-list/core/_requests'
import {
  getEspecialidades,
  getTiposAtendimentos,
} from '../../app/modules/apps/especialidades/especialidades-list/core/_requests'
import { getCargos } from '../../app/modules/apps/funcionarios-management/funcionarios-list/core/_requests'
import { getMedicos } from '../../app/modules/apps/relatorios/relatorio-list/core/_requests'
import { UsuarioDtoVinculo } from '../../app/modules/auth'
import { ID } from './crud-helper/models'
import { SituacaoAso, Status } from './enums'

const status = [
  { value: 'true', label: 'Ativo' },
  { value: 'false', label: 'Inativo' },
]

const retorno = [
  { value: true, label: 'Sim' },
  { value: false, label: 'Não' },
]

const getNestedValue = (obj: any, key: string): any =>
  key.split('.').reduce((acc, part) => acc && acc[part], obj)

const createOptions = <T>(
  data: T[],
  labelKey: keyof T | string,
  valueKey: keyof T | string
): IOption[] => {
  const options = data.map((item) => ({
    label: String(getNestedValue(item, labelKey as string)),
    value: getNestedValue(item, valueKey as string) as unknown as ID | string | Status | boolean,
  }))

  options.sort((a, b) => {
    const valueA = String(a.label).toLowerCase()
    const valueB = String(b.label).toLowerCase()
    return valueA.localeCompare(valueB)
  })

  return options
}

const getOptionsEmpresas = async (nomeFantasia: string) => {
  const data = await getEmpresas(
    `page=0&size=30&search=ativa:true${nomeFantasia ? `,nomeFantasia~*${nomeFantasia}*` : ''}`
  )

  return createOptions(data.data || [], 'nomeFantasia', 'id')
}

const getOptionsEstadosAtendimento = async (): Promise<IOption[]> => {
  const data = await getEstadosDeAtendimento()
  return createOptions(data.data || [], 'descricao', 'id')
}

const getOptionsCargos = async (): Promise<IOption[]> => {
  const data = await getCargos()
  return createOptions(data || [], 'descricao', 'name')
}

const getOptionsTiposAtendimento = async (): Promise<IOption[]> => {
  const data = await getTiposAtendimentos()
  return createOptions(data || [], 'descricao', 'name')
}

const getOptionsEspecialidades = async (especialidade: string): Promise<IOption[]> => {
  const data = await getEspecialidades(
    `page=0&size=20&search=ativa:true${especialidade ? `,nome~*${especialidade}*` : ''}`
  )

  return createOptions(data?.data || [], 'nome', 'id')
}

const getOptionsSituacoes = async () => {
  const data = await getSituacaoesAso()

  return createOptions(
    data.filter(
      (value) =>
        value.name === SituacaoAso.Atendido ||
        value.name === SituacaoAso.Iniciado ||
        value.name === SituacaoAso.Gerado
    ) || [],
    'descricao',
    'name'
  )
}

const getOptionsProfissionalSaude = async (nome: string) => {
  const data = await getMedicos(
    `page=0&size=20&search=cargo:MEDICO${nome ? `,pessoa.nome~*${nome}*` : ''}`
  )

  const uniqueData = Array.from(
    new Map(data?.data?.map((item) => [item.pessoa?.id, item])).values()
  )

  return createOptions(uniqueData || [], 'nome', 'pessoa.id')
}

const getOptionsSolicitante = async (nome: string) => {
  const data = await getMedicos(`page=0&size=20${nome ? `&search=pessoa.nome~*${nome}*` : ''}`)

  const uniqueData = Array.from(
    new Map(data?.data?.map((item) => [item.pessoa?.id, item])).values()
  )

  return createOptions(uniqueData || [], 'nome', 'pessoa.id')
}

const getOptionsTiposAtendimentoUsuario = (
  currentUser: UsuarioDtoVinculo | undefined
): IOption[] => {
  const tiposSet = new Set<string>()
  currentUser?.vinculos?.forEach((vinculo) => {
    if (vinculo.empresa && vinculo.empresa.tiposAtendimento) {
      vinculo.empresa.tiposAtendimento.forEach((tipo) => tiposSet.add(tipo))
    }
  })
  return createOptions(Array.from(tiposSet).map((value) => ({ tipo: value })) || [], 'tipo', 'tipo')
}

export const OptionsHelper = {
  status,
  retorno,
  getOptionsEmpresas,
  getOptionsEspecialidades,
  getOptionsEstadosAtendimento,
  getOptionsTiposAtendimentoUsuario,
  getOptionsProfissionalSaude,
  getOptionsSolicitante,
  getOptionsSituacoes,
  getOptionsTiposAtendimento,
  getOptionsCargos,
}
