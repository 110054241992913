import { ImportacaoErroList } from "./components/ImportacaoErroList"
import { ListViewProvider } from "./core/ListViewProvider"
import { QueryRequestProvider } from "./core/QueryRequestProvider"
import { QueryResponseProvider } from "./core/QueryResponseProvider"

const ImportacaoErroPage = () => (
    <QueryRequestProvider>
      <QueryResponseProvider>
        <ListViewProvider>
          <ImportacaoErroList />
        </ListViewProvider>
      </QueryResponseProvider>
    </QueryRequestProvider>
  )
  
  export {ImportacaoErroPage}