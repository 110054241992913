/* eslint-disable jsx-a11y/anchor-is-valid */
import Pagination from 'react-js-pagination'
import {
  useQueryResponseLoading,
  useQueryResponsePagination,
} from '../../core/QueryResponseProvider'
import { useQueryRequest } from '../../core/QueryRequestProvider'

const DownloadAsoListPagination = () => {
  const paginationDefault = useQueryResponsePagination()

  const isLoading = useQueryResponseLoading()
  const { updateState } = useQueryRequest()

  const updatePage = (page: number) => {
    if (isLoading || paginationDefault.page === page) {
      return
    }

    updateState({ page, size: paginationDefault.size || 10 })
  }

  return (
    <div className='row'>
      <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
      <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
        <div id='kt_table_empresas_paginate'>
          <Pagination
            activePage={paginationDefault.page + 1}
            itemsCountPerPage={paginationDefault.size}
            totalItemsCount={paginationDefault.totalElements}
            pageRangeDisplayed={5}
            onChange={(p) => updatePage(p - 1)}
            innerClass='pagination'
            itemClass='page-item'
            linkClass='page-link'
            hideFirstLastPages={true}
            prevPageText='Anterior'
            nextPageText='Próximo'
          />
        </div>
      </div>
    </div>
  )
}

export { DownloadAsoListPagination }
