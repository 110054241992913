import { FC, ReactNode } from 'react'

export type INavBar = {
  children: React.ReactNode
}

export const NavBar: FC<INavBar> = ({ children }) => (
  <nav className='navbar navbar-expand-lg p-0'>
    <div className='d-flex flex-row w-100'>{children}</div>
  </nav>
)
