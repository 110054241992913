import { FC, useState, useEffect } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import { ProcedimentoAso } from './Procedimento'
import { Parecer } from './Parecer'
import { AtendimentoView, PendenciaAso } from '../atendimento/core/_models'
import { getAtendimentoById, validateAso } from '../atendimento/core/_requests'
import { ID } from '../../../../_metronic/helpers'
import { TipoAso } from './TipoAso'
import { ClienteAso } from './ClienteAso'
import { Coordenador } from './Coordenador'
import { FatorRiscoEdit } from './FatorRisco'
import { Resultado } from './Resultado'

type Props = {
  id: ID | null
}

const Aso: FC<Props> = ({ id }) => {
  const [pendencias, setPendencias] = useState<PendenciaAso>()
  const [atendimentoAso, setAtendimentoAso] = useState<AtendimentoView | undefined>(undefined)
  const [key, setKey] = useState('procedimento')

  function verificaEstadoAso(id: ID) {
    validateAso(id).then((p) => {
      setPendencias(p)
    })
  }

  const defineAtendimento = () => {
    getAtendimentoById(id).then((a) => {
      setAtendimentoAso(a)
      verificaEstadoAso(a?.id)
    })
  }

  function possuiPendencia() {
    return (
      pendencias?.atendimento !== undefined ||
      pendencias?.paciente !== undefined ||
      pendencias?.responsavel !== undefined
    )
  }

  useEffect(() => {
    defineAtendimento()
    verificaEstadoAso(id)
  }, [id])

  return (
    <div>
      <div className='col-lg-12 col-xl-12 mb-xl-0'>
        <div className='text-left'>
          <label className='fw-bold fs-6 mb-1'>
            Médico Examinador: {atendimentoAso?.responsavel?.nome}
          </label>
        </div>
      </div>
      <div className='card-body p-1'>
        <div className='row mb-2'>
          <div className='table-responsive'>
            <table className='table align-middle gs-0'>
              <thead>
                <tr>
                  <th className='p-0 min-w-150px'></th>
                  <th className='p-0 min-w-100px'></th>
                  <th className='p-0 min-w-100px'></th>
                  <th className='p-0 min-w-100px'></th>
                  <th className='p-0 min-w-100px'></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className='d-flex flex-column w-100 me-2'>
                      <div className='d-flex flex-stack mb-2'>
                        <span className='text-bold me-2 fs-7 fw-semibold'>Nome paciente</span>
                      </div>
                      <div className='w-100'>
                        <span className='text-muted me-2 fs-7 fw-semibold'>
                          {atendimentoAso?.paciente?.pessoa?.nome}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex flex-column w-100 me-2'>
                      <div className='d-flex flex-stack mb-2'>
                        <span className='text-bold me-2 fs-7 fw-semibold'>Nascimento</span>
                      </div>
                      <div className='w-100'>
                        <span className='text-muted me-2 fs-7 fw-semibold'>
                          {atendimentoAso?.paciente?.pessoa?.dataNascimentoFormatada}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex flex-column w-100 me-2'>
                      <div className='d-flex flex-stack mb-2'>
                        <span className='text-bold me-2 fs-7 fw-semibold'>Cargo</span>
                      </div>
                      <div className='w-100'>
                        <span className='text-muted me-2 fs-7 fw-semibold'>
                          {atendimentoAso?.dadosAso?.cargo !== undefined
                            ? atendimentoAso?.dadosAso?.cargo
                            : '<Não informado>'}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex flex-column w-100 me-2'>
                      <div className='d-flex flex-stack mb-2'>
                        <span className='text-bold me-2 fs-7 fw-semibold'>Setor</span>
                      </div>
                      <div className='w-100'>
                        <span className='text-muted me-2 fs-7 fw-semibold'>
                          {atendimentoAso?.dadosAso?.setor !== undefined
                            ? atendimentoAso?.dadosAso?.setor
                            : '<Não informado>'}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex flex-column w-100 me-2'>
                      <div className='d-flex flex-stack mb-2'>
                        <span className='text-bold me-2 fs-7 fw-semibold'>Tipo Aso</span>
                      </div>
                      <div className='w-100'>
                        <span className='text-muted me-2 fs-7 fw-semibold'>
                          {atendimentoAso?.tipoAso ? atendimentoAso?.tipoAso : '<Não informado>'}
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {possuiPendencia() ? (
          <>
            <div className='card h-md-100' style={{ backgroundColor: '#F4F4F4' }}>
              <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bold fs-1 text-dark'>Pendências</span>
                </h3>
              </div>
              <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                <div className='me-7 mb-6 p-4'></div>

                {pendencias !== undefined && pendencias.atendimento !== undefined ? (
                  <>
                    <label className='required fw-bold fs-6 mb-2'>Atendimento</label>
                    <div id='msgUser' className='mb-10 bg-light-danger rounded'>
                      {pendencias.atendimento.map((a) => (
                        <div className='text-danger'>{a}.</div>
                      ))}
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {pendencias !== undefined && pendencias.paciente !== undefined ? (
                  <>
                    <label className='required fw-bold fs-6 mb-2 mx-5'>Paciente</label>
                    <div id='msgUser' className='mb-10 bg-light-danger rounded'>
                      {pendencias.paciente.map((a) => (
                        <div className='text-danger'>{a}.</div>
                      ))}
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {pendencias !== undefined && pendencias.responsavel !== undefined ? (
                  <>
                    <label className=' fw-bold fs-6 mx-5'>Médico</label>
                    <div id='msgUser' className='mb-10 bg-light-danger rounded'>
                      {pendencias.responsavel?.map((a) => (
                        <div className='text-danger'>{a}.</div>
                      ))}
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            <div id='msgUser' className='card-label bg-light-success rounded p-2'>
              <div className='text-success'>Aso sem pendências.</div>
            </div>
          </>
        )}

        <Tabs
          defaultActiveKey='procedimentos'
          id='justify-tab-example'
          className='mt-8 mb-5'
          justify
          variant='pills'
          activeKey={key}
          onSelect={(k) => setKey(k as string)}
        >
          <Tab eventKey='procedimentos' title='Exames'>
            <ProcedimentoAso atendimento={atendimentoAso} defineAtendimento={defineAtendimento} />
          </Tab>
          <Tab eventKey='fator' title='Fator de risco'>
            <FatorRiscoEdit atendimento={atendimentoAso} defineAtendimento={defineAtendimento} />
          </Tab>
          <Tab eventKey='parecer' title='Parecer'>
            <Parecer atendimento={atendimentoAso} defineAtendimento={defineAtendimento} />
          </Tab>
          <Tab eventKey='tipoAso' title='Tipo ASO'>
            <TipoAso atendimento={atendimentoAso} defineAtendimento={defineAtendimento} />
          </Tab>
          <Tab eventKey='cliente' title='Cliente ASO'>
            <ClienteAso atendimento={atendimentoAso} defineAtendimento={defineAtendimento} />
          </Tab>
          <Tab eventKey='coordenador' title='Coordenador'>
            <Coordenador atendimento={atendimentoAso} defineAtendimento={defineAtendimento} />
          </Tab>
          <Tab eventKey='resultado' title='Resultado'>
            <Resultado atendimento={atendimentoAso} defineAtendimento={defineAtendimento} />
          </Tab>
        </Tabs>
      </div>
    </div>
  )
}

export default Aso
