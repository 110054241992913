import { useState, useEffect } from 'react'
import {
  IconPaths,
  initialQueryState,
  OptionsHelper,
  useDebounce,
} from '../../../../../../../_metronic/helpers'
import EspecialidadeEditModalForm from '../../../especialidade-edit-modal-form/EspecialidadeEditModalForm'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { Button, CustomSelect, Input, Label } from '../../../../../../components'

const EspecialidadeListSearchComponent = () => {
  const { updateState } = useQueryRequest()
  const [tipoAtendimento, setTipoAtendimento] = useState<string>()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const debouncedSearchTerm = useDebounce(searchTerm, 150)

  const handleChangeNome = (e: React.ChangeEvent<HTMLInputElement>) => {
    let search = ''
    if (e.target.value) search += (search ? ',' : '') + 'nome~*' + e.target.value + '*'

    setSearchTerm(search)
  }

  useEffect(() => {
    if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
      updateState({
        filter: [],
        search: searchTerm,
        sort: 'nome',
        order: 'asc',
        ...initialQueryState,
        tipo: tipoAtendimento,
      })
    }
  }, [searchTerm, debouncedSearchTerm, tipoAtendimento])

  const handleShowModal = () => setShowModal(true)
  const handleCloseModal = () => setShowModal(false)

  return (
    <>
      <div className='d-flex flex-column flex-md-row align-items-center justify-content-between col-12'>
        <div className='d-flex flex-column flex-sm-row align-items-center col-12 col-md-10'>
          <div className='col-12 col-sm-7 col-md-6 col-lg-6 mb-5 me-0 me-sm-2'>
            <Label className='pb-1'>Nome</Label>
            <Input placeholder='Digite o nome' onChange={handleChangeNome} />
          </div>

          <div className='col-12 col-sm-5 col-md-4 col-lg-3 mb-5'>
            <Label className='pb-1'>Tipo atendimento</Label>
            <CustomSelect
              placeholder='Selecione'
              onChange={(selectedOption) => setTipoAtendimento(selectedOption?.value as string)}
              fetchOptions={OptionsHelper.getOptionsTiposAtendimento}
            />
          </div>
        </div>

        <div className='col-12 col-md-auto'>
          <Button className='btn-primary' icon={IconPaths.Add} onClick={handleShowModal}>
            Adicionar
          </Button>
        </div>
      </div>

      <EspecialidadeEditModalForm show={showModal} onHide={handleCloseModal} />
    </>
  )
}

export { EspecialidadeListSearchComponent }
