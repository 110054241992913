import { FormularioSituacao, ID, Response } from '../../../../../../_metronic/helpers'
import { AtendimentoForm } from '../../../atendimento_medicos/atendimentos-medico-list/core/_models'
import { FormularioList } from '../../../formularios/formularios-list/core/_models'
import { PacienteList } from '../../../paciente-management/pacientes-list/core/_models'

export type FormularioAtendimentoList = {
  concluido: boolean
  respondido: boolean
  dataFormatada: string
  atendimento: AtendimentoForm
  paciente: PacienteList
  situacao: FormularioSituacao
  formulario: FormularioList
  id: ID
  data: string
}

export type IAvaliacoesSelect = {
  avaliacaoId: ID
  atendimentoId: ID
  formularioId: ID
}

export type ListViewContextPropsAvaliacoes = {
  isAllSelected?: boolean
  selected: Array<IAvaliacoesSelect>
  onSelect: (selectedId: IAvaliacoesSelect) => void
  clearSelected: () => void
  disabled?: boolean
  onSelectAll?: () => void
}

export const initialListViewAvaliacoes: ListViewContextPropsAvaliacoes = {
  selected: [],
  onSelect: () => {},
  clearSelected: () => {},
  disabled: false,
  isAllSelected: false,
  onSelectAll: () => {},
}

export type AvaliacoesQueryResponse = Response<Array<FormularioAtendimentoList>>
