import { FC, PropsWithChildren } from 'react'
import { HeaderProps } from 'react-table'
import { ListViewContextProps } from '../../../../../_metronic/helpers'

type Props<T extends object> = {
  tableProps: PropsWithChildren<HeaderProps<T>>
  useListView: () => ListViewContextProps
}

export const SelectionHeader = <T extends object>({ tableProps, useListView }: Props<T>) => {
  const { isAllSelected, onSelectAll } = useListView()

  return (
    <div className='w-10px pe-2'>
      <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
        <input
          className='form-check-input'
          type='checkbox'
          data-kt-check={isAllSelected}
          data-kt-check-target='#kt_table_users .form-check-input'
          checked={isAllSelected}
          onChange={onSelectAll}
        />
      </div>
    </div>
  )
}
