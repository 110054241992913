import axios, { AxiosResponse } from 'axios'
import Swal from 'sweetalert2'
import { VinculosQueryResponse } from './_models'
import { ID } from '../../../../../../_metronic/helpers'

const API_URL = process.env.REACT_APP_API_URL
const FORMULARIOS_VINCULOS_URL = `${API_URL}/formularios/vinculos`
const FORMULARIOS_VINCULOS_ESPECIALIDADE_URL = `${API_URL}/formularios/vinculos/especialidade`

export const bindFormulario = (data: {
  formulario: ID
  especialidades: ID[]
  empresas: ID[]
}): Promise<VinculosQueryResponse> => {
  return axios
    .post(`${FORMULARIOS_VINCULOS_URL}`, data)
    .then((d: AxiosResponse<VinculosQueryResponse>) => d.data)
}

export const getVinculosGroupByEspecialidade = (
  formId: ID,
  query: string
): Promise<VinculosQueryResponse> => {
  return axios
    .get(`${FORMULARIOS_VINCULOS_ESPECIALIDADE_URL}/${formId}?${query}`)
    .then((d: AxiosResponse<VinculosQueryResponse>) => d.data)
}

/* 
O DELETE recebe parametros id_empresa e id_especialidade. Nesse caso, remove apenas um vínculo.

Passando apenas o parâmetro especialidade, irá remover o vinculo dessa especialidade para todas as empresas.

Passando apenas o parâmetro empresa, irá remover o vínculo para todas as especialidades da empresa.

Se não passar nenhum dos parâmetros, irá remover todos os vínculos do questionário.

*/

export const deleteVinculo = (
  formId: ID,
  especialidadeId?: ID,
  empresaId?: ID
): Promise<VinculosQueryResponse> => {
  let query = ''

  if (especialidadeId) {
    query += `idEspecialidade=${especialidadeId}`
  }

  if (empresaId) {
    query += (query ? '&' : '') + `idEmpresa=${empresaId}`
  }

  return axios
    .delete(`${FORMULARIOS_VINCULOS_URL}/${formId}?${query}`)
    .then((d: AxiosResponse<VinculosQueryResponse>) => d.data)
}
