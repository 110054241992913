import React, { FC, useEffect, useRef, useState } from 'react'
import { Formik, Form } from 'formik'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import {
  Button,
  CustomModal,
  CustomSelect,
  Radio,
  TextEditor,
  ToggleInput,
} from '../../../../../../components'
import {
  createFormulario,
  deleteCampos,
  updateCamposOrder,
  updateFormulario,
} from '../../core/_requests'
import Swal from 'sweetalert2'
import { Input, Label } from '../../../../../../components'
import { ListLoading } from '../../../../commons/components/ListLoading'
import { useNavigate } from 'react-router-dom'
import { validationSchema } from './validationSchema'
import {
  ButtonType,
  IconPaths,
  IconSize,
  InputType,
  ModalSize,
} from '../../../../../../../_metronic/helpers'
import FieldConfigModal from '../inputs/InputConfigModal'
import { FormularioCampoView, FormularioView } from '../../core/_models'

type Props = {
  formulario?: FormularioView
  isLoading?: boolean
}

const formFields: { name: string; type: InputType; class: string }[] = [
  { name: 'Editor de Texto', type: InputType.TextEditor, class: 'bi-file-earmark-text' },
  { name: 'Texto', type: InputType.Text, class: 'bi-text-paragraph' },
  { name: 'Marcar', type: InputType.Radio, class: 'bi-check-circle' },
  { name: 'Número', type: InputType.Number, class: 'bi-123' },
  { name: 'Selecionar', type: InputType.Select, class: 'bi-list-check' },
]

export const FormularioEdit: FC<Props> = ({ isLoading, formulario: form }) => {
  const modalRef = useRef<{ open: () => void; close: () => void }>(null)
  const navigate = useNavigate()

  const [deletedFields, setDeletedFields] = useState<number[]>([])
  const [orderHasChanged, setOrderHasChanged] = useState<boolean>(false)

  const [selectedField, setSelectedField] = useState<FormularioCampoView | null>(null)
  const [initialFormulario, setInitialFormulario] = useState<FormularioView>(
    form
      ? form
      : {
          assinar: false,
          descricao: '',
          ativo: true,
          campos: [],
          nome: '',
        }
  )

  const handleAddField = (
    setFieldValue: (field: string, value: any) => void,
    fields: FormularioCampoView[],
    tipo: InputType
  ) => {
    const newField: FormularioCampoView = {
      ordem: fields.length + 1,
      rotulo: 'Pergunta',
      instrucoes: '',
      tipo,
      obrigatorio: false,
      multiplo: false,
      respostaPadrao: '',
      opcoes:
        tipo === InputType.Select || tipo === InputType.Radio
          ? [
              { ordem: 1, descricao: 'Sim' },
              { ordem: 2, descricao: 'Não' },
            ]
          : [],
    }

    setFieldValue('campos', [...fields, newField])
  }
  const handleEditField = (field: FormularioCampoView) => {
    setSelectedField(field)
    modalRef.current?.open()
  }

  const handleUpdateField = (
    updatedConfig: FormularioCampoView,
    fields: FormularioCampoView[],
    setFieldValue: (field: string, value: any) => void
  ) => {
    const updatedFields = fields.map((field) =>
      field.ordem === updatedConfig.ordem ? { ...field, ...updatedConfig } : field
    )

    setFieldValue('campos', updatedFields)
    setSelectedField(null)
  }

  const renderField = (field: FormularioCampoView) => {
    switch (field.tipo) {
      case InputType.Select:
        const options = field.opcoes?.map((option) => ({
          value: option.descricao,
          label: option.descricao,
        }))

        return (
          <CustomSelect
            className='w-100'
            isMulti={field.multiplo}
            options={options}
            isRequired={field.obrigatorio}
            isDisabled={true}
            value={
              field.respostaPadrao
                ? { label: field.respostaPadrao, value: field.respostaPadrao }
                : undefined
            }
          />
        )
      case InputType.TextEditor:
        return <TextEditor value={field.respostaPadrao || ''} onChange={() => {}} disabled={true} />
      case InputType.Radio:
        return (
          <Radio
            id={String(field.id)}
            name={field.rotulo}
            value={field.respostaPadrao}
            options={field.opcoes}
            disabled={true}
          />
        )
      default:
        return (
          <Input
            type={field.tipo === InputType.Number ? 'number' : 'text'}
            name={field.rotulo}
            required={field.obrigatorio}
            disabled={true}
            value={
              field.tipo === InputType.Number
                ? field.respostaPadrao !== ''
                  ? Number(field.respostaPadrao)
                  : undefined
                : field.respostaPadrao
            }
          />
        )
    }
  }

  const handleDragEnd = (
    result: DropResult,
    fields: FormularioCampoView[],
    setFieldValue: (field: string, value: any) => void
  ) => {
    if (!result.destination) return

    const items = Array.from(fields)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)
    setOrderHasChanged(true)
    setFieldValue('campos', items)
  }

  const formatErrors = (error: any) => {
    let formattedErrors = ''

    if (error.errors) {
      for (let field in error.errors) {
        if (error.errors.hasOwnProperty(field)) {
          formattedErrors += `${field}: ${error.errors[field].join(', ')}\n`
        }
      }
    }

    return formattedErrors
  }

  const onSubmit = async (formulario: FormularioView) => {
    try {
      formulario.campos.forEach((field, index) => {
        field.ordem = index + 1
      })

      if (formulario.id) {
        await updateFormulario({
          ativo: formulario.ativo,
          nome: formulario.nome,
          descricao: formulario.descricao,
          id: formulario.id,
          assinar: formulario.assinar,
        })
        if (deletedFields.length > 0) deleteCampos(deletedFields)
        if (orderHasChanged || deletedFields.length > 0) updateCamposOrder(formulario.campos)
      } else {
        const campos = formulario.campos.map((campo) => ({
          ...campo,
          opcoes: campo.opcoes?.map((opcao) => opcao.descricao),
        }))

        const formUpdated = { ...formulario, campos }

        await createFormulario(formUpdated)
      }

      Swal.fire({
        text: `Formulário ${formulario.id ? 'alterado' : 'cadastrado'} com sucesso`,
        icon: 'success',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      }).then(() => navigate('/apps/formularios'))
    } catch (error: any) {
      Swal.fire({
        text: formatErrors(error.response.data.data),
        icon: 'error',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    }
  }

  return (
    <Formik
      initialValues={initialFormulario}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {(formik) => {
        const { values, setFieldValue, errors, touched, dirty } = formik
        return (
          <div className='container d-flex flex-row justify-content-between shadow-sm p-4 bg-red'>
            <Form className='col-md-8 p-4'>
              {errors.campos && touched.campos && typeof errors.campos === 'string' && (
                <div className='alert alert-danger' role='alert'>
                  {errors.campos}
                </div>
              )}

              <div>
                <Label className='pb-2'>Nome do formulário</Label>
                <Input
                  {...formik.getFieldProps('nome')}
                  required={true}
                  isInvalid={!!(formik.touched.nome && formik.errors.nome)}
                  isValid={formik.touched.nome && !formik.errors.nome}
                  errorMessage={formik.errors.nome}
                />
              </div>
              <div className='pt-4'>
                <Label className='pb-2'>Descrição</Label>
                <Input
                  {...formik.getFieldProps('descricao')}
                  required={true}
                  isInvalid={!!(formik.touched.descricao && formik.errors.descricao)}
                  isValid={formik.touched.descricao && !formik.errors.descricao}
                  errorMessage={formik.errors.descricao}
                />
              </div>

              <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-end pt-4 align-items-center'>
                <Label className='me-3'>Obrigatório Assinatura</Label>
                <ToggleInput
                  checked={formik.values.assinar}
                  onChange={(checked) => formik.setFieldValue('assinar', checked)}
                />
              </div>

              <DragDropContext
                onDragEnd={(result) => handleDragEnd(result, values.campos, setFieldValue)}
              >
                <Droppable droppableId='droppable'>
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {values.campos.map((field, index) => (
                        <Draggable
                          key={field.ordem}
                          draggableId={String(field.ordem)}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className='mb-4'
                            >
                              <div className='d-flex flex-column w-100 p-3'>
                                <Label
                                  className={`py-2 ${field.obrigatorio ? 'required' : ''}
                                  `}
                                >
                                  {field.rotulo}
                                </Label>

                                <div className='d-flex align-items-center justify-content-between w-100'>
                                  {renderField(field)}
                                  <div
                                    className='d-flex ms-2 align-items-center justify-content-end'
                                    style={{ height: '100%' }}
                                  >
                                    <Button
                                      className='p-0'
                                      icon={IconPaths.Gear}
                                      iconSize={IconSize.Large}
                                      onClick={() => handleEditField(field)}
                                    />
                                    <Button
                                      className='p-0'
                                      icon={IconPaths.Trash}
                                      iconSize={IconSize.Large}
                                      onClick={() => {
                                        setDeletedFields((prev) =>
                                          field.id ? [...prev, field.id] : [...prev]
                                        )
                                        setFieldValue(
                                          'campos',
                                          values.campos.filter((f) => f.ordem !== field.ordem)
                                        )
                                      }}
                                    />
                                  </div>
                                </div>
                                {field.instrucoes ? (
                                  <span className='text-muted small pt-1'>{field.instrucoes}</span>
                                ) : null}
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>

              <div className='d-flex justify-content-end mt-10'>
                <Button type={ButtonType.Submit} className='btn-primary'>
                  {form ? 'Salvar Alterações' : 'Criar Formulário'}
                </Button>
              </div>
            </Form>
            <div
              className='col-md-4 d-flex flex-column align-items-center justify-content-between rounded h-50 p-4'
              style={{ maxHeight: '500px' }}
            >
              <h2>Campos</h2>

              {formFields.map((field) => (
                <Button
                  onClick={() => handleAddField(setFieldValue, values.campos, field.type)}
                  className='btn-secondary mb-2 align-items-center w-50'
                  key={field.type}
                >
                  <i className={`bi me-2 ${field.class}`}></i>
                  <span>{field.name}</span>
                </Button>
              ))}
            </div>

            <CustomModal ref={modalRef} title='Configurar campo' size={ModalSize.Large}>
              {selectedField && (
                <FieldConfigModal
                  field={selectedField}
                  formId={initialFormulario.id}
                  onUpdate={(updatedConfig: FormularioCampoView) =>
                    handleUpdateField(updatedConfig, values.campos, setFieldValue)
                  }
                />
              )}
            </CustomModal>

            {(formik.isSubmitting || isLoading) && <ListLoading />}
          </div>
        )
      }}
    </Formik>
  )
}
