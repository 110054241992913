import { FC } from 'react'

type ITextInput = {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  placeholder: string
  className?: string
  value?: string
}

export const TextInput: FC<ITextInput> = ({ onChange, placeholder, className, value }) => {
  return (
    <input
      type='text'
      data-kt-user-table-filter='nome'
      className={`form-control form-control-solid w-100 ${className}`}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
    />
  )
}

TextInput.defaultProps = {
  placeholder: 'Edite seu Placehoder',
  className: '',
}
