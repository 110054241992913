import { Column } from 'react-table'
import { AtendimentoView } from '../../../../atendimento/core/_models'
import { HistoricoActionsCell } from './HistoricoActionsCell'
import { CustomHeader } from '../../../../commons/table/CustomHeader'
import { useQueryRequest } from '../../core/QueryRequestProvider'

const HistoricoColumns: ReadonlyArray<Column<AtendimentoView>> = [
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title='Protocolo'
        className='min-w-125px'
        useQueryRequest={useQueryRequest}
      />
    ),
    id: 'protocolo',
    accessor: 'protocolo',
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title='Nome Paciente'
        className='min-w-100px'
        useQueryRequest={useQueryRequest}
      />
    ),
    id: 'paciente.pessoa.nome',
    accessor: (row) => row.paciente?.pessoa?.nome,
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title='Sinais'
        className='min-w-150px'
        useQueryRequest={useQueryRequest}
      />
    ),
    id: 'sinais',
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title='Estado'
        className='min-w-125px'
        useQueryRequest={useQueryRequest}
      />
    ),
    id: 'estado.descricao',
    accessor: (row) => row.estado?.descricao,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Especialidade' useQueryRequest={useQueryRequest} />
    ),
    id: 'especialidade',
    accessor: (row) => row.especialidade?.nome,
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title='Data'
        className='min-w-125px'
        useQueryRequest={useQueryRequest}
      />
    ),
    id: 'inicioFormatado',
    accessor: 'inicioFormatado',
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title='Ações'
        className='text-end min-w-100px'
        useQueryRequest={useQueryRequest}
      />
    ),
    id: 'actions',
    Cell: ({ ...props }) => (
      <HistoricoActionsCell
        hasPrescricoes={props.row.original.prescricoes && props.row.original.prescricoes.length > 0}
        formulario={props.row.original.formularios && props.row.original.formularios[0]}
        atendimentoId={props.data[props.row.index].id}
      />
    ),
  },
]

export { HistoricoColumns }
