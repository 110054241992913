import { FC, ReactNode } from 'react'

export type INavBarItem = {
  children: ReactNode
  isActive?: boolean
  path?: string
  onClick?: () => void
  qtd?: number
  isFirst: boolean
  isLast: boolean
}

export const NavBarItem: FC<INavBarItem> = ({
  children,
  isActive,
  path,
  onClick,
  qtd,
  isFirst,
  isLast,
}) => {
  return (
    <a
      className={`flex-fill text-center py-3 px-3 cursor-pointer fs-md-8 fs-lg-7 align-content-center ${
        isActive ? 'bg-background text-primary fw-bolder' : 'bg-primary text-white fw-light'
      }`}
      href={path}
      onClick={onClick}
      style={{
        borderRadius: isFirst ? '8px 0 0 0' : isLast ? '0 8px 0 0' : '0',
        transition: 'all 0.3s ease',
      }}
    >
      {children} <span>{`(${qtd})`}</span>
    </a>
  )
}
