import { useListView } from '../../core/ListViewProvider'
import { ExameClienteListGrouping } from './ExameClienteListGrouping'
import { ExameClienteListSearchComponent } from './ExameClienteListSearchComponent'
import { ExameClienteListToolbar } from './ExameClienteListToolbar'

const ExameClienteListHeader = () => {
  const { selected } = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <ExameClienteListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <ExameClienteListGrouping /> : <ExameClienteListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export { ExameClienteListHeader }
