/* eslint-disable jsx-a11y/anchor-is-valid */

import { FC, useEffect } from 'react'

import { Importacao } from '../../core/_models'
import { MenuComponent } from '../../../../../../../_metronic/assets/ts/components'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../../../../../components'
import { IconPaths } from '../../../../../../../_metronic/helpers'

type Props = {
  importacao: Importacao
}

const ImportacoesActionsCell: FC<Props> = ({ importacao }) => {
  const navigate = useNavigate()
  const openDetalhes = () => {
    navigate('/apps/importacao/erros/' + importacao.id)
  }

  const openErros = () => {
    navigate('/apps/importacao/detalhes/' + importacao.id)
  }

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  return (
    <div className='d-flex flex-end'>
      {(importacao?.registrosErros ?? 0) > 0 && (
        <Button className='btn-primary btn-sm' onClick={openDetalhes} icon={IconPaths.Search}>
          Detalhes
        </Button>
      )}

      {(importacao?.registrosErros ?? 0) === 0 && (
        <Button className='btn-primary btn-sm' onClick={openErros} icon={IconPaths.Search}>
          Detalhes
        </Button>
      )}
    </div>
  )
}

export { ImportacoesActionsCell }
