/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState } from 'react'
import { KTSVG } from '../../../helpers'
import { UploadAvatar } from './UploadAvatar'

const UploadImagemPerfil: FC = () => {
  return (
    <div className='modal fade' id='kt_upload_imagem_perfil' aria-hidden='true'>
      <div className='modal-dialog modal-xl'>
        <div className='modal-content rounded'>
          <div className='modal-header justify-content-end border-0 pb-0'>
            <div
              className='btn btn-sm btn-icon btn-active-color-primary'
              data-bs-dismiss='modal'
            ></div>
          </div>
          <div className='modal-body pt-0 pb-15 px-5 px-xl-20'>
            <div className='mb-13 text-center'>
              <h1 className='mb-3'>Alterar imagem do perfil</h1>
            </div>
            <div className='d-flex flex-center flex-row-fluid'>
              <UploadAvatar />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { UploadImagemPerfil }
