import axios, { AxiosResponse } from 'axios'
import { ID } from '../../../../../../_metronic/helpers'
import { ArquivoDto } from '../../../atendimento/core/_models'

const API_URL = process.env.REACT_APP_API_URL
const PRESCRICOES_URL = `${API_URL}/atendimentos/prescricao/download`

export const downloadAllPrescricoes = (atendimentoId: ID): Promise<ArquivoDto[]> => {
  return axios.get(`${PRESCRICOES_URL}/${atendimentoId}`).then((resp: AxiosResponse) => resp.data)
}
export const downloadPrescricao = (atendimentoId: ID, prescricaoId: ID): Promise<ArquivoDto> => {
  return axios.get(`${PRESCRICOES_URL}/${atendimentoId}/${prescricaoId}`).then((resp) => resp.data)
}
