import { ChangeEvent, FC, useState } from 'react'
import { Label } from '../Label/Label'
import { FormikErrors, FormikTouched } from 'formik'

export type ICheckBox = {
  checked: boolean
  disabled?: boolean
  label: string
  touched?: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined
  error?: string | FormikErrors<any> | FormikErrors<any>[] | undefined | string[]
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void
  id: string
}

export const CheckBox: FC<ICheckBox> = ({
  checked,
  disabled,
  label,
  touched,
  error,
  onChange,
  onBlur,
  id,
}) => {
  return (
    <div className='form-check form-check-sm form-check-custom form-check-solid m-0 p-0'>
      <input
        id={`${id}_${label}`}
        name={`${id}_${label}`}
        className='form-check-input me-2'
        type='checkbox'
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        onBlur={onBlur}
      />
      <Label disabled={disabled} className={` ${touched && error ? 'is-invalid' : ''}`}>
        {label}
      </Label>
    </div>
  )
}

CheckBox.defaultProps = {
  disabled: false,
}
