import { FC, useEffect, useState } from 'react'
import { Label } from '../Label/Label'
import { FormikErrors, FormikTouched } from 'formik'
import { FormularioOpcaoList } from '../../modules/apps/formularios/formularios-create/core/_models'

type IRadio = {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void
  options: FormularioOpcaoList[]
  name?: string
  className?: string
  id: string
  touched?: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined
  error?: string | FormikErrors<any> | FormikErrors<any>[] | undefined | string[]
  value?: string
  disabled?: boolean
}

export const Radio: FC<IRadio> = ({
  onChange,
  onBlur,
  className,
  options,
  id,
  touched,
  error,
  value,
  disabled,
}) => {
  return (
    <div className='d-flex'>
      {options.map((option: FormularioOpcaoList) => (
        <div key={option.id} className='form-check form-check-inline'>
          <input
            type='radio'
            id={`${id}_${option.descricao}`}
            name={`${id}_${option.descricao}`}
            value={option.descricao}
            onChange={onChange}
            onBlur={onBlur}
            checked={option.descricao === value}
            className={`form-check-input form-check-input-sm ${
              touched && error ? 'is-invalid' : ''
            } ${className}`}
            disabled={disabled}
          />
          <Label disabled={disabled} className={`me-3 ${touched && error ? 'is-invalid' : ''}`}>
            {option.descricao}
          </Label>
        </div>
      ))}
    </div>
  )
}

Radio.defaultProps = {
  className: '',
  name: '',
  onBlur: () => {},
  touched: false,
  error: '',
  disabled: false,
}
