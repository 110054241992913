import { FC, ReactNode } from 'react'
import { GridCols } from '../../../_metronic/helpers'
import Select, { StylesConfig } from 'react-select'

type ILabel = {
  children?: ReactNode
  className?: string
  disabled?: boolean
}

export const Label: FC<ILabel> = ({ children, className, disabled }) => (
  <label className={`fs-6 fw-bolder ${className} ${disabled ? 'disabled' : ''}`}>{children}</label>
)

Label.defaultProps = {
  disabled: false,
  children: '',
  className: '',
}
