import { FC, ReactNode } from 'react'
import { IconPaths, KTSVG } from '../../../_metronic/helpers'

type IModalHeader = {
  onClose: () => void
  children: ReactNode
}

export const ModalHeader: FC<IModalHeader> = ({ onClose, children }) => {
  return (
    <div className='modal-header'>
      <h2 className='fw-bolder'>{children}</h2>
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={onClose}
        style={{ cursor: 'pointer' }}
      >
        <KTSVG path={IconPaths.Close} className='svg-icon-1' />
      </div>
    </div>
  )
}

ModalHeader.defaultProps = {}
