import React, { FC, useState } from 'react'
import { FormularioRespostaList } from '../../../../atendimento_medicos/atendimento-video/core/_models'

export type IModal = {
  data: FormularioRespostaList[] | undefined
}
export const FormularioModal: FC<IModal> = ({ data }) => {
  return (
    <>
      {data?.map((item) => (
        <div key={item.id} className='mb-4'>
          <h6 className='text-primary'>{item.campo}</h6>
          <p
            className='text-primary'
            dangerouslySetInnerHTML={{ __html: item.valor || 'Sem Resposta' }}
          />
          {item.valores.length > 0 && (
            <ul className='pl-3'>
              {item.valores.map((valor, index) => (
                <li key={index}>{valor}</li>
              ))}
            </ul>
          )}
        </div>
      ))}
    </>
  )
}
