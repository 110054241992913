import { FC } from 'react'
import { WithChildren } from '../../react18MigrationHelpers'

type Props = {
  onDelete?: () => void
}

export const Badge: FC<Props & WithChildren> = ({ children, onDelete }) => {
  return (
    <span className='badge bg-secondary p-3 fs-6 rounded-pill justify-content-between me-3 mb-5'>
      {children}
      <button
        type='button'
        className='btn-close btn-close-black ms-2 fs-2'
        aria-label='Close'
        onClick={onDelete}
      ></button>
    </span>
  )
}
