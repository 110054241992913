import { useMemo } from 'react'
import { useTable, ColumnInstance, Row } from 'react-table'
import { useQueryResponseData, useQueryResponseLoading } from '../core/QueryResponseProvider'
import { KTCardBody } from '../../../../../../_metronic/helpers'
import { ListLoading } from '../../../commons/components/ListLoading'
import { CustomHeaderColumn } from '../../../commons/table/CustomHeaderColumn'
import { CustomRow } from '../../../commons/table/CustomRow'
import { vinculosColumns } from './columns/_columns'
import { Vinculos } from '../core/_models'

const VinculosTable = () => {
  const vinculos = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => vinculos, [vinculos])
  const columns = useMemo(() => vinculosColumns, [])
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns,
    data,
  })

  return (
    <KTCardBody className='py-4 w-100'>
      <div className='table-responsive w-100'>
        <table
          id='kt_table_formularios'
          className='table align-middle table-row-bordered fs-6 gy-5 dataTable no-footer w-100'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0 border-secondary'>
              {headers.map((column: ColumnInstance<Vinculos>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<Vinculos>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={columns.length}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    Nenhum registro encontrado
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {/* <VinculosListPagination /> */}
      {isLoading && <ListLoading />}
    </KTCardBody>
  )
}

export { VinculosTable }
