import React, { FC, useEffect, useState } from 'react'
import AsyncSelect from 'react-select/async'
import { AtendimentoView, CidList } from '../../atendimento/core/_models'
import {
  adicionarCid,
  deleteCid,
  getAtendimentoById,
  getCIDs,
} from '../../atendimento/core/_requests'
import { ID } from '../../../../../_metronic/helpers'

type Props = {
  atendimento: AtendimentoView
  closeButton: boolean
}

const INITIAL_DATA = {
  value: 0,
  label: 'Digite o CID',
}

const FormCID: FC<Props> = ({ atendimento, closeButton }) => {
  const [selectData, setSelectData] = useState(INITIAL_DATA)
  const [idCID, setIdCID] = useState('')
  const [loading, setLoading] = useState(false)
  const [atendimentoCid, setAtendimentoCid] = useState<AtendimentoView>(atendimento)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)

  const mapResponseToValuesAndLabels = (data: CidList) => ({
    label: `${data.codigo} - ${data.descricao}`,
    value: data.codigo,
  })

  async function callApi(value?: string) {
    const data = await getCIDs('page=0&size=50&search=' + value)
      .then((response) => response.data?.map(mapResponseToValuesAndLabels))
      .then((final: any) =>
        final.filter((i: any) =>
          String(i.label)
            .toLowerCase()
            .includes(value?.toLowerCase() as string)
        )
      )
    return data
  }

  const handleCIDClick = () => {
    setLoading(true)
    adicionarCid(atendimento.id, idCID)
      .then(() => {
        defineAtendimento()
        setLoading(false)
        setHasErrors(false)
      })
      .catch(() => {
        setHasErrors(true)
        setLoading(false)
      })
  }

  const handleDeleteCID = (codigo: ID) => {
    setLoading(true)
    deleteCid(atendimento.id, codigo)
      .then(() => {
        defineAtendimento()
        setLoading(false)
        setHasErrors(false)
      })
      .catch(() => {
        setHasErrors(true)
        setLoading(false)
      })
  }

  const onCancel = () => {
    closeButton = true
  }

  const defineAtendimento = () => {
    getAtendimentoById(atendimento.id).then((a) => {
      setAtendimentoCid(a as AtendimentoView)
    })
  }

  useEffect(() => {
    callApi()
  }, [])
  console.log(selectData)

  return (
    <div className='card mb-5' data-kt-scroll='true' data-kt-scroll-offset='5px'>
      <div>
        <h3 className='fw-bolder border border-gray-200 m-0'>CID</h3>
        {!!atendimentoCid.cids ? (
          atendimentoCid.cids.length > 0 ? (
            atendimentoCid.cids.map((c, index) => (
              <div key={index} className='d-flex justify-content-between align-items-center mt-2'>
                <span className='fs-6 py-x text-muted'>
                  {c.cid.codigo} - {c.cid.descricao}
                </span>
                <button
                  className='btn btn-sm btn-light btn-color-muted btn-active-light-danger'
                  onClick={() => handleDeleteCID(c.id as ID)}
                >
                  <span className='menu-icon d-flex align-items-center' data-kt-element='icon'>
                    X
                  </span>
                </button>
              </div>
            ))
          ) : (
            <span className='fs-6 py-x text-muted'>Não informado</span>
          )
        ) : (
          <span className='fs-6 py-x text-muted'>Não informado</span>
        )}
      </div>

      <div className='col-lg-12 col-xl-12 mb-5 mb-xl-0'>
        <div className='fv-row mt-5 '>
          <label className='col-lg-12 fw-bold text-muted'>CID</label>
          <div className='d-flex position-relative my-1'>
            <AsyncSelect
              className='form-select-transparent w-450px'
              cacheOptions
              loadOptions={callApi}
              onChange={(data: any) => {
                setIdCID(data.value)
                setSelectData(data)
              }}
              value={selectData}
              defaultOptions
            />
          </div>
        </div>
        {hasErrors === true && (
          <div className='mt-5 alert alert-danger'>
            <div className='alert-text font-weight-bold'>Erro ao enviar o arquivo</div>
          </div>
        )}
        {hasErrors === false && (
          <div id='msgUser' className='mt-5 bg-light-success p-8 rounded'>
            <div className='text-success'>Os dados foram alterados com sucesso.</div>
          </div>
        )}
      </div>
      <div className='d-flex flex-center flex-row-fluid pt-12'>
        <button
          type='reset'
          className='btn btn-light me-3'
          data-bs-dismiss='modal'
          onClick={onCancel}
        >
          Cancelar
        </button>
        <button
          type='submit'
          className='btn btn-primary'
          disabled={loading}
          onClick={handleCIDClick}
        >
          {!loading && 'Salvar Alterações'}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Aguarde...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </div>
  )
}

export default FormCID
