/* eslint-disable jsx-a11y/anchor-is-valid */

import { FC, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { getCargos, vincularFuncionarioEmpresa } from '../core/_requests'
import Select from 'react-select'
import Swal from 'sweetalert2'
import { EmpresasVinculo } from '../../../empresa/empresas-list/table/EmpresasVinculo'
import { getUserByCPF } from '../../../../auth/core/_requests'
import clsx from 'clsx'
import { IMaskInput } from 'react-imask'
import { CustomSelect } from '../../../../../components'
import { OptionsHelper } from '../../../../../../_metronic/helpers'
import { Vinculo } from '../../../../auth'

type Props = {
  cpf: string
  nome: string
  vinculos: Vinculo[] | undefined
  onCancel: Function
  possuiEmail: boolean
}

const FuncionarioVincularForm: FC<Props> = ({ cpf, nome, vinculos, onCancel, possuiEmail }) => {
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [loading, setLoading] = useState(false)
  const [vinculosForm, setVinculosForm] = useState(vinculos)
  const [isMedico, setIsMedico] = useState<boolean>()
  const [exibirEmail, setExibirEmail] = useState<boolean>(!possuiEmail)
  const [CRM, setCRM] = useState<string | null>(null)

  var options: any = []

  function getMedicoCRM(): string | null {
    for (const vinculo of vinculos as Vinculo[]) {
      if (vinculo.cargo === 'MEDICO') {
        return vinculo.crm
      }
    }
    return null
  }

  const initialValues = {
    crm: '',
    uf: '',
    cargo: '',
    empresa: '',
    email: null,
  }

  const validationSchema = Yup.object().shape({
    cargo: Yup.string().required('Cargo é obrigatório'),
    empresa: Yup.string().required('Empresa é obrigatória'),
    crm: Yup.string().when('cargo', {
      is: 'MEDICO',
      then: Yup.string().required('Número do Conselho de Classe é obrigatório'),
    }),
    uf: Yup.string().when('cargo', {
      is: 'MEDICO',
      then: Yup.string().required('UF do Conselho de Classe é obrigatória'),
    }),
    email: Yup.string()
      .email('E-mail inválido')
      .when('exibirEmail', {
        is: true,
        then: Yup.string().required('E-mail é obrigatório'),
      })
      .nullable(),
  })

  const INITIAL_DATA = {
    value: 0,
    label: 'Digite o nome da empresa',
  }

  const [selectData, setselectData] = useState(INITIAL_DATA)

  const resetData = () => {
    setHasErrors(undefined)
    onCancel()
  }

  getCargos()
    .then((cargos) => {
      for (let i = 0; i < cargos.length; i++) {
        options.push({ value: cargos[i].name, label: cargos[i].descricao })
      }
    })
    .catch((error: any) => {
      console.error(error)
    })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        vincularFuncionarioEmpresa(
          cpf,
          values.cargo,
          values.empresa,
          values.crm,
          !!values.uf ? values.uf : null,
          exibirEmail ? values.email : null
        )
          .then((result) => {
            setselectData(INITIAL_DATA)
            setExibirEmail(false)
            getUserByCPF(cpf)
              .then((user) => {
                //Aqui tem que definir o id
                setVinculosForm(user.data.vinculos)
                Swal.fire({
                  text: 'Vínculado com sucesso!',
                  icon: 'success',
                  buttonsStyling: false,
                  confirmButtonText: 'Ok',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                })
              })
              .catch((error) => {
                Swal.fire('Não foi possível localizar os vínculos', '', 'error')
              })
            setHasErrors(false)
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            })
          })
          .catch((error: any) => {
            const errors = error.response.data.data.errors
            const errorMessages = [
              errors['email'] !== undefined ? `Email: ${errors.email[0]}` : '',
              errors['cargo'] !== undefined ? `Cargo: ${errors.cargo[0]}` : '',
              errors['empresa'] !== undefined ? `Empresa: ${errors.empresa[0]}` : '',
              errors['crm'] !== undefined ? `CRM: ${errors.crm[0]}` : '',
              errors['uf'] !== undefined ? `UF: ${errors.uf[0]}` : '',
            ]
              .filter((message) => message)
              .join('. ')
            setHasErrors(true)

            Swal.fire({
              text: `Não foi possível vincular o funcionário a empresa. ${errorMessages}.`,
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          })
          .finally(() => {
            window.scrollTo(0, 0)
            setLoading(false)
            setSubmitting(false)
          })
      }, 1000)
    },
  })

  useState(() => {
    if (!!vinculos) {
      //@ts-ignore
      setCRM(getMedicoCRM())
    }
    //@ts-ignore
  }, [vinculos])

  useEffect(() => {
    if (!!CRM) {
      const regex = /^(\d+)\/([A-Z]{2})$/
      const match = CRM.match(regex)

      if (match) {
        formik.setFieldValue('crm', match[1])
        formik.setFieldValue('uf', match[2])
      }
    }
  }, [])

  return (
    <div>
      {hasErrors === true && (
        <div className='mb-lg-15 alert alert-warning'>
          <div className='alert-text font-weight-bold'>Funcioário não possui cadastro.</div>
        </div>
      )}
      {hasErrors === false && (
        <div id='msgUser' className='mb-10 bg-light-success p-8 rounded'>
          <div className='text-success'>Os dados foram alterados com sucesso.</div>
        </div>
      )}
      <div id='container'>
        {/*This element's contents will be replaced with your component. --*/}
      </div>
      {/* begin::Form group */}
      <div>
        <form onSubmit={formik.handleSubmit} className='form'>
          <div className='fv-row mb-10'>
            <label className='form-label fw-bolder text-gray-900 fs-6'>Nome</label>
            <br />
            <label className='form-label fw-bolder text-gray-900 fs-6'>{nome}</label>
          </div>
          <div className='fv-row mb-10'>
            <label className='form-label fw-bolder text-gray-900 fs-6'>CPF</label>
            <br />
            <label className='form-label fw-bolder text-gray-900 fs-6'>{cpf}</label>
          </div>
          {exibirEmail && (
            <div className='row mb-12'>
              <label className='col-lg-12 col-form-label fw-bold fs-6'>
                <span className='required'>E-mail</span>
              </label>

              <div className='col-lg-12 fv-row'>
                <input
                  type='tel'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='E-mail'
                  {...formik.getFieldProps('email')}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.email}</div>
                  </div>
                )}
              </div>
            </div>
          )}
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Vínculos Empresas</h3>
          </div>
          <EmpresasVinculo vinculos={vinculosForm} cpf={cpf} key={1}></EmpresasVinculo>
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Cargo</label>
            <Select
              placeholder='Selecione um cargo'
              onChange={(data: any) => {
                formik.setFieldValue('cargo', data.value)
                setIsMedico(data.value === 'MEDICO' ? true : false)
              }}
              options={options}
              isDisabled={formik.isSubmitting}
            />
            {formik.touched.cargo && formik.errors.cargo && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.cargo}</div>
              </div>
            )}
          </div>
          {isMedico ? (
            <>
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Número do Conselho de Classe</label>
                {/* end::Label */}
                {/* begin::Input */}
                <IMaskInput
                  placeholder='Número do Conselho de Classe'
                  {...formik.getFieldProps('crm')}
                  mask='0000000000'
                  type='text'
                  name='crm'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.crm && formik.errors.crm },
                    {
                      'is-valid': formik.touched.crm && !formik.errors.crm,
                    }
                  )}
                  disabled={formik.isSubmitting || loading}
                />
                {formik.touched.crm && formik.errors.crm && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.crm}</span>
                    </div>
                  </div>
                )}
                {/* end::Input */}
              </div>
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>UF do Conselho de Classe</label>
                {/* end::Label */}

                {/* begin::Input */}
                <select
                  className='form-select form-select-solid form-select-lg fw-bold'
                  {...formik.getFieldProps('uf')}
                >
                  <option value=''>Selecione um estado...</option>
                  <option value='AC'>Acre</option>
                  <option value='AL'>Alagoas</option>
                  <option value='AP'>Amapá</option>
                  <option value='AM'>Amazonas</option>
                  <option value='BA'>Bahia</option>
                  <option value='CE'>Ceará</option>
                  <option value='DF'>Distrito Federal</option>
                  <option value='ES'>Espírito Santo</option>
                  <option value='GO'>Goiás</option>
                  <option value='MA'>Maranhão</option>
                  <option value='MT'>Mato Grosso</option>
                  <option value='MS'>Mato Grosso do Sul</option>
                  <option value='MG'>Minas Gerais</option>
                  <option value='PA'>Pará</option>
                  <option value='PB'>Paraíba</option>
                  <option value='PR'>Paraná</option>
                  <option value='PE'>Pernambuco</option>
                  <option value='PI'>Piauí</option>
                  <option value='RJ'>Rio de Janeiro</option>
                  <option value='RN'>Rio Grande do Norte</option>
                  <option value='RS'>Rio Grande do Sul</option>
                  <option value='RO'>Rondônia</option>
                  <option value='RR'>Roraima</option>
                  <option value='SC'>Santa Catarina</option>
                  <option value='SP'>São Paulo</option>
                  <option value='SE'>Sergipe</option>
                  <option value='TO'>Tocantins</option>
                </select>
                {formik.touched.uf && formik.errors.uf && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.uf}</div>
                  </div>
                )}
                {/* end::Input */}
              </div>
            </>
          ) : (
            <></>
          )}
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Empresa</label>
            <CustomSelect
              fetchOptions={OptionsHelper.getOptionsEmpresas}
              onChange={(data: any) => {
                formik.setFieldValue('empresa', data.value)
                setselectData(data)
              }}
              value={selectData}
              isClearable={false}
            />
            {formik.touched.empresa && formik.errors.empresa && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.empresa}</div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          <div className='d-flex flex-center flex-row-fluid pt-12'>
            <button
              type='reset'
              className='btn btn-light me-3'
              data-bs-dismiss='modal'
              onClick={() => resetData()}
            >
              Cancelar
            </button>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Salvar'}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Aguarde...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export { FuncionarioVincularForm }
