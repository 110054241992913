import { FC } from 'react'

type IBadge = {
  isActive?: boolean
  options?: string[]
}

export const Badge: FC<IBadge> = ({ isActive, options }) => {
  return (
    <span
      className={`badge rounded-pill p-3 ${
        isActive ? 'bg-success text-white' : 'bg-secondary'
      } badge-lg fs-5`}
    >
      {isActive ? options![0] : options![1]}
    </span>
  )
}

Badge.defaultProps = {
  options: ['Ativo', 'Inativo'],
}
