import { FC, useMemo } from 'react'
import { ID } from '../../../../../../../_metronic/helpers'
import { useListView } from '../../core/ListViewProvider'
import { IAvaliacoesSelect } from '../../core/_models'

export const AvaliacoesPendentesSelectionCell: FC<IAvaliacoesSelect> = ({
  atendimentoId,
  formularioId,
  avaliacaoId,
}) => {
  const { selected, onSelect } = useListView()
  const isSelected = useMemo(() => {
    return selected.some(
      (item: IAvaliacoesSelect) =>
        item.atendimentoId === atendimentoId &&
        item.formularioId === formularioId &&
        item.avaliacaoId === avaliacaoId
    )
  }, [selected])

  return (
    <div className='form-check form-check-sm form-check-custom form-check-solid'>
      <input
        className='form-check-input'
        type='checkbox'
        data-kt-check={isSelected}
        data-kt-check-target='#kt_table_avaliacoes .form-check-input'
        checked={isSelected}
        onChange={() => onSelect({ atendimentoId, formularioId, avaliacaoId })}
      />
    </div>
  )
}
