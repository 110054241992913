import { Response, QueryState, initialQueryState } from '../../../../../../_metronic/helpers'
import {
  FormularioAtendimentoList,
  ListViewContextPropsAvaliacoes,
} from '../../avaliacoes-pendentes-list/core/_models'

export const initialListViewAvaliacoes: ListViewContextPropsAvaliacoes = {
  selected: [],
  onSelect: () => {},
  clearSelected: () => {},
  disabled: false,
  isAllSelected: false,
  onSelectAll: () => {},
}

export type AvaliacoesQueryResponse = Response<Array<FormularioAtendimentoList>>

export interface QueryStateAvaliacoes extends QueryState {
  concluidos?: boolean
}

export type QueryRequestContextPropsAvaliacoes = {
  state: QueryStateAvaliacoes
  updateState: (updates: Partial<QueryStateAvaliacoes>) => void
}

export const initialQueryRequestAvaliacoes: QueryRequestContextPropsAvaliacoes = {
  state: { ...initialQueryState, concluidos: true },
  updateState: () => {},
}
