import { FC, useState } from 'react'
import { useListView } from '../core/ListViewProvider'
import { QUERIES, isNotEmpty } from '../../../../../../_metronic/helpers'
import { useQuery } from 'react-query'
import { getFuncionarioById } from '../core/_requests'
import { FuncionarioEditModalForm } from './FuncionarioEditModalForm'
import Swal from 'sweetalert2'

type Props = {
  cpf: String
  onCancel: () => void
}

const FuncionarioEditModalFormWrapper: FC<Props> = ({ cpf, onCancel }) => {
  const { itemIdForUpdate, setItemIdForUpdate } = useListView()
  const { idEmpresa, setIdEmpresa } = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)

  const {
    isLoading,
    data: user,
    error,
  } = useQuery(
    `${QUERIES.USERS_LIST}-user-${itemIdForUpdate}`,
    () => {
      return getFuncionarioById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err: any) => {
        setItemIdForUpdate(undefined)
        Swal.fire({
          text: err.response?.data.data.message,
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      },
    }
  )

  if (!itemIdForUpdate) {
    return (
      <FuncionarioEditModalForm
        isFuncionarioLoading={isLoading}
        cpf={cpf}
        funcionario={{ id: undefined }}
        onCancel={onCancel}
      />
    )
  }

  if (!isLoading && !error && user) {
    return (
      <FuncionarioEditModalForm
        isFuncionarioLoading={isLoading}
        cpf={cpf}
        funcionario={user}
        onCancel={onCancel}
      />
    )
  }

  return null
}

export { FuncionarioEditModalFormWrapper }
