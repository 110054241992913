import { FC, ReactNode } from 'react'
import { ID } from '../../../_metronic/helpers'
import { IAvaliacoesSelect } from '../../modules/apps/avaliacoes/avaliacoes-pendentes-list/core/_models'

type ISelectionHeader = {
  children: ReactNode
  selected?: ID[] | IAvaliacoesSelect[]
  className?: string
}

export const SelectionHeaderContainer: FC<ISelectionHeader> = ({
  children,
  selected,
  className,
}) => {
  return (
    <div className={`d-flex justify-content-end align-items-center ${className}`}>
      {selected && selected.length > 0 && (
        <div className='fw-bolder me-5'>
          <span className='me-2'>{selected.length}</span> Selecionado(s)
        </div>
      )}
      {children}
    </div>
  )
}
