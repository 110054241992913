import { ColumnInstance } from 'react-table'

type Props<T extends object = {}> = {
  column: ColumnInstance<T>
}

const CustomHeaderColumn = <T extends object = {}>({ column }: Props<T>): JSX.Element => (
  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
)

export { CustomHeaderColumn }
