/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useState, useEffect, useMemo } from 'react'
import { useQuery } from 'react-query'
import {
  createResponseContext,
  getParameterByName,
  initialQueryResponse,
  initialQueryState,
  PaginationState,
  QUERIES,
  stringifyRequestQuery,
  WithChildren,
} from '../../../../../_metronic/helpers'
import { useQueryRequest } from './QueryRequestProvider'
import { getAtendimentos } from '../../atendimento/core/_requests'
import { getAtendimentosPaciente } from './_requests'
import { useSearchParams } from 'react-router-dom'
import { AtendimentoView } from './_models'

const QueryResponseContext = createResponseContext<AtendimentoView>(initialQueryResponse)
const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const [searchParams] = useSearchParams()
  const { state } = useQueryRequest()
  const { idEmpresa } = useQueryResponse()
  const idPacienteParam = String(searchParams.get('paciente'))
  const { idPaciente } = useQueryResponse()
  const [query, setQuery] = useState<string>(decodeURIComponent(stringifyRequestQuery(state)))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])
  const idEmpresaParam = getParameterByName('filter_0', query)

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(decodeURIComponent(updatedQuery))
    }
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.ATENDIMENTO_MEDICO_LIST}-${query}`,
    () => {
      if (idPacienteParam !== 'null') {
        return getAtendimentosPaciente(Number(idPacienteParam), query)
      } else {
        return getAtendimentos(query)
      }
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  )

  useEffect(() => {
    const intervalId = setInterval(() => {
      refetch()
    }, 5000)

    return () => clearInterval(intervalId)
  }, [refetch])

  return (
    <QueryResponseContext.Provider
      value={{ isLoading: isFetching, refetch, response, query, idEmpresa, idPaciente }}
    >
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const { response } = useQueryResponse()
  if (!response) {
    return []
  }

  return response?.data || []
}

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const { response } = useQueryResponse()
  if (!response) {
    return defaultPaginationState
  }

  const responsePaginationState: PaginationState = {
    page: response?.page,
    size: response.size,
    totalElements: response.totalElements,
  }

  return responsePaginationState
}

const useQueryResponseLoading = (): boolean => {
  const { isLoading } = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
}
