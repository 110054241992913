import { useState, useEffect } from 'react'
import { initialQueryState, useDebounce } from '../../../../../../../_metronic/helpers'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { Input, Label } from '../../../../../../components'

const EmpresasClienteListSearchComponent = () => {
  const { updateState } = useQueryRequest()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [nomeSel, setNomeSel] = useState<string>('')

  const debouncedSearchTerm = useDebounce(searchTerm, 150)

  useEffect(() => {
    if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
      updateState({
        search: debouncedSearchTerm,
        sort: 'nome',
        order: 'asc',
        ...initialQueryState,
      })
    }
  }, [debouncedSearchTerm])

  const handleChangeNome = (e: React.ChangeEvent<HTMLInputElement>) => {
    const trimmedValue = e.target.value?.trim()

    setNomeSel(trimmedValue)
    setSearchTerm(trimmedValue ? `nome~*${trimmedValue}*` : '')
  }

  return (
    <div className='card-title'>
      <div>
        <Label className='pb-1'>Empregador ASO</Label>
        <Input
          className='w-550px'
          placeholder='Digite a empresa'
          onChange={handleChangeNome}
          value={nomeSel}
        />
      </div>
    </div>
  )
}

export { EmpresasClienteListSearchComponent }
