import { useQuery } from 'react-query'
import { isNotEmpty, QUERIES } from '../../../../../../_metronic/helpers'
import { FC } from 'react'
import { useListView } from '../../core/ListViewProvider'
import { getAtendimentoById } from '../../core/_requests'
import { AtendimentoEditModalForm } from './AtendimentoEditModalForm'

type Props = {
  cpf: String
}
function refreshPage() {
  window.location.reload()
}
const AtendimentoEditModalFormWrapper: FC<Props> = ({ cpf: string }) => {
  const { itemIdForUpdate, setItemIdForUpdate } = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: user,
    error,
  } = useQuery(
    `${QUERIES.USERS_LIST}-user-${itemIdForUpdate}`,
    () => {
      return getAtendimentoById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return (
      <AtendimentoEditModalForm isAtendimentoLoading={isLoading} atendimento={{ id: undefined }} />
    )
  }

  if (!isLoading && !error && user) {
    return <AtendimentoEditModalForm isAtendimentoLoading={isLoading} atendimento={user} />
  }

  return null
}

export { AtendimentoEditModalFormWrapper }
