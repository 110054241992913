import { useNavigate } from 'react-router-dom'
import { KTSVG } from '../../../../../../../_metronic/helpers'
import { useListView } from '../../core/ListViewProvider'

const DownloadAsoListToolbar = () => {
  const { setItemIdForUpdate } = useListView()
  const navigate = useNavigate()
  const openAddAtendimento = () => {
    navigate('/apps/pacientes-management/pacientes')
  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      {/* <button type='button' className='btn btn-primary' onClick={openAddAtendimento}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Adicionar
      </button> */}
    </div>
  )
}

export { DownloadAsoListToolbar }
