// @ts-nocheck

import { Column } from 'react-table'
import { ImportacaoErroCustomHeader } from './ImportacaoErroCustomHeader'

const importacoesErrosColumns: ReadonlyArray<Column<ImportacaoErro>> = [
  {
    Header: (props) => (
      <ImportacaoErroCustomHeader tableProps={props} title='Linha'  />
    ),
    id: 'linha',
    accessor: 'linha',
  },
  {
    Header: (props) => <ImportacaoErroCustomHeader tableProps={props} title='Campo'  />,
    id: 'campo',
    accessor: 'campo'
  },
  {
    Header: (props) => (
      <ImportacaoErroCustomHeader tableProps={props} title='Erro'  />
    ),
    id: 'erro',
    accessor: 'erro',
  },
]

export { importacoesErrosColumns }
