import { ID, Response, Tipo } from '../../../../../../_metronic/helpers'
import { PessoaList } from '../../../../auth'

export type AvatarDto = {
  action: 'u' | 'd'
  fileContent?: string
  fileName?: string
  fileType: string
}

export type TipoEnderecoList = {
  descricao?: string
  id?: number
}

export type EstadoList = {
  descricao?: string
  sigla?: string
  id?: number
}

export type EnderecoDtoCreate = {
  tipo: number
  cep: string
  logradouro: string
  numero: string
  complemento?: string | null
  bairro: string
  cidade: string
  uf: string
}

export type EnderecoDtoUpdate = {
  id: number
  ativo?: boolean
  cep: string
  logradouro: string
  numero: string
  complemento?: string | null
  bairro: string
  cidade: string
  uf: string
}

export type EnderecoList = {
  tipoEndereco: string
  uf: string
  logradouro: string
  complemento?: string | null
  ativo: boolean
  cep: string
  numero: string
  bairro: string
  cidade: string
  id: number
}

export type EnderecoView = {
  logradouro?: string
  complemento?: string
  estado?: EstadoList
  ativo?: boolean
  cep?: string
  numero?: string
  bairro?: string
  cidade?: string
  pessoa?: PessoaList
  tipo?: TipoEnderecoList
  empresa?: EmpresaList
  id?: number
}

export type EmpresaView = {
  tiposAtendimentoMap?: TipoAtendimentoMap[]
  permiteApiIntegracao?: boolean
  logoCadastrada?: boolean
  ativa?: boolean
  apiWebhook?: string
  grupoTelegram?: string
  nomeFantasia?: string
  cnpj?: string
  permiteNovoPaciente?: boolean
  responsaveis?: PessoaList[]
  tiposAtendimento?: Tipo[]
  nome?: string
  endereco?: EnderecoList
  id?: number
  dataInclusao?: string
  dataAlteracao?: string
}

export type EmpresaList = {
  logoCadastrada?: boolean
  permiteApiIntegracao?: boolean
  ativa?: boolean
  nomeFantasia?: string
  cnpj?: string
  nome?: string
  id?: ID
  dataInclusao?: string
  dataAlteracao?: string
}

export type EmpresaDtoVinculo = {
  nomeFantasia?: string
  cnpj?: string
  nome?: string
  id?: ID
}

export type TipoAtendimentoMap = {
  valor?: string
  name?: string
  descricao?: string
}

export type EmpresasQueryResponse = Response<Array<EmpresaList>>

export const initialEmpresa = {
  nome: '',
  nomeFantasia: '',
  cnpj: '',
  logo: {
    action: 'u',
    fileName: '',
    fileType: '',
    fileContent: '',
  },
  tiposAtendimento: ['PA'],
  permiteNovoPaciente: true,
}
