import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import { LayoutSplashScreen } from '../../../../_metronic/layout/core'
import { AuthTokenResponse, UsuarioWithImage } from './_models'
import * as authHelper from './AuthHelpers'
import { getAvatarUser, getLoggedUser } from './_requests'
import { WithChildren } from '../../../../_metronic/helpers'

type AuthContextProps = {
  auth: AuthTokenResponse | undefined
  saveAuth: (auth: AuthTokenResponse | undefined) => void
  currentUser: UsuarioWithImage | undefined
  setCurrentUser: Dispatch<SetStateAction<UsuarioWithImage | undefined>>
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}
const AuthProvider: FC<WithChildren> = ({ children }) => {
  const [auth, setAuth] = useState<AuthTokenResponse | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UsuarioWithImage | undefined>()
  const saveAuth = (auth: AuthTokenResponse | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
  }

  return (
    <AuthContext.Provider value={{ auth, saveAuth, currentUser, setCurrentUser, logout }}>
      {children}
    </AuthContext.Provider>
  )
}
const AuthInit: FC<WithChildren> = ({ children }) => {
  const { auth, logout, setCurrentUser } = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          const { data } = await getLoggedUser()
          if (data) {
            let imagemPerfil: string

            try {
              const v = await getAvatarUser()
              if (v.data !== null) {
                imagemPerfil = v.data.data
              } else {
                imagemPerfil = '/media/avatars/AVATARES_05.png'
              }
            } catch (error) {
              imagemPerfil = '/media/avatars/AVATARES_05.png'
            }
            const user = { ...data, imagemPerfil }
            setCurrentUser(user)
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth.accessToken) {
      requestUser()
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export { AuthProvider, AuthInit, useAuth }
