import { Column } from 'react-table'
import { ExameActionsCell } from './ExameActionsCell'
import { DataColumn } from '../../../../commons/table/DataColumn'
import { ExameAsoView } from '../../core/_models'
import { CustomHeader } from '../../../../commons/table/CustomHeader'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { SelectionHeader } from '../../../../commons/table/SelectionHeader'
import { SelectionCell } from '../../../../commons/table/SelectionCell'
import { useListView } from '../../core/ListViewProvider'

const ExameColumns: ReadonlyArray<Column<ExameAsoView>> = [
  {
    Header: (props) => <SelectionHeader useListView={useListView} tableProps={props} />,
    id: 'selection',
    Cell: ({ ...props }) => <SelectionCell useListView={useListView} id={props.row.original.id} />,
  },
  {
    Header: (props) => (
      <CustomHeader
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title='Exame'
        className='min-w-125px'
      />
    ),
    id: 'exame',
    accessor: 'exame',
  },
  {
    Header: (props) => (
      <CustomHeader
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title='Setor'
        className='min-w-125px'
      />
    ),
    id: 'setor.nome',
    accessor: (row) => row.setor?.nome,
  },
  {
    Header: (props) => (
      <CustomHeader
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title='Cargo'
        className='min-w-125px'
      />
    ),
    id: 'cargo.nome',
    accessor: (row) => row.cargo?.nome,
  },
  {
    Header: (props) => (
      <CustomHeader
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title='Data criação'
        isAbleToSort={false}
      />
    ),
    id: 'dataInclusao',
    Cell: ({ ...props }) => <DataColumn data={props.row.original.dataInclusao} />,
  },
  {
    Header: (props) => (
      <CustomHeader
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title='Data edição'
        isAbleToSort={false}
      />
    ),
    id: 'dataAlteracao',
    Cell: ({ ...props }) => <DataColumn data={props.row.original.dataAlteracao} />,
  },
  {
    Header: (props) => (
      <CustomHeader
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title='Ações'
        className='text-end min-w-100px'
      />
    ),
    id: 'actions',
    Cell: ({ ...props }) => <ExameActionsCell id={props.row.original.id} />,
  },
]

export { ExameColumns }
