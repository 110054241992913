import { ImportacaoDetalheListHeader } from "./ImportacaoDetalheListHeader"
import { ImportacaoDetalheListTable } from "./component/table/ImportacaoDetalheListTable"
import { KTCard } from "../../../../../../_metronic/helpers"

export const ImportacaoDetalheList = () => {
    return (
      <>
        <KTCard>
          <ImportacaoDetalheListHeader />
          <ImportacaoDetalheListTable />
        </KTCard>
      </>
    )
  }