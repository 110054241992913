import { useState, useEffect } from 'react'
import { ID, initialQueryState, KTSVG, useDebounce } from '../../../../../../../_metronic/helpers'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { getEmpresaClienteById } from '../../../empresasCliente-list/core/_request'
import { ClienteView } from '../../../empresasCliente-list/core/_models'

const CargoClienteListSearchComponent = () => {
  const { updateState } = useQueryRequest()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [nomeSel, setNomeSel] = useState<string>('')
  const [situacaoSel, setSituacaoSel] = useState<string>('')
  const [empresa, setEmpresa] = useState<ClienteView | null>(null)

  const urlParams = new URLSearchParams(window.location.search)
  const idCliente = urlParams.get('cliente')
  const debouncedSearchTerm = useDebounce(searchTerm, 150)
  const [filters, setFilters] = useState<Array<String>>([])
  const idEmpresa = urlParams.get('empresa')
  const title = empresa?.nome ? (
    <span className='fs-4'>
      Cargo do Empregador ASO <strong>{empresa?.nome}</strong>
    </span>
  ) : (
    'Cargo'
  )

  useEffect(() => {
    if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
      updateState({ search: trataSearch(), sort: 'nome', order: 'asc', ...initialQueryState })
    }
  }, [debouncedSearchTerm])
  const trataSearch = () => {
    return debouncedSearchTerm !== undefined && debouncedSearchTerm?.length > 0
      ? 'cliente:' + idCliente + ',' + debouncedSearchTerm
      : 'cliente:' + idCliente
  }
  const handleChangeNome = (e: any) => {
    setNomeSel(String(e.target.value))
    setSearchTerm(String(e.target.value) !== '' ? 'nome~*' + String(e.target.value) + '*' : '')
  }
  const handleChangeSituacao = (e: any) => {
    setSituacaoSel(String(e.target.value))
    setSearchTerm(
      (String(e.target.value) !== '' ? 'ativa:' + String(e.target.value) + ',' : '') +
        (nomeSel !== '' ? 'nome~*' + nomeSel + '*' + ",'nomeFantasia~*" + nomeSel + '*' : '')
    )
  }
  const limparCampos = () => {
    setNomeSel('')
    setSearchTerm('')
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const respostaEmpresa = await getEmpresaClienteById(idCliente as ID)
        setEmpresa(respostaEmpresa)
      } catch (error) {
        console.error('Erro ao obter dados da empresa:', error)
      }
    }
    if (idCliente) {
      fetchData()
    }
  }, [idEmpresa])

  return (
    <div className='card-title'>
      <div className='mb-5'>
        <label className='form-label ps-1'>{title}</label>

        <input
          type='text'
          data-kt-user-table-filter='nome'
          className='form-control form-control-solid w-550px ps-1'
          placeholder='Digite o nome'
          value={nomeSel}
          onChange={handleChangeNome}
        />
      </div>
      <div className='d-flex align-items-left position-relative'>
        <a
          className='btn btn-sm'
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
          onClick={limparCampos}
        >
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-1 m-1' />
        </a>
      </div>
    </div>
  )
}

export { CargoClienteListSearchComponent }
