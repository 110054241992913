import axios, { AxiosResponse } from 'axios'
import { ClienteQueryResponse, ClienteView } from './_models'
import { DocumentoType, ID } from '../../../../../../_metronic/helpers'

const API_URL = process.env.REACT_APP_API_URL
const EMPRESAS_CLIENTE_URL = `${API_URL}/empresas/cliente`

export const getEmpresasCliente = (query: string): Promise<ClienteQueryResponse> => {
  return axios
    .get(`${EMPRESAS_CLIENTE_URL}?${query}`)
    .then((d: AxiosResponse<ClienteQueryResponse>) => d.data)
}

export const getEmpresaClienteById = (idEmpresaCliente: ID): Promise<ClienteView> => {
  return axios
    .get(`${EMPRESAS_CLIENTE_URL}/${idEmpresaCliente}`)
    .then((response: AxiosResponse<ClienteView>) => response.data)
}

export const getEmpresaClienteAso = (
  idEmpresa: ID,
  query: string
): Promise<ClienteQueryResponse> => {
  //MUDAR PARA MANDAR POR SEARCH O IDMEPRESA
  return axios
    .get(`${EMPRESAS_CLIENTE_URL}?idEmpresa=${idEmpresa}&${query}`)
    .then((d: AxiosResponse<ClienteQueryResponse>) => d.data)
}

export const createEmpresaCliente = (
  idEmpresa: ID,
  nome: string,
  cnpj: string | null,
  tipo: DocumentoType,
  cpf: string | null,
  caepf: string | null
): Promise<ClienteView> => {
  return axios.post(`${EMPRESAS_CLIENTE_URL}/${idEmpresa}`, {
    nome: nome,
    cnpj: tipo !== 'CPF' && tipo !== 'CAEPF' ? removeAllSpaces(cnpj as string) : null,
    cpf: tipo !== 'CNPJ' && tipo !== 'CAEPF' ? removeAllSpaces(cpf as string) : null,
    caepf: tipo !== 'CPF' && tipo !== 'CNPJ' ? removeAllSpaces(caepf as string) : null,
    tipo,
  })
}

export const editEmpresaCliente = (
  idEmpresaCliente: ID,
  nome: string,
  cnpj: string | null,
  tipo: DocumentoType,
  cpf: string | null,
  caepf: string | null
): Promise<ClienteView> => {
  return axios.put(`${EMPRESAS_CLIENTE_URL}/${idEmpresaCliente}`, {
    nome: nome,
    cnpj: tipo !== 'CPF' && tipo !== 'CAEPF' ? removeAllSpaces(cnpj as string) : null,
    cpf: tipo !== 'CNPJ' && tipo !== 'CAEPF' ? removeAllSpaces(cpf as string) : null,
    caepf: tipo !== 'CPF' && tipo !== 'CNPJ' ? removeAllSpaces(caepf as string) : null,
    tipo,
  })
}

export const deleteEmpresaCliente = (idEmpresaCliente: ID): Promise<string> => {
  return axios.delete(`${EMPRESAS_CLIENTE_URL}/${idEmpresaCliente}`)
}

function removeAllSpaces(str: string) {
  return str.replace(/\s+/g, '') // Remove todos os espaços em branco
}
