import { FC, useEffect } from 'react'

import { MenuComponent } from '../../../../../../../_metronic/assets/ts/components'
import { Button } from '../../../../../../components'
import Swal from 'sweetalert2'
import { useAuth } from '../../../../../auth'
import { IconPaths, IconSize } from '../../../../../../../_metronic/helpers'
import { downloadAvaliacao } from '../../core/_requests'
import { FormularioAtendimentoList } from '../../../avaliacoes-pendentes-list/core/_models'

type Props = {
  formularioAtendimento: FormularioAtendimentoList
}

export const AvaliacoesConcluidasActionsCell: FC<Props> = ({ formularioAtendimento }) => {
  const { currentUser } = useAuth()

  const canAccess =
    currentUser?.vinculos?.some(
      (vinculo) => vinculo.cargo === 'COLABORADOR' || vinculo.cargo === 'RESPONSAVEL'
    ) || false

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const handleDownloadAvaliacao = () => {
    downloadAvaliacao(formularioAtendimento.atendimento.id, formularioAtendimento.formulario.id)
      .then((anexo) => {
        if (anexo !== undefined) {
          var a = document.createElement('a')
          a.href = 'data:application/pdf;base64,' + (anexo !== undefined ? anexo.contentBase64 : '')
          a.download = String(anexo.name)
          a.click()
        }
      })
      .catch((error) => {
        const errors = error.response.data.data.message
        console.log(error.response.data)

        Swal.fire({
          text: `Não foi possível realizar o download: ${errors}.`,
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      })
  }

  return (
    <>
      <div className='d-flex justify-content-end'>
        {canAccess ? (
          <Button
            className='btn-primary btn-sm'
            onClick={handleDownloadAvaliacao}
            icon={IconPaths.Download}
            iconSize={IconSize.Small}
          >
            Baixar
          </Button>
        ) : null}
      </div>
    </>
  )
}
