import axios, {AxiosResponse} from 'axios'

import { Importacao } from '../../list/core/_models'
import {ImportacoesQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const IMPORTACAO_URL = `${API_URL}/importacao`

const getErrosImportacoes = (id: number, query: string): Promise<ImportacoesQueryResponse> => {
  console.log(id);
  return axios
    .get(`${IMPORTACAO_URL}/erros/${id}?${query}`)
    .then((d: AxiosResponse<ImportacoesQueryResponse>) => d.data)
}

const getDadosImportacao = (id: number): Promise<Importacao> => {
  return axios
    .get(`${IMPORTACAO_URL}/${id}`)
    .then((d: AxiosResponse<Importacao>) => d.data)
}

export {getErrosImportacoes, getDadosImportacao}
