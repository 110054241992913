import axios, {AxiosResponse} from 'axios'

import {ImportacoesQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const IMPORTACAO_URL = `${API_URL}/importacao`

const getDetalhesImportacoes = (id: number, query: string): Promise<ImportacoesQueryResponse> => {
  return axios
    .get(`${IMPORTACAO_URL}/detalhes/${id}?${query}`)
    .then((d: AxiosResponse<ImportacoesQueryResponse>) => d.data)
}

export {getDetalhesImportacoes}
