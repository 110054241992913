import { useListView } from '../../core/ListViewProvider'
import { Button, SelectionHeaderContainer } from '../../../../../../components'
import { alertCertificado, IconPaths, showAlert } from '../../../../../../../_metronic/helpers'
import { useAuth } from '../../../../../auth'
import { signAvaliacao, signAvaliacaoLote } from '../../core/_requests'
import { AxiosError } from 'axios'

export const AvaliacoesPendentesListGrouping = () => {
  const user = useAuth().currentUser
  const possuiCertificado = user?.pessoa?.certificadoDigital

  const isMedico = user?.vinculos?.some((vinculo) => vinculo.cargo === 'MEDICO') || false

  const { selected } = useListView()

  const handleAssinar = () => {
    if (possuiCertificado) {
      try {
        selected.length > 1
          ? alertCertificado(
              signAvaliacaoLote,
              selected.map((item) => item.avaliacaoId)
            )
          : alertCertificado(signAvaliacao, {
              formularioId: selected[0].formularioId,
              atendimentoId: selected[0].atendimentoId,
            })
        showAlert({
          title: 'Sucesso!',
          text: `${selected.length > 1 ? 'Avaliações assinadas.' : 'Avaliação assinada.'}`,
          icon: 'success',
        })
      } catch (error) {
        const err = error as AxiosError
        showAlert({
          title: 'Erro ao assinar!',
          text: err.response?.data.data.message,
          icon: 'error',
        })
      }
    } else {
      showAlert({
        title: 'Ausência de certificado!',
        text: 'Informe primeiramente o seu certificado na edição do perfil.',
        icon: 'error',
      })
    }
  }

  const isPlural = () => selected.length > 1

  return (
    <>
      {isMedico ? (
        <SelectionHeaderContainer selected={selected}>
          <Button className='btn-secondary me-2' onClick={handleAssinar} icon={IconPaths.Key}>
            {isPlural() ? 'Assinar Avaliações' : 'Assinar Avaliação'}
          </Button>
        </SelectionHeaderContainer>
      ) : null}
    </>
  )
}
