import { FC, useEffect, useState } from 'react'
import {
  createEnderecoPaciente,
  createPaciente,
  getEnderecoByIdPaciente,
  updateAsoPaciente,
  updateEnderecoPaciente,
  updatePaciente,
} from '../core/_requests'
import {
  ButtonType,
  GenderType,
  ID,
  isNotEmpty,
  OptionsHelper,
  showAlert,
  Tipo,
} from '../../../../../../_metronic/helpers'

import { IMaskInput } from 'react-imask'
import { PacienteListLoading } from '../components/loading/PacienteListLoading'
import { PasswordMeterComponent } from '../../../../../../_metronic/assets/ts/components'
import clsx from 'clsx'
import { getEmpresaById } from '../../../empresa/empresas-list/core/_requests'
import { useFormik } from 'formik'
import { useListView } from '../core/ListViewProvider'
import { useQueryResponse } from '../core/QueryResponseProvider'
import Swal from 'sweetalert2'
import { Button, CustomSelect, IOption, LoadingIndicator } from '../../../../../components'
import {
  PacienteDtoCreate,
  PacienteDtoUpdate,
  PacienteDtoUpdateAso,
  PacienteView,
} from '../core/_models'
import {
  EnderecoDtoCreate,
  EnderecoDtoUpdate,
  EnderecoList,
} from '../../../empresa/empresas-list/core/_models'
import { editPacienteSchema } from './validation/pacienteSchema'
import { buildErrorMessage } from '../../../../../../_metronic/helpers/BuildErrorMessage'

type Props = {
  isPacienteLoading: boolean
  paciente: PacienteView | undefined
  cpf: string
}

function formatCep(cep: string) {
  if (cep !== undefined) {
    cep.replace(/([.-])/g, '')
    cep
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1-$2')
      .replace(/(.\d{3})(\d)/, '$1-$2')
      .replace(/(-\d{3})\d+?$/, '$1')
  } else {
    return ''
  }
  return cep
}
const PacienteEditModalForm: FC<Props> = ({ paciente, isPacienteLoading, cpf }) => {
  const { setItemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()
  const [empresa, setEmpresa] = useState<IOption>()

  const [endereco, setEndereco] = useState<EnderecoList>()
  const [isAso, setIsAso] = useState<boolean>(false)
  const [showEndereco, setShowEndereco] = useState<boolean>(false)
  const [showAso, setShowAso] = useState<boolean>(false)

  const getPacienteInitialValues = (
    paciente: PacienteView | undefined,
    endereco: EnderecoList | undefined
  ) => {
    return {
      // paciente
      primeiroNome: paciente?.pessoa?.primeiroNome || '',
      sobreNome: paciente?.pessoa?.sobreNome || '',
      cpf: paciente?.pessoa?.cpf || cpf,
      cns: paciente?.pessoa?.cns || '',
      telefone: paciente?.pessoa?.telefone || '',
      email: paciente?.pessoa.usuario?.email || '',
      sexo: paciente?.pessoa?.sexo || GenderType.Masculino,
      nascimento: paciente?.pessoa?.dataNascimento || '',

      // ASO
      numeroCi: paciente?.pessoa?.rg?.numero || '',
      orgaoCi: paciente?.pessoa?.rg?.orgao || '',
      dataCi: paciente?.pessoa?.rg?.data || '',
      numeroCtps: paciente?.pessoa?.ctps?.numero || '',
      serieCtps: paciente?.pessoa?.ctps?.serie || '',
      ufCtps: paciente?.pessoa?.ctps?.uf || '',
      matriculaAso: paciente?.dadosAso?.matricula || '',
      cargoAso: paciente?.dadosAso?.cargo || '',
      setorAso: paciente?.dadosAso?.setor || '',

      // endereco
      cep: endereco?.cep ? formatCep(String(endereco.cep)) : '',
      logradouro: endereco?.logradouro || '',
      numero: Number(endereco?.numero) || '',
      complemento: endereco?.complemento,
      bairro: endereco?.bairro || '',
      cidade: endereco?.cidade || '',
      uf: endereco?.uf || '',
    }
  }

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  function validadeDadosAso() {
    var values = formik.values
    if (!isNotEmpty(values.numeroCi)) {
      return false
    } else if (!isNotEmpty(values.orgaoCi)) {
      return false
    } else if (!isNotEmpty(values.matriculaAso)) {
      return false
    } else if (!isNotEmpty(values.cargoAso)) {
      return false
    } else if (!isNotEmpty(values.setorAso)) {
      return false
    }
    return true
  }

  function trataErros(error: any, setFieldError: any) {
    Swal.fire({
      html: error.response
        ? buildErrorMessage(error.response.data.data)
        : 'Um erro inesperado aconteceu.',
      icon: 'error',
      buttonsStyling: false,
      confirmButtonText: 'Ok',
      customClass: {
        confirmButton: 'btn btn-primary',
      },
    })
    const errors = error.response.data.data.errors
    if (errors) {
      setFieldError('cpf', errors['cpf'] !== undefined ? errors.cpf[0] : '')
      setFieldError('cns', errors['cns'] !== undefined ? errors.cns[0] : '')
      setFieldError('telefone', errors['telefone'] !== undefined ? errors.telefone[0] : '')
      setFieldError('nascimento', errors['nascimento'] !== undefined ? errors.nascimento[0] : '')
      setFieldError('nome', errors['nome'] !== undefined ? errors.nome[0] : '')
      setFieldError('email', errors['email'] !== undefined ? errors.email[0] : '')
      setFieldError('prontuario', errors['prontuario'] !== undefined ? errors.prontuario[0] : '')
      setFieldError('cep', errors['cep'] !== undefined ? errors.cep[0] : '')
      setFieldError('logradouro', errors['logradouro'] !== undefined ? errors.logradouro[0] : '')
      setFieldError('numero', errors['numero'] !== undefined ? errors.numero[0] : '')
      setFieldError('complemento', errors['complemento'] !== undefined ? errors.complemento[0] : '')
      setFieldError('bairro', errors['bairro'] !== undefined ? errors.bairro[0] : '')
      setFieldError('cidade', errors['cidade'] !== undefined ? errors.cidade[0] : '')
      setFieldError('uf', errors['uf'] !== undefined ? errors.uf[0] : '')
    }
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: getPacienteInitialValues(paciente, endereco),
    validationSchema: editPacienteSchema(showEndereco),
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      setSubmitting(true)
      try {
        if (!validateCtps()) {
          showAlert({
            title: 'Falta de Preenchimento!',
            text: 'Informe todos os campos relaciodado ao CTPS ou deixe em branco!',
            icon: 'error',
          })
        } else if (paciente) {
          const pacienteUp: PacienteDtoUpdate = {
            ativo: true,
            email: values.email ? values.email : null,
            pessoa: {
              id: paciente.pessoa.id,
              primeiroNome: values.primeiroNome,
              sobreNome: values.sobreNome,
              cpf: values.cpf,
              cns: values.cns ? values.cns : null,
              telefone: values.telefone ? values.telefone : null,
              sexo: values.sexo,
              nascimento: values.nascimento,
            },
          }
          const p = await updatePaciente(paciente.id, pacienteUp)

          if (isAso && validadeDadosAso() && showAso) {
            const pacienteAso: PacienteDtoUpdateAso = {
              pessoa: {
                id: paciente.pessoa.id,
                rg: { numero: values.numeroCi, orgao: values.orgaoCi },
                ctps: { numero: values.numeroCtps, serie: values.serieCtps, uf: values.ufCtps },
              },
              dadosAso: { cargo: values.cargoAso, setor: values.setorAso },
            }
            await updateAsoPaciente(p?.id, pacienteAso)
          }
          if (showEndereco) {
            if (endereco) {
              const end: EnderecoDtoUpdate = {
                id: endereco?.id as number,
                cep: values.cep,
                logradouro: values.logradouro,
                numero: String(values.numero),
                complemento: values.complemento,
                bairro: values.bairro,
                cidade: values.cidade,
                uf: values.uf,
              }
              await updateEnderecoPaciente(paciente.id, end)
            } else {
              const end: EnderecoDtoCreate = {
                tipo: 1,
                cep: values.cep,
                logradouro: values.logradouro,
                numero: String(values.numero),
                complemento: values.complemento,
                bairro: values.bairro,
                cidade: values.cidade,
                uf: values.uf,
              }
              await createEnderecoPaciente(Number(p?.id), end)
            }
          }
        } else {
          const pacienteCreate: PacienteDtoCreate = {
            primeiroNome: values.primeiroNome,
            sobreNome: values.sobreNome,
            cpf,
            cns: values.cns ? values.cns : null,
            email: values.email ? values.email : null,
            telefone: values.telefone ? values.telefone : null,
            sexo: values.sexo,
            nascimento: values.nascimento,
          }
          const p = await createPaciente(empresa?.value as number, pacienteCreate)
          if (p) {
            if (showEndereco) {
              const endereco: EnderecoDtoCreate = {
                tipo: 1,
                cep: values.cep,
                logradouro: values.logradouro,
                numero: String(values.numero),
                complemento: values.complemento,
                bairro: values.bairro,
                cidade: values.cidade,
                uf: values.uf,
              }

              await createEnderecoPaciente(Number(p.id), endereco)
            }

            if (isAso && validadeDadosAso() && showAso) {
              const pacienteAso: PacienteDtoUpdateAso = {
                pessoa: {
                  id: p?.pessoa.id,
                  rg: { numero: values.numeroCi, orgao: values.orgaoCi },
                  ctps: {
                    numero: values.numeroCtps,
                    serie: values.serieCtps,
                    uf: values.ufCtps,
                  },
                },
                dadosAso: { cargo: values.cargoAso, setor: values.setorAso },
              }
              await updateAsoPaciente(p?.id, pacienteAso)
            }
          }
        }
        showAlert({
          title: 'Sucesso!',
          text: `Paciente ${paciente ? 'atualizado' : 'criado'} com sucesso.`,
          icon: 'success',
        })
      } catch (error: any) {
        trataErros(error, setFieldError)
      } finally {
        setSubmitting(false)
      }
    },
  })

  const getEmpresa = () => {
    if (empresa?.value) {
      getEmpresaById(Number(empresa.value))
        .then((emp) => {
          const tipo = emp?.tiposAtendimento?.find((t) => t === Tipo.ASO)
          setIsAso(!!tipo)
        })
        .catch(() => showAlert({ title: 'Erro!', text: 'Empresa não localizada.', icon: 'error' }))
    }
  }

  const possuiDadosCtps = () =>
    isNotEmpty(formik.values.numeroCtps) &&
    isNotEmpty(formik.values.serieCtps) &&
    isNotEmpty(formik.values.ufCtps)

  const validateCtps = () => {
    let isValid = false
    if (possuiDadosCtps()) {
      isValid = true
    } else if (
      !isNotEmpty(formik.values.numeroCtps) &&
      !isNotEmpty(formik.values.serieCtps) &&
      !isNotEmpty(formik.values.ufCtps)
    ) {
      isValid = true
    }
    return isValid
  }
  const getEndereco = async (paciente: PacienteView | undefined) => {
    if (paciente !== undefined && paciente.id !== undefined) {
      var resultend = await getEnderecoByIdPaciente(paciente.id)

      if (resultend.data !== undefined) {
        setEndereco(resultend.data[0])
      }
    }
  }

  useEffect(() => {
    getEmpresa()
  }, [empresa])

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
    getEndereco(paciente)
  }, [paciente])

  return (
    <>
      <form
        id='kt_modal_add_paciente_form'
        className='form'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <div
          className='d-flex flex-column  me-n7 pe-7'
          id='kt_modal_add_paciente_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_paciente_header'
          data-kt-scroll-wrappers='#kt_modal_add_paciente_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Empresa</label>
            {!paciente ? (
              <>
                <CustomSelect
                  placeholder='Selecione uma empresa'
                  fetchOptions={OptionsHelper.getOptionsEmpresas}
                  onChange={(data) => setEmpresa(data)}
                  value={empresa}
                  isClearable={false}
                />
                {!empresa && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>Selecione uma empresa</span>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>{paciente?.empresa?.nome}</span>
              </div>
            )}
          </div>
        </div>
        <div className='fv-row mb-7'>
          <label className='required fw-bold fs-6 mb-2'>Primeiro Nome</label>
          <input
            placeholder='Primeiro Nome'
            {...formik.getFieldProps('primeiroNome')}
            type='text'
            name='primeiroNome'
            className={clsx(
              'form-control form-control-solid mb-3 mb-lg-0',
              { 'is-invalid': formik.touched.primeiroNome && formik.errors.primeiroNome },
              {
                'is-valid': formik.touched.primeiroNome && !formik.errors.primeiroNome,
              }
            )}
            autoComplete='off'
            disabled={formik.isSubmitting || isPacienteLoading}
          />
          {formik.touched.primeiroNome && formik.errors.primeiroNome && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.primeiroNome}</span>
              </div>
            </div>
          )}
        </div>
        <div className='fv-row mb-7'>
          <label className='required fw-bold fs-6 mb-2'>Sobrenome</label>
          <input
            placeholder='Sobrenome'
            {...formik.getFieldProps('sobreNome')}
            type='text'
            name='sobreNome'
            className={clsx(
              'form-control form-control-solid mb-3 mb-lg-0',
              { 'is-invalid': formik.touched.sobreNome && formik.errors.sobreNome },
              {
                'is-valid': formik.touched.sobreNome && !formik.errors.sobreNome,
              }
            )}
            autoComplete='off'
            disabled={formik.isSubmitting || isPacienteLoading}
          />
          {formik.touched.sobreNome && formik.errors.sobreNome && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.sobreNome}</span>
              </div>
            </div>
          )}
        </div>

        <div className='fv-row mb-7'>
          <label className='fw-bold fs-6 mb-2'>E-mail</label>
          <>
            <input
              placeholder='E-mail'
              {...formik.getFieldProps('email')}
              type='tel'
              name='email'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.email && formik.errors.email },
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
              disabled={formik.isSubmitting || isPacienteLoading}
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              </div>
            )}
          </>

          {/* end::Input */}
        </div>

        <div className='fv-row mb-7'>
          <label className='fw-bold fs-6 mb-2'>Telefone</label>
          <IMaskInput
            placeholder='Telefone'
            {...formik.getFieldProps('telefone')}
            mask='(00) 0 0000-0000'
            type='text'
            name='telefone'
            className={clsx(
              'form-control form-control-solid mb-3 mb-lg-0',
              { 'is-invalid': formik.touched.telefone && formik.errors.telefone },
              {
                'is-valid': formik.touched.telefone && !formik.errors.telefone,
              }
            )}
            disabled={formik.isSubmitting || isPacienteLoading}
          />
          {formik.touched.telefone && formik.errors.telefone && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.telefone}</span>
              </div>
            </div>
          )}
        </div>

        <div className='fv-row mb-7'>
          <label className='required fw-bold fs-6 mb-2'>CPF</label>

          <IMaskInput
            placeholder='CPF'
            {...formik.getFieldProps('cpf')}
            mask='000.000.000-00'
            type='text'
            name='cpf'
            className={clsx(
              'form-control form-control-solid mb-3 mb-lg-0',
              { 'is-invalid': formik.touched.cpf && formik.errors.cpf },
              {
                'is-valid': formik.touched.cpf && !formik.errors.cpf,
              }
            )}
            disabled={formik.isSubmitting || isPacienteLoading}
          />
          {formik.touched.cpf && formik.errors.cpf && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.cpf}</span>
              </div>
            </div>
          )}
        </div>
        <div className='fv-row mb-7'>
          <label className='fw-bold fs-6 mb-2'>CNS</label>

          <IMaskInput
            placeholder='CNS'
            {...formik.getFieldProps('cns')}
            mask='000000000000000'
            type='text'
            name='cns'
            className={clsx(
              'form-control form-control-solid mb-3 mb-lg-0',
              { 'is-invalid': formik.touched.cns && formik.errors.cns },
              {
                'is-valid': formik.touched.cns && !formik.errors.cns,
              }
            )}
            disabled={formik.isSubmitting || isPacienteLoading}
          />
          {formik.touched.cns && formik.errors.cns && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.cns}</span>
              </div>
            </div>
          )}
        </div>
        <div className='fv-row mb-7'>
          <label className='required fw-bold fs-6 mb-2'>Sexo</label>

          <select
            className='form-select form-select-solid form-select-lg'
            {...formik.getFieldProps('sexo')}
          >
            <option value={GenderType.Masculino}>Masculino</option>
            <option value={GenderType.Feminino}>Feminino</option>
          </select>
          {formik.touched.sexo && formik.errors.sexo && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{formik.errors.sexo}</div>
            </div>
          )}
          {formik.touched.sexo && formik.errors.sexo && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.sexo}</span>
            </div>
          )}
        </div>
        <div className='fv-row mb-7'>
          <label className='required fw-bold fs-6 mb-2'>Data de Nascimento</label>
          <input
            type='date'
            max={new Date().toISOString().split('T')[0]}
            className='form-control form-control-lg textbox-n form-control-solid'
            placeholder='Data de Nascimento'
            {...formik.getFieldProps('nascimento')}
          />
          {formik.touched.nascimento && formik.errors.nascimento && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{formik.errors.nascimento}</div>
            </div>
          )}
        </div>
        {isAso && showAso ? (
          <>
            <div className='fv-row mb-7'>
              <label className='fw-bold fs-6 mb-2'>Carteira de Identidade</label>
            </div>
            <div className='fv-row mb-7'>
              <label className='fw-bold fs-6 mb-2'>Número</label>
              <IMaskInput
                placeholder='Número'
                {...formik.getFieldProps('numeroCi')}
                mask='000000000000000'
                type='text'
                name='numeroCi'
                defaultValue={formik.values.numeroCi}
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  { 'is-invalid': formik.touched.numeroCi && formik.errors.numeroCi },
                  {
                    'is-valid': formik.touched.numeroCi && !formik.errors.numeroCi,
                  }
                )}
                disabled={formik.isSubmitting || isPacienteLoading}
              />
              {formik.touched.numeroCi && formik.errors.numeroCi && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.numeroCi}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='fv-row mb-7'>
              <div className='fv-row mb-7'>
                <label className='fw-bold fs-6 mb-2'>Órgão</label>
                <input
                  placeholder='Órgão'
                  {...formik.getFieldProps('orgaoCi')}
                  type='text'
                  name='orgaoCi'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.orgaoCi && formik.errors.orgaoCi },
                    {
                      'is-valid': formik.touched.orgaoCi && !formik.errors.orgaoCi,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isPacienteLoading}
                />
                {formik.touched.orgaoCi && formik.errors.orgaoCi && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.orgaoCi}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='fv-row mb-7'>
              <label className='fw-bold fs-6 mb-2'>Data expedição</label>
              <input
                placeholder='dataCi'
                {...formik.getFieldProps('dataCi')}
                type='date'
                name='dataCi'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  { 'is-invalid': formik.touched.dataCi && formik.errors.dataCi },
                  {
                    'is-valid': formik.touched.dataCi && !formik.errors.dataCi,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isPacienteLoading}
              />
              {formik.touched.dataCi && formik.errors.dataCi && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.dataCi}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='fv-row mb-7'>
              <label className='fw-bold fs-6 mb-2'>Carteira de Trabalho</label>
            </div>
            <div className='fv-row mb-7'>
              <div className='fv-row mb-7'>
                {/* begin::Input */}
                <label className='fw-bold fs-6 mb-2'>Número</label>
                <IMaskInput
                  placeholder='Número'
                  {...formik.getFieldProps('numeroCtps')}
                  mask='000000000000000'
                  type='text'
                  name='numeroCtps'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.numeroCtps && formik.errors.numeroCtps },
                    {
                      'is-valid': formik.touched.numeroCtps && !formik.errors.numeroCtps,
                    }
                  )}
                  disabled={formik.isSubmitting || isPacienteLoading}
                />
                {formik.touched.numeroCtps && formik.errors.numeroCtps && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.numeroCtps}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='fv-row mb-7'>
              <div className='fv-row mb-7'>
                <label className='fw-bold fs-6 mb-2'>Série</label>
                <IMaskInput
                  placeholder='Série'
                  {...formik.getFieldProps('serieCtps')}
                  mask='000.00000.00-0'
                  type='text'
                  name='serieCtps'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.serieCtps && formik.errors.serieCtps },
                    {
                      'is-valid': formik.touched.serieCtps && !formik.errors.serieCtps,
                    }
                  )}
                  disabled={formik.isSubmitting || isPacienteLoading}
                />
                {formik.touched.serieCtps && formik.errors.serieCtps && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.serieCtps}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='fv-row mb-7'>
              <label className='fw-bold fs-6 mb-2'>Estado</label>
              <select
                className='form-select form-select-solid form-select-lg fw-bold'
                {...formik.getFieldProps('ufCtps')}
              >
                <option value=''>Selecione estado emissão...</option>
                <option value='AC'>Acre</option>
                <option value='AL'>Alagoas</option>
                <option value='AP'>Amapá</option>
                <option value='AM'>Amazonas</option>
                <option value='BA'>Bahia</option>
                <option value='CE'>Ceará</option>
                <option value='DF'>Distrito Federal</option>
                <option value='ES'>Espírito Santo</option>
                <option value='GO'>Goiás</option>
                <option value='MA'>Maranhão</option>
                <option value='MT'>Mato Grosso</option>
                <option value='MS'>Mato Grosso do Sul</option>
                <option value='MG'>Minas Gerais</option>
                <option value='PA'>Pará</option>
                <option value='PB'>Paraíba</option>
                <option value='PR'>Paraná</option>
                <option value='PE'>Pernambuco</option>
                <option value='PI'>Piauí</option>
                <option value='RJ'>Rio de Janeiro</option>
                <option value='RN'>Rio Grande do Norte</option>
                <option value='RS'>Rio Grande do Sul</option>
                <option value='RO'>Rondônia</option>
                <option value='RR'>Roraima</option>
                <option value='SC'>Santa Catarina</option>
                <option value='SP'>São Paulo</option>
                <option value='SE'>Sergipe</option>
                <option value='TO'>Tocantins</option>
              </select>
              {formik.touched.ufCtps && formik.errors.ufCtps && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.ufCtps}</div>
                </div>
              )}
            </div>
            <div className='fv-row mb-7'>
              <label className='fw-bold fs-6 mb-2'>Dados empresariais</label>
            </div>
            <div className='fv-row mb-7'>
              <label className=' fw-bold fs-6 mb-2'>Matrícula</label>
              <IMaskInput
                placeholder='Matrícula'
                {...formik.getFieldProps('matriculaAso')}
                mask='000000000000000'
                type='text'
                name='matriculaAso'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  { 'is-invalid': formik.touched.matriculaAso && formik.errors.matriculaAso },
                  {
                    'is-valid': formik.touched.matriculaAso && !formik.errors.matriculaAso,
                  }
                )}
                disabled={formik.isSubmitting || isPacienteLoading}
              />
              {formik.touched.matriculaAso && formik.errors.matriculaAso && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.matriculaAso}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='fv-row mb-7'>
              <label className='fw-bold fs-6 mb-2'>Cargo</label>
              <input
                placeholder='Cargo'
                {...formik.getFieldProps('cargoAso')}
                type='text'
                name='cargoAso'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  { 'is-invalid': formik.touched.cargoAso && formik.errors.cargoAso },
                  {
                    'is-valid': formik.touched.cargoAso && !formik.errors.cargoAso,
                  }
                )}
                disabled={formik.isSubmitting || isPacienteLoading}
              />
              {formik.touched.cargoAso && formik.errors.cargoAso && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.cargoAso}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='fv-row mb-7'>
              <label className='fw-bold fs-6 mb-2'>Setor</label>
              <input
                placeholder='Setor'
                {...formik.getFieldProps('setorAso')}
                type='text'
                name='setorAso'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  { 'is-invalid': formik.touched.setorAso && formik.errors.setorAso },
                  {
                    'is-valid': formik.touched.setorAso && !formik.errors.setorAso,
                  }
                )}
                disabled={formik.isSubmitting || isPacienteLoading}
              />
              {formik.touched.setorAso && formik.errors.setorAso && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.setorAso}</span>
                  </div>
                </div>
              )}
            </div>
          </>
        ) : (
          <></>
        )}
        <div className='fv-row mb-2'>
          <div className='d-flex col-lg-4 ms-auto'>
            {isAso && (
              <Button className='btn-primary w-100' onClick={() => setShowAso((prev) => !prev)}>
                {showAso ? 'Remover Dados Aso' : 'Adicionar Dados Aso'}
              </Button>
            )}
          </div>
        </div>

        {showEndereco ? (
          <>
            <div className='fv-row mb-7'>
              <label className='fw-bold fs-6 mb-2'>Endereço</label>
            </div>
            <div className='fv-row mb-7'>
              <label className='fw-bold fs-6 mb-2'>Logradouro</label>

              <input
                placeholder='Logradouro'
                {...formik.getFieldProps('logradouro')}
                type='text'
                name='logradouro'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  { 'is-invalid': formik.touched.logradouro && formik.errors.logradouro },
                  {
                    'is-valid': formik.touched.logradouro && !formik.errors.logradouro,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isPacienteLoading}
              />
              {formik.touched.logradouro && formik.errors.logradouro && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.logradouro}</span>
                  </div>
                </div>
              )}
              {/* end::Input */}
            </div>
            {/* begin::Input group 
                <div className='fv-row mb-7'>
                  <label className='required fw-bold fs-6 mb-2'>Tipo Endereço</label>
                  <Select
                    placeholder='Selecione um tipo de Endereço'
                    onChange={handleChangeTipoEndereco}
                    // options={optionsEmpresa}
                    options={options}
                    className={clsx(
                      'basic-single',
                      { 'is-invalid': formik.touched.tipoEndereco && formik.errors.tipoEndereco },
                      {
                        'is-valid': formik.touched.tipoEndereco && !formik.errors.tipoEndereco,
                      }
                    )}
                  />
                  {formik.touched.tipoEndereco && formik.errors.tipoEndereco && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.tipoEndereco}</span>
                      </div>
                    </div>
                  )}
                  {/* end::Input 
                </div>
                {/* end::Input group */}
            {/* end::Input group */}
            <div className='fv-row mb-7'>
              {/* begin::Label */}
              <label className='fw-bold fs-6 mb-2'>Número</label>
              {/* end::Label */}

              {/* begin::Input */}
              <input
                placeholder='Número'
                {...formik.getFieldProps('numero')}
                type='number'
                name='numero'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  { 'is-invalid': formik.touched.numero && formik.errors.numero },
                  {
                    'is-valid': formik.touched.numero && !formik.errors.numero,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isPacienteLoading}
              />
              {formik.touched.numero && formik.errors.numero && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.numero}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='fv-row mb-7'>
              <label className='fw-bold fs-6 mb-2'>CEP</label>

              <IMaskInput
                placeholder='CEP'
                {...formik.getFieldProps('cep')}
                mask='00.000-000'
                type='text'
                name='cep'
                // hidden={hideFind}
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  { 'is-invalid': formik.touched.cep && formik.errors.cep },
                  {
                    'is-valid': formik.touched.cep && !formik.errors.cep,
                  }
                )}
                disabled={formik.isSubmitting || isPacienteLoading}
              />
              {formik.touched.cep && formik.errors.cep && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.cep}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='fv-row mb-7'>
              <label className='fw-bold fs-6 mb-2'>Complemento</label>

              <input
                placeholder='Complemento'
                {...formik.getFieldProps('complemento')}
                type='text'
                name='complemento'
                autoComplete='off'
                disabled={formik.isSubmitting || isPacienteLoading}
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  { 'is-invalid': formik.touched.complemento && formik.errors.complemento },
                  {
                    'is-valid': formik.touched.complemento && !formik.errors.complemento,
                  }
                )}
              />
            </div>
            <div className='fv-row mb-7'>
              <label className='fw-bold fs-6 mb-2'>Bairro</label>

              <input
                placeholder='Bairro'
                {...formik.getFieldProps('bairro')}
                type='text'
                name='bairro'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  { 'is-invalid': formik.touched.bairro && formik.errors.bairro },
                  {
                    'is-valid': formik.touched.bairro && !formik.errors.bairro,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isPacienteLoading}
              />
              {formik.touched.bairro && formik.errors.bairro && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.bairro}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='fv-row mb-7'>
              <label className='fw-bold fs-6 mb-2'>Cidade</label>

              <input
                placeholder='Cidade'
                {...formik.getFieldProps('cidade')}
                type='text'
                name='cidade'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  { 'is-invalid': formik.touched.cidade && formik.errors.cidade },
                  {
                    'is-valid': formik.touched.cidade && !formik.errors.cidade,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isPacienteLoading}
              />
              {formik.touched.cidade && formik.errors.cidade && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.cidade}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='fv-row mb-7'>
              <label className='fw-bold fs-6 mb-2'>Estado</label>

              <select
                className='form-select form-select-solid form-select-lg fw-bold'
                {...formik.getFieldProps('uf')}
              >
                <option value=''>Selecione um estado...</option>
                <option value='AC'>Acre</option>
                <option value='AL'>Alagoas</option>
                <option value='AP'>Amapá</option>
                <option value='AM'>Amazonas</option>
                <option value='BA'>Bahia</option>
                <option value='CE'>Ceará</option>
                <option value='DF'>Distrito Federal</option>
                <option value='ES'>Espírito Santo</option>
                <option value='GO'>Goiás</option>
                <option value='MA'>Maranhão</option>
                <option value='MT'>Mato Grosso</option>
                <option value='MS'>Mato Grosso do Sul</option>
                <option value='MG'>Minas Gerais</option>
                <option value='PA'>Pará</option>
                <option value='PB'>Paraíba</option>
                <option value='PR'>Paraná</option>
                <option value='PE'>Pernambuco</option>
                <option value='PI'>Piauí</option>
                <option value='RJ'>Rio de Janeiro</option>
                <option value='RN'>Rio Grande do Norte</option>
                <option value='RS'>Rio Grande do Sul</option>
                <option value='RO'>Rondônia</option>
                <option value='RR'>Roraima</option>
                <option value='SC'>Santa Catarina</option>
                <option value='SP'>São Paulo</option>
                <option value='SE'>Sergipe</option>
                <option value='TO'>Tocantins</option>
              </select>
              {formik.touched.uf && formik.errors.uf && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.uf}</div>
                </div>
              )}
            </div>
          </>
        ) : null}
        <div className='fv-row mb-2'>
          <div className='d-flex col-lg-4 ms-auto'>
            <Button className='btn-primary w-100' onClick={() => setShowEndereco((prev) => !prev)}>
              {showEndereco ? 'Remover Endereço' : 'Adicionar Endereço'}
            </Button>
          </div>
        </div>
        <div className='d-flex justify-content-center'>
          <Button
            type={ButtonType.Reset}
            onClick={cancel}
            className='btn btn-secondary me-3'
            data-kt_modal_add_paciente='close'
            disabled={formik.isSubmitting || isPacienteLoading}
          >
            Cancelar
          </Button>

          <Button
            type={ButtonType.Submit}
            className='btn btn-primary'
            data-kt_modal_add_paciente='submit'
            disabled={isPacienteLoading || formik.isSubmitting}
          >
            {formik.isSubmitting || isPacienteLoading ? (
              <LoadingIndicator />
            ) : (
              <span className='indicator-label'>Enviar</span>
            )}
          </Button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isPacienteLoading) && <PacienteListLoading />}
    </>
  )
}

export { PacienteEditModalForm }
