import { KTCard } from '../../../../../_metronic/helpers'
import { EmpresasListHeader } from './component/header/EmpresasListHeader'
import { ListViewProvider, useListView } from './core/ListViewProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'
import { EmpresaEditModal } from './empresa-edit-modal/EmpresaEditModal'
import { EmpresasTable } from './table/EmpresasTable'

const EmpresasList = () => {
  const { itemIdForUpdate } = useListView()
  return (
    <>
      <KTCard>
        <EmpresasListHeader />
        <EmpresasTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <EmpresaEditModal />}
    </>
  )
}

const EmpresasListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <EmpresasList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { EmpresasListWrapper }
