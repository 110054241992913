import { FC, useEffect, useState } from 'react'
import { AtendimentoView, TipoASO } from '../atendimento/core/_models'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import Swal from 'sweetalert2'
import Select from 'react-select'
import { alteraTipoAso, getTiposASO } from '../atendimento/core/_requests'

type Props = {
  atendimento: AtendimentoView | undefined
  defineAtendimento: Function
}

const TipoAso: FC<Props> = ({ atendimento, defineAtendimento }) => {
  const [loading, setLoading] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [tipoSel, setTipoSel] = useState<String | undefined>(undefined)
  const [tipo, setTipo] = useState<TipoASO | undefined>(undefined)
  const [options, setOptions] = useState<any>([])
  const INITIAL_DATA = {
    label: tipo !== undefined ? tipo.descricao : 'Selecione um valor',
    value: tipo !== undefined ? tipo.valor : '0',
  }
  const [selectData, setselectData] = useState(INITIAL_DATA)
  const parecerSchema = Yup.object().shape({
    parecer: Yup.string().required('Parecer é obrigatório'),
  })
  const parecerAsoForEdit = {
    parecer: '',
  }
  function getOptionsTipos() {
    getTiposASO()
      .then((data) => {
        const results: any = []
        if (data !== undefined) {
          data.forEach((value) => {
            if (atendimento?.tipoAso === value.name) {
              setTipo(value)
            }
            results.push({
              label: value.descricao,
              value: value.name,
            })
          })
          setOptions([...results])
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }
  const handleChangeTipo = (e: any) => {
    //Definir o campo de todos os inputs para filtro
    setTipoSel(e.value)
    tipoSel !== '0' ? setIsValid(true) : setIsValid(false)
  }
  useEffect(
    () => {
      getOptionsTipos()
    },
    [atendimento] // Only call effect if debounced search term changes
    // More details about useDebounce: https://usehooks.com/useDebounce/
  )
  const formik = useFormik({
    initialValues: parecerAsoForEdit,
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      setSubmitting(true)
      setLoading(true)
      try {
        alteraTipoAso(atendimento?.id, String(tipoSel))
          .then((result) => {
            defineAtendimento()
            Swal.fire({
              text: 'Atendimento alterado com sucesso!',
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            }).then(() => {
              getOptionsTipos()
              formik.resetForm()
            })
          })
          .catch((error) => {
            console.log(error)
          })
      } catch (ex: any) {
        const errors = ex.response.data.data.errors
        setFieldError('fatorRisco', errors['fatorRisco'] !== undefined ? errors.fatorRisco[0] : '')
        setFieldError('valor', errors['valor'] !== undefined ? errors.valor[0] : '')
      } finally {
        setSubmitting(false)
        setLoading(false)
      }
    },
  })
  return (
    <>
      <form
        id='kt_modal_add_paciente_form'
        className='form'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column me-n7 pe-7'
          id='kt_modal_add_paciente_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_paciente_header'
          data-kt-scroll-wrappers='#kt_modal_add_paciente_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}

          <div className='fv-row mb-7'>
            <label className='fw-bold fs-6'>
              Tipo Aso {tipo !== undefined ? ': ' + tipo.descricao : 'Não Definido'}
            </label>
          </div>
          <div className='fv-row mb-1'>
            <Select
              placeholder='Tipo Aso'
              onChange={handleChangeTipo}
              options={options}
              className='form-control form-control-solid'
              defaultValue={selectData}
            />
            {/* end::Input */}
          </div>
        </div>
        <div className='card-footer d-flex justify-content-end py-2 px-8'>
          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={loading || formik.isSubmitting || !isValid}
          >
            <span className='indicator-label'>Enviar</span>
            {(formik.isSubmitting || loading) && (
              <span className='indicator-progress'>
                Aguarde...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </>
  )
}

export { TipoAso }
