import * as Yup from 'yup'

export const editPacienteSchema = (showEndereco: boolean) =>
  Yup.object().shape({
    id: Yup.number().notRequired(),
    primeiroNome: Yup.string()
      .min(3, 'Minimo de 3 caracteres')
      .max(50, 'Máximo de 50 caracteres')
      .required('O campo nome é obrigatório')
      .test(
        'not-empty',
        'Campo não pode conter apenas espaços',
        (value) => typeof value === 'string' && value.trim().length > 0
      ),
    sobreNome: Yup.string()
      .min(3, 'Minimo de 3 caracteres')
      .max(50, 'Máximo de 50 caracteres')
      .required('O campo sobrenome é obrigatório')
      .test(
        'not-empty',
        'Campo não pode conter apenas espaços',
        (value) => typeof value === 'string' && value.trim().length > 0
      ),
    cpf: Yup.string()
      .required('O campo CPF é obrigatório')
      .test(
        'not-empty',
        'Campo não pode conter apenas espaços',
        (value) => typeof value === 'string' && value.trim().length > 0
      ),
    cns: Yup.string().notRequired().nullable(),
    email: Yup.string().email('Formato inválido').notRequired().nullable(),
    telefone: Yup.string().min(16, 'Número Inválido').notRequired().nullable(),
    sexo: Yup.string().required('O campo sexo é obrigatório'),
    nascimento: Yup.string().required('O campo data de nascimento é obrigatório'),

    tipoEndereco: Yup.string().notRequired(),

    cep: Yup.string()
      .min(8, 'Minimo de 8 caracteres')
      .max(20, 'Máximo de 20 caracteres')
      .test('is-required', 'O campo CEP é obrigatório', function (value) {
        return showEndereco ? !!value : true
      }),
    logradouro: Yup.string()
      .min(5, 'Minimo de 5 caracteres')
      .max(50, 'Máximo de 50 caracteres')
      .test('is-required', 'O campo logradouro é obrigatório', function (value) {
        return showEndereco ? !!value : true
      }),
    numero: Yup.number().test('is-required', 'O campo número é obrigatório', function (value) {
      return showEndereco ? !!value : true
    }),
    complemento: Yup.string().notRequired(),
    bairro: Yup.string()
      .min(3, 'Minimo de 3 caracteres')
      .max(50, 'Máximo de 50 caracteres')
      .test('is-required', 'O campo bairro é obrigatório', function (value) {
        return showEndereco ? !!value : true
      }),
    cidade: Yup.string()
      .min(3, 'Minimo de 3 caracteres')
      .max(50, 'Máximo de 50 caracteres')
      .test('is-required', 'O campo cidade é obrigatório', function (value) {
        return showEndereco ? !!value : true
      }),
    uf: Yup.string()
      .min(2, 'Minimo de 2 caracteres')
      .max(2, 'Máximo de 2 caracteres')
      .test('is-required', 'O campo UF é obrigatório', function (value) {
        return showEndereco ? !!value : true
      }),
  })
