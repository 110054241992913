import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'

import { ImportacaoDetalhePage } from './detalhes/ImportacaoDetalhePage'
import { ImportacaoErroPage } from './erros/ImportacaoErroPage'
import { ImportacaoListPage } from './list/ImportacaoListPage'

const importacoesBreadcrumbs: Array<PageLink> = [
  /* {
    title: 'Atendimento',
    path: '',
    isSeparator: false,
    isActive: false,
  }, */
]

const ImportacaoPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='lista'
          element={
            <>
              <PageTitle breadcrumbs={importacoesBreadcrumbs}>Importação</PageTitle>
              <ImportacaoListPage />
            </>
          }
        />
        <Route
          path='erros/:id'
          element={
            <>
              <PageTitle breadcrumbs={importacoesBreadcrumbs}>Erros importação</PageTitle>
              <ImportacaoErroPage />
            </>
          }
        />
        <Route
          path='detalhes/:id'
          element={
            <>
              <PageTitle breadcrumbs={importacoesBreadcrumbs}>Detalhes importação</PageTitle>
              <ImportacaoDetalhePage />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/apps/importacao/lista' />} />
    </Routes>
  )
}

export default ImportacaoPage
