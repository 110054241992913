import { IconPaths, KTSVG } from '../../../../../../../_metronic/helpers'
import { Button } from '../../../../../../components'
import { useListView } from '../../core/ListViewProvider'

const EmpresasListToolbar = () => {
  const { setItemIdForUpdate } = useListView()
  const openAddEmpresa = () => {
    setItemIdForUpdate(null)
  }

  return (
    <Button className='btn btn-primary' onClick={openAddEmpresa} icon={IconPaths.Add}>
      Adicionar
    </Button>
  )
}

export { EmpresasListToolbar }
