import { ImportacaoDetalheList } from "./components/ImportacaoDetalheList"
import { ListViewProvider } from "./core/ListViewProvider"
import { QueryRequestProvider } from "./core/QueryRequestProvider"
import { QueryResponseProvider } from "./core/QueryResponseProvider"

const ImportacaoDetalhePage = () => (
    <QueryRequestProvider>
      <QueryResponseProvider>
        <ListViewProvider>
          <ImportacaoDetalheList />
        </ListViewProvider>
      </QueryResponseProvider>
    </QueryRequestProvider>
  )
  
  export {ImportacaoDetalhePage}