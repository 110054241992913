import { FC } from 'react'

export type IDot = {
  cor: string
}

export const Dot: FC<IDot> = ({ cor }) => {
  return (
    <div
      className='d-inline-flex align-items-center justify-content-center'
      style={{ width: '50px', height: '50px', borderRadius: '50%' }}
    >
      <div className={`rounded-circle ${cor}`} style={{ width: '30%', height: '30%' }}></div>
      <audio src='/media/notifications/notification.mp3' />
    </div>
  )
}
