import { FC, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import Swal from 'sweetalert2'
import clsx from 'clsx'
import Select from 'react-select'
import { ID, KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import {
  adicionaFatorRisco,
  deleteFatorRisco,
  editarFatorRisco,
  getTiposFatorASO,
} from '../atendimento/core/_requests'
import { AtendimentoView, FatorRiscoAtendimentoView } from '../atendimento/core/_models'

type Props = {
  atendimento: AtendimentoView | undefined
  defineAtendimento: Function
}

const FatorRiscoEdit: FC<Props> = ({ atendimento, defineAtendimento }) => {
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState<any>([])
  const [tipoSel, setTipoSel] = useState<String | undefined>(undefined)
  const [fatorEdit, setFatorEdit] = useState<FatorRiscoAtendimentoView | undefined>(undefined)
  const INITIAL_DATA_FATOR_RISCO = {
    value: 0,
    label: 'Selecione um fator de risco',
  }
  const [selectDataFatorRisco, setSelectDataFatorRisco] = useState(INITIAL_DATA_FATOR_RISCO)
  const fatorRiscoSchema = Yup.object().shape({
    valor: Yup.string().required('Valor é obrigatório'),
  })
  const fatorRiscoAsoForEdit = {
    valor: '',
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: fatorRiscoAsoForEdit,
    validationSchema: fatorRiscoSchema,
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      setSubmitting(true)
      setLoading(true)
      try {
        if (!fatorEdit) {
          adicionaFatorRisco(atendimento?.id, String(tipoSel), values.valor)
            .then((a) => {
              defineAtendimento()
              Swal.fire({
                text: 'Fator de risco adicionado com sucesso!',
                icon: 'success',
                buttonsStyling: false,
                confirmButtonText: 'Ok',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              }).then(() => {
                formik.resetForm()
              })
            })
            .catch((error) => {
              console.log(error)
            })
        } else {
          editarFatorRisco(atendimento?.id, fatorEdit.id, String(tipoSel), values.valor)
            .then((a) => {
              defineAtendimento()
              setFatorEdit(undefined)
              Swal.fire({
                text: 'Fator de risco alterado com sucesso!',
                icon: 'success',
                buttonsStyling: false,
                confirmButtonText: 'Ok',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              }).then(() => {
                formik.resetForm()
              })
            })
            .catch((error) => {
              console.log(error)
            })
        }
      } catch (ex: any) {
        const errors = ex.response.data.data.errors
        setFieldError('fatorRisco', errors['fatorRisco'] !== undefined ? errors.fatorRisco[0] : '')
        setFieldError('valor', errors['valor'] !== undefined ? errors.valor[0] : '')
      } finally {
        setSubmitting(false)
        setLoading(false)
      }
    },
  })
  function getOptionsTipos() {
    getTiposFatorASO()
      .then((data) => {
        const results: any = []
        if (data !== undefined) {
          data.forEach((value) => {
            results.push({
              label: value.descricao,
              value: value.name,
            })
          })
          setOptions([...results])
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }
  const handleChangeTipo = (e: any) => {
    //Definir o campo de todos os inputs para filtro
    setTipoSel(e.value)
    setSelectDataFatorRisco(e)
  }
  useEffect(
    () => {
      getOptionsTipos()
    },
    [atendimento] // Only call effect if debounced search term changes
    // More details about useDebounce: https://usehooks.com/useDebounce/
  )
  const excluiFatorRisco = (id: ID) => {
    deleteFatorRisco(atendimento?.id, id)
      .then(() => {
        Swal.fire({
          text: 'Fator de Risco excluído com sucesso!',
          icon: 'success',
          buttonsStyling: false,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        }).then(() => {
          defineAtendimento()
        })
      })
      .catch((error) => {
        Swal.fire({
          text: 'Não foi possivel excluir! ' + error.response?.data.data.message,
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        }).then(() => {
          defineAtendimento()
        })
      })
  }
  const editFatorRisco = (id: ID) => {
    atendimento?.fatoresRisco?.map((fator) => {
      if (fator.id === id) {
        setFatorEdit(fator)
        setTipoSel(fator.fatorRisco)
        formik.setFieldValue('valor', fator.valor)
        formik.handleChange(true)
        setSelectDataFatorRisco({
          value: Number(fator.id),
          label: String(fator.fatorRisco),
        })
      }
    })
  }
  return (
    <>
      {atendimento?.fatoresRisco !== undefined ? (
        <>
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-1 text-dark'>Fatores de Risco</span>
            </h3>
          </div>
          <div className='card-body py-3'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table align-middle gs-0 gy-5'>
                {/* begin::Table head */}
                <thead>
                  <tr>
                    <th className='p-0 w-50px'></th>
                    <th className='p-0 min-w-200px'></th>
                    <th className='p-0 min-w-40px'></th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {atendimento.fatoresRisco !== undefined ? (
                    atendimento.fatoresRisco.map((p) => (
                      <tr>
                        <th>
                          <div className='symbol symbol-50px me-2'>
                            <span className='symbol-label'>
                              <img
                                src={toAbsoluteUrl('/media/icons/duotune/general/gen005.svg')}
                                className='h-50 align-self-center'
                                alt=''
                              />
                            </span>
                          </div>
                        </th>
                        <td>
                          <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                            {p.fatorRisco}
                          </a>
                          <span className='text-muted fw-semibold d-block fs-7'>{p.valor}</span>
                        </td>
                        <td className='text-end'>
                          <button
                            className='btn btn-sm btn-light btn-color-muted btn-active-light-warning'
                            onClick={() => editFatorRisco(p.id)}
                          >
                            <span
                              className='menu-icon d-flex align-items-center'
                              data-kt-element='icon'
                            >
                              <KTSVG
                                path='/media/icons/duotune/general/gen055.svg'
                                className='svg-icon-3'
                              />
                            </span>
                          </button>
                          <button
                            className='btn btn-sm btn-light btn-color-muted btn-active-light-danger'
                            onClick={() => excluiFatorRisco(p.id)}
                          >
                            <span
                              className='menu-icon d-flex align-items-center'
                              data-kt-element='icon'
                            >
                              <KTSVG
                                path='/media/icons/duotune/general/gen040.svg'
                                className='svg-icon-3'
                              />
                            </span>
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <></>
                  )}
                </tbody>
                {/* end::Table body */}
              </table>
              {/* end::Table */}
            </div>
            {/* end::Table container */}
          </div>
        </>
      ) : (
        <></>
      )}
      <form
        id='kt_modal_add_paciente_form'
        className='form'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column me-n7 pe-7'
          id='kt_modal_add_paciente_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_paciente_header'
          data-kt-scroll-wrappers='#kt_modal_add_paciente_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}

          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Dados Fator de Risco</label>
          </div>
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Tipo</label>
          </div>
          <div className='fv-row mb-7'>
            <Select
              placeholder='Selecione um Valor'
              onChange={handleChangeTipo}
              options={options}
              className='form-control form-control-solid'
              value={selectDataFatorRisco}
            />
            {/* end::Input */}
          </div>
          <div className='fv-row mb-7'>
            <input
              placeholder='Valor'
              {...formik.getFieldProps('valor')}
              type='text'
              name='valor'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.valor && formik.errors.valor },
                {
                  'is-valid': formik.touched.valor && !formik.errors.valor,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || loading}
            />
            {formik.touched.valor && formik.errors.valor && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.valor}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
        </div>
        <div className='card-footer d-flex justify-content-end py-2 px-8'>
          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={
              loading ||
              formik.isSubmitting ||
              !formik.isValid ||
              !formik.touched ||
              tipoSel === undefined
            }
          >
            <span className='indicator-label'>Enviar</span>
            {(formik.isSubmitting || loading) && (
              <span className='indicator-progress'>
                Aguarde...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </>
  )
}

export { FatorRiscoEdit }
