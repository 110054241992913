import { FC, ReactNode, useCallback, useEffect, useState } from 'react'
import { GridCols, ID, Status, useDebounce } from '../../../_metronic/helpers'
import Select, {
  OnChangeValue,
  GroupBase,
  StylesConfig,
  MultiValue,
  SingleValue,
  components,
  MultiValueProps,
  MultiValueRemoveProps,
} from 'react-select'

export type IOption = {
  label: string
  value: ID | string | Status | boolean
}

type ICustomSelect = {
  onChange?: (value: any) => void // FIX type any
  onBlur?: (value: any) => void // FIX type any
  placeholder?: string
  label?: string
  value?: IOption | IOption[]
  isMulti?: boolean
  isDisabled?: boolean
  isRequired?: boolean
  isClearable?: boolean
  customStyles?: StylesConfig<IOption>
  className?: string
  name?: string
  showMultiValueRemove?: boolean
  fetchOptions?: (inputValue: string) => Promise<IOption[]>
  options?: IOption[]
}

export const CustomSelect: FC<ICustomSelect> = ({
  onChange,
  onBlur,
  placeholder,
  value,
  isMulti,
  customStyles,
  className,
  isDisabled,
  isRequired,
  isClearable,
  name: label,
  showMultiValueRemove,
  fetchOptions,
  options: syncOptions,
}) => {
  const [options, setOptions] = useState<IOption[]>([])
  const [extendedOptions, setExtendedOptions] = useState<IOption[]>([])
  const [selectAll, setSelectAll] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [loading, setLoading] = useState(false)

  const debouncedInput = useDebounce(inputValue, 300) ?? ''

  const selectAllOption: IOption = {
    label: selectAll ? 'Desmarcar Todos' : 'Selecionar Todos',
    value: undefined,
  }

  const handleChange = (selected: MultiValue<IOption> | SingleValue<IOption>) => {
    if (selected && Array.isArray(selected)) {
      const isSelectAllClicked = selected.some((option) => option.value === undefined)

      if (isSelectAllClicked) {
        if (!selectAll) {
          setSelectAll(true)
          if (onChange) onChange(options)
        } else {
          setSelectAll(false)
          if (onChange) onChange([])
        }
      } else {
        setSelectAll(false)
        if (onChange) onChange(selected)
      }
    } else {
      if (onChange) onChange(selected)
    }
  }

  const MultiValueRemove = (props: MultiValueRemoveProps<any, boolean>) => {
    return showMultiValueRemove ? <components.MultiValueRemove {...props} /> : null
  }

  useEffect(() => {
    setExtendedOptions(isMulti ? [selectAllOption, ...options] : [...options])
  }, [options])

  useEffect(() => {
    const loadOptions = async () => {
      if (fetchOptions && !syncOptions) {
        setLoading(true)
        try {
          const result = await fetchOptions(debouncedInput)
          setOptions(result)
        } catch (error) {
          console.error('Error fetching options:', error)
        } finally {
          setLoading(false)
        }
      } else if (syncOptions) {
        setOptions(syncOptions)
      } else {
        setOptions([])
      }
    }

    loadOptions()
  }, [debouncedInput, fetchOptions, syncOptions])

  const handleInputChange = (newValue: string) => {
    setInputValue(newValue)
  }

  const customNoOptionsMessage = () => 'Sem Opções'

  return (
    <Select
      name={label}
      isClearable={isClearable}
      className={`${className}`}
      classNamePrefix='react-select'
      placeholder={placeholder}
      onChange={handleChange}
      onBlur={onBlur}
      options={extendedOptions}
      value={value}
      isMulti={isMulti}
      isLoading={loading}
      isDisabled={isDisabled}
      onInputChange={handleInputChange}
      noOptionsMessage={customNoOptionsMessage}
      components={{
        MultiValueRemove,
      }}
      required={isRequired}
      styles={{
        control: (provided) => ({
          ...provided,
          width: '100%',
          height: '100%',
          border: 'none',
          padding: 4,
          backgroundColor: 'var(--bs-gray-100)',
        }),
        menu: (provided) => ({
          ...provided,
          backgroundColor: 'var(--bs-gray-100)',
          zIndex: 9999,
          padding: 4,
        }),
        input: (provided) => ({
          ...provided,
          color: 'var(--bs-gray-700)', // Change text color when typing
        }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isSelected
            ? 'var(--bs-secondary)'
            : state.isFocused
            ? 'var(--bs-gray-300)'
            : 'transparent',
          color: 'var(--bs-gray-700)',
          cursor: 'pointer',
        }),
        singleValue: (provided) => ({
          ...provided,
          color: 'var(--bs-gray-700)',
        }),
        placeholder: (base: any) => ({
          ...base,
          color: 'var(--bs-gray-500)',
        }),

        ...customStyles,
      }}
    />
  )
}

CustomSelect.defaultProps = {
  onBlur: () => {},
  placeholder: 'Selecione...',
  label: 'Preencha props label',
  isMulti: false,
  isDisabled: false,
  isRequired: false,
  isClearable: true,
  showMultiValueRemove: true,
  className: '',
  name: '',
}
