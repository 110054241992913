import axios, { AxiosResponse } from 'axios'
import { ID } from '../../../../../../_metronic/helpers'
import { SetorAsoView, SetoresClienteQueryResponse } from './_models'

const API_URL = process.env.REACT_APP_API_URL
const CLIENTE_SETOR_URL = `${API_URL}/empresas/cliente/setor`

export const getSetoresCliente = (
  idEmpresaCliente: ID,
  query: string
): Promise<SetoresClienteQueryResponse> => {
  return axios
    .get(`${CLIENTE_SETOR_URL}?idEmpresa=${idEmpresaCliente}&${query}`)
    .then((d: AxiosResponse<SetoresClienteQueryResponse>) => d.data)
}
export const getSetorClienteById = (idSetor: ID): Promise<SetorAsoView | undefined> => {
  return axios
    .get(`${CLIENTE_SETOR_URL}/${idSetor}`)
    .then((response: AxiosResponse<SetorAsoView>) => response.data)
}
export const createSetor = (
  idEmpresaCliente: ID,
  nome: string
): Promise<SetorAsoView | undefined> => {
  return axios.post(`${CLIENTE_SETOR_URL}/${idEmpresaCliente}`, {
    nome,
  })
}
export const editSetorCliente = (
  idEmpresaCliente: ID,
  nome: string
): Promise<SetorAsoView | undefined> => {
  return axios.put(`${CLIENTE_SETOR_URL}/${idEmpresaCliente}`, {
    nome,
  })
}
export const deleteSetorCliente = (idSetor: ID): Promise<string> => {
  return axios.delete(`${CLIENTE_SETOR_URL}/${idSetor}`)
}

export const deleteLoteSetoresCliente = async (setorIds: ID[]): Promise<void> => {
  try {
    const requests = setorIds.map((id) => axios.delete(`${CLIENTE_SETOR_URL}/${id}`))
    await Promise.all(requests)
  } catch (error) {
    console.error('Erro deletando setores', error)
    throw error
  }
}
