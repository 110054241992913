/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { useDebounce, initialQueryState } from '../../../../../../../_metronic/helpers'
import { DateInput, Input, Label } from '../../../../../../components'

interface DownloadAsoListSearchComponentProps {}

const DownloadAsoListSearchComponent: React.FC<DownloadAsoListSearchComponentProps> = () => {
  const [idEmpresaConsulta, setIdEmpresaConsulta] = useState<number | undefined>(undefined)
  const { updateState } = useQueryRequest()
  const [searchTerm, setSearchTerm] = useState<string>('situacaoAso:FINALIZADO')
  const [dataSel, setDataSel] = useState<string>('')
  const [nomeSel, setNomeSel] = useState<string>('')
  const [filters, setFilters] = useState<Array<String>>(
    idEmpresaConsulta ? new Array(String(idEmpresaConsulta)) : []
  )
  const debouncedSearchTerm = useDebounce(searchTerm, 150)

  const buildSearchTerm = (name?: string, date?: string): string => {
    const filters: string[] = []

    if (date) filters.push(`termino>${date}T00:00:00`)
    if (name) filters.push(`paciente.pessoa.nome~*${name}*`)
    filters.push('situacaoAso:FINALIZADO')

    return filters.join(',')
  }

  const handleChangePaciente = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNomeSel(e.target.value)
    const search = buildSearchTerm(e.target.value, dataSel)
    setSearchTerm(search)
  }

  const handleChangeData = (date: string) => {
    setDataSel(date)
    const search = buildSearchTerm(nomeSel, date)
    setSearchTerm(search)
  }

  useEffect(() => {
    if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
      updateState({ filter: filters, search: debouncedSearchTerm, ...initialQueryState })
    }
  }, [debouncedSearchTerm])

  return (
    <div className='container'>
      <div className='row mb-3'>
        <div className='col-lg-6'>
          <div className='mb-3'>
            <Label className='pb-2'>Paciente</Label>
            <Input placeholder='Digite o paciente' onChange={handleChangePaciente} />
          </div>
        </div>
        <div className='col-lg-4'>
          <Label className='pb-2'>Consulta ASO finalizada a partir de:</Label>
          <DateInput
            placeholder='Digite data inicial'
            value={dataSel}
            onChange={handleChangeData}
          />
        </div>
      </div>
    </div>
  )
}

export { DownloadAsoListSearchComponent }
