import {
  AsoType,
  FatorRiscoType,
  ID,
  Response,
  ResultadoType,
  SituacaoAso,
  Tipo,
} from '../../../../../_metronic/helpers'
import { PessoaList, UsuarioList } from '../../../auth'
import { FormularioAtendimentoList } from '../../avaliacoes/avaliacoes-pendentes-list/core/_models'
import { EmpresaList } from '../../empresa/empresas-list/core/_models'
import { ClienteList } from '../../empresa/empresasCliente-list/core/_models'
import { ColaboradorList } from '../../empresa/empresasClienteCoordenador-list/core/_models'
import { EspecialidadeList } from '../../especialidades/especialidades-list/core/_models'
import { DadoAsoView, PacienteList } from '../../paciente-management/pacientes-list/core/_models'

export type AtendimentoList = {
  retorno?: boolean
  tipoAso?: AsoType
  estado?: EstadoAtendimentoList
  resultado?: string
  inicio?: string
  termino?: string
  solicitacao?: string
  presencaPaciente?: string
  aceiteTermos?: string
  pacienteOnline?: boolean
  inicioFormatado?: string
  responsavel?: PessoaList
  presencaPacienteFormatada?: string
  paciente?: PacienteList
  protocolo?: string
  dispositivos?: DispositivoList[]
  terminoFormatado?: string
  situacaoAso?: SituacaoAso
  formularios?: FormularioAtendimentoList[]
  especialidade?: EspecialidadeList
  empresa?: EmpresaList
  tipo?: Tipo
  aceiteTermosFormatado?: string
  solicitacaoFormatada?: string
  id?: number
}

export type AtendimentoView = {
  cliente?: ClienteList
  retorno?: boolean
  tipoAso?: AsoType
  estado?: EstadoAtendimentoList
  resultado?: ResultadoType
  inicio?: string
  criador?: PessoaList
  termino?: string
  dadosAso?: DadoAsoView
  solicitacao?: string
  urlSalaVideo?: string
  presencaPaciente?: string
  aceiteTermos?: string
  pacienteOnline?: boolean
  inicioFormatado?: string
  responsavel?: PessoaList
  presencaPacienteFormatada?: string
  paciente?: PacienteList
  protocolo?: string
  dispositivos?: DispositivoList[]
  terminoFormatado?: string
  pcmsoCliente?: ColaboradorList
  pcmsoEmpresa?: PessoaList
  prescricoes?: PrescricaoAtendimentoView[]
  informacoes?: InformacaoAtendimentoView[]
  procedimentos?: ProcedimentoAtendimentoView[]
  fatoresRisco?: FatorRiscoAtendimentoView[]
  situacaoAso?: SituacaoAso
  formularios?: FormularioAtendimentoList[]
  especialidade?: EspecialidadeList
  empresa?: EmpresaList
  anexos?: AnexoAtendimentoView[]
  tipo?: Tipo
  mensagens?: MensagemAtendimentoView[]
  anotacoes?: AnotacaoAtendimentoView[]
  cids?: CidAtendimentoView[]
  aceiteTermosFormatado?: string
  solicitacaoFormatada?: string
  id?: ID
}

export type AtendimentoPacienteList = {
  prescricoes?: PrescricaoAtendimentoView[]
  retorno?: boolean
  tipoAso?: AsoType
  estado?: EstadoAtendimentoList
  resultado?: string
  inicio?: string
  termino?: string
  solicitacao?: string
  presencaPaciente?: string
  aceiteTermos?: string
  pacienteOnline?: boolean
  inicioFormatado?: string
  responsavel?: PessoaList
  presencaPacienteFormatada?: string
  paciente?: PacienteList
  protocolo?: string
  dispositivos?: DispositivoList[]
  terminoFormatado?: string
  situacaoAso?: SituacaoAso
  formularios?: FormularioAtendimentoList[]
  especialidade?: EspecialidadeList
  empresa?: EmpresaList
  tipo?: Tipo
  aceiteTermosFormatado?: string
  solicitacaoFormatada?: string
  id?: ID
}

export type CidAtendimentoView = {
  id?: ID
  ordem?: number
  cid: CidList
}

export type TipoAnexoList = {
  descricao: string
  permiteListar: boolean
  permiteExcluir: boolean
  permiteIncluir: boolean
  id: ID
}

export type AnexoAtendimentoView = {
  dataFormatada: string
  permiteListar: boolean
  permiteExcluir: boolean
  permiteIncluir: boolean
  arquivo: ArquivoDto
  tipo: TipoAnexoList
  id: ID
  data: string
  url: string
}

export type ArquivoDto = {
  id?: ID
  name: string
  type: string
  length: number
  contentBase64: string
}

export type FatorRiscoAtendimentoView = {
  fatorRisco?: FatorRiscoType
  descricaoFatorRisco: string
  valor?: string
  id?: ID
}
export type TipoASO = {
  name?: string
  valor?: string
  descricao?: string
}
export type ProcedimentoAtendimentoView = {
  descricao?: string
  dataFormatada?: string
  anexo?: number
  nome?: string
  data?: string
  id?: ID
}
export type PendenciaAso = {
  atendimento?: string[]
  responsavel?: string[]
  paciente?: string[]
}

export type PrescricaoAtendimentoView = {
  medico?: PessoaList
  memed?: string
  dataFormatada?: string
  medicamentos?: MedicamentoPrescricaoList[]
  anexo?: number
  id?: number
  data?: string
}

export type MedicamentoPrescricaoList = {
  descricao?: string
  ordem?: number
  titulo?: string
  subTitulo?: string
  posologia?: string
  quantidade?: string
  nome?: string
  id?: number
}

export type InformacaoAtendimentoView = {
  descricao: string
  unidade: string | null
  dataFormatada: string
  profissional: PessoaList
  valor: number | null
  dispositivo: DispositivoList
  tipo: TipoInformacaoView
  id: ID
  anexo: number | null
  data: string
}

export type AtendimentoDtoInformacaoAgrupada = {
  id: ID | null | undefined
  dispositivo?: DispositivoList
  informacoes?: InformacaoAgrupada[]
}
export type TipoAtendimentoEmpresa = {
  descricao?: string
  name?: string
  valor?: string
}
export type InformacaoAgrupada = {
  id: number
  descricao: string
  unidade: string
  valor: number
  data: string
  dataFormatada: string
}

export type TiposDispositivos =
  | 'BALANCA'
  | 'OXIMETRO'
  | 'TERMOMETRO'
  | 'PRESSAO'
  | 'CAMERA'
  | 'OTOSCOPIO'
  | 'INSPECAO'
  | 'GLICOSIMETRO'

export type DispositivoList = {
  id: ID
  tipo: TiposDispositivos
  /* DispositivoType */
  serie: string
  descricao: string
}

export type TipoInformacaoView = {
  descricao: string
  unidade: string | null
  ordem: ID
  siglaUnidade: string | null
  id: ID
}

export type EstadoAtendimentoList = {
  ativo: boolean
  nome: string
  descricao: string
  id: ID
}

/* export type Estado = {
  id?: ID
  nome?: string
  descricao?: string
  ativo?: string
  estadoInicial?: boolean
  estadoFinal?: boolean
  proximosEstados?: string[]
} */

export type AnotacaoAtendimentoView = {
  anotacao?: string
  dataFormatada?: string
  tipo?:
    | 'ANAMNESE'
    | 'AVALIACAO'
    | 'DIAGNOSTICO'
    | 'TERAPIA'
    | 'OBSERVACAO'
    | 'PARECER'
    | 'PENDENCIA'
  usuario: UsuarioList
  data?: string
  id?: ID
}

export type MensagemAtendimentoView = {
  mensagem?: string
  dataFormatada?: string
  usuario?: PessoaList
  id?: number
  data?: string
}

export type CidList = {
  descricao?: string
  codigo?: string
  id?: ID
  /*  sexo?: string
  obito?: boolean
  classificacao?: string
  categoria?: string
  grupo?: string
  capitulo?: string */
}

export type TiposAtendimento = {
  descricao: string
  valor: string
  name: string
}

export type AtendimentoQueryResponse = Response<Array<AtendimentoView>>

export type AtendimentoIniciadoQueryResponse = Response<Array<AtendimentoView>>

export type AtendimentoPacienteQueryResponse = Response<Array<AtendimentoPacienteList>>

export type AtendimentosQueryResponse = Response<Array<AtendimentoList>>

export type CIDQueryResponse = Response<Array<CidList>>

export type EstadosDeAtendimentoQueryResponse = Response<Array<EstadoAtendimentoList>>

/* export type SituacaoAsoQueryResponse = Response<Array<SituacaoAso>> */

export const initialAtendimento: AtendimentoView = {
  id: -1,
}
