/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from 'react'
import { ID } from '../../../../../../../_metronic/helpers'
import Swal from 'sweetalert2'
import { Modal, Button, InputGroup, FormControl } from 'react-bootstrap'
import { gerarToken, webhook } from '../../core/_requests'
import { getEmpresaById } from '../../../../empresa/empresas-list/core/_requests'
import { Button as CustomButton } from '../../../../../../components'
import { EmpresaView } from '../../../../empresa/empresas-list/core/_models'

type Props = {
  id: ID
}

const EmpresasActionsCell: FC<Props> = ({ id }) => {
  const [showTokenModal, setShowTokenModal] = useState(false)
  const [showWebhookModal, setShowWebhookModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [token, setToken] = useState<string>('')
  const [copied, setCopied] = useState<boolean>(false)
  const [empresa, setEmpresa] = useState<EmpresaView>()
  const [webhookUrl, setWebhookUrl] = useState<string>(empresa?.apiWebhook as string)

  const getEmpresa = async (id: ID) => {
    setLoading(true)
    try {
      const empresa = await getEmpresaById(id)
      setEmpresa(empresa)
    } catch (error) {
      setLoading(false)
      Swal.fire({
        text: 'Erro ao buscar dados da empresa.',
        icon: 'error',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    }
    setLoading(false)
  }

  const handleGenerateTokenClick = async () => {
    try {
      const { accessToken } = await gerarToken(id)
      setToken(accessToken)
      setShowTokenModal(true)
    } catch (error) {
      Swal.fire({
        text: 'Erro ao gerar token.',
        icon: 'error',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    }
  }

  const handleCopyToken = () => {
    //@ts-ignore
    navigator.clipboard.writeText(token.accessToken)
    setCopied(true)
  }

  const handleTokenModalClose = () => {
    setCopied(false)
    setShowTokenModal(false)
  }

  const handleWebhookModalClose = () => {
    setWebhookUrl('')
    setShowWebhookModal(false)
  }

  const handleWebhookSubmit = async () => {
    try {
      await webhook(id, webhookUrl)
      window.location.reload()
      Swal.fire({
        text: 'WebHook configurado com sucesso!',
        icon: 'success',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
      setShowWebhookModal(false)
    } catch (error) {
      Swal.fire({
        text: 'Erro ao configurar WebHook.',
        icon: 'error',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    }
  }

  const openWebhookModal = () => {
    getEmpresa(id)
    setShowWebhookModal(true)
  }

  return (
    <>
      <div className='d-flex flex-end'>
        <CustomButton className='btn-primary btn-sm me-2' onClick={handleGenerateTokenClick}>
          Gerar Token
        </CustomButton>
        <CustomButton className='btn-secondary btn-sm' onClick={openWebhookModal}>
          WebHook
        </CustomButton>
      </div>

      {/* Token Modal */}
      <Modal show={showTokenModal} onHide={handleTokenModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Gerar Token</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Token gerado com sucesso. Copie o token abaixo:</p>
          <InputGroup>
            <FormControl
              as='textarea'
              //@ts-ignore
              value={token.accessToken}
              readOnly
              aria-label='Generated Token'
              rows={5}
            />
            <Button variant='outline-secondary' onClick={handleCopyToken}>
              {copied ? 'Copiado' : 'Copiar'}
            </Button>
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleTokenModalClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Webhook Modal */}
      <Modal show={showWebhookModal} onHide={handleWebhookModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Configurar WebHook</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Aguarde...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          ) : (
            <InputGroup>
              <FormControl
                type='text'
                placeholder='Insira a URL do WebHook'
                value={webhookUrl}
                onChange={(e) => setWebhookUrl(e.target.value)}
              />
            </InputGroup>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleWebhookModalClose}>
            Fechar
          </Button>
          <Button variant='primary' onClick={handleWebhookSubmit}>
            Enviar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export { EmpresasActionsCell }
