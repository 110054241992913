import axios, { AxiosResponse } from 'axios'
import { FatorRiscoType, ID } from '../../../../../../_metronic/helpers'
import { RiscoAsoView, RiscosClienteQueryResponse } from './_models'

const API_URL = process.env.REACT_APP_API_URL
const RISCOS_CLIENTE_URL = `${API_URL}/empresas/cliente/risco`

export const getRiscosCliente = (
  idEmpresaCliente: ID,
  query: string,
  cliente: ID
): Promise<RiscosClienteQueryResponse> => {
  return axios
    .get(`${RISCOS_CLIENTE_URL}?empresa=${idEmpresaCliente}&search=cliente.id:${cliente}&${query}`)
    .then((d: AxiosResponse<RiscosClienteQueryResponse>) => d.data)
}
export const getRiscoClienteById = (idRisco: ID): Promise<RiscoAsoView | undefined> => {
  return axios
    .get(`${RISCOS_CLIENTE_URL}/${idRisco}`)
    .then((response: AxiosResponse<RiscoAsoView>) => response.data)
}
export const createRisco = (
  idEmpresaCliente: ID,
  idCargo: ID,
  idSetor: ID,
  fatorRisco: string, //FatorRiscoType
  descricao: string
): Promise<RiscoAsoView | undefined> => {
  return axios.post(`${RISCOS_CLIENTE_URL}/${idEmpresaCliente}`, {
    cargo: idCargo,
    setor: idSetor,
    fatorRisco,
    descricao,
  })
}

export const editRisco = (
  idEmpresaCliente: ID,
  idCargo: ID,
  idSetor: ID,
  fatorRisco: string, //FatorRiscoType
  descricao: string
): Promise<RiscoAsoView | undefined> => {
  return axios.put(`${RISCOS_CLIENTE_URL}/${idEmpresaCliente}`, {
    cargo: idCargo,
    setor: idSetor,
    fatorRisco,
    descricao,
  })
}
export const deleteRiscoCliente = (idRisco: ID): Promise<string> => {
  return axios.delete(`${RISCOS_CLIENTE_URL}/${idRisco}`)
}

export const deleteLoteRiscosCliente = async (riscoIds: Array<ID>): Promise<void> => {
  try {
    const requests = riscoIds.map((id) => axios.delete(`${RISCOS_CLIENTE_URL}/${id}`))
    await Promise.all(requests)
  } catch (error) {
    console.error('Erro deletando riscos', error)
    throw error
  }
}
