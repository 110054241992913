import { useListView } from '../../core/ListViewProvider'
import { Button, SelectionHeaderContainer } from '../../../../../../components'
import { CargoType, IconPaths } from '../../../../../../../_metronic/helpers'
import { useAuth } from '../../../../../auth'
import Swal from 'sweetalert2'
import { downloadAvaliacao, downloadAvaliacaoLote } from '../../core/_requests'

export const AvaliacoesConcluidasListGrouping = () => {
  const { currentUser } = useAuth()
  const { selected } = useListView()

  const canAccess =
    currentUser?.vinculos?.some(
      (vinculo) =>
        vinculo.cargo === CargoType.Colaborador || vinculo.cargo === CargoType.Responsavel
    ) || false

  const handleDownload = () => {
    selected.length > 1
      ? downloadAvaliacaoLote(selected.map((item) => item.avaliacaoId))
          .then((anexos) => {
            anexos.forEach((anexo) => {
              if (anexo !== undefined) {
                var a = document.createElement('a')
                a.href =
                  'data:application/pdf;base64,' + (anexo !== undefined ? anexo.contentBase64 : '')
                a.download = String(anexo.name)
                a.click()
              }
            })
          })
          .catch((error) => {
            const errors = error.response.data.data.message
            console.log(error.response.data)

            Swal.fire({
              text: `Não foi possível realizar o download: ${errors}.`,
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          })
      : downloadAvaliacao(selected[0].atendimentoId, selected[0].formularioId)
          .then((anexo) => {
            if (anexo !== undefined) {
              var a = document.createElement('a')
              a.href =
                'data:application/pdf;base64,' + (anexo !== undefined ? anexo.contentBase64 : '')
              a.download = String(anexo.name)
              a.click()
            }
          })
          .catch((error) => {
            const errors = error.response.data.data.message
            console.log(error.response.data)

            Swal.fire({
              text: `Não foi possível realizar o download: ${errors}.`,
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          })
  }

  const isPlural = () => selected.length > 1

  return (
    <SelectionHeaderContainer selected={selected}>
      {canAccess ? (
        <Button className='btn-secondary' onClick={handleDownload} icon={IconPaths.Download}>
          {isPlural() ? 'Baixar Avaliações' : 'Baixar Avaliação'}
        </Button>
      ) : null}
    </SelectionHeaderContainer>
  )
}
