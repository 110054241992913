import axios, { AxiosResponse } from 'axios'
import { AtendimentoView } from '../../apps/atendimento/core/_models'
import { AuthTokenResponse } from '../../auth'

const API_URL = process.env.REACT_APP_API_URL

export const LOGIN_URL = `${API_URL}/auth/token`
const ATENDIMENTO_URL = `${API_URL}/public/paciente/atendimento`
const TERMS_URL = `${API_URL}/public/paciente/termos`

export function loginByToken(cpf: string, protocolo: string): Promise<AuthTokenResponse> {
  return axios
    .post<AuthTokenResponse>(LOGIN_URL, {
      cpf,
      protocolo,
    })
    .then((retorno: AxiosResponse<AuthTokenResponse>) => {
      return retorno.data
    })
}

export function acceptTerms(protocolo: string): Promise<void> {
  const pacienteToken = localStorage.getItem('pacienteToken')

  if (!pacienteToken) {
    return Promise.reject('Token de paciente não encontrado')
  }

  const headers = {
    Authorization: `Bearer ${pacienteToken}`,
  }

  return axios
    .patch(`${TERMS_URL}/${protocolo}`, {}, { headers })
    .then((response: AxiosResponse<AtendimentoView>) => response.data)
    .catch((error) => {
      return error
    })
}

export const getAtendimentoByProtocolo = (
  protocolo: string
): Promise<AtendimentoView | undefined> => {
  const pacienteToken = localStorage.getItem('pacienteToken')

  if (!pacienteToken) {
    return Promise.reject('Token de paciente não encontrado')
  }

  const headers = {
    Authorization: `Bearer ${pacienteToken}`,
  }

  return axios
    .get(`${ATENDIMENTO_URL}/${protocolo}`, { headers })
    .then((response: AxiosResponse<AtendimentoView>) => response.data)
    .catch((error) => {
      console.error('Erro ao buscar atendimento:', error)
      if (error.response && error.response.status === 403) {
        return Promise.reject(error)
      } else {
        return undefined
      }
    })
}
