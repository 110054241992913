import { FC } from 'react'
import { useStopwatch } from 'react-timer-hook'

type Props = {
  expiryTimestamp: Date
}

export const Cronometro: FC<Props> = ({ expiryTimestamp }) => {
  const { days, seconds, minutes, hours, isRunning } = useStopwatch({
    autoStart: true,
    offsetTimestamp: expiryTimestamp,
  })

  return (
    <div className='card h-md-100 me-2'>
      <p className='fw-bold fs-5'>{isRunning ? 'Em andamento' : 'Finalizado'}</p>
      <div className='' style={{ fontSize: '20px' }}>
        <span>
          {days}d:{hours}h
        </span>
        :<span>{minutes}m</span>:<span>{seconds}s</span>
      </div>
    </div>
  )
}
