import { Column } from 'react-table'
import { AvaliacoesConcluidasSelectionHeader } from './AvaliacoesConcluidasSelectionHeader'
import { AvaliacoesConcluidasSelectionCell } from './AvaliacoesConcluidasSelectionCell'
import { AvaliacoesConcluidasActionsCell } from './AvaliacoesConcluidasActionsCell'
import { FormularioAtendimentoList } from '../../../avaliacoes-pendentes-list/core/_models'
import { CustomHeader } from '../../../../commons/table/CustomHeader'
import { useQueryRequest } from '../../core/QueryRequestProvider'

export const avaliacoesConcluidasColumns: ReadonlyArray<Column<FormularioAtendimentoList>> = [
  {
    Header: (props) => <AvaliacoesConcluidasSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({ ...props }) => (
      <AvaliacoesConcluidasSelectionCell
        atendimentoId={props.row.original.atendimento.id}
        formularioId={props.row.original.formulario.id}
        avaliacaoId={props.row.original.id}
      />
    ),
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title='Paciente'
        className='min-w-125px'
        useQueryRequest={useQueryRequest}
      />
    ),
    id: 'atendimento.paciente.pessoa.nome',
    accessor: (row) => row.paciente.nome,
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title='Protocolo'
        className='min-w-125px'
        useQueryRequest={useQueryRequest}
      />
    ),
    id: 'atendimento.protocolo',
    accessor: (row) => row.atendimento.protocolo,
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title='Avaliação'
        className='min-w-125px'
        useQueryRequest={useQueryRequest}
      />
    ),
    id: 'formulario.nome',
    accessor: (row) => row.formulario.nome,
  },

  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title='Ações'
        className='text-end min-w-100px'
        useQueryRequest={useQueryRequest}
      />
    ),
    id: 'actions',
    Cell: ({ ...props }) => (
      <AvaliacoesConcluidasActionsCell formularioAtendimento={props.row.original} />
    ),
  },
]
