import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { AvaliacoesPendentesListWrapper } from './avaliacoes-pendentes-list/AvaliacoesPendentesList'
import { AvaliacoesConcluidasListWrapper } from './avaliacoes-concluidas-list/AvaliacoesConcluidasList'

const usersBreadcrumbs: Array<PageLink> = [
  /* {
    title: 'Avaliações',
    path: '/apps/avaliacoes',
    isSeparator: false,
    isActive: false,
  }, */
  /* {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  }, */
]

const AvaliacoesPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='pendentes/lista'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Avaliações Pendentes</PageTitle>
              <AvaliacoesPendentesListWrapper />
            </>
          }
        />
        <Route
          path='concluidas/lista'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Avaliações Concluídas</PageTitle>
              <AvaliacoesConcluidasListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/apps/avaliacoes' />} />
    </Routes>
  )
}

export default AvaliacoesPage
