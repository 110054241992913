import { useListView } from '../../core/ListViewProvider'
import { EmpresasClienteListGrouping } from './EmpresasClienteListGrouping'
import { EmpresasClienteListSearchComponent } from './EmpresasClienteListSearchComponent'
import { EmpresasClienteListToolbar } from './EmpresasClienteListToolbar'

const EmpresasClienteListHeader = () => {
  const { selected } = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <EmpresasClienteListSearchComponent />
      <div className='card-toolbar'>
        {selected.length > 0 ? <EmpresasClienteListGrouping /> : <EmpresasClienteListToolbar />}
      </div>
    </div>
  )
}

export { EmpresasClienteListHeader }
