import { AxiosError } from 'axios'
import Swal, { SweetAlertResult } from 'sweetalert2'
import { ID } from './crud-helper/models'

export const alertCertificado = async (
  onConfirm: Function,
  selected: { formularioId: ID; atendimentoId: ID } | ID[]
) => {
  const result = await Swal.fire({
    title: 'Digite a senha do Certificado',
    input: 'password',
    icon: 'info',
    showCancelButton: true,
    confirmButtonText: 'Enviar',
    cancelButtonText: 'Cancelar',
    reverseButtons: true,
    buttonsStyling: false,
    customClass: {
      confirmButton: 'btn btn-primary',
      cancelButton: 'btn btn-secondary',
    },
    inputAttributes: {
      maxlength: '16',
      autocapitalize: 'off',
      autocorrect: 'off',
    },
    preConfirm: (senha) => {
      if (!senha || senha.length <= 6) {
        Swal.showValidationMessage(`A senha deve conter no mínimo 6 caracteres`)
      } else {
        Swal.showLoading()
      }
      return senha
    },
  })

  if (result.isConfirmed && result.value) {
    try {
      await onConfirm(selected, result.value)
    } catch (error) {
      throw error
    }
    Swal.hideLoading()
  }
}

export const onDeleteAlert = async (deleteAction: Function) => {
  const result = await Swal.fire({
    title: 'Você tem certeza?',
    text: 'Essa ação não pode ser desfeita!',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Sim, excluir!',
    cancelButtonText: 'Cancelar',
    reverseButtons: true,
    customClass: {
      confirmButton: 'btn btn-danger',
      cancelButton: 'btn btn-secondary',
    },
    buttonsStyling: false,
  })

  if (result.isConfirmed) {
    await deleteAction()
  }
}

export const showAlert = (options: {
  title: string
  text: string
  icon?: 'success' | 'error' | 'warning' | 'info' | 'question'
  showLoading?: boolean
}): Promise<SweetAlertResult<any>> => {
  return Swal.fire({
    title: options.title,
    text: options.text,
    icon: options.icon,
    buttonsStyling: false,
    confirmButtonText: 'Ok',
    customClass: { confirmButton: 'btn btn-primary' },
    ...(options.showLoading && {
      showConfirmButton: false,
      allowOutsideClick: false,
      didOpen: () => Swal.showLoading(),
    }),
  })
}
