import { FC, useState, createContext, useContext } from 'react'
import { WithChildren } from '../../../../../../_metronic/helpers'
import {
  initialQueryRequest,
  QueryRequestContextPropsRelatorios,
  QueryStateRelatorios,
} from './_models'

const QueryRequestContext = createContext<QueryRequestContextPropsRelatorios>(initialQueryRequest)

const QueryRequestProvider: FC<WithChildren> = ({ children }) => {
  const [state, setState] = useState<QueryStateRelatorios>(initialQueryRequest.state)

  const updateState = (updates: Partial<QueryStateRelatorios>) => {
    const updatedState = { ...state, ...updates } as QueryStateRelatorios
    setState(updatedState)
  }

  return (
    <QueryRequestContext.Provider value={{ state, updateState }}>
      {children}
    </QueryRequestContext.Provider>
  )
}

const useQueryRequest = () => useContext(QueryRequestContext)
export { QueryRequestProvider, useQueryRequest }
