import { QUERIES, isNotEmpty } from '../../../../../../_metronic/helpers'

import { FC, useState } from 'react'
import { PacienteEditModalForm } from './PacienteEditModalForm'
import { getPacienteById } from '../core/_requests'
import { useListView } from '../core/ListViewProvider'
import { useQuery } from 'react-query'

type Props = {
  cpfFind: string
}
const PacienteEditModalFormWrapper: FC<Props> = ({ cpfFind }) => {
  const { itemIdForUpdate, setItemIdForUpdate } = useListView()

  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: user,
    error,
  } = useQuery(
    `${QUERIES.USERS_LIST}-user-${itemIdForUpdate}`,
    () => {
      return getPacienteById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return <PacienteEditModalForm isPacienteLoading={isLoading} paciente={user} cpf={cpfFind} />
  }

  if (!isLoading && !error && user) {
    return <PacienteEditModalForm isPacienteLoading={isLoading} paciente={user} cpf={cpfFind} />
  }

  return null
}

export { PacienteEditModalFormWrapper }
