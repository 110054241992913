import { useEffect, useMemo, useState } from 'react'
import { useTable, ColumnInstance, Row } from 'react-table'
import { useQueryResponse, useQueryResponseData } from '../core/QueryResponseProvider'
import { KTCardBody, stringifyRequestQuery } from '../../../../../../_metronic/helpers'
import { ListLoading } from '../../../commons/components/ListLoading'
import { PersonalAtendimentosListPagination } from '../component/pagination/PersonalAtendimentosListPagination'

import { personalAtendimentosColumns } from './columns/_columns'
import { AtendimentoView } from '../../../atendimento/core/_models'
import { AtendimentoResumo } from '../core/_models'
import { getAtendimentosResumo } from '../core/_requests'
import { InfoHeader } from '../../../../../components'
import { CustomHeaderColumn } from '../../../commons/table/CustomHeaderColumn'
import { CustomRow } from '../../../commons/table/CustomRow'
import { useQueryRequest } from '../core/QueryRequestProvider'

const PersonalAtendimentosTable = () => {
  const { state } = useQueryRequest()
  const atendimentos = useQueryResponseData()
  const { isLoading } = useQueryResponse()
  const data = useMemo(() => atendimentos, [atendimentos])
  const columns = useMemo(() => personalAtendimentosColumns, [])
  const [infos, setInfos] = useState<AtendimentoResumo | undefined>(undefined)

  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns,
    data,
  })

  useEffect(() => {
    getAtendimentosResumo(stringifyRequestQuery(state)).then((data) => setInfos(data))
  }, [state])

  return (
    <KTCardBody className='py-4'>
      {isLoading && infos === undefined ? (
        <ListLoading />
      ) : (
        <div>
          {infos && <InfoHeader infos={infos} />}

          <div className='table-responsive'>
            <table
              id='kt_table_meus_atendimentos'
              className='table align-middle table-row-bordered fs-6 gy-5 dataTable no-footer'
              {...getTableProps()}
            >
              <thead>
                <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0 border-secondary'>
                  {headers.map((column: ColumnInstance<AtendimentoView>) => (
                    <CustomHeaderColumn key={column.id} column={column} />
                  ))}
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
                {rows.length > 0 ? (
                  rows.map((row: Row<AtendimentoView>, i) => {
                    prepareRow(row)
                    return <CustomRow row={row} key={`row-${i}-${row.id}`} />
                  })
                ) : (
                  <tr>
                    <td colSpan={columns.length}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        Nenhum registro encontrado
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}
      <PersonalAtendimentosListPagination />
    </KTCardBody>
  )
}

export { PersonalAtendimentosTable }
