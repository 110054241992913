import { useQuery } from 'react-query'
import { isNotEmpty, QUERIES } from '../../../../../../_metronic/helpers'
import { useListView } from '../core/ListViewProvider'
import { EmpresaClienteEditModalForm } from './EmpresaClienteEditModalForm'
import { getEmpresaClienteById } from '../core/_request'

const EmpresaClienteEditModalFormWrapper = () => {
  const { itemIdForUpdate, setItemIdForUpdate } = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: empresa,
    error,
  } = useQuery(
    `${QUERIES.EMPRESAS_LIST}-empresa-${itemIdForUpdate}`,
    () => {
      return getEmpresaClienteById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return <EmpresaClienteEditModalForm isEmpresaLoading={isLoading} cliente={undefined} />
  }

  if (!isLoading && !error && empresa) {
    return <EmpresaClienteEditModalForm isEmpresaLoading={isLoading} cliente={empresa} />
  }

  return null
}

export { EmpresaClienteEditModalFormWrapper }
