import { EmpresasListToolbar } from './EmpresasListToolbar'
import { EmpresasListGrouping } from './EmpresasListGrouping'
import { EmpresasListSearchComponent } from './EmpresasListSearchComponent'
import { useListView } from '../../core/ListViewProvider'

const EmpresasListHeader = () => {
  const { selected } = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <EmpresasListSearchComponent />
      <div className='card-toolbar'>
        {selected.length > 0 ? <EmpresasListGrouping /> : <EmpresasListToolbar />}
      </div>
    </div>
  )
}

export { EmpresasListHeader }
