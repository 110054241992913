/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { initialQueryState, useDebounce } from '../../../../../../../_metronic/helpers'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { Input, Label } from '../../../../../../components'

const EmpresasListSearchComponent = () => {
  const { updateState } = useQueryRequest()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const debouncedSearchTerm = useDebounce(searchTerm, 150)

  useEffect(() => {
    if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
      updateState({
        search: debouncedSearchTerm,
        sort: 'nomeFantasia',
        order: 'asc',
        ...initialQueryState,
      })
    }
  }, [debouncedSearchTerm])
  const handleChangeNome = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(
      String(e.target.value) !== ''
        ? 'nome~*' +
            String(e.target.value) +
            '*' +
            ",'nomeFantasia~*" +
            String(e.target.value) +
            '*'
        : ''
    )
  }

  return (
    <div>
      <Label className='pb-2'>Empresa</Label>
      <Input className='w-550px' placeholder='Digite o nome' onChange={handleChangeNome} />
    </div>
  )
}

export { EmpresasListSearchComponent }
