import axios from 'axios'
import { ID } from '../../../../../../_metronic/helpers'
import { ApiTokenReponse } from './_models'

const API_URL = process.env.REACT_APP_API_URL
const EMPRESA_URL = `${API_URL}/empresas`

export const webhook = (idEmpresa: ID, url: string): Promise<undefined> => {
  return axios
    .put(`${EMPRESA_URL}/${idEmpresa}`, { apiWebhook: url })
    .then((response) => response.data)
}

export const gerarToken = (idEmpresa: ID): Promise<ApiTokenReponse> => {
  return axios.post(`${EMPRESA_URL}/integracao/${idEmpresa}`).then((response) => response.data)
}
