// @ts-nocheck

import { Column } from 'react-table'
import { ImportacaoDetalheCustomHeader } from './ImportacaoDetalheCustomHeader'

const importacoesDetalhesColumns: ReadonlyArray<Column<ImportacaoDetalhe>> = [
  {
    Header: (props) => (
      <ImportacaoDetalheCustomHeader tableProps={props} title='Protocolo de atendimento'  />
    ),
    id: 'atendimento',
    accessor: 'atendimento',
  },
  {
    Header: (props) => <ImportacaoDetalheCustomHeader tableProps={props} title='Prontuário'  />,
    id: 'prontuario',
    accessor: 'prontuario'
  },
  {
    Header: (props) => (
      <ImportacaoDetalheCustomHeader tableProps={props} title='Paciente'  />
    ),
    id: 'paciente',
    accessor: 'paciente',
  },
]

export { importacoesDetalhesColumns }
