/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react'
import {
  AnexoAtendimentoView,
  AtendimentoView,
  InformacaoAtendimentoView,
  PrescricaoAtendimentoView,
  ProcedimentoAtendimentoView,
} from '../../atendimento/core/_models'
import { downloadAnexo, getAtendimentoById } from '../../atendimento/core/_requests'
import { ID, KTSVG, getArquivoAnexo, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import Anotacao from './table/Anotacao'

type Props = {
  idAtendimento?: Number
}

const HistoricoDetalhes: FC<Props> = ({ idAtendimento }) => {
  const [atendimentoInfo, setAtendimentoInfo] = useState<AtendimentoView | undefined>(undefined)
  const [loading, setLoading] = useState(false)

  function getAtendimento() {
    if (Number.isInteger(idAtendimento)) {
      getAtendimentoById(Number(idAtendimento))
        .then((result) => {
          setAtendimentoInfo(result)
        })
        .catch((error) => {
          alert(error)
        })
    }
  }

  const getNomeByAnexoId = (
    anexo: AnexoAtendimentoView,
    informacoes: InformacaoAtendimentoView[] = [],
    prescricoes: PrescricaoAtendimentoView[] = [],
    procedimentos: ProcedimentoAtendimentoView[] = []
  ) => {
    for (const info of informacoes) {
      if (info.anexo === anexo.id) {
        return info.descricao
      }
    }

    if (anexo.tipo?.descricao === 'PRESCRICAO') {
      return 'Prescrição'
    }

    for (const procedimento of procedimentos) {
      if (procedimento.anexo === anexo.id) {
        return procedimento.nome
      }
    }

    return anexo.tipo?.descricao
  }

  useEffect(() => {
    // callApi() { ":", "!", ">", "<", "~" }
    getAtendimento()
  }, [idAtendimento])
  return (
    <div
      className='card mb-5 mb-xl-10'
      data-kt-scroll='true'
      data-kt-scroll-height='auto'
      data-kt-scroll-offset='5px'
    >
      <div className='col-lg-12 col-xl-5 mb-5 mb-xl-0'>
        <div className='fv-row mb-5 '>
          <label className='col-lg-8 fw-bold text-muted'>Nome do paciente</label>

          <div className='col-lg-8'>
            <span className='fw-bolder fs-6 text-dark'>{atendimentoInfo?.paciente?.nome}</span>
          </div>
        </div>
        <div className='fv-row mb-5'>
          <label className='col-lg-8 fw-bold text-muted'>Criado por</label>

          <div className='col-lg-8'>
            <span className='fw-bolder fs-6 text-dark'>{atendimentoInfo?.criador?.nome}</span>
          </div>
        </div>
        <div className='fv-row mb-5'>
          <label className='col-lg-8 fw-bold text-muted'>Responsável</label>

          <div className='col-lg-8'>
            <span className='fw-bolder fs-6 text-dark'>
              {atendimentoInfo?.responsavel?.nome || 'Sem responsável'}
            </span>
          </div>
        </div>
      </div>

      <div className='fv-row mb-12'>
        <label className='col-lg-4 fw-bold text-muted'>CID</label>
        <div className='col-lg-8 fv-row'>
          {atendimentoInfo?.cids ? (
            atendimentoInfo.cids.map((c, index) => (
              <span key={index} className='fw-bold fs-6'>
                {c.cid.codigo} - {c.cid.descricao}
              </span>
            ))
          ) : (
            <span className='fw-bold fs-6'>Não definido</span>
          )}
        </div>
      </div>
      <div className='fv-row mb-5'>
        <label className='col-lg-10 fw-bold text-muted'>Data da Solicitação</label>

        <div className='fv-row mb-5'>
          <span className='fw-bold fs-6'>
            {atendimentoInfo?.inicio !== 'null'
              ? String(atendimentoInfo?.inicioFormatado)
              : String(atendimentoInfo?.solicitacaoFormatada)}
          </span>
        </div>
      </div>

      <div className='fv-row mb-12'>
        <label className='col-lg-4 fw-bold text-muted'>Estado</label>

        <div className='col-lg-8 fv-row'>
          <span className='fw-bold fs-6'>{atendimentoInfo?.estado?.descricao}</span>
        </div>
      </div>

      <div className='fv-row mb-1'>
        <label className='col-lg-4 fw-bold text-muted'>Informações equipamentos</label>
        <div className='d-flex flex-row-fluid'>
          <ul>
            {atendimentoInfo?.informacoes?.map((info) => (
              <li>{info.descricao + ' - ' + info.valor + info.unidade}</li>
            ))}
          </ul>
        </div>
      </div>

      <div className='fv-row mb-1'>
        {atendimentoInfo?.anotacoes && (
          <>
            <label className='col-lg-4 fw-bold text-muted'>Anotações</label>
            <div className='d-flex flex-row-fluid'>
              <ul>
                {atendimentoInfo?.anotacoes?.map((anot) => {
                  return <Anotacao nota={anot} />
                })}
              </ul>
            </div>
          </>
        )}
      </div>
      <div className='col-lg-8 col-xl-8 mb-5 mb-xl-0'>
        <div className='fv-row mb-1'>
          <label className='col-lg-8 fw-bold text-muted'>Prescrição/Medicamentos</label>
          <div className='d-flex flex-row-fluid'>
            <ul>
              {atendimentoInfo?.prescricoes?.map((p) =>
                p.medicamentos?.map((m) => <li>{m.nome + ' - ' + m.posologia}</li>)
              )}
            </ul>
          </div>
        </div>
        <div className='fv-row mb-1'>
          <label className='col-lg-8 fw-bold text-muted'>Prescrição/Exames</label>
          <div className='d-flex flex-row-fluid'>
            <ul>
              {atendimentoInfo?.procedimentos?.map((p) => (
                <li>{p.nome}</li>
              ))}
            </ul>
          </div>
        </div>
        <div className='fv-row mb-1'>
          <label className='col-lg-8 fw-bold text-muted'>Anexos</label>
          <div className='d-flex flex-row-fluid'>
            <ul>
              {atendimentoInfo?.anexos?.map((p) => {
                const nome = getNomeByAnexoId(
                  p,
                  atendimentoInfo.informacoes,
                  atendimentoInfo?.prescricoes,
                  atendimentoInfo.procedimentos
                )

                if (
                  p?.tipo?.descricao === 'Atestado de Saúde Ocupacional' &&
                  !p?.url?.endsWith('_assinado.pdf')
                ) {
                  return null
                }

                return (
                  <button
                    key={p.id}
                    className='btn btn-light btn-active-light-primary btn-sm'
                    onClick={() => getArquivoAnexo(atendimentoInfo.id, p.id)}
                  >
                    <span className='indicator-label'>{`${nome}: ${p.url}`}</span>
                    <KTSVG
                      path='/media/icons/duotune/files/fil021.svg'
                      className='svg-icon-5 m-0'
                    />
                  </button>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export { HistoricoDetalhes }
