import { useListView } from '../../core/ListViewProvider'
import { DownloadAsoListGrouping } from './DownloadAsoListGrouping'
import { DownloadAsoListSearchComponent } from './DownloadAsoListSearchComponent'
import { DownloadAsoListToolbar } from './DownloadAsoListToolbar'

interface DownloadAsoListHeaderProps {}

const DownloadAsoListHeader: React.FC<DownloadAsoListHeaderProps> = () => {
  const { selected } = useListView()

  return (
    <div className='border-0 pt-6'>
      <DownloadAsoListSearchComponent />
      <div className='card-toolbar'>
        {selected.length > 0 ? <DownloadAsoListGrouping /> : <DownloadAsoListToolbar />}
      </div>
    </div>
  )
}

export { DownloadAsoListHeader }
