import React from 'react'

import { AsideMenuAtendimentoMain } from './AsideMenuAtendimentoMain'
import clsx from 'clsx'
import { AtendimentoView } from '../../../atendimento/core/_models'

type Props = {
  asideMenuCSSClasses: string[]
  atendimento: AtendimentoView
}

const AsideMenuAtendimento: React.FC<Props> = ({ asideMenuCSSClasses, atendimento }) => {
  return (
    <div id='kt_aside_menu_atendimento_wrapper' className='px-2 my-lg-1'>
      <div
        id='#kt_aside_menu'
        className={clsx(
          'menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500',
          asideMenuCSSClasses.join(' ')
        )}
      >
        <AsideMenuAtendimentoMain atendimento={atendimento} />
      </div>
    </div>
  )
}

export { AsideMenuAtendimento }
