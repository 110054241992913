import { KTCard } from '../../../../../_metronic/helpers'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'
import { ListViewProvider } from './core/ListViewProvider'
import { AvaliacoesPendentesListHeader } from './components/header/AvaliacoesPendentesListHeader'
import { AvaliacoesPendentesTable } from './table/AvaliacoesPendentesTable'

const AvaliacoesPendentesList = () => {
  return (
    <>
      <KTCard>
        <AvaliacoesPendentesListHeader />
        <AvaliacoesPendentesTable />
      </KTCard>
    </>
  )
}

export const AvaliacoesPendentesListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <AvaliacoesPendentesList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)
