export const buildErrorMessage = (errorData: any) => {
  let message = `<p>${errorData.message || 'Um erro ocorreu.'}</p>`

  if (errorData.errors) {
    const errorDetails = []

    for (const key in errorData.errors) {
      if (Array.isArray(errorData.errors[key])) {
        const attributeErrors = errorData.errors[key]
          .map((error: string) => `<strong>${key}:</strong> ${error}`)
          .join('<br><br>')
        errorDetails.push(`<div>${attributeErrors}</div><br>`)
      }
    }

    if (errorDetails.length > 0) {
      message += `${errorDetails.join('<hr>')}</div>`
    }
  }

  return message
}
