/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useRef, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { MenuComponent } from '../../../../../../../_metronic/assets/ts/components'
import { IconPaths, IconSize, QUERIES } from '../../../../../../../_metronic/helpers'
import { useListView } from '../../core/ListViewProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import Swal from 'sweetalert2'
import { AxiosError } from 'axios'
import { CancelarAtendimentoModal } from '../../component/CancelarAtendimentoModal/CancelaratendimentoModal'
import { AtendimentoView } from '../../../../atendimento/core/_models'
import { Modal } from 'react-bootstrap'
import { useAuth } from '../../../../../auth'
import { MarcarFaltaModal } from '../../component/MarcarFaltaModal/MarcarFaltaModal'
import { ReabrirAtendimentoModal } from '../../component/ReabrirAtendimentoModal/ReabrirAtendimentoModal'
import { ProcedimentoAso } from '../../../../atendimento-ASO/Procedimento'
import {
  downloadProntuario,
  getAtendimentoById,
  deleteAtendimento,
} from '../../../../atendimento/core/_requests'
import { MarcarPresencaModal } from '../../component/MarcarPresencaModal/MarcarPresencaModal'
import { Button, Dropdown, DropdownItem, Input } from '../../../../../../components'
import { AnotacaoModal } from '../../atendimento-medico-anotacao-modal/AnotacaoModal'
type Props = {
  atendimento: AtendimentoView
}

const AtendimentoMedicoActionsCell: FC<Props> = ({ atendimento }) => {
  const modalRef = useRef<{ open: () => void; close: () => void }>(null)

  const { setItemIdForUpdate } = useListView()
  const { query, setShouldRefetch, refetch } = useQueryResponse()
  const queryClient = useQueryClient()
  const { currentUser } = useAuth()
  const [showFaltaAtendimento, setShowFaltaAtendimento] = useState(false)
  const [showCancelarAtendimento, setShowCancelarAtendimento] = useState(false)
  const [showReabrirAtendimento, setShowReabrirAtendimento] = useState(false)
  const [showMarcarPresenca, setShowMarcarPresenca] = useState(false)

  const isCancelado = atendimento.estado?.nome === 'Cancelado'
  const isAso = atendimento.tipo === 'ASO'
  const isADM =
    currentUser?.grupo?.nome === 'SUPER_MEDICO' || currentUser?.grupo?.nome === 'SUPER_ADMIN'
  const isMedico =
    !(currentUser?.grupo?.nome === 'SUPER_MEDICO') &&
    currentUser?.vinculos?.some((v) => v.cargo === 'MEDICO')

  const [atendimentoExame, setAtendimentoExame] = useState<AtendimentoView | undefined>(atendimento)
  const [showProcedimento, setShowProcedimento] = useState(false)
  const handleCloseProcedimento = () => {
    setShouldRefetch && setShouldRefetch(true)
    refetch()
    setShowProcedimento(false)
  }
  const handleShowProcedimento = () => {
    setShouldRefetch && setShouldRefetch(false)
    setShowProcedimento(true)
  }

  const handleCloseAnotacao = () => {
    setShouldRefetch && setShouldRefetch(true)
    refetch()
  }
  const handleShowAnotacao = () => {
    modalRef.current?.open()
    setShouldRefetch && setShouldRefetch(false)
  }

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const handleCancelarAtendimento = () => {
    setShowCancelarAtendimento((prev) => !prev)
  }

  const handleFaltouAtendimento = () => {
    setShowFaltaAtendimento((prev) => !prev)
  }

  const handleDownloadProntuario = () => {
    downloadProntuario(atendimento.id)
      .then((anexo) => {
        if (anexo !== undefined) {
          var a = document.createElement('a') //Create <a>
          a.href = 'data:application/pdf;base64,' + (anexo !== undefined ? anexo.contentBase64 : '') //Image Base64 Goes here
          a.download = String(anexo.name) //File name Here
          a.click()
        }
      })
      .catch((error) => {
        const errors = error.response.data.data.message

        Swal.fire({
          text: `Não foi possível realizar o download: ${errors}.`,
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      })
  }

  const handleReabrirAtendimento = () => {
    setShowReabrirAtendimento((prev) => !prev)
  }

  const handleMarcarPresenca = () => {
    setShowMarcarPresenca((prev) => !prev)
  }

  const openEditModal = () => {
    setItemIdForUpdate(atendimento.id)
  }
  const defineAtendimento = () => {
    getAtendimentoById(atendimento?.id).then((a) => {
      setAtendimentoExame(a)
      // verificaEstadoAso(a?.id)
    })
  }

  const deleteItem = useMutation(() => deleteAtendimento(atendimento.id), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.ATENDIMENTO_MEDICO_LIST}-${query}`])
    },
    onError(error, variables, context) {
      const err = error as AxiosError
      Swal.fire({
        text: err.response?.data.data.message,
        icon: 'error',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  })

  return (
    <>
      <div className='d-flex justify-content-end'>
        <div className='me-2'>
          <Button
            className='btn-primary btn-sm fs-7'
            iconSize={IconSize.Small}
            icon={IconPaths.Search}
            onClick={openEditModal}
          >
            Visualizar
          </Button>
        </div>
        <Dropdown name='Ações' size={175}>
          {atendimento?.presencaPacienteFormatada === null && (
            <DropdownItem onClick={handleMarcarPresenca}>Marcar presença</DropdownItem>
          )}
          {!isCancelado && (
            <DropdownItem onClick={handleCancelarAtendimento}> Cancelar atendimento</DropdownItem>
          )}

          <DropdownItem onClick={handleFaltouAtendimento}>Marcar falta paciente</DropdownItem>
          {atendimento?.estado?.nome === 'Finalizado' && (
            <DropdownItem onClick={handleDownloadProntuario}>Baixar atendimento</DropdownItem>
          )}
          {atendimento?.estado?.nome !== 'Iniciado' &&
            atendimento?.estado?.nome !== 'Aguardando' && (
              <DropdownItem onClick={handleReabrirAtendimento}>Reabrir atendimento</DropdownItem>
            )}
          <DropdownItem onClick={handleShowProcedimento}>Inserir Exames</DropdownItem>

          {atendimento?.estado?.nome == 'Pendente' ? (
            <DropdownItem onClick={handleShowAnotacao}>Resolver Pendência</DropdownItem>
          ) : (
            <DropdownItem onClick={handleShowAnotacao}>Marcar como Pendente</DropdownItem>
          )}
        </Dropdown>
      </div>

      {showCancelarAtendimento && (
        <CancelarAtendimentoModal
          atendimento={atendimento}
          showCancelarAtendimento={showCancelarAtendimento}
          onClose={handleCancelarAtendimento}
        />
      )}

      {showFaltaAtendimento && (
        <MarcarFaltaModal
          atendimento={atendimento}
          showMarcarFalta={showFaltaAtendimento}
          onClose={handleFaltouAtendimento}
        />
      )}

      {showMarcarPresenca && (
        <MarcarPresencaModal
          atendimento={atendimento}
          showMarcarPresenca={showMarcarPresenca}
          onClose={handleMarcarPresenca}
          isMedico={isMedico}
        />
      )}

      {showReabrirAtendimento && (
        <ReabrirAtendimentoModal
          atendimento={atendimento}
          showReabrirAtendimento={showReabrirAtendimento}
          onClose={handleReabrirAtendimento}
        />
      )}
      <AnotacaoModal
        ref={modalRef}
        onHide={handleCloseAnotacao}
        isRequired={atendimento.estado?.nome !== 'Pendente'}
        atendimentoId={atendimento.id}
      />
      <Modal className='modal-xl' show={showProcedimento} onHide={handleCloseProcedimento}>
        <Modal.Header closeButton>
          <Modal.Title>Exames</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ProcedimentoAso
            atendimento={atendimentoExame}
            defineAtendimento={defineAtendimento}
            onClose={handleCloseProcedimento}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}

export { AtendimentoMedicoActionsCell }
