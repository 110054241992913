import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import { AnotacaoAtendimentoView } from '../../../atendimento/core/_models'

interface AnnotationProps {
  nota: AnotacaoAtendimentoView
}

const Anotacao: React.FC<AnnotationProps> = ({ nota }) => {
  return (
    <ul>
      <li>
        {nota.tipo}
        {' - '}
        {ReactHtmlParser(nota.anotacao as string)}
      </li>
    </ul>
  )
}

export default Anotacao
