import { ColumnInstance, Row, useTable } from 'react-table'
import { useQueryResponseData, useQueryResponseLoading } from '../../../core/QueryResponseProvider'

import { ImportacaoDetalhe } from '../../../core/_models'
import { ImportacaoDetalheListPagination } from '../pagination/ImportacaoDetalheListPagination'
import { KTCardBody } from '../../../../../../../../_metronic/helpers'
import React from 'react'
import { importacoesDetalhesColumns } from './columns/_columns'
import { useMemo } from 'react'
import { CustomHeaderColumn } from '../../../../../commons/table/CustomHeaderColumn'

const ImportacaoDetalheListTable = () => {
  const detalhesImportacao = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => detalhesImportacao, [detalhesImportacao])
  const columns = useMemo(() => importacoesDetalhesColumns, [])
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns,
    data: data as ImportacaoDetalhe[],
  })
  console.log(rows)
  return (
    <KTCardBody className='py-4'>
      {isLoading ? (
        'Carregando...'
      ) : (
        <div className='table-responsive'>
          <table
            id='kt_table_importacoes'
            className='table align-middle table-row-bordered fs-6 gy-5 dataTable no-footer'
            {...getTableProps()}
          >
            <thead>
              <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0 border-secondary'>
                {headers.map((column: ColumnInstance<ImportacaoDetalhe>) => (
                  <CustomHeaderColumn key={column.id} column={column} />
                ))}
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map((row: Row<ImportacaoDetalhe>, i) => {
                  prepareRow(row)
                  return (
                    <React.Fragment key={`row-${i}-${row.id}`}>
                      <tr>
                        {row.cells.map((cell, j) => {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.column.id === 'prontuario' || cell.column.id === 'paciente' ? (
                                <div className='d-flex flex-column'>
                                  <span>
                                    {cell.column.id === 'prontuario' &&
                                      (row?.original?.prontuario ? row?.original?.prontuario : '-')}
                                  </span>
                                  <span>
                                    {cell.column.id === 'paciente' &&
                                      (row?.original?.paciente ? row?.original?.paciente : '-')}
                                  </span>
                                </div>
                              ) : (
                                cell.render('Cell')
                              )}
                            </td>
                          )
                        })}
                      </tr>
                    </React.Fragment>
                  )
                })
              ) : (
                <tr>
                  <td colSpan={columns.length}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      Nenhum registro encontrado
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
      <ImportacaoDetalheListPagination />
    </KTCardBody>
  )
}

export { ImportacaoDetalheListTable }
