import { useState, useEffect } from 'react'
import {
  IconPaths,
  initialQueryState,
  Status,
  useDebounce,
} from '../../../../../../../_metronic/helpers'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { Button, Label, TextInput } from '../../../../../../components'
import { useListView } from '../../core/ListViewProvider'

const VinculosListSearchComponent = () => {
  const { setItemIdForUpdate } = useListView()
  const { updateState } = useQueryRequest()

  const [nomeSel, setNomeSel] = useState<string>('')

  const [searchTerm, setSearchTerm] = useState<string>('')

  const debouncedSearchTerm = useDebounce(searchTerm, 150)

  useEffect(() => {
    if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
      updateState({
        filter: [],
        search: searchTerm,
        sort: 'nome',
        order: 'asc',
        ...initialQueryState,
      })
    }
  }, [searchTerm, debouncedSearchTerm])

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNomeSel(e.target.value)
    let search = ''

    const searchQuery = e.target.value !== '' ? `especialidade.nome~*${e.target.value}*` : ''

    search = `${searchQuery}`

    setSearchTerm(search)
  }

  const createVinculo = () => setItemIdForUpdate(null)

  return (
    <div className='d-flex flex-column flex-md-row align-items-center justify-content-between col-12'>
      <div className='d-flex flex-column flex-sm-row align-items-center col-12 col-md-10'>
        <div className='mb-5 me-0 me-sm-2 col-12 col-sm-7 col-md-6'>
          <Label className='pb-1'>Especialidade</Label>
          <TextInput placeholder='Digite a especialidade' onChange={onChange} />
        </div>
      </div>

      <div className='col-12 col-md-auto'>
        <Button className='btn-primary' icon={IconPaths.Add} onClick={createVinculo}>
          Criar Vínculo
        </Button>
      </div>
    </div>
  )
}

export { VinculosListSearchComponent }
