import React, { useEffect, useState } from 'react'

interface IDateInputProps {
  type?: string
  name?: string
  placeholder?: string
  value?: string | number | undefined
  required?: boolean
  className?: string
  disabled?: boolean
  onChange?: (value: string) => void
}

export const DateInput: React.FC<IDateInputProps> = ({
  placeholder,
  value: initialValue,
  required,
  className,
  disabled,
  onChange,
}) => {
  const [value, setValue] = useState(initialValue)

  useEffect(() => setValue(initialValue), [initialValue])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
    if (onChange) onChange(e.target.value)
  }

  return (
    <input
      type='date'
      className={`form-control form-control-solid ${className}`}
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
      required={required}
      disabled={disabled}
    />
  )
}

DateInput.defaultProps = {
  name: '',
  placeholder: 'Digite a data inicial',
  value: '',
  required: false,
  disabled: false,
  className: '',
  onChange: () => {},
}
