import React, { useState, useEffect } from 'react'
import { ID, initialQueryState, useDebounce } from '../../../../../../../_metronic/helpers'

import { Input, Label } from '../../../../../../components'
import { useQueryRequest } from '../../core/QueryRequestProvider'

export const AvaliacoesPendentesListSearchComponent = () => {
  const { updateState } = useQueryRequest()

  const [paciente, setPaciente] = useState<string>('')
  const [protocolo, setProtocolo] = useState<string>('')

  const [searchTerm, setSearchTerm] = useState<string>('')

  const debouncedSearchTerm = useDebounce(searchTerm, 150)

  useEffect(() => {
    if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
      updateState({
        search: searchTerm,
        sort: 'atendimento.paciente.pessoa.nome',
        order: 'asc',
        ...initialQueryState,
      })
    }
  }, [debouncedSearchTerm])

  const handlePaciente = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPaciente(e.target.value)
    let search = ''

    const searchQuery =
      e.target.value !== '' ? `atendimento.paciente.pessoa.nome~*${e.target.value}*` : ''

    if (protocolo) search += (search ? ',' : '') + `atendimento.protocolo~*${protocolo}*`

    search = `${searchQuery}`

    setSearchTerm(search)
  }

  const handleProtocolo = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProtocolo(e.target.value)
    let search = ''

    const searchQuery = e.target.value !== '' ? `atendimento.protocolo~*${e.target.value}*` : ''
    if (paciente) search += (search ? ',' : '') + `atendimento.paciente.pessoa.nome~*${protocolo}*`

    search = `${searchQuery}`

    setSearchTerm(search)
  }

  return (
    <div className='row w-100'>
      <div className='col-12 col-md-5 mb-5'>
        <Label className='pb-1'>Paciente</Label>
        <Input placeholder='Digite o paciente' onChange={handlePaciente} />
      </div>
      <div className='col-12 col-md-4 mb-5'>
        <Label className='pb-1'>Protocolo</Label>
        <Input placeholder='Digite o protocolo' onChange={handleProtocolo} />
      </div>
    </div>
  )
}
