import { KTCard } from '../../../../../_metronic/helpers'
import { AtendimentoMedicoEditModal } from './atendimento-medico-edit-modal/AtendimentoMedicoEditModal'
import { AtendimentoMedicoListHeader } from './component/header/AtendimentoMedicoListHeader'
import { ListViewProvider, useListView } from './core/ListViewProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'
import { AtendimentoMedicoTable } from './table/AtendimentoMedicoTable'

const AtendimentoMedicoList = () => {
  const { itemIdForUpdate } = useListView()

  return (
    <>
      <KTCard>
        <AtendimentoMedicoListHeader />
        <AtendimentoMedicoTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <AtendimentoMedicoEditModal />}
    </>
  )
}

const AtendimentoMedicoListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <AtendimentoMedicoList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { AtendimentoMedicoListWrapper }
