import axios, { AxiosResponse } from 'axios'
import Swal from 'sweetalert2'
import { FormulariosQueryResponse } from './_models'
import { ID } from '../../../../../../_metronic/helpers'

const API_URL = process.env.REACT_APP_API_URL
const FORMULARIOS_URL = `${API_URL}/formularios`

export const getFormularios = (query?: string): Promise<FormulariosQueryResponse> => {
  return axios
    .get(`${FORMULARIOS_URL}?${query}`)
    .then((d: AxiosResponse<FormulariosQueryResponse>) => d.data)
}

export const deleteFormularioById = (id: ID): Promise<FormulariosQueryResponse> => {
  return axios
    .delete(`${FORMULARIOS_URL}/${id}`)
    .then((d: AxiosResponse<FormulariosQueryResponse>) => d.data)
}

export const toggleStatus = (formulario: {
  ativo: boolean
  id: ID
}): Promise<FormulariosQueryResponse> => {
  return axios
    .patch(`${FORMULARIOS_URL}`, formulario)
    .then((d: AxiosResponse<FormulariosQueryResponse>) => d.data)
}
