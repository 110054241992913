import { Column } from 'react-table'
import { CoordenadorActionsCell } from './CoordenadorActionsCell'
import { DataColumn } from '../../../../commons/table/DataColumn'
import { ColaboradorList } from '../../core/_models'
import { CustomHeader } from '../../../../commons/table/CustomHeader'
import { useQueryRequest } from '../../core/QueryRequestProvider'

const coordenadorColumns: ReadonlyArray<Column<ColaboradorList>> = [
  {
    Header: (props) => (
      <CustomHeader
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title='Coordenador'
        className='min-w-125px'
      />
    ),
    id: 'nome',
    accessor: 'nome',
  },
  {
    Header: (props) => (
      <CustomHeader
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title='Número do Conselho de Classe '
        className='min-w-125px'
      />
    ),
    id: 'crm',
    accessor: 'crm',
  },
  {
    Header: (props) => (
      <CustomHeader
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title='RQE'
        className='min-w-125px'
      />
    ),
    id: 'rqe',
    accessor: 'rqe',
  },
  {
    Header: (props) => (
      <CustomHeader
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title='Data criação'
        isAbleToSort={false}
      />
    ),
    id: 'dataInclusao',
    Cell: ({ ...props }) => <DataColumn data={props.data[props.row.index].dataInclusao} />,
  },
  {
    Header: (props) => (
      <CustomHeader
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title='Data edição'
        isAbleToSort={false}
      />
    ),
    id: 'dataAlteracao',
    Cell: ({ ...props }) => <DataColumn data={props.data[props.row.index].dataAlteracao} />,
  },
  {
    Header: (props) => (
      <CustomHeader
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title='Ações'
        className='text-end min-w-100px'
      />
    ),
    id: 'actions',
    Cell: ({ ...props }) => <CoordenadorActionsCell colaborador={props.row.original} />,
  },
]

export { coordenadorColumns }
