import axios, { AxiosResponse } from 'axios'
import { ID } from '../../../../../../_metronic/helpers'
import { CargoAsoView, CargosClienteQueryResponse } from './_models'

const API_URL = process.env.REACT_APP_API_URL
const EMPRESAS_CLIENTE_CARGO_URL = `${API_URL}/empresas/cliente/cargo`

export const getCargosCliente = (
  idEmpresaCliente: ID,
  query: string
): Promise<CargosClienteQueryResponse> => {
  return axios
    .get(`${EMPRESAS_CLIENTE_CARGO_URL}?idEmpresa=${idEmpresaCliente}&${query}`)
    .then((d: AxiosResponse<CargosClienteQueryResponse>) => d.data)
}

export const getCargoClienteById = (idCargo: ID): Promise<CargoAsoView | undefined> => {
  return axios
    .get(`${EMPRESAS_CLIENTE_CARGO_URL}/${idCargo}`)
    .then((response: AxiosResponse<CargoAsoView>) => response.data)
}
export const createCargoCliente = (
  idEmpresaCliente: ID,
  nome: string,
  codigo: string
): Promise<CargoAsoView | undefined> => {
  return axios
    .post(`${EMPRESAS_CLIENTE_CARGO_URL}/${idEmpresaCliente}`, {
      codigo,
      nome,
    })
    .then((response: AxiosResponse<CargoAsoView>) => response.data)
}
export const editCargoCliente = (
  idEmpresaCliente: ID,
  nome: string,
  codigo: string
): Promise<CargoAsoView | undefined> => {
  return axios
    .put(`${EMPRESAS_CLIENTE_CARGO_URL}/${idEmpresaCliente}`, {
      codigo,
      nome,
    })
    .then((response: AxiosResponse<CargoAsoView>) => response.data)
}

export const deleteCargoCliente = (idCargo: ID): Promise<string> => {
  return axios.delete(`${EMPRESAS_CLIENTE_CARGO_URL}/${idCargo}`)
}

export const deleteSelectedCargosCliente = (cargoIds: Array<ID>): Promise<void> => {
  const requests = cargoIds.map((id) => axios.delete(`${EMPRESAS_CLIENTE_CARGO_URL}/${id}`))
  return axios.all(requests).then(() => {})
}
