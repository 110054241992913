/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState, useEffect } from 'react'
import Avatar from 'react-avatar-edit'
import { AuthInit, AuthProvider, useAuth } from '../../../../app/modules/auth'
import { editAvatarUser } from '../../../../app/modules/auth/core/_requests'
const API_URL = process.env.REACT_APP_API_URL
export const USUARIO_PICTURE_URL = `${API_URL}/usuarios/picture`

const UploadAvatar: FC = () => {
  const [src, setSrc] = useState(undefined)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [preview, setPreview] = useState(undefined)
  const [file, setFile] = useState<File>()
  const [loading, setLoading] = useState(false)

  const onClose = () => {
    setPreview(undefined)
    setHasErrors(undefined)
    refreshPage()
  }
  const onCrop = (view: any) => {
    setHasErrors(undefined)
    setPreview(view)
  }

  const handleUploadClick = () => {
    if (!file) {
      return
    }
    setLoading(true)
    setHasErrors(undefined)
    editAvatarUser('u', preview, file.name, file.type)
      .then((v) => {
        setLoading(false)
        setHasErrors(false)
        setPreview(undefined)
      })
      .catch((error) => {
        setHasErrors(true)
        setLoading(false)
      })
    //👇 Uploading the file using the fetch API to the server
    // fetch(USUARIO_PICTURE_URL + 'action=u', {
    //   method: 'POST',
    //   body: file,
    //   // 👇 Set headers manually for single file upload
    //   headers: {
    //     'content-type': file.type,
    //     'content-length': `${file.size}`, // 👈 Headers need to be a string
    //   },
    // })
    //   .then((v) => {
    //          setLoading(false)
    //     //     // setHasErrors(false)
    //     //     // handleClickScroll()
    //       })
    //       .catch((error) => {
    //     //     // msg = error.response.data.data.message
    //     //     // setHasErrors(true)
    //          setLoading(false)
    //       })
  }
  function refreshPage() {
    window.location.reload()
  }
  function onBeforeFileLoad(elem: any) {
    if (elem.target.files[0].size > 10000000) {
      alert('Arquivo muito grande!')
      elem.target.value = ''
    }
    setFile(elem.target.files[0])
    setHasErrors(undefined)
  }
  return (
    <div>
      {hasErrors === true && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>Erro ao enviar o arquivo</div>
        </div>
      )}
      {hasErrors === false && (
        <div id='msgUser' className='mb-10 bg-light-success p-8 rounded'>
          <div className='text-success'>Os dados foram alterados com sucesso.</div>
        </div>
      )}
      <Avatar
        width={180}
        height={200}
        onCrop={onCrop}
        onClose={onClose}
        onBeforeFileLoad={onBeforeFileLoad}
        src={src}
        label={'Enviar Imagem'}
      />
      {preview && <img src={preview} />}
      <div className='d-flex flex-center flex-row-fluid pt-12'>
        <button
          type='reset'
          className='btn btn-light me-3'
          data-bs-dismiss='modal'
          onClick={refreshPage}
        >
          Cancelar
        </button>
        <button
          type='submit'
          className='btn btn-primary'
          disabled={loading}
          onClick={handleUploadClick}
        >
          {!loading && 'Salvar Alterações'}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Aguarde...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </div>
  )
}

export { UploadAvatar }
