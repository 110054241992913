import { FC, useState } from 'react'
import { ID, KTSVG } from '../../../../../../../_metronic/helpers'
import { Row } from 'react-table'
import { EspecialidadeView } from '../../core/_models'
import EspecialidadeEditModalForm from '../../../especialidade-edit-modal-form/EspecialidadeEditModalForm'
import EspecialidadeStatusModal from '../../../dasativar-especialidade-modal/EspecialidadeStatusModal'
import { Dropdown, DropdownItem } from '../../../../../../components'

type Props = {
  id: ID
  row: Row<EspecialidadeView>
}

const EspecialidadeActionsCell: FC<Props> = ({ id, row }) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [showModalStatus, setShowModalStatus] = useState<boolean>(false)

  const handleShowModal = () => setShowModal(true)
  const handleCloseModal = () => setShowModal(false)

  const handleShowModalStatus = () => setShowModalStatus(true)
  const handleCloseModalStatus = () => setShowModalStatus(false)

  return (
    <Dropdown name='Ações'>
      <DropdownItem onClick={handleShowModal}>Editar</DropdownItem>
      <DropdownItem onClick={handleShowModalStatus}>
        {row.original.ativa ? 'Desativar' : 'Ativar'}
      </DropdownItem>
      <EspecialidadeEditModalForm
        show={showModal}
        onHide={handleCloseModal}
        valuesEdit={row.original}
      />
      <EspecialidadeStatusModal
        show={showModalStatus}
        onHide={handleCloseModalStatus}
        especialidade={row.original}
      />
    </Dropdown>
  )
}

export { EspecialidadeActionsCell }
