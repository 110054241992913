import {
  FC,
  ReactNode,
  forwardRef,
  useState,
  useImperativeHandle,
  createContext,
  useContext,
} from 'react'
import { ModalSize } from '../../../_metronic/helpers'
import { Modal } from 'react-bootstrap'

type ICustomModal = {
  onHide?: () => void
  children: ReactNode
  size?: ModalSize
  title: string
}

const ModalContext = createContext<{ close: () => void } | null>(null)

export const useModal = () => {
  const context = useContext(ModalContext)
  if (!context) {
    throw new Error('useModal must be used within a ModalContext.Provider')
  }
  return context
}

export const CustomModal = forwardRef<{ open: () => void; close: () => void }, ICustomModal>(
  ({ onHide, children, size = ModalSize.Medium, title }, ref) => {
    const [show, setShow] = useState<boolean>(false)

    const close = () => {
      setShow(false)
      onHide && onHide()
    }

    const open = () => setShow(true)

    useImperativeHandle(ref, () => ({
      open,
      close,
    }))

    return (
      <Modal className={size} show={show} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <ModalContext.Provider value={{ close }}>
          <Modal.Body>{children}</Modal.Body>
        </ModalContext.Provider>
      </Modal>
    )
  }
)
