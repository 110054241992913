import { AtendimentoReport } from '../../../meus-atendimentos/meus-atendimentos-list/core/_models'
import { IInfoRelatorio } from './_models'
import { utils, writeFile } from 'xlsx'

type DataProps = Array<{
  [key: string]: string
}>

export interface IExportDownload {
  headers: Array<{ key: string; label: string }>
  data: DataProps
}

interface Info {
  total: number
  assistencial: number
  screening: number
  ocupacional: number
  emAtendimento: number
  finalizado: number
  cancelado: number
  aguardando: number
}

export function calcularInformacoesAtendimentos(atendimentos: AtendimentoReport[]): IInfoRelatorio {
  let info = {
    total: atendimentos.length,
    assistencial: 0,
    screening: 0,
    ocupacional: 0,
    emAtendimento: 0,
    finalizado: 0,
    cancelado: 0,
    aguardando: 0,
  }

  atendimentos.forEach((atendimento) => {
    switch (atendimento.tipoAtendimento) {
      case 'Assistencial':
        info.assistencial++
        break
      case 'Screening':
        info.screening++
        break
      case 'Ocupacional':
        info.ocupacional++
        break
    }

    switch (atendimento.estadoAtendimento) {
      case 'Em atendimento':
        info.emAtendimento++
        break
      case 'Atendimento finalizado':
        info.finalizado++
        break
      case 'Atendimento Cancelado':
        info.cancelado++
        break
      case 'Aguardando Atendimento':
        info.aguardando++
        break
    }
  })

  return info
}

const generateFile = (data: unknown[], filename: string) => {
  const wb = utils.book_new()
  const ws = utils.json_to_sheet(data)
  utils.book_append_sheet(wb, ws)
  writeFile(wb, filename)
}

const formatDataToDownload = ({ headers, data }: IExportDownload) => {
  const getLabel = (value: string) => headers.find((header) => header.key === value)?.label

  const xlsx = data.map((values) => {
    const bodyData: { [key: string]: string } = {}
    const sortBodyData: { [key: string]: string } = {}

    Object.keys(values).forEach((key) => {
      bodyData[getLabel(key) as string] = values[key]
    })

    headers.forEach((header) => {
      sortBodyData[header.label] = bodyData[header.label]
    })

    return sortBodyData
  })

  return xlsx
}

export const handleOnExport = ({ headers, data }: IExportDownload) => {
  const values = formatDataToDownload({ headers, data })

  return generateFile(values, 'relatorio_atendimentos.xlsx')
}

export const formatDataForExport = (infos: Info, atendimentos: AtendimentoReport[]) => {
  const detailedHeaders = [
    { key: 'Total de Atendimentos', label: 'Total de Atendimentos' },
    { key: 'Assistenciais', label: 'Assistenciais' },
    { key: 'Screenings', label: 'Screenings' },
    { key: 'Ocupacionais', label: 'Ocupacionais' },
    { key: 'Aguardando Atendimento', label: 'Aguardando Atendimento' },
    { key: 'Em Atendimento', label: 'Em Atendimento' },
    { key: 'Atendimentos Finalizados', label: 'Atendimentos Finalizados' },
    { key: 'Atendimentos Cancelados', label: 'Atendimentos Cancelados' },
    { key: 'PROTOCOLO', label: 'PROTOCOLO' },
    { key: 'PACIENTE', label: 'PACIENTE' },
    { key: 'TIPO', label: 'TIPO' },
    { key: 'MÉDICO RESPONSÁVEL', label: 'MÉDICO RESPONSÁVEL' },
    { key: 'EMPRESA', label: 'EMPRESA' },
    { key: 'CNPJ', label: 'CNPJ' },
    { key: 'DATA', label: 'DATA' },
  ]

  const generalInfo = {
    'Total de Atendimentos': infos?.total,
    Assistenciais: infos?.assistencial,
    Screenings: infos?.screening,
    Ocupacionais: infos?.ocupacional,
    'Aguardando Atendimento': infos?.aguardando,
    'Em Atendimento': infos?.emAtendimento,
    'Atendimentos Finalizados': infos?.finalizado,
    'Atendimentos Cancelados': infos?.cancelado,
  }

  const detailedInfo = atendimentos?.map((atendimento) => ({
    PROTOCOLO: atendimento?.protocolo,
    PACIENTE: `${atendimento?.paciente?.nome}`,
    TIPO: atendimento?.tipoAtendimento,
    'MÉDICO RESPONSÁVEL': atendimento?.responsavel?.nome || '',
    EMPRESA: atendimento?.empresa?.nomeFantasia,
    CNPJ: atendimento?.empresa?.cnpj,
    DATA: atendimento?.inicioFormatado,
  }))

  const headers = detailedHeaders
  const data = [generalInfo, ...(detailedInfo || [])]

  return { headers, data }
}
