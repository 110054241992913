import { FC } from 'react'

import { FuncionarioFindModalForm } from './FuncionarioFindModalForm'
import { IconPaths, KTSVG } from '../../../../../../_metronic/helpers'

const FuncionarioFindModal: FC = () => {
  return (
    <div className='modal fade' id='kt_funcionario_find_modal' aria-hidden='true'>
      <div className='modal-dialog modal-lg'>
        <div className='modal-content rounded'>
          <div className='modal-header justify-content-end border-0 pb-0'>
            <div data-bs-dismiss='modal'>
              <KTSVG path={IconPaths.Close} className='svg-icon-1 cursor-pointer' />
            </div>
          </div>
          <div className='modal-body pt-0 pb-15 px-15 px-xl-20'>
            <div className='mb-13 text-center'>
              <h1 className='mb-3'>Localizar Funcionário</h1>
            </div>

            <div>
              <FuncionarioFindModalForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export { FuncionarioFindModal }
