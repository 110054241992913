import { Outlet, Route, Routes } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { EmpresasListWrapper } from './empresas-list/EmpresasList'

const apiBreadcrumbs: Array<PageLink> = [
  {
    title: 'API',
    path: '/apps/api',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ApiPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path=''
          element={
            <>
              <PageTitle breadcrumbs={apiBreadcrumbs}>Lista</PageTitle>
              <EmpresasListWrapper />
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export default ApiPage
