/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { MenuComponent } from '../../../../../../../_metronic/assets/ts/components'
import { ID, KTSVG, onDeleteAlert, QUERIES } from '../../../../../../../_metronic/helpers'
import { useListView } from '../../core/ListViewProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import Swal from 'sweetalert2'
import { AxiosError } from 'axios'
import { deleteExameCliente } from '../../core/_request'
import { Dropdown, DropdownItem } from '../../../../../../components'

type Props = {
  id: ID
}

const ExameActionsCell: FC<Props> = ({ id }) => {
  const { setItemIdForUpdate } = useListView()
  const { query } = useQueryResponse()
  const queryClient = useQueryClient()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => setItemIdForUpdate(id)

  const deleteItem = useMutation(() => deleteExameCliente(id), {
    onSuccess: () => {
      Swal.fire({
        text: 'Risco excluído com sucesso!',
        icon: 'success',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      }).then((_) => queryClient.invalidateQueries([`${QUERIES.EMPRESAS_LIST}-${query}`]))
    },
    onError(error, variables, context) {
      const err = error as AxiosError
      Swal.fire({
        text: err.response?.data.message,
        icon: 'error',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  })

  const handleDelete = () => onDeleteAlert(deleteItem.mutateAsync)

  return (
    <Dropdown name='Ações'>
      <DropdownItem onClick={openEditModal}>Alterar</DropdownItem>
      <DropdownItem onClick={handleDelete}>Remover</DropdownItem>
    </Dropdown>
  )
}

export { ExameActionsCell }
