import { ImportacaoListTable } from '../table/ImportacaoListTable'
import { ImportacaoModal } from '../../form-modal/ImportacaoModal'
import { KTCard } from '../../../../../../_metronic/helpers/components/KTCard'
import { useListView } from '../core/ListViewProvider'
import { ImportacaoListHeader } from './header/ImportacaoListHeader'

export const ImportacaoList = () => {
  const { itemIdForUpdate } = useListView()
  return (
    <>
      <KTCard>
        <ImportacaoListHeader />
        <ImportacaoListTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <ImportacaoModal />}
    </>
  )
}
