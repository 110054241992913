/* eslint-disable jsx-a11y/anchor-is-valid */

import { Outlet, Route, Routes } from 'react-router-dom'
import { Login } from './components/Login'
import { Atendimento } from './components/Atendimento'

const AuthLayout = () => {
  return (
    <div>
      <nav className='navbar navbar-expand-lg navbar-primary bg-primary'>
        <div className='container-fluid'>
          <a className='navbar-brand d-block mx-auto' href='/'>
            <img src='/media/logos/logo-dark.png' alt='Logo' style={{ maxWidth: '150px' }} />
          </a>
        </div>
      </nav>

      <div className='container-fluid d-flex justify-content-center align-items-center'>
        <Outlet />
      </div>
    </div>
  )
}

const TeleConsultaPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='/:protocolo' element={<Login />} />
      <Route path='/:protocolo/atendimento' element={<Atendimento />} />
    </Route>
  </Routes>
)

export { TeleConsultaPage }
