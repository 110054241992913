import axios from 'axios'
import { AtendimentoResumo } from './_models'

const API_URL = process.env.REACT_APP_API_URL
const ATENDIMENTO_URL = `${API_URL}/atendimentos`

export const getResumoAtendimentos = (query: string): Promise<AtendimentoResumo> => {
  return axios.get(`${ATENDIMENTO_URL}/resumo?${query}`).then((resp) => resp.data)
}

export const getResumoAtendimentosResponsavel = (): Promise<any> => {
  return axios.get(`${ATENDIMENTO_URL}/responsavel/resumo`).then((resp) => resp.data)
}
