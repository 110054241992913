import { FC, useEffect } from 'react'
import { AtendimentoView } from '../../../core/_models'
import { Dot } from '../../../../../../components'

type Props = {
  atendimento: AtendimentoView
}

const OnlineColumn: FC<Props> = ({ atendimento }) => {
  const cor = atendimento?.pacienteOnline ? 'bg-success' : 'bg-secondary'

  useEffect(() => {
    if (atendimento?.pacienteOnline) {
      const audio = document.querySelector('audio')
      audio?.play()
    }
  }, [atendimento?.pacienteOnline, cor])

  return <Dot cor={cor} />
}

export { OnlineColumn }
