import { VincularFormularioModalWrapper } from './VincularFormularioModalWrapper'
import { Modal } from '../../../../../../components'
import { useListView } from '../../core/ListViewProvider'

const id = 'vincular_formulario'

const VincularFormularioModal = () => {
  const { setItemIdForUpdate, nomeFormulario } = useListView()

  return (
    <Modal title={nomeFormulario} id={id} onClose={() => setItemIdForUpdate(undefined)}>
      <VincularFormularioModalWrapper id={id} />
    </Modal>
  )
}

export { VincularFormularioModal }
