import { Route, Routes, Outlet, Navigate } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { ResetPassword } from './ResetPassword'

const resetPasswordBreadcrumbs: Array<PageLink> = [
  {
    title: 'Redefinir Senha Usuário',
    path: '/reset-password',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ResetPasswordPage = () => {
  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid'>
      <a href='../../demo1/dist/index.html' className='d-block d-lg-none mx-auto py-20'>
        <img
          alt='Logo'
          src={toAbsoluteUrl('/media/logos/logo-white.png')}
          className='theme-light-show h-50px'
        />
        <img
          alt='Logo'
          src={toAbsoluteUrl('/media/logos/logo-dark.png')}
          className='theme-dark-show h-50px'
        />
      </a>
      <div className='d-flex flex-column flex-column-fluid flex-center w-lg-50 p-10'>
        <div className='d-flex justify-content-between flex-column-fluid flex-column w-100 mw-450px'>
          {/* <div className="d-flex  flex-row-auto" > */}

          <ResetPassword />

          {/* </div> */}
        </div>
      </div>
      {/* begin::Aside */}
      <div
        className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2'
        style={{ backgroundImage: 'url("media/misc/auth-bg.png")' }}
      ></div>
      {/* end::Aside */}
    </div>
  )
}

export default ResetPasswordPage
