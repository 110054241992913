import { FormulariosListSearchComponent } from './FormulariosListSearchComponent'

const FormulariosListHeader = () => {
  return (
    <div className='card-header border-0 pt-6'>
      <FormulariosListSearchComponent />
    </div>
  )
}

export { FormulariosListHeader }
