import { IconPaths } from '../../../../../../../_metronic/helpers'
import { useListView } from '../../core/ListViewProvider'
import { Button } from '../../../../../../components'

const ImportacaoListToolbar = () => {
  const { setItemIdForUpdate } = useListView()

  const openImportAtendimentos = () => {
    setItemIdForUpdate(999)
  }

  return (
    <div className='d-flex justify-content-end pt-3' data-kt-user-table-toolbar='base'>
      <Button className='btn-primary' onClick={openImportAtendimentos} icon={IconPaths.Add}>
        Nova importação
      </Button>
    </div>
  )
}

export { ImportacaoListToolbar }
