import { useQuery } from 'react-query'
import { isNotEmpty, QUERIES } from '../../../../../../_metronic/helpers'
import { useListView } from '../core/ListViewProvider'
import { getEmpresaById, getEnderecoByIdEmpresa, getLogo } from '../core/_requests'
import { EmpresaEditModalForm } from './EmpresaEditModalForm'
import { useEffect, useState } from 'react'

const EmpresaEditModalFormWrapper = () => {
  const { itemIdForUpdate, setItemIdForUpdate } = useListView()
  const [logo, setLogo] = useState<string>('')
  const [isLogoLoading, setIsLogoLoading] = useState<boolean>(true)

  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading: isEmpresaLoading,
    data: empresa,
    error,
  } = useQuery(
    [`${QUERIES.EMPRESAS_LIST}-empresa-${itemIdForUpdate}`],
    () => getEmpresaById(itemIdForUpdate),
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  useEffect(() => {
    if (empresa) {
      if (empresa?.logoCadastrada) {
        getLogo(empresa.id)
          .then((resp) => setLogo(resp.data))
          .finally(() => setIsLogoLoading(false))
      } else {
        setIsLogoLoading(false)
      }
    }
  }, [empresa])

  if (!itemIdForUpdate) {
    return <EmpresaEditModalForm isEmpresaLoading={isEmpresaLoading} empresa={{ id: undefined }} />
  }

  if (!isEmpresaLoading && !isLogoLoading && !error && empresa) {
    return <EmpresaEditModalForm isEmpresaLoading={false} empresa={empresa} logo={logo} />
  }

  return null
}

export { EmpresaEditModalFormWrapper }
