import { useState, useEffect } from 'react'
import {
  initialQueryState,
  OptionsHelper,
  stringifyRequestQueryResumo,
  useDebounce,
} from '../../../../../../../_metronic/helpers'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { getEstadosDeAtendimento } from '../../../../atendimento/core/_requests'
import {
  CustomSelect,
  DateInput,
  Input,
  IOption,
  Label,
  NavBar,
  NavBarItem,
} from '../../../../../../components'
import { EstadoAtendimentoList } from '../../../../atendimento/core/_models'
import { getResumoAtendimentos } from '../../core/_requests'
import { useQueryResponse } from '../../core/QueryResponseProvider'

const AtendimentoMedicoListSearchComponent = () => {
  const { state } = useQueryRequest()
  const { response } = useQueryResponse()
  const [empresa, setEmpresa] = useState<IOption>()
  const [tipoAtendimento] = useState<IOption>()
  const { updateState } = useQueryRequest()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [protocoloSel, setProtocoloSel] = useState<string>('')
  const [dataSel, setDataSel] = useState<string>('')
  const [dataConsulta, setDataConsulta] = useState<string>('')
  const [especialidadeSel, setEspecialidadeSel] = useState<IOption>()
  const [pacienteSel, setPacienteSel] = useState('')
  const [retorno, setRetorno] = useState<IOption>()
  const [estadosAtendimento, setEstadosAtendimento] = useState<
    {
      estado: EstadoAtendimentoList
      qtd: number
    }[]
  >([])
  const [estadoId, setEstadoId] = useState<number>(1)

  const [filters, setFilters] = useState<Array<String>>(
    empresa ? new Array(String(empresa.value)) : []
  )
  const debouncedSearchTerm = useDebounce(searchTerm, 150)

  const updateFilter = () => {
    const search = [
      retorno ? `retorno:${retorno.value}*` : '',
      empresa ? `paciente.empresa:${empresa.value}` : '',
      tipoAtendimento ? `tipo:${tipoAtendimento.value}` : '',
      dataConsulta ? `inicio>${dataConsulta}T00:00:00` : '',
      estadoId ? `estado:${estadoId}` : '',
      dataSel ? `solicitacao>${dataSel}T00:00:00` : '',
      especialidadeSel ? `especialidade:${especialidadeSel.value}` : '',
      protocoloSel ? `protocolo~*${protocoloSel}*` : '',
      pacienteSel ? `paciente.pessoa.nome~*${pacienteSel}*` : '',
    ]
      .filter(Boolean)
      .join(',')
    setSearchTerm(search)
  }

  const fetchResumosAtendimento = async () => {
    const data: {
      estado: EstadoAtendimentoList
      qtd: number
    }[] = []
    const resp = await getEstadosDeAtendimento()
    const resumo = await getResumoAtendimentos(stringifyRequestQueryResumo(state))
    for (const estado of resp.data || []) {
      if (estado.descricao && resumo.hasOwnProperty(estado.descricao)) {
        data.push({ estado, qtd: resumo[estado.descricao as keyof typeof resumo] || 0 })
      }
    }
    setEstadosAtendimento(data)
  }

  useEffect(() => {
    fetchResumosAtendimento()
  }, [response])

  useEffect(() => {
    updateFilter()
  }, [
    empresa,
    tipoAtendimento,
    dataConsulta,
    dataSel,
    estadoId,
    especialidadeSel,
    pacienteSel,
    protocoloSel,
    retorno,
  ])

  useEffect(() => {
    if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
      updateState({
        filter: filters,
        search: searchTerm,
        sort: estadoId !== 3 ? 'solicitacao' : 'termino',
        order: 'asc',
        ...initialQueryState,
      })
      fetchResumosAtendimento()
    }
  }, [searchTerm, debouncedSearchTerm, filters])

  useEffect(() => {
    setFilters(empresa ? [String(empresa.value)] : [])
  }, [empresa])

  return (
    <>
      <NavBar>
        {estadosAtendimento?.map(({ estado, qtd }, index) => (
          <NavBarItem
            isActive={estadoId === estado.id}
            onClick={() => setEstadoId(estado.id as number)}
            qtd={qtd}
            key={estado.id}
            isFirst={index === 0}
            isLast={index === estadosAtendimento.length - 1}
          >
            {estado.descricao &&
              estado.descricao
                .replace(/Atendimento com/gi, '')
                .replace(/Paciente/gi, '')
                .trim()
                .replace(/^\w/, (c) => c.toUpperCase())}
          </NavBarItem>
        ))}
      </NavBar>
      <div className='col-12 py-6 px-9 justify-content-center'>
        <div className='row mb-3'>
          <div className='col-lg-3'>
            <Label className='form-label mb-1'>Paciente</Label>
            <Input
              placeholder='Digite o Pacientes'
              value={pacienteSel}
              onChange={(e) => setPacienteSel(e.target.value)}
            />
          </div>
          <div className='col-lg-3 col-md-12'>
            <Label className='form-label mb-1'>Empresa</Label>
            <CustomSelect
              placeholder='Selecione uma empresa'
              onChange={(empresa) => setEmpresa(empresa)}
              fetchOptions={OptionsHelper.getOptionsEmpresas}
              value={empresa}
            />
          </div>
          <div className='col-lg-3 col-md-12'>
            <Label className='form-label mb-1'>Especialidade</Label>
            <CustomSelect
              placeholder='Selecione uma especialidade'
              onChange={(data) => setEspecialidadeSel(data)}
              fetchOptions={OptionsHelper.getOptionsEspecialidades}
              value={especialidadeSel}
            />
          </div>
          <div className='col-lg-3 col-md-12'>
            <Label className='form-label mb-1'>Protocolo</Label>
            <Input
              placeholder='Digite o protocolo'
              value={protocoloSel}
              onChange={(e) => setProtocoloSel(e.target.value)}
            />
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col-lg-4 col-md-12'>
            <Label className='form-label mb-1'>Consulta de retorno?</Label>
            <CustomSelect
              placeholder='Selecione'
              options={OptionsHelper.retorno}
              value={retorno}
              onChange={(data) => setRetorno(data)}
            />
          </div>
          <div className='col-lg-4 col-md-12'>
            <Label className='form-label mb-1'>Agendamentos a partir de:</Label>
            <DateInput
              placeholder='Selecione a data inicial'
              value={dataSel}
              onChange={(data) => setDataSel(data)}
            />
          </div>
          <div className='col-lg-4 col-md-12'>
            <Label className='form-label mb-1'>Consultas iniciadas a partir de:</Label>
            <DateInput
              placeholder='Digite data inicial'
              value={dataConsulta}
              onChange={(data) => setDataConsulta(data)}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export { AtendimentoMedicoListSearchComponent }
