import * as Yup from 'yup'

import { FC, useState } from 'react'

import AsyncSelect from 'react-select/async'
import Swal from 'sweetalert2'
import clsx from 'clsx'
import { createImportacao } from '../list/core/_requests'
import { getEmpresas } from '../../empresa/empresas-list/core/_requests'
import { useFormik } from 'formik'
import { useListView } from '../list/core/ListViewProvider'
import { useQueryResponse } from '../list/core/QueryResponseProvider'
import { EmpresaList } from '../../empresa/empresas-list/core/_models'

type Props = {
  importacao?: any
}

const ImportacaoModalForm: FC<Props> = ({ importacao }) => {
  const { setItemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()
  const [selectEmpresa, setSelectEmpresa] = useState()
  const [selectFirstLine, setSelectFirstLine] = useState<string>('n')
  const [arquivoValue, setArquivoValue] = useState<File | undefined>()

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const importacaoSchema = Yup.object().shape({
    idEmpresa: Yup.string().required('O campo empresa é obrigatório'),
    descricao: Yup.string()
      .min(3, 'Mínimo de 3 caracteres')
      .max(50, 'Máximo de 50 caracteres')
      .required('O campo descrição é obrigatório'),
    firstRowContainsColNames: Yup.boolean().required('O campo email é obrigatório'),
    arquivo: Yup.mixed().required('O arquivo é obrigatório'),
  })

  const mapResponseToValuesAndLabels = (data: EmpresaList) => ({
    value: data.id,
    label: data.nomeFantasia,
    ativa: data.ativa,
  })

  async function callApi(value: string) {
    const data = await getEmpresas('page=0&size=30' + value)
      .then((response) => response.data?.map(mapResponseToValuesAndLabels))
      .then((final: any) =>
        final.filter(
          (i: any) => String(i.label).toLowerCase().includes(value.toLowerCase()) && i.ativa
        )
      )
    data.sort((a: any, b: any) => {
      const labelA = a.label.toLowerCase()
      const labelB = b.label.toLowerCase()
      if (labelA < labelB) return -1
      if (labelA > labelB) return 1
      return 0
    })

    return data
  }

  const importacaoForEdit = {
    idEmpresa: 0,
    descricao: '',
    firstRowContainsColNames: false,
    arquivo: '',
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: importacaoForEdit,
    validationSchema: importacaoSchema,
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      setSubmitting(true)
      try {
        const result: any = await createImportacao(
          values.idEmpresa,
          values.descricao,
          values.firstRowContainsColNames,
          arquivoValue!
        )
        setSubmitting(false)
        cancel(true)
        handleResult(result.data.sucesso)
      } catch (error) {
        setSubmitting(false)
      }
    },
  })

  const handleFirstLineSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectFirstLine(e.target.value)
    formik.setFieldValue('firstRowContainsColNames', e.target.value === 's')
  }

  const handleResult = (success: boolean) => {
    Swal.fire({
      text: success
        ? 'Importação realizada sem erros!'
        : 'Erro no processamento da importação. Verifique os detalhes e tente novamente.',
      icon: success ? 'success' : 'error',
      buttonsStyling: false,
      confirmButtonText: 'Ok',
      customClass: {
        confirmButton: 'btn btn-primary',
      },
    })
  }

  return (
    <>
      <form
        id='kt_modal_importacao_form'
        className='form'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <div className='menu-item px-8'>
          <div className='d-flex mt-4 align-items-center '>
            <div className='fv-row mb-7 w-100'>
              <label className='required fw-bold '>Empresa</label>
              <AsyncSelect
                className='form-select-transparent'
                cacheOptions
                loadOptions={callApi}
                onChange={(data: any) => {
                  setSelectEmpresa(data)
                  formik.setFieldValue('idEmpresa', data.value)
                }}
                value={selectEmpresa}
                defaultOptions
                isDisabled={formik.isSubmitting}
              />
              {formik.touched.idEmpresa && formik.errors.idEmpresa && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.idEmpresa}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='fv-row mb-7 w-100'>
            <label className='required fw-bold fs-6 mb-2'>Descrição</label>
            <input
              placeholder='Descrição'
              {...formik.getFieldProps('descricao')}
              type='text'
              name='descricao'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.descricao && formik.errors.descricao },
                {
                  'is-valid': formik.touched.descricao && !formik.errors.descricao,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting}
            />
            {formik.touched.descricao && formik.errors.descricao && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.descricao}</span>
                </div>
              </div>
            )}
          </div>
          <div className='mb-5'>
            <label className='required fw-bold fs-6 mb-2'>
              Primeira linha contém nome das colunas?
            </label>
            <div className='nav-group nav-group-fluid'>
              <label>
                <input
                  type='radio'
                  className='btn-check'
                  name='type'
                  value='n'
                  checked={selectFirstLine === 'n'}
                  disabled={formik.isSubmitting}
                  onChange={handleFirstLineSelect}
                />
                <span className='btn btn-sm btn-color-muted btn-active btn-active-primary'>
                  Não
                </span>
              </label>

              <label>
                <input
                  type='radio'
                  className='btn-check'
                  name='type'
                  value='s'
                  checked={selectFirstLine === 's'}
                  onChange={handleFirstLineSelect}
                />
                <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                  Sim
                </span>
              </label>
            </div>
          </div>

          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Arquivo</label>
            <input
              name='file'
              type='file'
              onChange={(event) => {
                formik.setFieldValue('arquivo', event.currentTarget.files?.[0].name)
                setArquivoValue(
                  event.currentTarget.files !== null ? event.currentTarget.files[0] : undefined
                )
              }}
              className='form-control'
            />
            {formik.touched.arquivo && formik.errors.arquivo && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.arquivo}</div>
              </div>
            )}
          </div>
        </div>

        <div className='text-center mt-4'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
          >
            Cancelar
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-importacao-modal-action='submit'
            disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            {!formik.isSubmitting && <span className='indicator-label'>Importar</span>}
            {formik.isSubmitting && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Aguarde...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </>
  )
}

export { ImportacaoModalForm }
