import { useListView } from '../../core/ListViewProvider'
import { AtendimentoMedicoListSearchComponent } from './AtendimentoMedicoListSearchComponent'

const AtendimentoMedicoListHeader = () => {
  const { selected } = useListView()
  return (
    <div className='border-0'>
      <AtendimentoMedicoListSearchComponent />
      {/*  <div className='card-toolbar'>
        <SelectionHeader selected={selected}>
          {selected.length > 0 ? (
            <AtendimentoMedicoListGrouping />
          ) : (
            <AtendimentoMedicoListToolbar />
          )}
        </SelectionHeader>
      </div> */}
    </div>
  )
}

export { AtendimentoMedicoListHeader }
