import { useListView } from '../../../core/ListViewProvider'
import { AtendimentosListSearchComponent } from './AtendimentosListSearchComponent'
import { UsersListToolbar } from './UserListToolbar'
import { UsersListGrouping } from './UsersListGrouping'

const AtendimentosListHeader = () => {
  const { selected } = useListView()
  return (
    <div className='border-0 pt-6'>
      <AtendimentosListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <UsersListGrouping /> : <UsersListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export { AtendimentosListHeader }
