const QUERIES = {
  USERS_LIST: 'users-list',
  PACIENTE_LIST: 'paciente-list',
  PACIENTE_HISTORICO_LIST: 'paciente-historico-list',
  EMPRESAS_LIST: 'empresas-list',
  ESPECIALIDADES_LIST: 'especialidades-list',
  ATENDIMENTO_MEDICO_LIST: 'atendimento-medico-list',
  IMPORTACOES_LIST: 'importacoes-list',
  IMPORTACOES_ERROS_LIST: 'importacoes-erros-list',
  IMPORTACOES_DETALHES_LIST: 'importacoes-detalhes-list',
  FORMULARIOS_LIST: 'formularios-list',
  AVALIACOES_LIST: 'avaliacoes-list',
  VINCULOS_LIST: 'vinculos-list',
}

export { QUERIES }
