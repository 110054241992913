import { useQuery } from 'react-query'
import { isNotEmpty, QUERIES } from '../../../../../../_metronic/helpers'
import { useListView } from '../core/ListViewProvider'
import { CargoClienteEditModalForm } from './CargoClienteEditModalForm'
import { getCargoClienteById } from '../core/_request'

const CargoClienteEditModalFormWrapper = () => {
  const { itemIdForUpdate, setItemIdForUpdate } = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: cargo,
    error,
  } = useQuery(
    `${QUERIES.EMPRESAS_LIST}-empresa-${itemIdForUpdate}`,
    () => {
      return getCargoClienteById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return <CargoClienteEditModalForm isEmpresaLoading={isLoading} cargo={{ id: undefined }} />
  }

  if (!isLoading && !error && cargo) {
    return <CargoClienteEditModalForm isEmpresaLoading={isLoading} cargo={cargo} />
  }

  return null
}

export { CargoClienteEditModalFormWrapper }
