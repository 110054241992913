import axios, { AxiosResponse } from 'axios'
import { AtendimentosQueryResponse } from '../../../atendimento/core/_models'

const API_URL = process.env.REACT_APP_API_URL
const ATENDIMENTO_URL = `${API_URL}/atendimentos`

export const getAtendimentosAso = (query: string): Promise<AtendimentosQueryResponse> => {
  return axios
    .get(`${ATENDIMENTO_URL}/aso?${query}`)
    .then((d: AxiosResponse<AtendimentosQueryResponse>) => d.data)
}
