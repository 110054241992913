import { FC, useState, createContext, useContext } from 'react'
import { WithChildren } from '../../../../../../_metronic/helpers'
import {
  initialQueryRequestAvaliacoes,
  QueryRequestContextPropsAvaliacoes,
  QueryStateAvaliacoes,
} from './_models'

const QueryRequestContext = createContext<QueryRequestContextPropsAvaliacoes>(
  initialQueryRequestAvaliacoes
)

const QueryRequestProvider: FC<WithChildren> = ({ children }) => {
  const [state, setState] = useState<QueryStateAvaliacoes>(initialQueryRequestAvaliacoes.state)

  const updateState = (updates: Partial<QueryStateAvaliacoes>) => {
    const updatedState = { ...state, ...updates } as QueryStateAvaliacoes
    setState(updatedState)
  }

  return (
    <QueryRequestContext.Provider value={{ state, updateState }}>
      {children}
    </QueryRequestContext.Provider>
  )
}

const useQueryRequest = () => useContext(QueryRequestContext)
export { QueryRequestProvider, useQueryRequest }
