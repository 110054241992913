import { FC } from 'react'
import { AtendimentoResumo } from '../../modules/apps/meus-atendimentos/meus-atendimentos-list/core/_models'
import { ResumoAtendimentoType } from '../../../_metronic/helpers'

export type IInfoHeader = {
  infos: AtendimentoResumo
}

export const InfoHeader: FC<IInfoHeader> = ({ infos }) => {
  const numCols = 4
  const entries = Object.entries(infos)

  const rows = Array.from({ length: Math.ceil(entries.length / numCols) }, (_, rowIndex) =>
    entries.slice(rowIndex * numCols, rowIndex * numCols + numCols)
  )

  return (
    <div className='container'>
      <h4>Relatório</h4>
      <div className='row'>
        {rows.map((row, rowIndex) => (
          <div key={`row-${rowIndex}`} className='row border-bottom border-secondary mb-3'>
            {row.map(([key, value]) => (
              <div key={key} className={`col-md-${12 / numCols} d-flex flex-column p-3`}>
                <strong>{key}</strong>
                <span>{value}</span>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}
