import { ImportacaoModalForm } from './ImportacaoModalForm'
import { QUERIES } from '../../../../../_metronic/helpers/crud-helper/consts'
import { isNotEmpty } from '../../../../../_metronic/helpers/crud-helper/helpers'
import { useListView } from '../list/core/ListViewProvider'
import {useQuery} from 'react-query'

const ImportacaoModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: importacao,
    error, 
  } = useQuery(
    `${QUERIES.IMPORTACOES_LIST}-form-${itemIdForUpdate}`,
    () => {
      return; 
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return <ImportacaoModalForm importacao={{id: undefined}} />
  }

  if (!isLoading && !error) {
    return <ImportacaoModalForm importacao={importacao} />
  }

  return null
}

export {ImportacaoModalFormWrapper}
