import { useListView } from '../../core/ListViewProvider'
import { RiscoClienteListGrouping } from './RiscoClienteListGrouping'
import { RiscoClienteListSearchComponent } from './RiscoClienteListSearchComponent'
import { RiscoClienteListToolbar } from './RiscoClienteListToolbar'

const RiscoClienteListHeader = () => {
  const { selected } = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <RiscoClienteListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <RiscoClienteListGrouping /> : <RiscoClienteListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export { RiscoClienteListHeader }
