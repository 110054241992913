import Swal from 'sweetalert2'
import { IconPaths, showAlert } from '../../../../../../../_metronic/helpers'
import { Button, SelectionHeaderContainer } from '../../../../../../components'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { RelatorioListSearchComponent } from './RelatorioSearchComponent'
import { getCsv, getPdf } from '../../core/_requests'

const RelatorioListHeader = () => {
  const { state } = useQueryRequest()

  const downloadPdf = async () => {
    if (state.search === '') {
      showAlert({
        title: 'Filtro Necessário',
        text: 'Por favor, realize um filtro antes de continuar.',
        icon: 'warning',
      })
      return
    }
    showAlert({
      title: 'Aguarde...',
      text: 'Carregando os dados...',
      icon: 'info',
      showLoading: true,
    })

    try {
      const file = await getPdf(`search=${state.search}` as string)

      if (file !== undefined) {
        if (!file.contentBase64) throw new Error('Sem dados para download.')

        const a = document.createElement('a')
        a.href = 'data:application/pdf;base64,' + file.contentBase64
        a.download = String(file.name)
        a.click()
      }
      Swal.close()
      showAlert({
        title: 'Sucesso!',
        text: 'Os dados foram baixados com sucesso.',
        icon: 'success',
      })
    } catch (error) {
      Swal.close()
      const errorMessage =
        error instanceof Error ? error.message : 'Houve um erro ao baixar os dados.'
      showAlert({ title: 'Erro!', text: errorMessage, icon: 'error' })
    }
  }

  const downloadCsv = async () => {
    if (state.search === '') {
      showAlert({
        title: 'Filtro Necessário',
        text: 'Por favor, realize um filtro antes de continuar.',
        icon: 'warning',
      })
      return
    }
    showAlert({
      title: 'Aguarde...',
      text: 'Carregando os dados...',
      icon: 'info',
      showLoading: true,
    })

    try {
      const file = await getCsv(`${state.search}` as string)

      if (file !== undefined) {
        if (!file.contentBase64) throw new Error('Sem dados para download.')
        const binaryString = window.atob(file.contentBase64)

        const arrayBuffer = new ArrayBuffer(binaryString.length)
        const uint8Array = new Uint8Array(arrayBuffer)

        for (let i = 0; i < binaryString.length; i++) {
          uint8Array[i] = binaryString.charCodeAt(i)
        }

        const blob = new Blob([uint8Array], { type: 'text/csv' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = file.name || 'download.csv'
        link.click()
      }

      Swal.close()
      showAlert({
        title: 'Sucesso!',
        text: 'Os dados foram baixados com sucesso.',
        icon: 'success',
      })
    } catch (error) {
      Swal.close()
      const errorMessage =
        error instanceof Error ? error.message : 'Houve um erro ao baixar os dados.'
      showAlert({ title: 'Erro!', text: errorMessage, icon: 'error' })
    }
  }

  return (
    <div className='border-0 pt-6 px-6'>
      <RelatorioListSearchComponent />
      <div className='card-toolbar pt-3'>
        <SelectionHeaderContainer>
          <Button className='btn-primary me-2' icon={IconPaths.Pdf} onClick={downloadPdf}>
            Baixar em PDF
          </Button>
          <Button className='btn-primary' icon={IconPaths.Csv} onClick={downloadCsv}>
            Baixar em csv
          </Button>
        </SelectionHeaderContainer>
      </div>
    </div>
  )
}

export { RelatorioListHeader }
