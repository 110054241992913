import { AxiosError } from 'axios'
import { useQueryClient, useMutation } from 'react-query'
import Swal from 'sweetalert2'
import { onDeleteAlert, QUERIES } from '../../../../../../../_metronic/helpers'
import { useListView } from '../../core/ListViewProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { deleteSelectedEmpresaCliente } from '../../../empresas-list/core/_requests'
import { Button, SelectionHeaderContainer } from '../../../../../../components'

const EmpresasClienteListGrouping = () => {
  const { selected, clearSelected } = useListView()
  const queryClient = useQueryClient()
  const { query } = useQueryResponse()

  const deleteSelectedItems = useMutation(() => deleteSelectedEmpresaCliente(selected), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.EMPRESAS_LIST}-${query}`])
      clearSelected()
    },
    onError(error, variables, context) {
      const err = error as AxiosError
      Swal.fire({
        text: err.response?.data.data.message,
        icon: 'error',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  })

  const handleDelete = () => onDeleteAlert(deleteSelectedItems.mutateAsync)

  return (
    <SelectionHeaderContainer selected={selected}>
      <Button className='btn btn-danger' onClick={handleDelete}>
        Remover selecionada(s)
      </Button>
    </SelectionHeaderContainer>
  )
}

export { EmpresasClienteListGrouping }
