import axios, { AxiosResponse } from 'axios'
import { AvaliacoesQueryResponse } from './_models'
import { ID } from '../../../../../../_metronic/helpers'

const API_URL = process.env.REACT_APP_API_URL
const AVALIACOES_RESPONSAVEL_URL = `${API_URL}/atendimentos/formulario/responsavel`
const AVALIACOES_URL = `${API_URL}/atendimentos/formulario`

export const getAvaliacoesUnsigned = (query: string): Promise<AvaliacoesQueryResponse> => {
  return axios
    .get(`${AVALIACOES_URL}/pendente?${query}`)
    .then((d: AxiosResponse<AvaliacoesQueryResponse>) => d.data)
}

export const signAvaliacaoLote = (avaliacoesIds: ID[], senha: String): Promise<void> => {
  return axios.put(`${AVALIACOES_RESPONSAVEL_URL}/assinar`, {
    formulariosAtendimento: avaliacoesIds,
    senha,
  })
}

export const signAvaliacao = (
  { formularioId: formulario, atendimentoId: atendimento }: { formularioId: ID; atendimentoId: ID },
  senha: String
): Promise<void> => {
  return axios.patch(`${AVALIACOES_RESPONSAVEL_URL}/assinar`, { atendimento, formulario, senha })
}
