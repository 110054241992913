import { useQueryClient, useMutation } from 'react-query'
import { useListView } from '../../core/ListViewProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { Button, SelectionHeaderContainer } from '../../../../../../components'
import { IconPaths } from '../../../../../../../_metronic/helpers'
import { useAuth } from '../../../../../auth'
import Swal from 'sweetalert2'

const HistoricoListGrouping = () => {
  const user = useAuth().currentUser
  const possuiCertificado = user?.pessoa?.certificadoDigital

  const { selected } = useListView()

  const modalPassword = () => {
    if (possuiCertificado) {
      Swal.fire({
        title: 'Digite a senha do Certificado',
        input: 'password',
        // inputLabel: "Senha",
        inputPlaceholder: 'Digite a senha do Certificado',
        padding: 10,
        confirmButtonColor: '#46216D',
        confirmButtonText: 'Enviar',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        inputAttributes: {
          maxlength: '16',
          autocapitalize: 'off',
          autocorrect: 'off',
        },
        preConfirm: async (senha) => {
          if (!senha || senha.length <= 6) {
            Swal.showValidationMessage(`A senha deve conter no mínimo 6 caracteres`)
          } else {
            Swal.showLoading()
            /* singSelectedAso(selected, senha).then(() => {
              Swal.fire("ASOs assinados!", "", "success");
            }).catch((error) => {
              const err = error as AxiosError;
              Swal.fire({
                text: err.response?.data.data.message,
                icon: 'error',
                buttonsStyling: false,
                confirmButtonText: "Ok",
                customClass: {
                  confirmButton: "btn btn-primary"
                }
              })
            }) */
            Swal.hideLoading()
          }
          return { senha }
        },
      })
    } else {
      Swal.fire({
        text: 'Informe primeiramente o seu certificado na edição do perfil.',
        icon: 'error',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    }
  }

  return (
    <SelectionHeaderContainer selected={selected}>
      <Button
        className='btn-secondary btn-active-secondary-primary'
        onClick={modalPassword}
        icon={IconPaths.Key}
      >
        Assinar Avaliação
      </Button>
    </SelectionHeaderContainer>
  )
}

export { HistoricoListGrouping }
