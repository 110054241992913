import React, { FC, useEffect, useState } from 'react'
import {
  ArquivoDto,
  AtendimentoView,
  DispositivoList,
  InformacaoAtendimentoView,
} from '../../../atendimento/core/_models'
import { downloadAnexo, getAtendimentoById } from '../../../atendimento/core/_requests'
import { Button, CustomCarousel, LoadingIndicator } from '../../../../../components'
import { ListLoading } from '../../../commons/components/ListLoading'

export type Leitura = {
  descricao: string
  anexo: ArquivoDto | null
  valor: number | null
  unidade: string | null
  dataFormatada: string
  data: Date
}

type IData = {
  dispositivo: DispositivoList
  leituras: Leitura[]
}

const AtendimentoShowDispositivos: FC<{ atendimento?: AtendimentoView }> = ({ atendimento }) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<IData[]>([])

  const [showCarousel, setShowCarousel] = useState(false)
  const [carouselItems, setCarouselItems] = useState<
    { mediaUrl: string; title: string; type: string }[]
  >([])
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(undefined)

  const atualizarAtendimento = async () => {
    if (!atendimento) return

    setLoading(true)
    try {
      const result = await getAtendimentoById(atendimento.id)

      const dispAux = await processInformacoes(result?.informacoes || [])
      setData(dispAux)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    atualizarAtendimento()
  }, [atendimento])

  const processInformacoes = async (informacoes: InformacaoAtendimentoView[]) => {
    const dispAux: IData[] = []

    await Promise.all(
      informacoes.map(async (informacao: InformacaoAtendimentoView) => {
        const anexo = informacao.anexo
          ? await downloadAnexo(atendimento?.id, informacao.anexo)
          : null

        const leitura: Leitura = {
          descricao: informacao.descricao,
          anexo,
          valor: informacao.valor,
          unidade: informacao.unidade,
          dataFormatada: informacao.dataFormatada,
          data: new Date(informacao.data),
        }

        const dispositivoId = informacao.dispositivo?.id
        let info = dispAux.find((d) => d.dispositivo.id === dispositivoId)

        if (info) {
          info.leituras.push(leitura)
        } else {
          dispAux.push({
            dispositivo: informacao.dispositivo,
            leituras: [leitura],
          })
        }
      })
    )

    return dispAux
  }

  const openCarousel = (
    items: { mediaUrl: string; title: string; type: string }[],
    selectedItemIndex: number
  ) => {
    setCarouselItems(items)
    setSelectedIndex(selectedItemIndex)
    setShowCarousel(true)
  }

  const collectAnexoUrls = (
    leituras: Leitura[]
  ): { mediaUrl: string; title: string; type: string }[] => {
    return leituras
      .filter((leitura) => leitura.anexo)
      .map((leitura) => ({
        mediaUrl: `data:${leitura.anexo?.type};base64,${leitura.anexo?.contentBase64}`,
        title: `${leitura.descricao}: (${leitura.dataFormatada})`,
        type: leitura.anexo?.type as string,
      }))
  }

  const handleModalClose = (event: React.MouseEvent) => {
    if (event.target === event.currentTarget) setShowCarousel(false)
  }

  return (
    <div>
      {!loading ? (
        data.map((d) => (
          <div key={d.dispositivo.id} style={{ minHeight: 300 }}>
            <h2>{d.dispositivo.descricao}</h2>
            {d.leituras.map((leitura, index) => {
              return (
                <div key={index}>
                  {leitura.anexo ? (
                    <div className='row mb-3 align-items-center'>
                      <div className='col-lg-10'>
                        <span>
                          {' '}
                          {leitura.descricao}: ({leitura.dataFormatada})
                        </span>
                      </div>
                      <div className='col-lg-2'>
                        <Button
                          className='btn-sm btn-primary w-100'
                          onClick={() => {
                            const mediaUrls = collectAnexoUrls(d.leituras)
                            const selectedIndex = mediaUrls.findIndex(
                              (item) =>
                                item.mediaUrl ===
                                `data:${leitura.anexo?.type};base64,${leitura.anexo?.contentBase64}`
                            )
                            openCarousel(mediaUrls, selectedIndex)
                          }}
                        >
                          {leitura.anexo.type.startsWith('image') ? 'Ver Imagem' : 'Ver Vídeo'}
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <p>
                      {leitura.descricao}: {leitura.valor} {leitura.unidade} (
                      {leitura.dataFormatada})
                    </p>
                  )}
                </div>
              )
            })}
          </div>
        ))
      ) : (
        <ListLoading />
      )}
      {showCarousel && (
        <div
          className='modal show d-flex align-item-center justify-content-center'
          style={{
            display: 'block',
            zIndex: 1050,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
            height: '100%',
          }}
          onClick={handleModalClose}
        >
          <div
            className='modal-dialog'
            onClick={(e) => e.stopPropagation()}
            style={{
              position: 'relative',
              width: '80%',
              height: '80%',
              maxWidth: '1000px',
              margin: '0 auto',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div className='modal-content'>
              <div className='modal-body'>
                <CustomCarousel items={carouselItems} initialIndex={selectedIndex} />
              </div>
            </div>
          </div>
        </div>
      )}
      <button
        type='button'
        className='btn btn-primary mb-3'
        disabled={loading}
        onClick={atualizarAtendimento}
      >
        {loading ? <LoadingIndicator /> : <span>Atualizar Informações</span>}
      </button>
    </div>
  )
}

export { AtendimentoShowDispositivos }
