import { useListView } from '../../core/ListViewProvider'
import { CargoClienteListGrouping } from './CargoClienteListGrouping'
import { CargoClienteListSearchComponent } from './CargoClienteListSearchComponent'
import { CargoClienteListToolbar } from './CargoClienteListToolbar'

const CargoClienteListHeader = () => {
  const { selected } = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <CargoClienteListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <CargoClienteListGrouping /> : <CargoClienteListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export { CargoClienteListHeader }
