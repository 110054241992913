import { KTCard } from '../../../../../_metronic/helpers'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'
import { ListViewProvider } from './core/ListViewProvider'
import { AvaliacoesConcluidasListHeader } from './components/header/AvaliacoesConcluidasListHeader'
import { AvaliacoesConcluidasTable } from './table/AvaliacoesConcluidasTable'
const AvaliacoesConcluidasList = () => {
  return (
    <>
      <KTCard>
        <AvaliacoesConcluidasListHeader />
        <AvaliacoesConcluidasTable />
      </KTCard>
    </>
  )
}

export const AvaliacoesConcluidasListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <AvaliacoesConcluidasList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)
