import axios, {AxiosResponse} from 'axios'

import {ImportacoesQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const IMPORTACAO_URL = `${API_URL}/importacao`

const getImportacoes = (query: string): Promise<ImportacoesQueryResponse> => {
  return axios
    .get(`${IMPORTACAO_URL}?${query}`)
    .then((d: AxiosResponse<ImportacoesQueryResponse>) => d.data)
}

function createImportacao(
  idEmpresa: number,
  descricao: string,
  firstRow: boolean,
  arquivo: File
) { 
  const formData = new FormData();
  formData.append('idEmpresa', String(idEmpresa));
  formData.append('descricao', descricao);
  formData.append('firstRowContainsColNames', String(firstRow));
  formData.append('arquivo', arquivo);
  const config = {
    headers: {
        'content-type': 'multipart/form-data'
    }
}
  return axios.post<{ data: any }>(`${IMPORTACAO_URL}/atendimentos/excel`, formData, config);
}

export {getImportacoes, createImportacao}
