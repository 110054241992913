export enum IconPaths {
  Add = '/media/icons/duotune/arrows/arr075.svg',
  NewFile = '/media/icons/duotune/files/fil005.svg',
  Search = '/media/icons/duotune/general/gen004.svg',
  Pdf = '/media/icons/duotune/files/fil003.svg',
  Csv = '/media/icons/duotune/files/fil002.svg',
  Close = '/media/icons/duotune/arrows/arr061.svg',
  Trash = '/media/icons/duotune/general/gen027.svg',
  Gear = '/media/icons/duotune/coding/cod001.svg',
  Key = '/media/general/key.svg',
  Arrow = '/media/icons/duotune/arrows/arr072.svg',
  CloudDownload = '/media/icons/duotune/files/fil021.svg',
  Download = '/media/icons/duotune/arrows/arr044.svg',
  ArrowDown = '/media/icons/duotune/arrows/arr072.svg',
  ArrowRight = '/media/icons/duotune/arrows/arr071.svg',
  ArrowLeft = '/media/icons/duotune/arrows/arr074.svg',
  ArrowUp = '/media/icons/duotune/arrows/arr073.svg',
  Record = '/media/icons/duotune/communication/com016.svg',
}

export enum ModalSize {
  Small = 'modal-sm',
  Medium = 'modal-md',
  Large = 'modal-lg',
  ExtraLarge = 'modal-xl',
}

export enum IconSize {
  Small = 3,
  Medium = 2,
  Large = 1,
}

export enum ButtonType {
  Submit = 'submit',
  Reset = 'reset',
  Button = 'button',
}

export enum GridCols {
  One = 'col-lg-12 col-md-12',
  Two = 'col-lg-6 col-md-12',
  Three = 'col-lg-4 col-md-12',
  Four = 'col-lg-3 col-md-12',
  Six = 'col-lg-2 col-md-12',
}

export enum Status {
  Ativo = 'true',
  Inativo = 'false',
}

export enum InputType {
  Select = 'SELECT',
  TextEditor = 'EDITOR',
  Radio = 'RADIO',
  Number = 'NUMERO',
  Text = 'TEXTO',
  Data = 'DATA',
}

export enum AnexoType {
  Memed = 5,
}

export enum Permission {
  Medico = 'EMPLOYEE',
  Usuario = 'USER',
  Admin = 'ADMIN',
  SuperAdmin = 'SUPER_ADMIN',
  SuperMedico = 'SUPER_MEDICO',
  SuperGerente = 'SUPER_GERENTE',
}

export enum FormularioSituacao {
  Iniciado = 'INICIADO',
  Respondido = 'RESPONDIDO',
  Cancelado = ' CANCELADO',
  Gerado = 'GERADO',
  Assinado = 'ASSINADO',
  Finalizado = 'FINALIZADO',
}

export enum Tipo {
  PA = 'PA',
  ASO = 'ASO',
  SC = 'SC',
  TK = 'TK',
  CA = 'CA',
}

export enum DocumentoType {
  Cpf = 'CPF',
  Cnpj = 'CNPJ',
  Caepf = 'CAEPF',
}

export enum FatorRiscoType {
  FISICO = 'FISICO',
  QUIMICO = 'QUIMICO',
  BIOLOGICO = 'BIOLOGICO',
  ERGONOMICO = 'ERGONOMICO',
  ACIDENTE = 'ACIDENTE',
}

export enum CargoType {
  Responsavel = 'RESPONSAVEL',
  Colaborador = 'COLABORADOR',
  Medico = 'MEDICO',
}

export enum AtendimentoType {
  Assistencial = 'PA',
  Ocupacional = 'ASO',
  Especialista = 'CA',
  Screening = 'SC',
  Telekit = 'TK',
}

export enum ResumoAtendimentoType {
  PA = 'Assistencial',
  ASO = 'Ocupacional',
  CA = 'Especialista',
  SC = 'Screening',
  TK = 'O que singifica',
}

export enum AtendimentoEstado {
  Aguardando = 1,
  EmAtendimento = 2,
  Finalizado = 3,
  NãoCompareceu = 4,
  Cancelado = 5,
}

export enum DispositivoType {
  Balanca = 'BALANCA',
  Oximetro = 'OXIMETRO',
  Termometro = 'TERMOMETRO',
  Pressao = 'PRESSAO',
  Camera = 'CAMERA',
  Otoscopio = 'OTOSCOPIO',
  Inspecao = 'INSPECAO',
  Glicosimetro = 'GLICOSIMETRO',
}

export enum AsoType {
  Periodico = 'PERIODICO',
  Admissiona = 'ADMISSIONAL',
  Demissional = 'DEMISSIONAL',
  Funcao = 'FUNCAO',
  Risco = 'RISCO',
  Retorno = 'RETORNO',
  Monitoracao = 'MONITORACAO',
}

export enum SituacaoAso {
  Iniciado = 'INICIADO',
  Atendido = 'ATENDIDO',
  Falta = 'FALTA',
  Gerado = 'GERADO',
  Finalizado = 'FINALIZADO',
  Cancelado = 'CANCELADO',
}

export enum ResultadoType {
  Apto = 'APTO',
  AptoComRestricao = 'APTO_COM_RESTRICAO',
  Inapto = 'INAPTO',
  InaptoTemporario = 'INAPTO_TEMPORARIO',
}

export enum GenderType {
  Masculino = 'MASCULINO',
  Feminino = 'FEMININO',
}
