import { FC, useEffect, useState } from 'react'
import { ID, KTCard } from '../../../../../../../_metronic/helpers'
import { ListLoading } from '../../../../commons/components/ListLoading'
import { FormularioModal } from './FormularioModal'
import { getFormularioAtendimento } from '../../../../atendimento/core/_requests'
import { useSearchParams } from 'react-router-dom'
import { FormularioView } from '../../../../formularios/formularios-create/core/_models'
import { FormularioAtendimentoView } from '../../core/_models'

type IFormularioModal = {
  formId: ID
  onHide: () => void
  onAnswered: () => void
}

export const FormularioModalWrapper: FC<IFormularioModal> = ({ formId, onHide, onAnswered }) => {
  const [formularioAtendimento, setFormularioAtendimento] = useState<FormularioAtendimentoView>()
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const [searchParams] = useSearchParams()
  const atendimentoId = Number(searchParams.get('atendimento'))

  useEffect(() => {
    const fetchData = async () => {
      try {
        const formularioAtendimento = await getFormularioAtendimento(atendimentoId, formId)

        setFormularioAtendimento(formularioAtendimento)

        setIsLoading(false)
      } catch (error) {
        console.error('Error fetching formulario:', error)
        setIsLoading(false)
      }
    }

    fetchData()
  }, [])

  return (
    <KTCard>
      {isLoading || !formularioAtendimento ? (
        <ListLoading />
      ) : (
        <>
          <FormularioModal
            formularioAtendimento={formularioAtendimento}
            isLoading={isLoading}
            onHide={onHide}
            onAnswered={onAnswered}
          />
        </>
      )}
    </KTCard>
  )
}
