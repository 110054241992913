/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import {
  initialQueryState,
  OptionsHelper,
  useDebounce,
} from '../../../../../../../_metronic/helpers'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { CustomSelect, Input, Label } from '../../../../../../components'
import { IStatusOptions } from '../../../../formularios/formularios-list/component/header/FormulariosListSearchComponent'

const EmpresasListSearchComponent = () => {
  const { updateState } = useQueryRequest()
  const [searchTerm, setSearchTerm] = useState<string>('')

  const [status, setStatus] = useState<IStatusOptions>()
  const [empresa, setEmpresa] = useState<string>('')

  const debouncedSearchTerm = useDebounce(searchTerm, 150)

  useEffect(() => {
    if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
      updateState({
        search: debouncedSearchTerm,
        sort: 'nomeFantasia',
        order: 'asc',
        ...initialQueryState,
      })
    }
  }, [debouncedSearchTerm])

  const buildSearchQuery = (empresa: string, status?: IStatusOptions): string => {
    const statusQuery = status ? `ativa:${status.value},` : ''
    const searchQuery = empresa ? `nome~*${empresa}*` : ''
    return `${statusQuery}${searchQuery}`
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmpresa(e.target.value)
    setSearchTerm(buildSearchQuery(e.target.value, status))
  }

  const handleStatus = (stat: IStatusOptions) => {
    setStatus(stat)
    setSearchTerm(buildSearchQuery(empresa, stat))
  }

  return (
    <div className='d-flex align-items-center flex-grow-1'>
      <div className='col-12 col-sm-7 col-md-6 mb-5 me-0 me-sm-2'>
        <Label className='pb-2'>Empresa</Label>
        <Input placeholder='Digite a empresa' onChange={onChange} />
      </div>
      <div className='col-12 col-sm-5 col-md-2 mb-5'>
        <Label className='pb-2'>Situação</Label>
        <CustomSelect
          placeholder='Selecione'
          onChange={handleStatus}
          options={OptionsHelper.status}
          value={status}
        />
      </div>
    </div>
  )
}

export { EmpresasListSearchComponent }
