import { Outlet, Route, Routes } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { EmpresasClienteListWrapper } from './empresasCliente-list/EmpresasClienteList'
import { CargoClienteListWrapper } from './empresasClienteCargo-list/CargoClienteList'
import { CoordenadorClienteListWrapper } from './empresasClienteCoordenador-list/CoordenadorClienteList'
import { SetorClienteListWrapper } from './empresasClienteSetor-list/SetorClienteList'
import { RiscoClienteListWrapper } from './empresasClienteRiscos-list/RiscoClienteList'
import { ExameClienteListWrapper } from './empresasClienteExames-list/ExameClienteList'

const empresasClienteBreadcrumbs: Array<PageLink> = [
  {
    title: 'Empregador ASO',
    path: '/apps/empresasCliente/lista',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const EmpresasClientePage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='lista'
          element={
            <>
              <PageTitle /* breadcrumbs={empresasClienteBreadcrumbs} */>Empregador ASO</PageTitle>
              <EmpresasClienteListWrapper />
            </>
          }
        />
        <Route
          path='cargos'
          element={
            <>
              <PageTitle breadcrumbs={empresasClienteBreadcrumbs}>Cargos</PageTitle>
              <CargoClienteListWrapper />
            </>
          }
        />
        <Route
          path='setores'
          element={
            <>
              <PageTitle breadcrumbs={empresasClienteBreadcrumbs}>Setores</PageTitle>
              <SetorClienteListWrapper />
            </>
          }
        />
        <Route
          path='coordenadores'
          element={
            <>
              <PageTitle breadcrumbs={empresasClienteBreadcrumbs}>Coordenadores</PageTitle>
              <CoordenadorClienteListWrapper />
            </>
          }
        />
        <Route
          path='riscos'
          element={
            <>
              <PageTitle breadcrumbs={empresasClienteBreadcrumbs}>Riscos</PageTitle>
              <RiscoClienteListWrapper />
            </>
          }
        />
        <Route
          path='exames'
          element={
            <>
              <PageTitle breadcrumbs={empresasClienteBreadcrumbs}>Exames</PageTitle>
              <ExameClienteListWrapper />
            </>
          }
        />
      </Route>
      {/* <Route index element={<Navigate to='/apps/empresas/lista' />} /> */}
    </Routes>
  )
}

export default EmpresasClientePage
