/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useState, useEffect, useMemo } from 'react'
import { useQuery } from 'react-query'
import {
  createResponseContext,
  getParameterByName,
  ID,
  initialListView,
  initialQueryResponse,
  initialQueryState,
  PaginationState,
  QUERIES,
  stringifyRequestQuery,
  WithChildren,
} from '../../../../../../_metronic/helpers'
import { getPacientes, getPacientesEmpresa } from './_requests'
import { PacienteList } from './_models'
import { useQueryRequest } from './QueryRequestProvider'
import { useSearchParams } from 'react-router-dom'

const QueryResponseContext = createResponseContext<PacienteList>(initialQueryResponse)
const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const [searchParams] = useSearchParams()
  const { idPaciente } = useQueryResponse()
  const idEmpresa = String(searchParams.get('empresa'))
  const { state } = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])
  const idEmpresaParam = getParameterByName('filter_0', query)
  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.PACIENTE_LIST}-${query}`,
    () => {
      if (idEmpresaParam) {
        return getPacientesEmpresa(idEmpresaParam, query)
      }
      return getPacientes(query)
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  )

  return (
    <QueryResponseContext.Provider
      value={{ isLoading: isFetching, refetch, response, query, idEmpresa, idPaciente }}
    >
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const { response } = useQueryResponse()
  if (!response) {
    return []
  }

  return response?.data || []
}

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const { response } = useQueryResponse()
  if (!response) {
    return defaultPaginationState
  }
  const useQueryResponseIdEmpresa = (): string => {
    const { idEmpresa } = useQueryResponse()
    return idEmpresa
  }
  const responsePaginationState: PaginationState = {
    page: response?.page,
    size: response.size,
    totalElements: response.totalElements,
  }

  return responsePaginationState
}

const useQueryResponseLoading = (): boolean => {
  const { isLoading } = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
}
