import { ImportacaoErroListHeader } from "./ImportacaoErroListHeader"
import { ImportacaoErroListTable } from "./component/table/ImportacaoErroListTable"
import { KTCard } from "../../../../../../_metronic/helpers"

export const ImportacaoErroList = () => {
    //const {itemIdForUpdate} = useListView()

    return (
      <>
        <KTCard>
          <ImportacaoErroListHeader />
          <ImportacaoErroListTable />
        </KTCard>
      </>
    )
  }