import { PaginationState, QUERIES, WithChildren, createResponseContext, initialQueryResponse, initialQueryState, stringifyRequestQuery } from '../../../../../../_metronic/helpers';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { ImportacaoErro } from './_models';
import { getErrosImportacoes } from './_requests';
import { useAuth } from '../../../../auth';
import { useParams } from 'react-router-dom';
import { useQueryRequest } from './QueryRequestProvider';

const QueryResponseContext = createResponseContext<ImportacaoErro>(initialQueryResponse);

const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const [refresh, setRefresh] = useState('');
  const auth = useAuth();
  const { state } = useQueryRequest();
  const queryClient = useQueryClient()
  const [query, setQuery] = useState<string>(decodeURIComponent(stringifyRequestQuery(state)));
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);
  const [idEmpresa, setIdEmpresa] = useState('');
  const [idPaciente, setIdPaciente] = useState('');
  const { id } = useParams();


  useEffect(() => {
    if (query !== updatedQuery) {
      getErrosImportacoes(Number(id), query).catch((error) => {
        console.log(error);
      });
      setQuery(decodeURIComponent(updatedQuery));
    }
  }, [query, updatedQuery]);


  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.IMPORTACOES_ERROS_LIST}-${query}`,
    () => (getErrosImportacoes(Number(id), query)),
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  return (
    <QueryResponseContext.Provider value={{ isLoading: isFetching, refetch, response, query, idEmpresa, idPaciente }}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);

const useQueryResponseData = () => {
  const { response } = useQueryResponse();
  if (!response) {
    return [];
  }
  return response?.data || [];
};

const useQueryResponsePagination = (): PaginationState => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  };

  const { response } = useQueryResponse();
  if (!response) {
    return defaultPaginationState;
  }

  const responsePaginationState: PaginationState = {
    page: response?.page,
    size: response.size,
    totalElements: response.totalElements,
  };

  return responsePaginationState;
};

const useQueryResponseLoading = (): boolean => {
  const { isLoading } = useQueryResponse();
  return isLoading;
};

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
};
