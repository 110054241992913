import { ColumnInstance, Row, useTable } from 'react-table'
import { useQueryResponseData, useQueryResponseLoading } from '../core/QueryResponseProvider'

import { Importacao } from '../core/_models'
import { ImportacaoListPagination } from '../components/pagination/ImportacaoListPagination'
import { KTCardBody } from '../../../../../../_metronic/helpers'
import React from 'react'
import { importacoesColumns } from './columns/_columns'
import { useMemo } from 'react'
import { CustomHeaderColumn } from '../../../commons/table/CustomHeaderColumn'
import { CustomRow } from '../../../commons/table/CustomRow'

const ImportacaoListTable = () => {
  const importacoes = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => importacoes, [importacoes])
  const columns = useMemo(() => importacoesColumns, [])
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns,
    data: data as Importacao[],
  })

  return (
    <KTCardBody className='py-4'>
      {isLoading ? (
        'Carregando...'
      ) : (
        <div className='table-responsive'>
          <table
            id='kt_table_importacoes'
            className='table align-middle table-row-bordered fs-6 gy-5 dataTable no-footer'
            {...getTableProps()}
          >
            <thead>
              <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0 border-secondary'>
                {headers.map((column: ColumnInstance<Importacao>) => (
                  <CustomHeaderColumn key={column.id} column={column} />
                ))}
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map((row: Row<Importacao>, i) => {
                  prepareRow(row)
                  return <CustomRow row={row} key={`row-${i}-${row.id}`} />
                })
              ) : (
                <tr>
                  <td colSpan={columns.length}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      Nenhum registro encontrado
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
      <ImportacaoListPagination />
    </KTCardBody>
  )
}

export { ImportacaoListTable }
